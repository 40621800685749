import {RoleModel} from '../../../_models/role.model';

export class LoadControlUsers {
  public static readonly type = '[Control] LoadControlUsers';

  constructor(
    public page?: number,
    public role?: string,
    public schools?: string,
    public beginDate?: string,
    public endDate?: string,
  ) {}
}

export class SearchControlUsers {
  public static readonly type = '[Control] SearchControlUsers';

  constructor(public search: string) {}
}

export class UpdateControlUsersPage {
  public static readonly type = '[Control] UpdateControlUsersPage';

  constructor(public page?: number) {}
}

export class LoadControlRoles {
  public static readonly type = '[Control] LoadControlRoles';

  constructor() {}
}

export class DeleteControlUser {
  public static readonly type = '[Control] DeleteControlUser';

  constructor(public payload: { userID: string }) {}
}

export class CreateControlRole {
  public static readonly type = '[Control] CreateControlRole';

  constructor(public payload: { data: RoleModel }) {}
}

export class DeleteControlRoles {
  public static readonly type = '[Control] DeleteControlRoles';

  constructor(public payload: { roleID: string }) {}
}

export class EditControlRoles {
  public static readonly type = '[Control] EditControlRoles';

  constructor(public payload: { data: any }) {}
}

export class LoadControlGrades {
  public static readonly type = '[Control] LoadControlGrades';

  constructor() {}
}

export class CreateControlGrade {
  public static readonly type = '[Control] CreateControlGrade';

  constructor(public payload: { data: { title: string } }) {}
}

export class DeleteControlGrade {
  public static readonly type = '[Control] DeleteControlGrade';

  constructor(public payload: { gradeID: string }) {}
}

export class EditControlGrade {
  public static readonly type = '[Control] EditControlGrade';

  constructor(public payload: { data: any }) {}
}

export class LoadControlCategories {
  public static readonly type = '[Control] LoadControlCategories';

  constructor(public page: number) {}
}

export class LoadControlQuestions {
  public static readonly type = '[Control] LoadControlQuestions';

  constructor(public page: number) {}
}

export class LoadControlVideos {
  public static readonly type = '[Control] LoadControlVideos';

  constructor(public page: number) {}
}

export class LoadControlWorksheets {
  public static readonly type = '[Control] LoadControlWorksheets';

  constructor(public search: string, public page: number, public grade: string) {}
}

export class LoadControlBooks {
  public static readonly type = '[Control] LoadControlBooks';

  constructor() {}
}

export class LoadControlSchools {
  public static readonly type = '[Control] LoadControlSchools';

  constructor(public search: string, public page: number, public sort: string, public state: string) {}
}

export class LoadControlSchoolsWithoutLicense {
  public static readonly type = '[Control] LoadControlSchoolsWithoutLicense';

  constructor(
    public search: string,
    public page: number,
    public sort: string,
    public state: { state_code: string; state_name: string },
  ) {}
}

export class DeleteControlSchool {
  public static readonly type = '[Control] DeleteControlSchool';

  constructor(public payload: { schoolID: string }) {}
}

export class LoadControlSchoolsLicense {
  public static readonly type = '[Control] LoadControlSchoolsLicense';

  constructor() {}
}

export class DeleteControlSchoolsLicense {
  public static readonly type = '[Control] DeleteControlSchoolsLicense';

  constructor(public _id: string) {}
}

export class LoadControlDashboardData {
  public static readonly type = '[Control] LoadControlDashboardData';

  constructor() {}
}

export class LoadControlDashboardSchoolData {
  public static readonly type = '[Control] LoadControlDashboardSchoolData';

  constructor(public schoolID: string, public userID?: string) {}
}

export class ClearControlDashboardSchoolData {
  public static readonly type = '[Control] ClearControlDashboardSchoolData';

  constructor() {}
}

export class LoadControlUserData {
  public static readonly type = '[Control] LoadControlUserData';

  constructor(public _id: string) {}
}

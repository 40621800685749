import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API} from '../../api';
import {CategoryModel} from "../../../_models/category.model";
import {VideoModel} from "../../../_models/video.model";
import {QuestionModel} from "../../../_models/question.model";

@Injectable({
  providedIn: 'root',
})
export class PracticeService {
  constructor(private http: HttpClient) {}

  public getExams() {
    return this.http.get<CategoryModel[]>(API.url('exams'));
  }
  public getGrades() {
    return this.http.get<CategoryModel[]>(API.url('grades'));
  }
  public getCurrentCategories() {
    return this.http.get<CategoryModel[]>(API.url('categories/tree/list'));
  }
  public getCategoriesById(_id) {
    return this.http.get<CategoryModel[]>(API.url('categories/'+_id)+'/tree');
  }

  public getCategoryVideos(category_id) {
    return this.http.get<VideoModel[]>(API.url('videos/'+category_id));
  }

  public getVideoById(video_id) {
    return this.http.get<VideoModel>(API.url('videos/video/'+video_id));
  }

  public getCategoryQuestions(category_id) {
    return this.http.get<QuestionModel[]>(API.url('question/parent/'+category_id));
  }
  public getCategoriesProgress() {
    return this.http.get<[]>(API.url('trackers/practice/progress'));
  }
}

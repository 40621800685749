import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {UserState} from "../../_stores/user/_state/user.state";
import {Store} from "@ngxs/store";
import {API} from "../../_stores/api";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'ui-awards',
  templateUrl: './ui-awards.component.html',
  styleUrls: ['./ui-awards.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiAwardsComponent implements OnInit {
  interface: any = {
    user: undefined,
    awards: []
  }
  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) { }

  ngOnInit(): void {
    this.initUser();
    this.initAwards();
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }

  initAwards(): void {
    this.http.get<any[]>(API.url('awards')).subscribe({
      next: (_next) => {
        this.interface.awards = _next;
        this.cdr.detectChanges();
      },
    });
  }


  checkUserAward(award): boolean {
    let _award_status = this.interface.user?.awards.find((a) => a._id == award._id);
    return _award_status;
  }

}

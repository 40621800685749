export class AssignmentsBuilderClearMaterials {
  public static readonly type = '[AssignmentsBuilderClearMaterials] start';
  constructor() {}
}

export class AssignmentsBuilderAddWorksheet {
  public static readonly type = '[AssignmentsBuilderAddWorksheet] start';
  constructor(public payload: { worksheet: any }) {}
}
export class AssignmentsBuilderRemoveWorksheet {
  public static readonly type = '[AssignmentsBuilderRemoveWorksheet] start';
  constructor(public payload: { worksheet: any }) {}
}

export class AssignmentsBuilderAddQuiz {
  public static readonly type = '[AssignmentsBuilderAddQuiz] start';
  constructor(public payload: { quiz: any }) {}
}
export class AssignmentsBuilderRemoveQuiz {
  public static readonly type = '[AssignmentsBuilderRemoveQuiz] start';
  constructor(public payload: { quiz: any }) {}
}

export class AssignmentsBuilderAddDrill {
  public static readonly type = '[AssignmentsBuilderAddDrill] start';
  constructor(public payload: { drill: any }) {}
}
export class AssignmentsBuilderRemoveDrill {
  public static readonly type = '[AssignmentsBuilderRemoveDrill] start';
  constructor(public payload: { drill: any }) {}
}

export class AssignmentsBuilderRemoveMaterial {
  public static readonly type = '[AssignmentsBuilderRemoveDrill] start';
  constructor(public payload: { material: any }) {}
}

export class AssignmentsBuilderAddVideo {
  public static readonly type = '[AssignmentsBuilderAddVideo] start';
  constructor(public payload: { video: any }) {}
}
export class AssignmentsBuilderRemoveVideo {
  public static readonly type = '[AssignmentsBuilderRemoveVideo] start';
  constructor(public payload: { video: any }) {}
}

export class AssignmentsBuilderAddIBook {
  public static readonly type = '[AssignmentsBuilderAddIBook] start';
  constructor(public payload: { i_book: any }) {}
}
export class AssignmentsBuilderRemoveIBook {
  public static readonly type = '[AssignmentsBuilderRemoveIBook] start';
  constructor(public payload: { i_book: any }) {}
}
export class AssignmentsBuilderAddEBook {
  public static readonly type = '[AssignmentsBuilderAddEBook] start';
  constructor(public payload: { e_book: any }) {}
}
export class AssignmentsBuilderRemoveEBook {
  public static readonly type = '[AssignmentsBuilderRemoveEBook] start';
  constructor(public payload: { e_book: any }) {}
}

<div class="ui-control-question-add-container">
  <div class="ui-form ui-control-question-add-form">
    <div class="ui-control-question-add-form-container">
      <div class="ui-control-question-section-settings">
        <details class="ui-control-question-details">
          <summary>
            <div class="flex flex-row gap-2 text-md text-pap-300">
              <div class="flex justify-center bg-pap-500 rounded-full w-6 h-6"><span
                class="text-white font-bold">1</span></div>
              <p class="text-pap-500 font-semibold">Default fields</p></div>
            <div class="birds">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6 text-pap-200">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
              </svg>
            </div>
          </summary>
          <div class="ui-control-question-add-form-text">
            <div class="ui-form-input-group w-full gap-2">
              <div class="ui-form-text ui-form-text-xs">Title</div>
              <div class="ui-form-input"
                   [class.ui-form-input-error]="interface._cache_error.fields.includes('title')">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     stroke-width="1.5"
                     class="h-6 w-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                </svg>
                <input id="title" name="title" type="text"
                       [value]="interface._cache.validate.title || ''"
                       (keyup)="actionEnterTitle($event)" placeholder="Enter Title">
              </div>
            </div>
            <div class="ui-form-input-group w-full gap-2">
              <div class="ui-form-text ui-form-text-xs">Question full text</div>
              <div class="ui-form-textarea"
                   [class.ui-form-textarea-error]="interface._cache_error.fields.includes('full')">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     stroke-width="1.5"
                     class="h-6 w-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                </svg>
                <textarea id="question_full_text" name="question_full_text" type="text"
                          [value]="interface._cache.validate.full || ''"
                          (keyup)="actionEnterVideoDescription($event)"
                          placeholder="Enter Question Full Text"></textarea>
              </div>
            </div>
            <div class="ui-form-input-group w-full gap-2">
              <div class="ui-form-text ui-form-text-xs">Question except text</div>
              <div class="ui-form-input"
                   [class.ui-form-input-error]="interface._cache_error.fields.includes('except')">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     stroke-width="1.5"
                     class="h-6 w-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                </svg>
                <input id="except" name="except" type="text"
                       [value]="interface._cache.validate.title || ''"
                       (keyup)="actionEnterExcept($event)" placeholder="Enter Question Except Text">
              </div>
            </div>
            <div class="ui-form-input-group w-full gap-2">
              <div class="ui-form-text ui-form-text-xs">Description</div>
              <div class="ui-form-textarea"
                   [class.ui-form-textarea-error]="interface._cache_error.fields.includes('videoDescription')">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     stroke-width="1.5"
                     class="h-6 w-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                </svg>
                <textarea id="question_description" name="question_description" type="text"
                          [value]="interface._cache.validate.description || ''"
                          (keyup)="actionEnterVideoDescription($event)"
                          placeholder="Enter Description"></textarea>
              </div>
            </div>
          </div>
        </details>
      </div>
      <div class="ui-control-question-section-settings">
        <details class="ui-control-question-details">
          <summary>
            <div class="flex flex-row gap-2 text-md text-pap-300">
              <div class="flex justify-center bg-pap-400 rounded-full w-6 h-6"><span
                class="text-white font-semibold">2</span></div>
              <p class="text-pap-500">Option fields</p></div>
            <div class="birds">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6 text-pap-200">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
              </svg>
            </div>
          </summary>
          <div class="ui-control-question-add-form-options">
            <div class="ui-form-input-group ui-form-input-group-row w-full gap-2">
              <div class="flex flex-col gap-2 w-full">
                <div class="ui-form-text ui-form-text-xs">Order</div>
                <div class="ui-form-input"
                     [class.ui-form-input-error]="interface._cache_error.fields.includes('order')">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                       stroke-width="1.5"
                       class="h-6 w-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                  </svg>
                  <input id="order" name="order" type="text"
                         [value]="interface._cache.validate.order || ''"
                         (keyup)="actionEnterTitle($event)" placeholder="Enter Title">
                </div>
              </div>
              <div class="flex flex-col gap-2 w-full">
                <div class="ui-form-text ui-form-text-xs">Complexity</div>
                <div class="ui-form-select">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor"
                       stroke-width="1.5">
                    <path d="M12 14l9-5-9-5-9 5 9 5z" />
                    <path
                      d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                  </svg>
                  <input id="complexity" name="complexity" type="text"
                         [value]="interface._cache.validate.complexity_selected || ''"
                         (click)="actionToggleSelectComplexity()" class="capitalize">
                  <div class="ui-form-select-list"
                       [class.ui-form-select-list-open]="interface._cache.open_select_complexities">
                    <div class="ui-form-select-list-items ui-select-school-list">
                      <ng-container *ngIf="interface.complexities.length === 0">
                        <div class="ui-form-select-list-item">
                          <p>You have no types</p>
                          <span>Please add a type</span>
                        </div>
                      </ng-container>
                      <ng-container *ngFor="let complexity of interface.complexities">
                        <div class="ui-form-select-list-item" (click)="actionSelectComplexity(complexity)">
                          <p class="capitalize">{{complexity}}</p>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pointer-events-none" fill="none"
                       viewBox="0 0 24 24"
                       stroke="currentColor" stroke-width="1.5">
                    <path *ngIf="!interface._cache.open_select_complexities" stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19 9l-7 7-7-7" />
                    <path *ngIf="interface._cache.open_select_complexities" stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M5 15l7-7 7 7" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="ui-form-input-group ui-form-input-group-row w-full gap-2">
              <div class="flex flex-col gap-2 w-full">
                <div class="ui-form-text ui-form-text-xs">Type</div>
                <div class="ui-form-select">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor"
                       stroke-width="1.5">
                    <path d="M12 14l9-5-9-5-9 5 9 5z" />
                    <path
                      d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                  </svg>
                  <input id="question_type" name="category_type" type="text"
                         [value]="interface._cache.validate.question_type_selected || ''"
                         (click)="actionToggleSelectQuestionType()" class="capitalize">
                  <div class="ui-form-select-list"
                       [class.ui-form-select-list-open]="interface._cache.open_select_question_types">
                    <div class="ui-form-select-list-items ui-select-school-list">
                      <ng-container *ngIf="interface.question_types.length === 0">
                        <div class="ui-form-select-list-item">
                          <p>You have no types</p>
                          <span>Please add a type</span>
                        </div>
                      </ng-container>
                      <ng-container *ngFor="let question_type of interface.question_types">
                        <div class="ui-form-select-list-item" (click)="actionSelectQuestionType(question_type)">
                          <p class="capitalize">{{question_type}}</p>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pointer-events-none" fill="none"
                       viewBox="0 0 24 24"
                       stroke="currentColor" stroke-width="1.5">
                    <path *ngIf="!interface._cache.open_select_question_types" stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19 9l-7 7-7-7" />
                    <path *ngIf="interface._cache.open_select_question_types" stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M5 15l7-7 7 7" />
                  </svg>
                </div>
              </div>
              <div class="flex flex-col gap-2 w-full">
                <div class="ui-form-text ui-form-text-xs">Answer Type</div>
                <div class="ui-form-select">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor"
                       stroke-width="1.5">
                    <path d="M12 14l9-5-9-5-9 5 9 5z" />
                    <path
                      d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                  </svg>
                  <input id="answer_type" name="answer_type" type="text"
                         [value]="interface._cache.validate.answer_type_selected || ''"
                         (click)="actionToggleSelectAnswerType()" class="capitalize">
                  <div class="ui-form-select-list"
                       [class.ui-form-select-list-open]="interface._cache.open_select_answer_types">
                    <div class="ui-form-select-list-items ui-select-school-list">
                      <ng-container *ngIf="interface.answer_types.length === 0">
                        <div class="ui-form-select-list-item">
                          <p>You have no types</p>
                          <span>Please add a type</span>
                        </div>
                      </ng-container>
                      <ng-container *ngFor="let answer_type of interface.answer_types">
                        <div class="ui-form-select-list-item" (click)="actionSelectAnswerType(answer_type)">
                          <p class="capitalize">{{answer_type}}</p>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pointer-events-none" fill="none"
                       viewBox="0 0 24 24"
                       stroke="currentColor" stroke-width="1.5">
                    <path *ngIf="!interface._cache.open_select_question_types" stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19 9l-7 7-7-7" />
                    <path *ngIf="interface._cache.open_select_question_types" stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M5 15l7-7 7 7" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="ui-form-input-group w-full">
              <div class="ui-form-text ui-form-text-xs">Visible</div>
              <div class="ui-form-radio-group">
                <div class="ui-form-radio ui-form-radio-xs" (click)="actionSelectVisible($event)"
                     [class.ui-form-radio-selected]="interface?._cache?.validate?.visible">
                  <input name="category_visible" type="radio"
                         [checked]="interface?._cache?.validate?.visible">
                  <span>{{'Visible'}}</span>
                </div>
                <div class="ui-form-radio ui-form-radio-xs" (click)="actionSelectVisible($event)"
                     [class.ui-form-radio-selected]="!interface?._cache?.validate?.visible">
                  <input name="category_visible" type="radio"
                         [checked]="!interface?._cache?.validate?.visible">
                  <span>{{'Hidden'}}</span>
                </div>
              </div>
            </div>
            <div class="ui-form-input-group w-full">
              <div class="ui-form-checkbox flex flex-row">
                <input type="checkbox" [checked]="interface._cache.validate.calc">
                <div class="ui-form-text ui-form-text-xs">Calc</div>
              </div>
            </div>
          </div>
        </details>
      </div>
    </div>
    <div class="ui-form-question-preview">
      <ng-container *ngFor="let answer of interface.answers">
        <div
          class="ui-form-question-preview-answer-variant"
          [class.ui-form-question-preview-answer-variant-incorrect]="!answer.isCorrect">
          <div class="letter capitalize text-2xl font-extrabold mr-4">{{answer.letter}}</div>
          <div class="text-variant">{{answer.text}}</div>
          <div class="icon-delete ml-auto">
            <svg (click)="actionDeleteVariantAnswer(answer)" class="w-5 h-5 mr-1 text-red-500"
                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
            </svg>
          </div>
        </div>

      </ng-container>
      <ng-container *ngIf="interface._cache.isAddVariantAnswer">
        <div class="flex items-center gap-4">
          <div class="ui-form-input-group w-full gap-2">
            <div class="ui-form-text ui-form-text-xs">Enter answer`s letter</div>
            <div class="ui-form-input"
                 [class.ui-form-input-error]="interface._cache_error.fields.includes('letter')">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                   stroke-width="1.5"
                   class="h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
              </svg>
              <input id="letter" name="letter" type="text"
                     [value]="interface._cache.validate.title || ''"
                     (keyup)="actionEnterAnswerLetter($event)" placeholder="Enter answer letter">
            </div>
          </div>
          <div class="ui-form-checkbox flex flex-col">
            <div class="ui-form-text ui-form-text-xs">Correct</div>
            <input type="checkbox" [checked]="interface._cache.answer.isCorrect" (click)="actionCheckIsCorrect($event)">
          </div>
        </div>
        <div class="ui-form-input-group w-full gap-2">
          <div class="ui-form-text ui-form-text-xs">Answer text</div>
          <div class="ui-form-textarea"
               [class.ui-form-textarea-error]="interface._cache_error.fields.includes('answer_text')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 stroke-width="1.5"
                 class="h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
            </svg>
            <textarea id="answer_text" name="answer_text" type="text"
                      [value]="interface._cache.validate.description || ''"
                      (keyup)="actionEnterAnswerText($event)"
                      placeholder="Enter answer text"></textarea>
          </div>
        </div>
        <div class="ui-variants-answer-actions">
          <button class="ui-btn ui-btn-icon ui-btn-white ui-btn-lg" (click)="actionVariantAnswerClose()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
            </svg>
            <span>Cancel</span>
          </button>
          <button class="ui-btn ui-btn-icon ui-btn-lg" (click)="actionConfirmVariantAnswer()"
                  [disabled]="interface.loading">
            <svg *ngIf="!interface.loading" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <svg *ngIf="interface.loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span>Save answer variant</span>
          </button>
        </div>
      </ng-container>
      <button *ngIf="!interface._cache.isAddVariantAnswer"
              class="ui-btn ui-btn-icon ui-btn-pap ui-form-question-add-variant-answer"
              (click)="actionAddVariantAnswer()"
              [disabled]="interface.loading">
        <span>Add variant of answer</span>
      </button>
    </div>
  </div>
  <div class="ui-control-question-add-action">
    <button class="ui-data-question-add-action-no" (click)="actionClose()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
      </svg>
      <span>Cancel</span>
    </button>
    <button class="ui-btn ui-control-question-add" (click)="actionAddVideo()"
            [disabled]="interface._cache_error.fields.length > 0">
      <svg *ngIf="!interface.loading" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
           stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
      <svg *ngIf="interface.loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span>{{'buttons.confirm' | transloco}}</span>
    </button>
  </div>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPageReportsComponent} from './ui-page-reports.component';
import {RouterModule} from '@angular/router';
import {UiAppNoDataModule} from '../ui-app-no-data/ui-app-no-data.module';
import {PipesModule} from '../../app.pipes.module';
import {UiTooltipModule} from '../ui-tooltip/ui-tooltip.module';
import {UiDatePickerModule} from '../ui-date-picker/ui-date-picker.module';
import {NgApexchartsModule} from 'ng-apexcharts';

@NgModule({
  declarations: [UiPageReportsComponent],
  exports: [UiPageReportsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: UiPageReportsComponent,
      },
    ]),
    UiAppNoDataModule,
    PipesModule,
    UiTooltipModule,
    UiDatePickerModule,
    NgApexchartsModule,
  ],
})
export class UiPageReportsModule {}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {UiAppNoDataModule} from "../../_ui/ui-app-no-data/ui-app-no-data.module";
import {UiControlSchoolsLicensesComponent} from "./ui-control-schools-licenses.component";
import {UiTooltipModule} from "../../_ui/ui-tooltip/ui-tooltip.module";
import {PipesModule} from "../../app.pipes.module";
import {AppTranslocoModule} from "../../app.transloco.module";
import {UiDatePickerModule} from "../../_ui/ui-date-picker/ui-date-picker.module";


@NgModule({
  declarations: [UiControlSchoolsLicensesComponent],
  exports: [UiControlSchoolsLicensesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiControlSchoolsLicensesComponent,
        children: []
      }
    ]),
    UiAppNoDataModule,
    UiTooltipModule,
    PipesModule,
    AppTranslocoModule,
    UiDatePickerModule
  ]
})
export class UiControlSchoolsLicensesModule { }

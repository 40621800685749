import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {API} from "../../api";

@Injectable({
  providedIn: 'root'
})
export class WorksheetsService {

  constructor(private http: HttpClient) {
  }

  public getWorksheetById(id){
    return this.http.get(API.url('worksheets/' + id))
  }
}

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ModalOpenAction, ModalOpenActionWithID} from '../../_stores/modal/_actions/modal.actions';
import {Store} from '@ngxs/store';
import {UiPageStudentsModel} from '../../_models/ui-page-students.model';
import {UserState} from '../../_stores/user/_state/user.state';
import {GetTeachersStudentsPagination} from '../../_stores/user/_actions/user.actions';

@Component({
  selector: 'ui-page-students',
  templateUrl: './ui-page-students.component.html',
  styleUrls: ['./ui-page-students.component.scss'],
})
export class UiPageStudentsComponent implements OnInit {
  interface: UiPageStudentsModel = {
    _cache: { choose_class: '', open_choose_classes: false, students: [] },
    classes: [],
    totalPage: 0,
    currentPage: 0,
    loading: false,
    students: [],
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new GetTeachersStudentsPagination(1));
  }

  ngOnInit(): void {
    this.initStudents();
    this.initClasses();
    this.cdr.detectChanges();
  }

  initStudents() {
    this.interface.loading = true;
    this.store.select(UserState.selectAllStudentsOfCurrentTeacher).subscribe({
      next: (_students) => {
        this.interface.students = _students;
        this.interface._cache.students = _students;
        // this.interface.currentPage = _students.currentPage;
        // this.interface.totalPage = _students.totalPage;
        this.interface._cache.choose_class = 'All classes';
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }

  actionStudentAdd() {
    this.store.dispatch(new ModalOpenAction('student_add'));
  }
  actionCleverStudentImport(): void {

  }
  actionStudentEdit(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('student_edit', _id, false));
  }
  actionStudentDelete(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('student_delete', _id, false));
  }

  actionSearchStudents(event) {
    if (event.target.value && event.target.value.length > 0) {
      this.interface.students = this.interface.students.filter((item) => {
        return item.displayName.toLowerCase().includes(event.target.value.toLowerCase());
      });
    } else {
      this.interface.students = this.interface._cache.students;
    }
  }

  actionFirstPage(): void {
    this.store.dispatch(new GetTeachersStudentsPagination(1));
  }
  actionPrevPage(): void {
    this.store.dispatch(new GetTeachersStudentsPagination(this.interface.currentPage - 1));
  }
  actionLoadPage(page): void {
    this.store.dispatch(new GetTeachersStudentsPagination(page));
  }
  actionNextPage(): void {
    this.store.dispatch(new GetTeachersStudentsPagination(this.interface.currentPage + 1));
  }
  actionLastPage(): void {
    this.store.dispatch(new GetTeachersStudentsPagination(this.interface.totalPage));
  }

  private initClasses() {
    let map = this.interface.students.map((student) => student.classes[0].title);
    this.interface.classes = Array.from(new Set(map));
  }

  actionToggleChooseClass() {
    this.interface._cache.open_choose_classes = !this.interface._cache.open_choose_classes;
  }

  actionFilteredStudentsByClass(_class) {
    this.actionClearChooseClass();
    this.interface._cache.choose_class = _class;
    this.interface._cache.open_choose_classes = false;
    this.interface.students = this.interface.students.filter((item) => {
      return item.classes[0].title == _class;
    });
  }

  actionClearChooseClass() {
    this.interface._cache.choose_class = 'All classes';
    this.initStudents();
  }
}

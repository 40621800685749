import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timer'
})
export class TimerPipe implements PipeTransform {
  transform(value: number): string {
    const _minutes = Math.floor(value / 60);
    const _seconds = Math.floor(value) - (_minutes * 60);

    const _minutesOut = ((_minutes.toString().length < 2) ? '0' : '') + _minutes;
    const _secondsOut = ((_seconds.toString().length < 2) ? '0' : '') + _seconds;

    return _minutesOut + ':' + _secondsOut;
  }
}

<div class="ui-block ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Assignment</h2>
      <p>You have <strong>{{interface.assignments.length}}</strong> active assignments</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile">
      <button class="ui-btn" routerLink="/assignments">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
        </svg>
        <span>View all assignments</span>
      </button>
    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col py-4">
    <ng-container *ngIf="interface.loading">
      <ui-no-data text="Loading your assignment..."></ui-no-data>
    </ng-container>
    <ng-container *ngIf="!interface.loading">
      <ng-container *ngIf="interface.assignments.length == 0">
        <ng-container  [ngTemplateOutlet]="ASSIGNMENT_EMPTY"></ng-container>
      </ng-container>
      <ng-container *ngIf="interface.assignments.length > 0">
        <ng-container  [ngTemplateOutlet]="ASSIGNMENT_LIST"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
      <button class="ui-btn" routerLink="/assignments">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
        </svg>
        <span>View all assignments</span>
      </button>
    </div>
  </div>
</div>
<ng-template #ASSIGNMENT_EMPTY>
  <ui-no-data type="dino-computer" text="No assignments in your class yet?."></ui-no-data>
</ng-template>
<ng-template #ASSIGNMENT_LIST>
  <div class="ui-assignments-listing">
    <ng-container *ngFor="let assignment of interface.assignments | slice: 0 : 8 | sort:'asc':'title' ; let cl=index">
      <ui-assignment [assignment]="assignment"></ui-assignment>
    </ng-container>
  </div>
</ng-template>

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UiPageClassModel} from '../../../_models/ui-page-class.model';
import {Store} from '@ngxs/store';
import {ClassesState} from '../../../_stores/classes/_state/classes.state';
import {ModalOpenActionWithID} from '../../../_stores/modal/_actions/modal.actions';
import {AssignmentsState} from "../../../_stores/asignments/_state/assignments.state";

@Component({
  selector: 'ui-page-class',
  templateUrl: './ui-page-class.component.html',
  styleUrls: ['./ui-page-class.component.scss'],
})
export class UiPageClassComponent implements OnInit {
  interface: UiPageClassModel = {
    class: undefined,
    assignments: [],
    loading: false,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initClass();
    this.initClassAssignments();
  }

  initClass(): void {
    this.interface.loading = true;
    this.store.select(ClassesState.selectClassByRouteId).subscribe((_class) => {
      if(_class) {
        this.interface.class = _class;
        this.interface.loading = false;
        this.cdr.detectChanges();
      }
    });
  }
  initClassAssignments(): void {
    this.store.select(AssignmentsState.selectAssignmentsByClassRouteId).subscribe((_assignments) => {
      if(_assignments) {
        this.interface.assignments = _assignments;
        this.cdr.detectChanges();
      }
    });
  }

  actionOpenEditClass(): void {
    this.store.dispatch(new ModalOpenActionWithID('class-edit', this.interface.class._id, false));
  }
  actionStudentEdit(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('student_edit', _id, false));
  }
  actionStudentDelete(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('student_delete', _id, false));
  }

  actionAssignmentArchive(assignment, event): void {
    event.currentTarget.parentElement.parentElement.classList.toggle('ui-class-assignment-header-title-action-open');
  }

  actionAssignmentDelete(assignment, event): void {
    event.currentTarget.parentElement.parentElement.classList.toggle('ui-class-assignment-header-title-action-open');
  }

  actionOpenAssignmentMenu(event): void {
    event.currentTarget.parentElement.classList.toggle('ui-class-assignment-header-title-action-open');
  }
}

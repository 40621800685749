import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";
import {ModalState} from "../../_stores/modal/_state/modal.state";
import {ModalCloseAction} from "../../_stores/modal/_actions/modal.actions";
import {DeleteControlSchoolsLicense} from "../../_stores/control/_actions/control.actions";

@Component({
  selector: 'ui-control-license-delete',
  templateUrl: './ui-control-license-delete.component.html',
  styleUrls: ['./ui-control-license-delete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiControlLicenseDeleteComponent implements OnInit {
  interface: any = {
    _id: null
  }

  constructor(private store: Store, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initDataID();
  }

  initDataID() {
    this.store.select(ModalState.selectOpenedID).subscribe((_openedID) => {
      this.interface._id = _openedID;
      this.cdr.detectChanges();
    });
  }

  actionConfirm(): void {
    this.store.dispatch(new DeleteControlSchoolsLicense(this.interface._id));
    this.store.dispatch(new ModalCloseAction());
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }

}

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {ModalCloseAction} from '../../_stores/modal/_actions/modal.actions';
import {CreateVideo} from '../../_stores/videos/_actions/videos.actions';
import {VideosState} from '../../_stores/videos/_state/videos.state';
import {QuestionModel} from '../../_models/question.model';
import {AnswerModel} from '../../_models/answer.model';

export interface UiControlQuestionAddModel {
  answers: AnswerModel[];
  question_types: string[];
  answer_types: string[];
  complexities: string[];
  new_question: QuestionModel;
  _cache: {
    answer: {
      column: null;
      description: string;
      isCorrect: boolean;
      letter: string;
      parentId: string;
      text: string;
      title: string;
    };
    isAddVariantAnswer: boolean;
    open_select_complexities: boolean;
    open_select_question_types: boolean;
    open_select_answer_types: boolean;
    open_complexity: boolean;

    validate: {
      answer_type_selected: string;
      question_type_selected: string;
      complexity_selected: string;
      order: number;
      full: string;
      description: string;
      thumbnail: string;
      title: string;
      slug: string;
      visible: boolean;
      calc: boolean;
    };
  };
  _cache_error: { fields: string };
  loading: boolean;
}

@Component({
  selector: 'ui-control-question-add',
  templateUrl: './ui-control-question-add.component.html',
  styleUrls: ['./ui-control-question-add.component.scss'],
})
export class UiControlQuestionAddComponent implements OnInit {
  public interface: UiControlQuestionAddModel = {
    _cache: {
      answer: {
        column: null,
        description: '',
        isCorrect: false,
        letter: '',
        parentId: '',
        text: '',
        title: '',
      },
      isAddVariantAnswer: false,
      open_complexity: false,
      open_select_answer_types: false,
      open_select_complexities: false,
      open_select_question_types: false,
      validate: {
        answer_type_selected: '',
        calc: false,
        complexity_selected: '',
        description: '',
        full: '',
        order: 0,
        question_type_selected: '',
        slug: '',
        thumbnail: '',
        title: '',
        visible: false,
      },
    },
    answers: [],
    question_types: ['reading', 'text', 'sentence'],
    answer_types: ['input', 'single', 'multiple'],
    complexities: ['easy', 'medium', 'hard'],
    _cache_error: { fields: '' },
    loading: false,
    new_question: undefined,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.store.select(VideosState.selectVideosByModalId).subscribe((category) => {
      this.interface.new_question = { ...this.interface.new_question, parent: category };
    });
    this.interface._cache.validate.question_type_selected = this.interface.question_types[0];
    this.interface._cache.validate.answer_type_selected = this.interface.answer_types[0];
    this.interface._cache.validate.complexity_selected = this.interface.complexities[0];
    this.cdr.detectChanges();
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }

  actionEnterVideoDescription(event) {
    this.interface._cache.validate.description = event.target.value;
    this.actionValidateFields();
    this.cdr.detectChanges();
  }

  actionValidateFields() {
    this.interface._cache_error.fields = '';
    Object.keys(this.interface._cache.validate).map((item) => {
      if (!!this.interface._cache.validate[item]) {
      } else {
        this.interface._cache_error.fields = this.interface._cache_error.fields.concat(item.toString());
      }
    });
    this.cdr.detectChanges();
  }

  actionEnterVimeoID(event) {
    // this.interface._cache.validate.vimeoID = event.target.value;
    // this.interface._cache.validate.videoUrl = 'https://argoprep.com/?ept_vimeo&type=link_file&id='.concat(
    //   this.interface._cache.validate.vimeoID,
    // );
    // this.interface._cache.validate.videoThumbnail = 'https://argoprep.com/?ept_vimeo&type=poster&id='.concat(
    //   this.interface._cache.validate.vimeoID,
    // );
    // this.actionValidateFields();
    this.cdr.detectChanges();
  }

  actionEnterVideoUrl(event) {
    // this.interface._cache.validate.videoUrl = event.target.value;
    // this.interface._cache.validate.videoUrl + this.interface._cache.validate.videoID;
    // this.cdr.detectChanges();
  }

  actionSelectVisible(event) {
    this.interface._cache.validate.visible = !this.interface._cache.validate.visible;
    this.cdr.detectChanges();
  }

  actionSelectCalc(event) {
    this.interface._cache.validate.calc = !this.interface._cache.validate.calc;
    this.cdr.detectChanges();
  }

  actionEnterTitle(event) {
    this.interface._cache.validate.title = event.target.value;
    this.interface._cache.validate.slug = this.interface._cache.validate.title.toLowerCase().replace(/ /g, '_');
    this.actionValidateFields();
    this.cdr.detectChanges();
  }

  actionAddVideo() {
    this.interface.new_question = {
      _id: '',
      answers: [],
      description: '',
      explanation: { audio: '', text: '', video: { link_file: '', link_hls: '', poster: '' } },
      options: { answers_type: '', calc: false, complexity: '', rights: [], type: '', visible: false },
      order: 0,
      parent: '',
      slug: '',
      text: { except: '', full: '' },
      thumbnail: '',
      title: '',
      unlock: { payment_id: null, type: '' },
    };
    this.store.dispatch(new CreateVideo({ data: this.interface.new_question }));
  }

  actionEnterAnswerText($event: KeyboardEvent) {}

  actionEnterAnswerLetter(event) {
    this.interface._cache.answer = { ...this.interface._cache.answer, letter: event.target.value };
    this.cdr.detectChanges();
  }

  actionEnterExcept($event: KeyboardEvent) {}

  actionSelectQuestionType(question_type: string) {
    this.interface._cache.validate.question_type_selected = question_type;
    this.actionToggleSelectQuestionType();
    this.cdr.detectChanges();
  }

  actionToggleSelectQuestionType() {
    this.interface._cache.open_select_question_types = !this.interface._cache.open_select_question_types;
  }

  actionSelectAnswerType(answer_type: string) {
    this.interface._cache.validate.answer_type_selected = answer_type;
    this.actionToggleSelectAnswerType();
    this.cdr.detectChanges();
  }

  actionToggleSelectAnswerType() {
    this.interface._cache.open_select_answer_types = !this.interface._cache.open_select_answer_types;
  }

  actionSelectComplexity(complexity: string) {
    this.interface._cache.validate.complexity_selected = complexity;
    this.actionToggleSelectComplexity();
    this.cdr.detectChanges();
  }

  actionToggleSelectComplexity() {
    this.interface._cache.open_select_complexities = !this.interface._cache.open_select_complexities;
  }

  actionConfirm() {}

  actionAddVariantAnswer() {
    this.interface._cache.isAddVariantAnswer = !this.interface._cache.isAddVariantAnswer;
  }

  actionDeleteVariantAnswer(answer) {
    this.interface.answers = this.interface.answers.filter((value) => value.text === answer.text);
    this.cdr.detectChanges();
  }

  actionVariantAnswerClose() {
    this.interface._cache.isAddVariantAnswer = false;
  }

  actionConfirmVariantAnswer() {
    const answer = {
      column: null,
      description: this.interface._cache.answer.description,
      isCorrect: this.interface._cache.answer.isCorrect,
      letter: this.interface._cache.answer.letter,
      parentId: this.interface._cache.answer.parentId,
      text: this.interface._cache.answer.text,
      title: this.interface._cache.answer.title,
    };
    this.interface._cache.isAddVariantAnswer = false;
    this.interface.answers.push(answer);
  }

  private initMaterialsOfCategory() {}

  actionCheckIsCorrect(event) {
    this.interface._cache.answer = {
      ...this.interface._cache.answer,
      isCorrect: !this.interface._cache.answer.isCorrect,
    };
    console.log('this.interface._cache.answer: ', this.interface._cache.answer);
  }
}

export class CreateCategory {
  public static readonly type = '[Categories] Create Category';

  constructor(public payload: { data: any }) {}
}

export class EditCategory {
  public static readonly type = '[Categories] Edit Category';

  constructor(public payload: { data: any }) {}
}

export class GetCategory {
  public static readonly type = '[Categories] Get Category';

  constructor(public payload: { categoryID: string }) {}
}

export class DeleteCategory {
  public static readonly type = '[Categories] Delete Category';

  constructor(public payload: { categoryID: string }) {}
}

export class GetCategoryList {
  public static readonly type = '[Categories] Get Category List';
}

export class GetCategoryListByParent {
  public static readonly type = '[Categories] Get Category List By ParentId';

  constructor(public payload: { parentId: string }) {}
}

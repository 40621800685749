import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiControlUserDeleteComponent} from "./ui-control-user-delete.component";


@NgModule({
  declarations: [UiControlUserDeleteComponent],
  exports: [UiControlUserDeleteComponent],
  imports: [
    CommonModule
  ]
})
export class UiControlUserDeleteModule { }

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UnderscorePipe} from './_pipes/underscore.pipe';
import {DiffPipe} from './_pipes/diff.pipe';
import {SafePipe} from './_pipes/safe.pipe';
import {SortPipe} from './_pipes/sort.pipe';
import {MathPipe} from './_pipes/math.pipe';
import {StripHtmlPipe} from './_pipes/striphtml.pipe';
import {TimerPipe} from './_pipes/time.pipe';
import {RandomPipe} from "./_pipes/random.pipe";
import {AgoDatePipe} from "./_pipes/ago-date.pipe";
import {SeriesPipe} from "./_pipes/series.pipe";
import {ResultViewerPipe} from "./_pipes/result-viewer.pipe";
import {TimeFullPipe} from "./_pipes/time-full.pipe";

@NgModule({
  declarations: [
    UnderscorePipe,
    DiffPipe,
    SafePipe,
    SortPipe,
    MathPipe,
    StripHtmlPipe,
    TimerPipe,
    RandomPipe,
    AgoDatePipe,
    SeriesPipe,
    ResultViewerPipe,
    TimeFullPipe
  ],
  exports: [
    UnderscorePipe,
    DiffPipe,
    SafePipe,
    SortPipe,
    MathPipe,
    StripHtmlPipe,
    TimerPipe,
    RandomPipe,
    AgoDatePipe,
    SeriesPipe,
    ResultViewerPipe,
    TimeFullPipe
  ],
  imports: [CommonModule],
})
export class PipesModule {}

<div class="ui-control-category-add-container">
  <div class="ui-form ui-control-category-add-form">
    <div class="flex flex-row gap-2">
      <div class="ui-form-input-group w-full">
        <div class="ui-form-text ui-form-text-xs">Title</div>
        <div class="ui-form-input"
             [class.ui-form-input-error]="interface._cache_error.fields.includes('categoryTitle')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
               stroke-width="1.5"
               class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
          </svg>
          <input id="categoryTitle" name="categoryTitle" type="text"
                 [value]="interface._cache.validate_form_fields.categoryTitle || ''"
                 (keyup)="actionEnterCategoryTitle($event)" placeholder="Enter Title of category">
        </div>
      </div>
      <div class="ui-form-input-group w-full">
        <div class="ui-form-text ui-form-text-xs">Slug</div>
        <div class="ui-form-input" [class.ui-form-input-error]="interface._cache_error.fields.includes('categorySlug')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
               stroke-width="1.5"
               class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
          </svg>
          <input id="categorySlug" name="categorySlug" type="text"
                 [value]="interface._cache.validate_form_fields.categorySlug || ''"
                 (keyup)="actionEnterCategorySlug($event)" placeholder="Enter Slug of category">
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-2">
      <div class="ui-form-select" [class.ui-form-select-disabled]="true">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
             stroke="currentColor"
             stroke-width="1.5">
          <path d="M12 14l9-5-9-5-9 5 9 5z" />
          <path
            d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
        </svg>
        <input id="category_type" name="category_type" type="text" readonly
               [value]="interface._cache.category_type_selected?.title" placeholder="Select Type of category">
        <div class="ui-form-select-list" [class.ui-form-select-list-open]="interface._cache.open_select_category">
          <div class="ui-form-select-list-items ui-select-school-list">
            <ng-container *ngIf="interface.category_types.length === 0">
              <div class="ui-form-select-list-item">
                <p>You have no types</p>
                <span>Please add a type</span>
              </div>
            </ng-container>
            <ng-container *ngFor="let category_type of interface.category_types">
              <div class="ui-form-select-list-item" (click)="actionSelectCategoryType(category_type)">
                <p class="capitalize">{{category_type.title}}</p>
              </div>
            </ng-container>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pointer-events-none" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" stroke-width="1.5">
          <path *ngIf="!interface._cache.open_select_category_type" stroke-linecap="round" stroke-linejoin="round"
                d="M19 9l-7 7-7-7" />
          <path *ngIf="interface._cache.open_select_category_type" stroke-linecap="round" stroke-linejoin="round"
                d="M5 15l7-7 7 7" />
        </svg>
      </div>
      <div class="ui-form-select"
           [class.ui-form-select-error]="interface._cache_error.fields.includes('category_material_selected')">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path d="M12 14l9-5-9-5-9 5 9 5z" />
          <path
            d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
        </svg>
        <input id="material" name="material" type="text" readonly
               [value]="interface._cache.validate_form_fields.category_material_selected?.title"
               placeholder="Select Material of category" (click)="actionToggleSelectCategoryMaterial()">
        <div class="ui-form-select-list" [class.ui-form-select-list-open]="interface._cache.open_select_material">
          <div class="ui-form-select-list-items ui-select-school-list">
            <ng-container *ngIf="interface.materials.length === 0">
              <div class="ui-form-select-list-item">
                <p>You have no materials</p>
                <span>Please add a material</span>
              </div>
            </ng-container>
            <ng-container *ngFor="let material of interface.materials">
              <div class="ui-form-select-list-item" (click)="actionSelectCategoryMaterial(material)">
                <p class="capitalize">{{material.title}}</p>
              </div>
            </ng-container>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pointer-events-none" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" stroke-width="1.5">
          <path *ngIf="!interface._cache.open_select_category_type" stroke-linecap="round" stroke-linejoin="round"
                d="M19 9l-7 7-7-7" />
          <path *ngIf="interface._cache.open_select_category_type" stroke-linecap="round" stroke-linejoin="round"
                d="M5 15l7-7 7 7" />
        </svg>
      </div>
    </div>
    <div class="flex flex-row gap-2">
      <div class="ui-form-input-group w-full">
        <div class="ui-form-text ui-form-text-xs">Thumbnail</div>
        <div class="ui-form-input"
             [class.ui-form-input-error]="interface._cache_error.fields.includes('categoryThumbnail')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
               stroke-width="1.5"
               class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
          </svg>
          <input id="categoryThumbnail" name="categoryTitle" type="text"
                 [value]="interface._cache.validate_form_fields.categoryThumbnail || ''"
                 (keyup)="actionEnterCategoryThumbnail($event)" placeholder="Enter Thumbnail url">
        </div>
      </div>
      <div class="ui-form-input-group w-full">
        <div class="ui-form-text ui-form-text-xs">Options</div>
        <div class="ui-form-radio-group">
          <div class="ui-form-radio ui-form-radio-xs" (click)="actionSelectVisible($event)"
               [class.ui-form-radio-selected]="interface.category_visible">
            <input name="category_visible" type="radio"
                   [checked]="interface.category_visible">
            <span>{{'Visible'}}</span>
          </div>
          <div class="ui-form-radio ui-form-radio-xs" (click)="actionSelectVisible($event)"
               [class.ui-form-radio-selected]="!interface.category_visible">
            <input name="category_visible" type="radio"
                   [checked]="!interface.category_visible">
            <span>{{'Hidden'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-2">
      <div class="ui-form-input-group w-full">
        <div class="ui-form-text ui-form-text-xs">Description</div>
        <div class="ui-form-textarea"
             [class.ui-form-textarea-error]="interface._cache_error.fields.includes('categoryDescription')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
               stroke-width="1.5"
               class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
          </svg>
          <textarea id="categoryDescription" name="categoryDescription" type="text"
                    [value]="interface._cache.validate_form_fields.categoryDescription || ''"
                    (keyup)="actionEnterCategoryDescription($event)"
                    placeholder="Enter Description of category"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-control-category-add-action">
    <button class="ui-data-category-add-action-no" (click)="actionClose()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
      </svg>
      <span>Cancel</span>
    </button>
    <button class="ui-btn ui-control-category-add" (click)="actionAddCategory()"
            [disabled]="interface._cache_error.fields.length > 0">
      <svg *ngIf="!interface.loading" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
           stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
      <svg *ngIf="interface.loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span>{{'buttons.confirm' | transloco}}</span>
    </button>
  </div>
</div>

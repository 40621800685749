import {VideoModel} from '../../../_models/video.model';
import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {VideosService} from '../_services/videos.service';
import {ModalState} from '../../modal/_state/modal.state';
import {ModalModel} from '../../../_models/modal.model';
import {PracticeStateModel} from '../../practice/_state/practice.state';
import {UserState} from '../../user/_state/user.state';
import {first} from 'rxjs';
import {ModalCloseAction} from '../../modal/_actions/modal.actions';
import {
  CreateVideo,
  DeleteVideo,
  EditVideo,
  GetVideo,
  GetVideoList,
  GetVideoListByParent,
} from '../_actions/videos.actions';

export interface VideosStateModel {
  isLoading: boolean;
  lastCreatedVideo: VideoModel;
  videos: VideoModel[];
  video: VideoModel;
}

export const clearVideos = {
  isLoading: false,
  lastCreatedVideo: null,
  videos: [],
  video: null,
};

@State<VideosStateModel>({
  name: 'SAP_VIDEOS',
  defaults: clearVideos,
})
@Injectable()
export class VideosState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  constructor(private store: Store, private videosService: VideosService) {}

  @Selector()
  public static selectVideosState(state: VideosStateModel) {
    return state;
  }

  @Selector()
  public static selectVideo(state: VideosStateModel) {
    return state.video;
  }

  @Selector()
  public static selectVideoList(state: VideosStateModel) {
    return state.videos;
  }

  @Selector()
  public static selectLastCreatedVideo(state: VideosStateModel) {
    return state.lastCreatedVideo;
  }

  @Selector([ModalState])
  public static selectVideosByModalId(state: VideosStateModel, modal: ModalModel) {
    return modal && modal._id ? modal._id : '';
  }

  @Selector([ModalState])
  public static selectEditVideoByModalId(state: VideosStateModel, modal: ModalModel) {
    return state.videos.find((value) => value._id === modal._id);
  }

  ngxsAfterBootstrap(ctx?: StateContext<VideosStateModel>): void {
    this.store
      .select(UserState.selectUser)
      .pipe(first((user) => !!user))
      .subscribe((user) => {
        if (user.role.name.toLowerCase() === 'administrator') {
          //ctx.dispatch(new GetVideoList()); /*TODO грузить только на нужной странице а не на всех страницах админа*/
        }
      });
  }

  ngxsOnInit(ctx?: StateContext<PracticeStateModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange<PracticeStateModel>): void {}

  @Action(CreateVideo)
  create(ctx: StateContext<VideosStateModel>, { payload }: CreateVideo) {
    ctx.patchState({
      isLoading: true,
    });
    this.videosService.createVideo(payload.data).subscribe({
      next: (res) => {
        //console.log('res: ', res);
        const state = ctx.getState();

        ctx.patchState({
          video: res,
          videos: [res, ...state.videos],
          lastCreatedVideo: res,
          isLoading: false,
        });
        // this.store.dispatch(new EventVideoCreated({ categoryId: res._id }));
        // this.store.dispatch(new GetVideoList());
        this.store.dispatch(new GetVideoListByParent({ parentId: res.parent }));
        this.store.dispatch(new ModalCloseAction());
      },
      error: (err) => {
        //console.log('err: ', err);
      },
      complete: () => {},
    });
  }

  @Action(EditVideo)
  edit(ctx: StateContext<VideosStateModel>, { payload }: EditVideo) {
    ctx.patchState({
      isLoading: true,
    });
    return this.videosService.editVideo(payload.data).subscribe({
      next: (res) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          video: res,
        });
        ctx.dispatch(new GetVideoListByParent({ parentId: res.parent }));
        ctx.dispatch(new ModalCloseAction());
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(GetVideo)
  getVideo({ getState, setState }: StateContext<VideosStateModel>, { payload }: any) {
    const state = getState();
    setState({
      ...state,
      video: null,
    });
    return this.videosService.getVideo(payload.videoID).subscribe({
      next: (res) => {
        setState({
          ...state,
          video: res,
        });
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(DeleteVideo)
  deleteVideo(ctx: StateContext<VideosStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      video: null,
    });
    return this.videosService.deleteVideo(payload.videoID).subscribe({
      next: (res: VideoModel) => {
        ctx.dispatch(new GetVideoListByParent({ parentId: res.parent }));
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(GetVideoList)
  getVideoList({ getState, setState }: StateContext<VideosStateModel>) {
    return this.videosService.getVideosList().subscribe({
      next: (res) => {
        const state = getState();
        setState({
          ...state,
        });
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(GetVideoListByParent)
  getVideoListByParent({ getState, setState }: StateContext<VideosStateModel>, { payload }: any) {
    return this.videosService.getVideosListByParent(payload.parentId).subscribe({
      next: (res) => {
        const state = getState();
        setState({
          ...state,
          videos: res,
        });
      },
      error: () => {},
      complete: () => {},
    });
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {map} from "rxjs/operators";

@Component({
  selector: 'ui-page-worksheet',
  templateUrl: './ui-page-worksheet.component.html',
  styleUrls: ['./ui-page-worksheet.component.scss'],
})
export class UiPageWorksheetComponent implements OnInit, OnDestroy {

  constructor(private store: Store, private activatedRoute: ActivatedRoute,) {

  }

  public worksheet;
  private subscribeWorksheetById: Subscription
  ngOnInit(): void {
    this.subscribeWorksheetById = this.activatedRoute.data.pipe(
      map((data: { worksheet }) => {
        this.worksheet =  data.worksheet;
      })).subscribe();

  }

  ngOnDestroy(): void {
    this.subscribeWorksheetById.unsubscribe();
  }

}

export class ToggleSidebar  {
  public static readonly type = '[App] ToggleSidebar';
  constructor() {}
}
export class CountUsers  {
  public static readonly type = '[Event] Count Users';
  constructor(public data: any) {}
}

export class MessageToAI  {
  public static readonly type = '[App] MessageToAI';
  constructor(public message: any) {}
}
export class ClearMessageToAI  {
  public static readonly type = '[App] ClearMessageToAI';
  constructor() {}
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPageHomeworksComponent} from './ui-page-homeworks.component';
import {UiAppNoDataModule} from '../ui-app-no-data/ui-app-no-data.module';
import {RouterModule} from '@angular/router';
import {UiTooltipModule} from '../ui-tooltip/ui-tooltip.module';
import {PipesModule} from '../../app.pipes.module';
import {UiHomeworkModule} from "../ui-homework/ui-homework.module";

@NgModule({
  declarations: [UiPageHomeworksComponent],
  exports: [UiPageHomeworksComponent],
  imports: [
    CommonModule,
    UiAppNoDataModule,
    RouterModule,
    UiTooltipModule,
    PipesModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageHomeworksComponent,
        children: [
          {
            path: ":homeworks_id",
            loadChildren: () =>
              import("../ui-page-homework-details/ui-page-homework-details.module").then((mod) => mod.UiPageHomeworkDetailsModule),
/*            resolve: {
              homeworksData: UiPageHomeworkDetailsResolver
            }*/
          }
        ]
      }
    ]),
    UiHomeworkModule
  ]
})
export class UiPageHomeworksModule {}

import {ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngxs/store';
import {LoadControlSchoolsWithoutLicense} from '../../_stores/control/_actions/control.actions';
import {ControlState} from '../../_stores/control/_state/control.state';
import {ModalOpenAction, ModalOpenActionWithID} from '../../_stores/modal/_actions/modal.actions';
import {UserModel} from '../../_models/user.model';
import {SchoolModel} from '../../_models/school.model';
import {Router} from '@angular/router';

interface UiControlSchoolsModel {
  load: boolean;
  states: any[];
  search: string;
  sortedField: {
    stateName: string;
    schoolName: string;
    users: string;
    schoolLevel: string;
    license: string;
    states: string;
  };
  sort: string;
  pages: any[];
  currentPage: number;
  data: { school: SchoolModel; users: UserModel[] }[];
  perPage: number;
  totalData: number;
  totalPage: number;
  _cache: {
    data: { school: SchoolModel; users: UserModel[] }[];
    choose_state: { state_code: string; state_name: string };
    open_choose_state: boolean;
    search: boolean;
    sortedByName: boolean;
    sortedByState: boolean;
    sortedByNcesId: boolean;
    sortedByLevel: boolean;
    sortedByLicense: boolean;
  };
  sortIndex: {
    asc: string;
    desc: string;
  };
}

@Component({
  selector: 'ui-control-schools-no-license',
  templateUrl: './ui-control-schools-no-license.component.html',
  styleUrls: ['./ui-control-schools-no-license.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlSchoolsNoLicenseComponent implements OnInit, OnChanges {
  interface: UiControlSchoolsModel = {
    _cache: {
      choose_state: { state_code: '', state_name: '' },
      data: [],
      open_choose_state: false,
      search: false,
      sortedByLevel: false,
      sortedByLicense: false,
      sortedByName: false,
      sortedByNcesId: false,
      sortedByState: false,
    },
    load: false,
    states: [],
    sortedField: { license: '', schoolLevel: '', schoolName: '', stateName: '', users: '', states: '' },
    sortIndex: { asc: '@asc', desc: '@desc' },
    currentPage: 0,
    data: [],
    pages: [],
    perPage: 0,
    search: '',
    sort: '',
    totalData: 0,
    totalPage: 0,
  };

  private readonly FIRST_PAGE = 1;

  constructor(private store: Store, private cdr: ChangeDetectorRef, private router: Router) {
    this.store.dispatch(
      new LoadControlSchoolsWithoutLicense('', this.FIRST_PAGE, '', {
        state_code: 'ALL',
        state_name: 'All states',
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes && changes.currentPage.currentValue) || (changes.total && changes.total.currentValue)) {
      this.initData();
    }
  }

  ngOnInit(): void {
    this.initStates();
    this.initSortedFields();
    this.initData();
    this.cdr.detectChanges();
  }

  initData(): void {
    this.store.select(ControlState.selectSchools).subscribe((_data) => {
      this.interface = {
        ...this.interface,
        data: _data.data,
        currentPage: _data.currentPage,
        totalPage: _data.totalPage,
        totalData: _data.totalData,
        pages: this.getPages(this.interface.currentPage, this.interface.totalPage),
      };
      this.interface._cache.data = this.interface.data;
      this.interface.load = true;
      this.cdr.detectChanges();
    });
    this.cdr.detectChanges();
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(new ModalOpenAction('control_schools_add'));
  }

  actionOpenEditDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_schools_edit', _id));
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_schools_delete', _id));
  }

  actionNextPage(event): void {
    let element = document.querySelector('thead');
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    this.interface.pages = [];
    this.interface = {
      ...this.interface,
      currentPage: event,
    };
    this.interface.currentPage = event;
    this.loadData(
      this.interface?.search,
      this.interface.currentPage,
      this.interface?.sort,
      this.interface._cache.choose_state,
    );

    this.cdr.detectChanges();
  }

  actionSearchSchool(event) {
    if (event.target.value && event.target.value.length > 0) {
      this.interface = { ...this.interface, search: event.target.value };
      this.loadData(this.interface?.search, this.FIRST_PAGE, this.interface?.sort, this.interface._cache.choose_state);
      this.initData();
      this.cdr.detectChanges();
    } else {
      this.interface = { ...this.interface, search: undefined };
      this.loadData(this.interface?.search, this.FIRST_PAGE, this.interface.sort, this.interface._cache.choose_state);
      this.initData();
      this.cdr.detectChanges();
    }
  }

  actionSortByField(sortedField: string, sortIndex: string) {
    this.interface = { ...this.interface, sort: sortedField + sortIndex };
    this.loadData(this.interface?.search, this.FIRST_PAGE, this.interface.sort, this.interface._cache.choose_state);
    this.initData();
    this.cdr.detectChanges();
  }

  getCountUsers(users: UserModel[]) {
    let argoprep_count = 0;
    let clever_count = 0;
    for (const user of users) {
      if (user.providerRegister && user.providerRegister.length > 0) {
        if (user.providerRegister.find((value) => value.name === 'argoprep' && value.active === true)) {
          argoprep_count++;
        }
        if (user.providerRegister.find((value) => value.name === 'clever' && value.active === true)) {
          clever_count++;
        }
      }
    }
    return { clever: clever_count, argoprep: argoprep_count };
  }

  actionToggleChooseState() {
    this.interface._cache.open_choose_state = !this.interface._cache.open_choose_state;
  }

  actionChooseSchoolsByState(state?: { state_code: string; state_name: string }) {
    if (!state) {
      this.interface._cache = {
        ...this.interface._cache,
        choose_state: { state_code: 'ALL', state_name: 'All states' },
      };
      this.loadData(this.interface?.search, this.FIRST_PAGE, this.interface.sort, this.interface._cache.choose_state);
      this.actionToggleChooseState();
      this.cdr.detectChanges();
    }
    if (state.state_name === 'No data') {
      this.interface.data = this.interface.data.filter((value) => !value.school.state);
      this.interface._cache = {
        ...this.interface._cache,
        choose_state: state,
      };
      this.loadData(this.interface?.search, this.FIRST_PAGE, this.interface.sort, this.interface._cache.choose_state);
      this.actionToggleChooseState();
      this.cdr.detectChanges();
    }
    if (state.state_name !== 'No data') {
      this.interface._cache = {
        ...this.interface._cache,
        choose_state: state,
      };
      this.loadData(this.interface?.search, this.FIRST_PAGE, this.interface.sort, this.interface._cache.choose_state);
      this.actionToggleChooseState();
      this.cdr.detectChanges();
    }
    this.interface._cache.open_choose_state = false;
    this.cdr.detectChanges();
  }

  getStateName(state: string) {
    if (state && state.length > 0) {
      return this.interface.states.find(
        (value) =>
          value.state_code.toUpperCase() === state.toUpperCase() ||
          value.state_name.toUpperCase() === state.toUpperCase(),
      )?.state_name;
    }
    return 'No data';
  }

  goToDetail(partUrl: string) {
    let url = this.router.url;
    let indexOf = url.indexOf('no-licenses');
    let newUrl = url.substring(0, indexOf);
    return newUrl + partUrl;
  }

  private getPages(currentPage: number, totalPage: number) {
    if (currentPage <= 10) {
      if (totalPage < 10) {
        return [...Array(totalPage).keys()].map((x) => ++x);
      }
      return [...Array(10).keys()].map((x) => ++x);
    }
    if (currentPage > 10) {
      if (currentPage >= totalPage - 2) {
        return [totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
      } else {
        return [currentPage - 1, currentPage, currentPage + 1];
      }
    }
  }

  private initSortedFields() {
    this.interface.sortedField = {
      ...this.interface.sortedField,
      schoolName: 'schoolName',
      schoolLevel: 'schoolLevel',
      license: 'license',
      users: 'count_users',
      states: 'states',
    };
  }

  private initStates() {
    this.interface.states = [
      { state_code: 'ID', state_name: 'Idaho' },
      { state_code: 'IA', state_name: 'Iowa' },
      { state_code: 'AL', state_name: 'Alabama' },
      { state_code: 'AK', state_name: 'Alaska' },
      { state_code: 'AZ', state_name: 'Arizona' },
      { state_code: 'AR', state_name: 'Arkansas' },
      { state_code: 'WY', state_name: 'Wyoming' },
      { state_code: 'WA', state_name: 'Washington' },
      { state_code: 'VT', state_name: 'Vermont' },
      { state_code: 'VA', state_name: 'Commonwealth of Virginia' },
      { state_code: 'WI', state_name: 'Wisconsin' },
      { state_code: 'HI', state_name: 'Hawaii' },
      { state_code: 'DE', state_name: 'Delaware' },
      { state_code: 'GA', state_name: 'Georgia' },
      { state_code: 'WV', state_name: 'West Virginia' },
      { state_code: 'IL', state_name: 'Illinois' },
      { state_code: 'IN', state_name: 'Indiana' },
      { state_code: 'CA', state_name: 'California' },
      { state_code: 'KS', state_name: 'Kansas' },
      { state_code: 'KY', state_name: 'Kentucky' },
      { state_code: 'CO', state_name: 'Colorado' },
      { state_code: 'CT', state_name: 'Connecticut' },
      { state_code: 'LA', state_name: 'Louisiana' },
      { state_code: 'MA', state_name: 'Massachusetts' },
      { state_code: 'MN', state_name: 'Minnesota' },
      { state_code: 'MS', state_name: 'Mississippi' },
      { state_code: 'MO', state_name: 'Missouri' },
      { state_code: 'MI', state_name: 'Michigan' },
      { state_code: 'MT', state_name: 'Montana' },
      { state_code: 'ME', state_name: 'Maine' },
      { state_code: 'MD', state_name: 'Maryland' },
      { state_code: 'NE', state_name: 'Nebraska' },
      { state_code: 'NV', state_name: 'Nevada' },
      { state_code: 'NH', state_name: 'New Hampshire' },
      { state_code: 'NJ', state_name: 'New Jersey' },
      { state_code: 'NY', state_name: 'New York' },
      { state_code: 'NM', state_name: 'New Mexico' },
      { state_code: 'OH', state_name: 'Ohio' },
      { state_code: 'OK', state_name: 'Oklahoma' },
      { state_code: 'OR', state_name: 'Oregon' },
      { state_code: 'PA', state_name: 'Pennsylvania' },
      { state_code: 'RI', state_name: 'Rhode Island and Providence Plantations' },
      { state_code: 'ND', state_name: 'North Dakota' },
      { state_code: 'NC', state_name: 'North Carolina' },
      { state_code: 'TN', state_name: 'Tennessee' },
      { state_code: 'TX', state_name: 'Texas' },
      { state_code: 'FL', state_name: 'Florida' },
      { state_code: 'SD', state_name: 'South Dakota' },
      { state_code: 'SC', state_name: 'South Carolina' },
      { state_code: 'UT', state_name: 'Utah' },
    ];
    this.interface._cache.choose_state = { state_code: 'ALL', state_name: 'All states' };
    this.cdr.detectChanges();
  }

  private loadData(search, page, sort, state) {
    this.interface.load = false;
    this.store.dispatch(new LoadControlSchoolsWithoutLicense(search, page, sort, state));
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiClassesComponent} from "./ui-classes.component";
import {UiAppNoDataModule} from "../ui-app-no-data/ui-app-no-data.module";
import {RouterModule} from "@angular/router";
import {PipesModule} from "../../app.pipes.module";
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";
import {UiClassModule} from "../ui-class/ui-class.module";


@NgModule({
  declarations: [UiClassesComponent],
  exports: [UiClassesComponent],
  imports: [
    CommonModule,
    UiAppNoDataModule,
    RouterModule,
    PipesModule,
    UiTooltipModule,
    UiClassModule
  ]
})
export class UiClassesModule { }

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {UiAppNoDataModule} from '../../_ui/ui-app-no-data/ui-app-no-data.module';
import {UiControlGradesComponent} from './ui-control-grades.component';
import {UiTooltipModule} from '../../_ui/ui-tooltip/ui-tooltip.module';
import {PipesModule} from '../../app.pipes.module';
import {AppTranslocoModule} from '../../app.transloco.module';

@NgModule({
  declarations: [UiControlGradesComponent],
  exports: [UiControlGradesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: UiControlGradesComponent,
        children: [],
      },
    ]),
    UiAppNoDataModule,
    UiTooltipModule,
    PipesModule,
    AppTranslocoModule,
  ],
})
export class UiControlGradesModule {}

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AssignmentBuilderService {
  ebooks: { _id: string; materials: string; title: string; pages: { page: 0; id: string }[] }[] = [];
  totalPages: { page: 0; id: string }[] = [];
  count = 0;
  materials: any[] = [];

  constructor() {}

  addEbookAssignment(assignment) {
    if (this.ebooks.find((value) => value._id === assignment.id)) {
      const index = this.ebooks.findIndex((value) => value._id === assignment.id);
      const mergeArr = [].concat(this.ebooks[index].pages, assignment.pages);
      this.ebooks[index] = {
        ...this.ebooks[index],
        pages: mergeArr,
      };
    } else {
      const arr = [];
      arr.push(assignment.pages);
      this.ebooks.push({
        _id: assignment.id,
        materials: assignment.materials,
        title: assignment.title,
        pages: arr,
      });
    }
    this.getCountAllEBookPages(assignment);
    return this.ebooks;
  }

  clearTotalPages() {
    this.totalPages = [];
  }

  clearEbooks() {
    this.ebooks = [];
  }

  getCountAllEBookPages(assignment) {
    this.totalPages = [].concat(this.totalPages, assignment.pages);
  }

  clearCount() {
    this.count = 0;
  }
}

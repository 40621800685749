import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AssignmentModel} from '../../_models/assignment.model';
import {Store} from '@ngxs/store';
import {AssignmentsState} from '../../_stores/asignments/_state/assignments.state';


@Component({
  selector: 'ui-page-assignments',
  templateUrl: './ui-page-assignments.component.html',
  styleUrls: ['./ui-page-assignments.component.scss'],
})
export class UiPageAssignmentsComponent implements OnInit {
  interface: {
    loading: boolean
    assignments: AssignmentModel[],
    tabs: any[],
    _cache: {
      assignments: AssignmentModel[],
      tab: any
    }
  } = {
    loading: true,
    assignments: [],
    tabs: [],
    _cache: {
      assignments: [],
      tab: undefined
    },
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initTabs();
    this.initAssignments();
  }

  initAssignments(): void {
    this.store.select(AssignmentsState.selectAssignmentList).subscribe({
      next: (_assignments) => {
        this.interface._cache.assignments = _assignments;
        this.initAssignmentsList();
        this.cdr.detectChanges();
      },
    });
  }
  initTabs(): void {
    this.interface.tabs = [
      {
        _id: null,
        title: 'active'
      },
      {
        _id: null,
        title: 'archive'
      }
    ];
    this.interface._cache.tab = this.interface.tabs[0];
  }
  initAssignmentsList(): void {
    if(this.interface._cache.tab.title == 'active') {
      this.interface.assignments = this.interface._cache.assignments.filter((_assignments) => _assignments.options.isActive);
    }
    if(this.interface._cache.tab.title == 'archive') {
      this.interface.assignments = this.interface._cache.assignments.filter((_assignments) => !_assignments.options.isActive);
    }
  }

  actionChangeActiveTab(tab): void {
    this.interface._cache.tab = tab;
    this.initAssignmentsList();
  }
  actionSearchAssignments(event): void {
    if (event.target.value.length === 0) {
      this.initAssignmentsList();
    } else {
      this.interface.assignments = this.interface._cache.assignments.filter((item) => {
        return item.title.toLowerCase().includes(event.target.value.toLowerCase());
      });
    }
  }


  childContentLoad(event): void {
    this.interface.loading = false;
  }
  childContentUnLoad(event): void {
    this.interface.loading = true;
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiStudentAddComponent} from './ui-student-add.component';
import {TranslocoModule} from '@ngneat/transloco';
import {UiTooltipModule} from '../ui-tooltip/ui-tooltip.module';

@NgModule({
  declarations: [UiStudentAddComponent],
  exports: [UiStudentAddComponent],
  imports: [CommonModule, TranslocoModule, UiTooltipModule],
})
export class UiStudentAddModule {}

import {Action, NgxsOnChanges, NgxsOnInit, NgxsSimpleChange, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {NotificationModel} from "../../../_models/notification.model";
import {NotificationClose, NotificationOpen} from "../_actions/notification.actions";


export const _NotificationDefault: NotificationModel = {
  status: false,
  type: null,
  close: true
};

@State<NotificationModel>({
  name: 'SAP_NOTIFICATION',
  defaults: _NotificationDefault,
})
@Injectable()
export class NotificationState implements NgxsOnInit, NgxsOnChanges {
  constructor(private store: Store) {}

  ngxsOnInit(ctx?: StateContext<any>): any {}
  ngxsOnChanges(change: NgxsSimpleChange<NotificationModel>): void {}

  @Selector()
  static selectStatus(state: NotificationModel) {
    return state;
  }


  @Action(NotificationOpen)
  notificationOpen(ctx: StateContext<NotificationModel>, type) {
    const state = ctx.getState();
    if (state.status && type.data == state.type) {
      return;
    }
    ctx.patchState({
      ...state,
      status: true,
      type: type.data,
      close: type.close != undefined ? type.close : true,
    });

    setTimeout(() => {
      ctx.dispatch(new NotificationClose());
    }, 1500);
  }

  @Action(NotificationClose)
  notificationClose(ctx: StateContext<NotificationModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      status: false,
      type: null,
      close: true,
    });
  }
}

<div class="ui-practice-exit">
  <img src="assets/images/shapes/warning.png" alt="">
  <div class="ui-practice-exit-text">Are you sure want to leave? All your progress will be lost.</div>
  <div class="ui-practice-exit-actions">
    <button class="ui-practice-exit-action-no" (click)="actionClose()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
      </svg>
      <span>Go back</span>
    </button>
    <button class="ui-practice-exit-action-yes" (click)="actionOpenResult()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
      <span>Yes leave</span>
    </button>
  </div>
</div>

<div class="ui-block ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Schools</h2>
      <p>Total number of schools: <strong>{{interface.totalData}}</strong></p>
    </div>
    <div class="ui-block-header-action">

    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container [ngTemplateOutlet]="DATA_TABLE_ACTIONS"></ng-container>
    <ng-container *ngIf="!interface.load">
      <ui-no-data type="loading" text="Loading data" class="flex-grow h-[793px]"></ui-no-data>
    </ng-container>
    <ng-container *ngIf="interface.load">
      <ng-container *ngIf="interface.data && interface.data.length === 0">
        <ui-no-data type="dino" text="No data available..." class="flex-grow h-[793px]"></ui-no-data>
      </ng-container>
      <ng-container *ngIf="interface.data && interface.data.length != 0">
        <ng-container [ngTemplateOutlet]="DATA_TABLE"></ng-container>
        <ng-container [ngTemplateOutlet]="DATA_TABLE_PAGINATION"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="ui-block-footer">
  </div>
</div>

<ng-template #DATA_TABLE_ACTIONS>
  <div class="ui-schools-filters">
    <div class="ui-categories-filter-input-group">
      <div class="ui-schools-filter-select">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5" class="h-6 w-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
        </svg>
        <input name="states" type="text" readonly [value]="interface._cache.choose_state?.state_name"
               placeholder="Choose State" (click)="actionToggleChooseState()">
        <div class="ui-schools-filter-select-list"
             [class.ui-schools-filter-select-list-open]="interface._cache.open_choose_state">
          <div class="ui-schools-filter-select-list-items">
            <div class="ui-schools-filter-select-list-item" (click)="actionChooseSchoolsByState()">
              <p>{{'All states'}}</p>
            </div>
            <div class="ui-schools-filter-select-list-item"
                 (click)="actionChooseSchoolsByState({state_code: 'N/D',state_name:'No data'})">
              <p>{{'No data'}}</p>
            </div>
            <ng-container *ngFor="let state of interface.states | sort:'asc':'state_name' ">
              <div class="ui-schools-filter-select-list-item" (click)="actionChooseSchoolsByState(state)">
                <p>{{state["state_name"] }}</p>
                <p>{{'[' + state["state_code"] + ']' }}</p>
              </div>
            </ng-container>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" stroke-width="1.5" (click)="actionToggleChooseState()">
          <path *ngIf="!interface._cache.open_choose_state" stroke-linecap="round" stroke-linejoin="round"
                d="M19 9l-7 7-7-7" />
          <path *ngIf="interface._cache.open_choose_state" stroke-linecap="round" stroke-linejoin="round"
                d="M5 15l7-7 7 7" />
        </svg>
      </div>
    </div>
    <div class="ui-schools-filter-input-group">
      <div class="ui-schools-filter-input">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
        </svg>
        <input name="search" type="text" placeholder="Search" (keyup)="actionSearchSchool($event)">
      </div>
      <div class="ui-schools-filter-text-xs" *ngIf="interface?._cache?.search">Found
        <strong>{{interface.data.length}}</strong> schools
      </div>
    </div>
  </div>
</ng-template>
<ng-template #DATA_TABLE>
  <div class="flex w-full overflow-auto rounded-lg">
    <table class="ui-table">
      <caption></caption>
      <thead>
      <tr>
        <th scope="col">
          <div class="ui-form-checkbox"><input type="checkbox"></div>
        </th>
        <th>
          <div class="ui-table-header">
            <div>Name</div>
            <div class="ui-table-header-sort">
              <ng-container
                *ngTemplateOutlet="sortedField; context: {field:interface.sortedField.schoolName, sortedIndex: interface.sortIndex}"></ng-container>
            </div>
          </div>
        </th>
        <th>
          <div class="ui-table-header">
            <div>State</div>
            <div class="ui-table-header-sort">
              <ng-container
                *ngTemplateOutlet="sortedField; context: {field:interface.sortedField.stateName, sortedIndex: interface.sortIndex}"></ng-container>
            </div>
          </div>
        </th>
        <th>
          <div class="ui-table-header">
            <div>Users</div>
            <div class="ui-table-header-sort">
              <ng-container
                *ngTemplateOutlet="sortedField; context: {field:interface.sortedField.users, sortedIndex: interface.sortIndex}"></ng-container>
            </div>
          </div>
        </th>
        <th>
          <div class="ui-table-header">
            <div>Level</div>
            <div class="ui-table-header-sort">
              <ng-container
                *ngTemplateOutlet="sortedField; context: {field:interface.sortedField.schoolLevel, sortedIndex: interface.sortIndex}"></ng-container>
            </div>
          </div>
        </th>
        <th>
          <div class="ui-table-header">
            <div>License</div>
            <div class="ui-table-header-sort">
              <ng-container
                *ngTemplateOutlet="sortedField; context: {field:interface.sortedField.license, sortedIndex: interface.sortIndex}"></ng-container>
            </div>
          </div>
        </th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let item of interface.data">
        <tr>
          <td>
            <div class="ui-form-checkbox"><input type="checkbox"></div>
          </td>
          <td>
            <div class="flex flex-col">
              <a class="font-medium text-slate-900 hover:text-pap-400" [href]="goToDetail(item?.school?._id+'/detail')" target="_blank"> {{item.school["schoolName"]}}</a>
              <div class="text-xs text-slate-400">{{item.school["schoolDistrict"]}}</div>
            </div>
          </td>
          <td>
            <div class="flex flex-col">
              <div class="font-medium text-slate-900 hover:text-pap-400">
                {{ getStateName(item?.school?.state)}}
              </div>
              <div class="text-xs text-slate-400">{{item?.school?.schoolDistrict || ''}}</div>
            </div>
          </td>
          <td>
            <div class="flex items-center">
              <div class="flex flex-col">
                <div class="flex w-full"><span
                  class="text-xs text-slate-400 w-[2rem]">{{item.users && item.users.length > 0 ? getCountUsers(item.users).clever : 0}}</span><span
                  class="text-xs"> Clever </span></div>
                <div class="flex w-full"><span
                  class="text-xs text-slate-400 w-[2rem]">{{item.users && item.users.length > 0 ? getCountUsers(item.users).argoprep : 0}}</span><span
                  class="text-xs"> ArgoPrep </span></div>
              </div>
            </div>
          </td>
          <td>
            <div class="flex items-center">{{item.school["schoolLevel"]}}</div>
          </td>
          <td>
            <div class="flex items-center">
              <ng-container *ngIf="item.school.license"><span class="ui-badge ui-badge-succeed">Licensed</span>
              </ng-container>
              <ng-container *ngIf="!item.school.license"><span class="ui-badge ui-badge-error">Not licensed</span>
              </ng-container>
            </div>
          </td>
          <td>
            <button tooltip title="Edit" class="ui-btn ui-btn-xs ui-btn-icon"
                    (click)="actionOpenEditDataItemModal(item.school._id)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
              </svg>
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</ng-template>
<ng-template #DATA_TABLE_PAGINATION>
  <ui-pagination [pages]="interface.pages"
                 [currentPage]="interface.currentPage"
                 [totalPage]="interface.totalPage" (nextPage)="actionNextPage($event)">
  </ui-pagination>
</ng-template>


<ng-template #sortedField let-field="field" let-sortedIndex="sortedIndex">
  <ng-container *ngIf="!interface.sort.includes(field)">
    <svg (click)="actionSortByField(field,sortedIndex['asc'])"
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         stroke="currentColor" class="w-3 h-3">
      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
    </svg>
    <svg (click)="actionSortByField(field,sortedIndex['desc'])"
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         stroke="currentColor" class="w-3 h-3">
      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  </ng-container>
  <ng-container *ngIf="interface.sort.includes(field)">
    <svg (click)="actionSortByField(field,sortedIndex['asc'])"
         [class.select]="interface.sort.includes(interface.sortIndex.asc) && interface.sort.includes(field)"
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         stroke="currentColor" class="w-3 h-3">
      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
    </svg>
    <svg (click)="actionSortByField(field,sortedIndex['desc'])"
         [class.select]="interface.sort.includes(interface.sortIndex.desc) && interface.sort.includes(field)"
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         stroke="currentColor" class="w-3 h-3">
      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  </ng-container>
</ng-template>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiAppNoDataComponent} from './ui-app-no-data.component';

@NgModule({
  declarations: [UiAppNoDataComponent],
  exports: [UiAppNoDataComponent],
  imports: [CommonModule],
})
export class UiAppNoDataModule {}

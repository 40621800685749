import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiClassEditComponent} from './ui-class-edit.component';
import {UiGradesModule} from '../ui-grades/ui-grades.module';
import {UiTooltipModule} from '../ui-tooltip/ui-tooltip.module';
import {TranslocoModule} from '@ngneat/transloco';

@NgModule({
  declarations: [UiClassEditComponent],
  exports: [UiClassEditComponent],
  imports: [CommonModule, UiGradesModule, UiTooltipModule, TranslocoModule],
})
export class UiClassEditModule {}

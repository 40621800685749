import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngxs/store';
import {ModalCloseAction} from '../../_stores/modal/_actions/modal.actions';
import {HttpClient} from '@angular/common/http';
import {UserState} from '../../_stores/user/_state/user.state';
import {GradeModel} from '../../_models/grade.model';
import {UserModel} from '../../_models/user.model';
import {RoleModel} from "../../_models/role.model";
import {SchoolModel} from "../../_models/school.model";
import {ClassesState} from "../../_stores/classes/_state/classes.state";
import {UiStudentEditModel} from "../../_models/ui-student-edit.model";
import {PracticeState} from "../../_stores/practice/_state/practice.state";
import {ClassModel} from "../../_models/class.model";
import {API} from "../../_stores/api";
import {GetTeachersStudents} from "../../_stores/user/_actions/user.actions";
import {take} from "rxjs";

@Component({
  selector: 'ui-student-edit',
  templateUrl: './ui-student-edit.component.html',
  styleUrls: ['./ui-student-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiStudentEditComponent implements OnInit {
  interface: UiStudentEditModel = {
    loading: false,
    student: new (class implements UserModel {
      _id: string;
      avatar: string;
      language: string;
      displayName: string;
      email: string;
      firstName: string;
      lastName: string;
      password: string;
      practice: string | GradeModel;
      role: RoleModel;
      school: SchoolModel;
      classes?: ClassModel[];
      ain: number;
    })(),
    classes: [],
    grades: [],
    user: undefined,
    _cache: {
      open_select_class: false,
      open_select_grade: false,
      class_selected: undefined,
      grade_selected:undefined,
      check_ain: true
    },
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) {}

  ngOnInit(): void {
    this.initDefaultStudent();
    this.initEditedStudent();
    this.initGrades();
    this.initGlobalUser();
    this.initGlobalUserClasses();
  }

  initDefaultStudent(): void {
    this.interface.student = {
      _id: '',
      avatar: '',
      language: 'en',
      displayName: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      practice: '',
      role: {
        _id: '62ebc2e38e33b7bc52c853c6',
        name: 'Student'
      },
      school: undefined,
      ain: null,
    };
  }
  initGlobalUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
    });
  }
  initGlobalUserClasses(): void {
    this.store.select(ClassesState.selectClassList).subscribe({
      next: (_classes) => {
        this.interface.classes = _classes;
      },
    });
  }
  initGrades(): void {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.interface.grades = _grades;
      this.cdr.detectChanges();
    });
  }
  initEditedStudent(): void {
    this.store.select(UserState.selectEditedStudent).pipe(take(1)).subscribe((_student) => {
      this.interface.student = {..._student};
      this.interface._cache.grade_selected = <GradeModel>this.interface.student.practice;
      this.interface._cache.class_selected = this.interface.student.classes[0]
      this.cdr.detectChanges();
    });
  }



  actionSelectClass(_class): void {
    this.interface._cache.class_selected = _class;
    this.interface._cache.open_select_class = false;
    this.interface.student.classes = [_class];
  }
  actionSelectGrade(_grade): void {
    this.interface._cache.grade_selected = _grade;
    this.interface._cache.open_select_grade = false;
    this.interface.student.practice = _grade;
  }
  actionSelectAvatar(index): void {
    this.interface.student.avatar = 'https://cdn.argoprep.com/avatars/avatar-' + index + '.svg';
  }
  actionEnterFirstName(event): void {
    this.interface.student.firstName = event.target.value;
  }
  actionEnterLastName(event): void {
    this.interface.student.lastName = event.target.value;
  }
  actionEnterLogin(event): void {
    this.interface.student.email = event.target.value;
  }
  actionEnterPassword(event): void {
    this.interface.student.password = event.target.value;
  }
  actionGeneratePassword(): void {
    this.interface.student.password = Math.random().toString(36).slice(-6);
  }
  actionToggleSelectClass(): void {
    this.interface._cache.open_select_class = !this.interface._cache.open_select_class;
  }
  actionToggleSelectGrade(): void {
    this.interface._cache.open_select_grade = !this.interface._cache.open_select_grade;
  }

  actionConfirm(): void {
    this.interface.loading = true;
    let _body = this.interface.student;

    this.http.put<UserModel>(API.url('users/students/'+this.interface.student._id+'/teacher'), _body).subscribe({
        next: (res) => {
          this.store.dispatch(new GetTeachersStudents());
          this.store.dispatch(new ModalCloseAction());
          this.interface.loading = false;
          this.cdr.detectChanges();
        },
        error: (err) => {
          this.interface.loading = false;
          this.cdr.detectChanges();
        },
    });
  }
  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}

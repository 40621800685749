import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {ModalCloseAction} from '../../_stores/modal/_actions/modal.actions';
import {API} from '../../_stores/api';
import {HttpClient} from '@angular/common/http';
import {CategoriesState} from '../../_stores/categories/_state/categories.state';
import {CreateCategory} from '../../_stores/categories/_actions/categories.actions';

@Component({
  selector: 'ui-control-category-add',
  templateUrl: './ui-control-category-add.component.html',
  styleUrls: ['./ui-control-category-add.component.scss'],
})
export class UiControlCategoryAddComponent implements OnInit {
  public interface = {
    new_category: {
      order: 0,
      slug: '',
      title: '',
      description: '',
      thumbnail: '',
      type: {
        _id: '',
        title: '',
        description: '',
        order: 0,
        slug: '',
        thumbnail: '',
      },
      parent: '',
      options: {
        visible: false,
      },
      materials: undefined,
    },
    category_visible: false,
    _cache: {
      validate_form_fields: {
        categoryDescription: '',
        categoryThumbnail: '',
        categoryTitle: '',
        categorySlug: '',
        category_material_selected: {
          _id: '',
          title: '',
        },
      },
      parent_category: undefined,
      category_type_selected: {
        _id: '',
        title: '',
        description: '',
        order: 0,
        slug: '',
        thumbnail: '',
      },
      open_select_category: false,
      open_select_category_type: false,
      open_select_material: false,
    },
    _cache_error: { fields: '   ' },
    loading: false,
    category_types: [
      {
        _id: '',
        title: '',
        description: '',
        order: 0,
        slug: '',
        thumbnail: '',
      },
    ],
    materials: [{ _id: '', title: '' }],
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) {}

  ngOnInit(): void {
    this.store.select(CategoriesState.selectCategoriesByModalId).subscribe((category) => {
      this.interface._cache = { ...this.interface._cache, parent_category: category };
    });
    this.initTypesOfCategory();
    this.initMaterialsOfCategory();
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }

  actionEnterCategoryDescription(event) {
    this.interface._cache.validate_form_fields.categoryDescription = event.target.value;
    this.actionValidateFields();
    this.cdr.detectChanges();
  }

  actionValidateFields() {
    this.interface._cache_error.fields = '';
    Object.keys(this.interface._cache.validate_form_fields).map((item) => {
      if (!!this.interface._cache.validate_form_fields[item]) {
      } else {
        this.interface._cache_error.fields = this.interface._cache_error.fields.concat(item.toString());
      }
    });
    this.cdr.detectChanges();
  }

  actionAddCategory() {
    this.interface.new_category = {
      ...this.interface.new_category,
      title: this.interface._cache.validate_form_fields.categoryTitle,
      slug: this.interface._cache.validate_form_fields.categorySlug,
      type: this.interface._cache.category_type_selected,
      thumbnail: this.interface._cache.validate_form_fields.categoryThumbnail,
      description: this.interface._cache.validate_form_fields.categoryDescription,
      parent: this.interface._cache.parent_category,
      options: { visible: this.interface.category_visible },
      materials:
        this.interface._cache.validate_form_fields.category_material_selected._id &&
        this.interface._cache.validate_form_fields.category_material_selected._id.length > 0
          ? this.interface._cache.validate_form_fields.category_material_selected
          : null,
    };
    this.interface.loading = true;
    this.store.dispatch(new CreateCategory({ data: this.interface.new_category }));
  }

  actionEnterCategoryTitle(event) {
    this.interface._cache.validate_form_fields.categoryTitle = event.target.value;
    this.interface._cache.validate_form_fields.categorySlug = this.interface._cache.validate_form_fields.categoryTitle
      .toLowerCase()
      .replace(/ /g, '_');
    this.actionValidateFields();
    this.cdr.detectChanges();
  }

  actionEnterCategorySlug(event) {
    this.interface._cache.validate_form_fields.categorySlug = event.target.value.replace(/ /g, '_');
    this.actionValidateFields();
    this.cdr.detectChanges();
  }

  actionSelectCategoryType(category_type) {
    this.interface._cache = {
      ...this.interface._cache,
      category_type_selected: category_type,
      open_select_category: !this.interface._cache.open_select_category,
    };
    this.cdr.detectChanges();
  }

  actionToggleSelectCategoryMaterial() {
    this.interface._cache = {
      ...this.interface._cache,
      open_select_material: !this.interface._cache.open_select_material,
    };
  }

  actionSelectCategoryMaterial(_category_material_selected) {
    this.interface._cache = {
      ...this.interface._cache,
      open_select_material: !this.interface._cache.open_select_material,
    };
    this.interface._cache.validate_form_fields = {
      ...this.interface._cache.validate_form_fields,
      category_material_selected: _category_material_selected,
    };
    this.actionValidateFields();
    this.cdr.detectChanges();
  }

  actionEnterCategoryThumbnail(event) {
    this.interface._cache.validate_form_fields = {
      ...this.interface._cache.validate_form_fields,
      categoryThumbnail: event.target.value,
    };
    this.actionValidateFields();
    this.cdr.detectChanges();
  }

  actionSelectVisible(event) {
    this.interface.category_visible = !this.interface.category_visible;
  }

  private initTypesOfCategory() {
    this.interface._cache.category_type_selected = {
      _id: '5f563dc90899ef7770857ce1',
      title: 'category',
      description: '',
      order: 0,
      slug: 'category',
      thumbnail: '',
    };
    this.cdr.detectChanges();
  }

  private initMaterialsOfCategory() {
    this.http.get<[{ _id: ''; title: '' }]>(API.url('/material')).subscribe({
      next: (_materials) => {
        this.interface = {
          ...this.interface,
          materials: _materials.filter((type) => this.filteredMaterialTypes(type)),
        };
      },
      error: (err) => {
        //console.log('err: ', err);
      },
      complete: () => {},
    });
  }

  private filteredMaterialTypes(type: { _id: ''; title: '' }) {
    return (
      type.title &&
      (type.title === 'category' || type.title === 'question' || type.title === 'worksheet' || type.title === 'video')
    );
  }
}

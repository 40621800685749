export class AuthLogin  {
  public static readonly type = '[Auth] AuthLogin';
  constructor(public login: string, public password: string) {}
}
export class AuthLogout  {
  public static readonly type = '[Auth] AuthLogout';
  constructor() {}
}
export class DetectAuthToken  {
  public static readonly type = '[Auth] DetectAuthToken';
  constructor() {}
}
export class AuthStatus  {
  public static readonly type = '[Auth] AuthStatus';
  constructor() {}
}
export class AuthSuccess  {
  public static readonly type = '[Auth] AuthSuccess';
  constructor(public token?: string) {}
}

export class AuthError  {
  public static readonly type = '[Auth] AuthError';
  constructor(public error: any) {}
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BhGetPremiumComponent} from "./bh-get-premium.component";
import {NgxStripeModule} from "ngx-stripe";
import {TranslocoModule} from "@ngneat/transloco";


@NgModule({
  declarations: [BhGetPremiumComponent],
  exports: [BhGetPremiumComponent],
    imports: [
        CommonModule,
        NgxStripeModule.forRoot('pk_live_4R0JoUhfmMQU5BhzyWm30pPo'),
        TranslocoModule
    ]
})
export class BhGetPremiumModule { }

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslocoModule} from '@ngneat/transloco';
import {UiControlVideoEditComponent} from './ui-control-video-edit.component';
import {UiTooltipModule} from '../ui-tooltip/ui-tooltip.module';
import {UiVideoplayerModule} from '../ui-videoplayer/ui-videoplayer.module';

@NgModule({
  declarations: [UiControlVideoEditComponent],
  exports: [UiControlVideoEditComponent],
  imports: [CommonModule, TranslocoModule, UiTooltipModule, UiVideoplayerModule],
})
export class UiControlVideoEditModule {}

import {Pipe, PipeTransform} from '@angular/core';
import {orderBy} from 'lodash';

/*
 *ngFor="let c of oneDimArray | practiceSort:'asc'"
 *ngFor="let c of oneDimArray | practiceSort:'desc'"
 *ngFor="let c of arrayOfObjects | practiceSort:'asc':'propertyName'"
*/

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(value: any[], order = '', column: string = ''): any[] {

    if (!value || order === '' || !order) {
      return value;
    } // no array

    if (value.length <= 1) {
      return value;
    } // array with only one item


    if (!column || column === '') {
      if (order === 'asc'){
        return value.sort();
      }
      else{
        return value.sort().reverse();
      }
    } // sort 1d array
    // @ts-ignore
    return orderBy(value, [column], [order]);
  }

}

<div class="ui-profile-container">
  <div class="ui-form ui-profile-add-form">
    <div class="ui-form-input-group">
      <div class="ui-form-text ui-form-text-xs">Enter a user ID to automatically fill out the form</div>
      <div class="ui-form-input" [class.ui-profile-input-error]="interface._cache.error_fields.includes('firstName')">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
        <input id='user_number' name='user_number' type='number' min="1000000" max="9999999" [value]="interface._cache.user_number || ''" (keyup)='actionEnterAIN($event)' placeholder="User AIN">
      </div>
      <div class="ui-form-text ui-form-text-error ui-form-text-xs hidden">User AIN not found, please use the form</div>
    </div>
    <div class="ui-form-divider">
      <div class="ui-form-divider-line" aria-hidden="true">
        <span></span>
      </div>
      <div class="ui-form-divider-text">
        <span>OR</span>
      </div>
    </div>
    <div class='ui-form-select' [class.ui-register-select-error]="interface._cache.error_fields.includes('school')" [class.ui-form-select-readonly]="interface._cache.ain">
      <svg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='1.5'>
        <path d='M12 14l9-5-9-5-9 5 9 5z' />
        <path d='M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z' />
        <path stroke-linecap='round' stroke-linejoin='round' d='M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222' />
      </svg>
      <input id='school' name='school' type='text' readonly [value]="interface._cache.selected_school" placeholder="{{'inputs.school' | transloco}}" (click)="actionToggleSearchSchool()">
      <div class="ui-form-select-list" [class.ui-form-select-list-open]="interface._cache.open_search_school">
        <div class='ui-form-input'>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
          <input id='school_search' name='school_search' type='text' required (keyup)='actionSearchSchool($event)' placeholder="{{'inputs.school_search' | transloco}}" autoComplete='off'>
        </div>
        <div class="ui-form-select-list-items ui-select-school-list">
          <ng-container *ngIf="interface.schools.length == 0">
            <div class="ui-form-select-list-item">
              <p>Not found</p>
              <span>No schools from your request</span>
            </div>
          </ng-container>
          <ng-container *ngFor='let school of interface.schools'>
            <div class="ui-form-select-list-item" (click)="actionSelectSchool(school)">
              <p>{{school.schoolName}}</p>
              <span>{{school.address}}, {{school.city}}</span>
            </div>
          </ng-container>
        </div>

      </div>
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" (click)="actionToggleSearchSchool()">
        <path *ngIf="!interface._cache.open_search_school" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
        <path *ngIf="interface._cache.open_search_school" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
      </svg>
    </div>
    <div class="ui-profile-avatar">
      <div class="ui-profile-avatar-current" tooltip title="Select your avatar from list">
        <svg *ngIf="!interface.user.avatar" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
        <img *ngIf="interface.user.avatar" src="{{interface.user.avatar}}" alt="" loading="lazy">
      </div>
      <div class="ui-profile-avatars-list" [class.ui-profile-avatars-list-disabled]="interface._cache.ain">
        <ng-container *ngFor="let avatar of [].constructor(12); let a=index">
          <img src="https://cdn.argoprep.com/avatars/avatar-{{a}}.svg" alt="{{a}}" loading="lazy" (click)="actionSelectAvatar(a)">
        </ng-container>
      </div>
    </div>
    <div class="ui-form-radio-group" [class.ui-form-radio-group-disabled]="interface._cache.ain">
      <ng-container *ngFor='let role of interface.roles'>
        <div class="ui-form-radio ui-form-radio-xs" [class.ui-form-radio-selected]="interface.user.role._id == role._id" (click)="actionSelectRole(role)">
          <input name="firstname" type="radio" [checked]="interface.user.role._id == role._id">
          <span>{{role.name | titlecase}}</span>
        </div>
      </ng-container>
    </div>
    <div class="ui-form-input" [class.ui-form-input-error]="interface._cache.error_fields.includes('firstName')" [class.ui-form-input-readonly]="interface._cache.ain">
      <svg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='1.5'>
        <path stroke-linecap='round' stroke-linejoin='round' d='M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z' />
      </svg>
      <input id="firstname" name="firstname" type="text" (keyup)="actionEnterFirstName($event)" required placeholder="{{'inputs.firstname' | transloco}}" autoComplete='off' [readonly]="interface._cache.ain">
    </div>
    <div class="ui-form-input" [class.ui-form-input-error]="interface._cache.error_fields.includes('lastName')" [class.ui-form-input-readonly]="interface._cache.ain">
      <svg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='1.5'>
        <path stroke-linecap='round' stroke-linejoin='round' d='M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z' />
      </svg>
      <input id="lastname" name="lastname" type="text" (keyup)="actionEnterLastName($event)" required placeholder="{{'inputs.lastname' | transloco}}" autoComplete='off' [readonly]="interface._cache.ain">
    </div>
  </div>
  <div class="ui-profile-add-action">
    <button class="ui-profile ui-profile-add" (click)="actionAddProfile()" [disabled]="interface.user.firstName.length == 0 || interface.user.lastName.length == 0 || interface.user.avatar.length == 0">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
      </svg>
      <span>{{'buttons.confirm' | transloco}}</span>
    </button>
  </div>
</div>

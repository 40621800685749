import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {UiAppNoDataModule} from "../../_ui/ui-app-no-data/ui-app-no-data.module";
import {UiTooltipModule} from "../../_ui/ui-tooltip/ui-tooltip.module";
import {PipesModule} from "../../app.pipes.module";
import {AppTranslocoModule} from "../../app.transloco.module";
import {UiPaginationModule} from "../../_ui/ui-pagination/ui-pagination.module";
import {UiControlSchoolsNoLicenseComponent} from "./ui-control-schools-no-license.component";


@NgModule({
  declarations: [UiControlSchoolsNoLicenseComponent],
  exports: [UiControlSchoolsNoLicenseComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: UiControlSchoolsNoLicenseComponent,
        children: [
          {
            path: ':license',
            component: UiControlSchoolsNoLicenseComponent,
          },
        ],
      },
    ]),
    UiAppNoDataModule,
    UiTooltipModule,
    PipesModule,
    AppTranslocoModule,
    UiPaginationModule,
  ],
})
export class UiControlSchoolsNoLicenseModule { }

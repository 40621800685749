import {AssignmentModel} from '../../../_models/assignment.model';
import {MaterialModel} from '../../../_models/material.model';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  ClearAssignments,
  CreateAssignment,
  DeleteAssignment,
  EditAssignment,
  GetAssignment,
  GetAssignmentList,
  GetHomeworks
} from "../_actions/asignments.actions";
import {AssignmentsService} from '../_services/assignments.service';
import {PracticeStateModel} from '../../practice/_state/practice.state';
import {EventAssignmentCreated} from '../../event_ws/_actions/events.actions';
import {RouterState} from '@ngxs/router-plugin';
import {RouterStateModel as RouterStateOuterModel} from '@ngxs/router-plugin/src/router.state';
import {RouterStateModel} from '../../../_models/router-state.model';
import {ModalState} from '../../modal/_state/modal.state';
import {ModalModel} from '../../../_models/modal.model';
import {UserState} from "../../user/_state/user.state";
import {first} from "rxjs";
import {GetClassList} from "../../classes/_actions/classes.actions";

export interface AssignmentsStateModel {
  isLoading: boolean;
  lastCreatedAssignment: AssignmentModel;
  assignmentList: AssignmentModel[];
  assignment: AssignmentModel;
  selectedMaterialMap: { [id: string]: MaterialModel };
  selectedEditMaterialMap: { [id: string]: MaterialModel };
}

export const clearAssignments = {
  isLoading: false,
  lastCreatedAssignment: null,
  assignmentList: [],
  assignment: null,
  selectedMaterialMap: {},
  selectedEditMaterialMap: {},
};

@State<AssignmentsStateModel>({
  name: 'SAP_ASSIGNMENTS',
  defaults: clearAssignments,
})
@Injectable()
export class AssignmentsState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  constructor(private store: Store, private assignmentsService: AssignmentsService) {}

  @Selector()
  public static selectAssignmentsState(state: AssignmentsStateModel) {
    return state;
  }

  @Selector()
  public static selectAssignment(state: AssignmentsStateModel) {
    return state.assignment;
  }

  @Selector()
  public static selectAssignmentList(state: AssignmentsStateModel) {
    return state.assignmentList;
  }

  @Selector()
  public static selectHomeworkList(state: AssignmentsStateModel) {
    return state.assignmentList;
  }

  @Selector()
  public static selectLastCreatedAssignment(state: AssignmentsStateModel) {
    return state.lastCreatedAssignment;
  }

  @Selector()
  public static selectSelectedMaterialMap(state: AssignmentsStateModel) {
    return state.selectedMaterialMap;
  }

  @Selector()
  public static selectEditSelectedMaterialMap(state: AssignmentsStateModel) {
    return state.selectedEditMaterialMap;
  }

  @Selector([RouterState])
  public static selectAssignmentsByClassRouteId(
    state: AssignmentsStateModel,
    route: RouterStateOuterModel<RouterStateModel>,
  ) {
    return state.assignmentList.filter((_assignment) => _assignment.class._id == route.state.params.class_id);
  }

  @Selector([ModalState])
  public static selectAssignmentsByModalId(state: AssignmentsStateModel, modal: ModalModel) {
    return state.assignmentList.find((_assignment) => _assignment._id == modal._id);
  }

  @Selector([RouterState])
  public static selectAssignmentsByRouteId(
    state: AssignmentsStateModel,
    route: RouterStateOuterModel<RouterStateModel>,
  ) {
    return state.assignmentList.find((_assignment) => _assignment._id == route.state.params.assignment_id);
  }

  @Selector([RouterState])
  public static selectHomeworkByRouteId(state: AssignmentsStateModel, route: RouterStateOuterModel<RouterStateModel>) {
    return state.assignmentList.find((_assignment) => _assignment._id == route.state.params.homeworks_id);
  }

  static selectAssignmentById(id: string) {
    return createSelector([AssignmentsState.selectAssignmentList], (assignment: AssignmentModel[]) => {
      return assignment.find((x) => x._id === id);
    });
  }

  static selectHomeworkById(id: string) {
    return createSelector([AssignmentsState.selectHomeworkList], (assignment: AssignmentModel[]) => {
      return assignment.find((x) => x._id === id);
    });
  }

  static selectHomeworkByIdMaterialId(id: string, matId: string ) {
    return createSelector([AssignmentsState.selectHomeworkById(id)], (assignment: AssignmentModel) => {
      return assignment.materials.find((x) => x.id === matId);
    });
  }

  ngxsAfterBootstrap(ctx?: StateContext<PracticeStateModel>): void {
    this.store.select(UserState.selectUser).pipe(
      first(user => !!user)
    ).subscribe(
      user => {
        if (user.role.name.toLowerCase() === 'teacher' || user.role.name.toLowerCase() === 'parent'){
          ctx.dispatch(new GetAssignmentList());
          ctx.dispatch(new GetClassList());
        }
        if (user.role.name.toLowerCase() === 'student'){
          ctx.dispatch(new GetHomeworks());
        }
      }
    );
  }

  ngxsOnInit(ctx?: StateContext<PracticeStateModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange<PracticeStateModel>): void {}

  @Action(CreateAssignment)
  create({ getState, patchState }: StateContext<AssignmentsStateModel>, { payload }: CreateAssignment) {
    patchState({
      isLoading: true,
    });
    this.assignmentsService.createAssignment(payload.data).subscribe({
      next: (res) => {
        const state = getState();
        patchState({
          assignmentList: [res, ...state.assignmentList],
          lastCreatedAssignment: res,
          isLoading: false,
        });
        this.store.dispatch(new EventAssignmentCreated({ assignmentId: res._id }));
        this.store.dispatch(new GetAssignmentList());
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  @Action(EditAssignment)
  edit(ctx: StateContext<AssignmentsStateModel>, { payload }: EditAssignment) {
    ctx.patchState({
      isLoading: true,
    });
    return this.assignmentsService.editAssignment(payload.data).subscribe({
      next: (res) => {
        const state = ctx.getState();
        ctx.dispatch(new GetAssignmentList());
        ctx.setState({
          ...state,
        });
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  @Action(GetAssignment)
  getAssignment({ getState, setState }: StateContext<AssignmentsStateModel>, { payload }: any) {
    const state = getState();
    setState({
      ...state,
      assignment: null,
    });
    return this.assignmentsService.getAssignmentsList().subscribe({
      next: (res: any) => {
        setState({
          ...state,
          assignment: res,
        });
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  @Action(DeleteAssignment)
  deleteAssignment({ getState, setState }: StateContext<AssignmentsStateModel>, { payload }: any) {
    const state = getState();
    setState({
      ...state,
      assignment: null,
    });
    return this.assignmentsService.deleteAssignment(payload.assignmentID).subscribe({
      next: (res: AssignmentModel) => {
        setState({
          ...state,
          assignmentList: state.assignmentList.filter((m) => m._id !== res._id),
        });
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  @Action(GetAssignmentList)
  getAssignmentList({ getState, setState }: StateContext<AssignmentsStateModel>) {
    return this.assignmentsService.getAssignmentsList().subscribe({
      next: (res) => {
        const state = getState();
        setState({
          ...state,
          assignmentList: res,
        });
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  @Action(GetHomeworks)
  getHomeworkList({ getState, setState }: StateContext<AssignmentsStateModel>) {
    return this.assignmentsService.getHomeworksList().subscribe({
      next: (res) => {
        const state = getState();
        setState({
          ...state,
          assignmentList: res,
        });
      },
      error: (err) => {},
      complete: () => {},
    });
  }


  @Action(ClearAssignments)
  clearAssignments({ getState, setState }: StateContext<AssignmentsStateModel>) {
    const state = getState();
    setState({
      ...state,
      assignmentList: [],
    });
  }

}

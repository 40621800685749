import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {UserModel} from "../../_models/user.model";
import {GradeModel} from "../../_models/grade.model";
import {RoleModel} from "../../_models/role.model";
import {SchoolModel} from "../../_models/school.model";
import {Store} from "@ngxs/store";
import {HttpClient} from "@angular/common/http";
import {UserState} from "../../_stores/user/_state/user.state";
import {API} from "../../_stores/api";
import {ModalCloseAction} from "../../_stores/modal/_actions/modal.actions";
import {CurrentUsers} from "../../_stores/user/_actions/user.actions";

@Component({
  selector: 'ui-profile-bind',
  templateUrl: './ui-profile-bind.component.html',
  styleUrls: ['./ui-profile-bind.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiProfileBindComponent implements OnInit {
  interface: {
    loading: boolean;
    student: UserModel;
    user: UserModel;
    _cache: {
      check_ain: boolean;
    }
  } = {
    loading: false,
    student: new (class implements UserModel {
      _id: string;
      avatar: string;
      language: string;
      displayName: string;
      email: string;
      firstName: string;
      lastName: string;
      password: string;
      practice: string | GradeModel;
      role: RoleModel;
      school: SchoolModel;
      ain: number;
    })(),
    user: undefined,
    _cache: {
      check_ain: true
    },
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) {

  }

  ngOnInit(): void {
    this.initDefaultStudent();
    this.initGlobalUser();

  }

  initDefaultStudent(): void {
    this.interface.student = {
      _id: '',
      avatar: '',
      language: 'en',
      displayName: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      practice: '',
      role: {
        _id: '62ebc2e38e33b7bc52c853c6',
        name: 'Student'
      },
      school: undefined,
      ain: null,
    };
  }
  initGlobalUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.interface.student.school = this.interface.user.school;
    });
  }



  actionEnterAin(event): void {
    if (event.target.value.length >= 0 && event.target.value.length <= 7) {
      this.interface.student.ain = event.target.value;
    } else {
      event.target.value = this.interface.student.ain;
    }
    if (this.interface.student.ain > 1000000 && this.interface.student.ain < 9999999) {
      this.actionCheckUserAin();
    }
  }
  actionCheckUserAin(): void {
    this.http.get<UserModel>(API.url('users/student/ain/'+this.interface.student.ain)).subscribe({
      next: (user) => {
        this.interface.student = user;
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.interface.student.ain = null;
        this.interface._cache.check_ain = false;
        this.cdr.detectChanges();
      },
    });


  }

  actionConfirm(): void {
    this.interface.loading = true;
    let _body;
    _body = {
      ain: this.interface.student.ain,
    }
    this.http.put(API.url('auth/bind/user'), _body).subscribe({
      next: (value) => {
        this.interface.loading = false;
        this.store.dispatch(new CurrentUsers());
        this.store.dispatch(new ModalCloseAction());
      },
      error: (err) => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }
  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}

import {ChangeDetectorRef, Component, Input, OnInit} from "@angular/core";
import {ClassModel} from "../../_models/class.model";
import {Store} from "@ngxs/store";
import {Router} from "@angular/router";
import {Clipboard} from "@angular/cdk/clipboard";
import {NotificationOpen} from "../../_stores/notification/_actions/notification.actions";

@Component({
  selector: 'ui-class',
  templateUrl: './ui-class.component.html',
  styleUrls: ['./ui-class.component.scss']
})
export class UiClassComponent implements OnInit {
  @Input('class') set _class(_class) {
    if (_class) {
      this.interface.class = _class;
    }
  }
  interface: {
    class: ClassModel
  } = {
    class: undefined
  }
  constructor(private store: Store, private cdr: ChangeDetectorRef, private router: Router, private clipboard: Clipboard) { }

  ngOnInit(): void {
  }

  actionLinkToInvite(school_class: ClassModel) {
    const _invite_url = window.location.origin + '/invite/' + school_class.sin;
    this.clipboard.copy(_invite_url);
    this.store.dispatch(new NotificationOpen('class_invite_link_copy'));
    this.cdr.detectChanges();
  }
}

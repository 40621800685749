import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API} from '../../api';
import {UserModel} from '../../../_models/user.model';
import {StudentModel} from '../../../_models/ui-page-students.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public registerUser(user: any) {
    return this.http.post<UserModel>(API.url('auth/register'), user);
  }

  public inviteUser(user: any) {
    return this.http.post<UserModel>(API.url('auth/register/invitation'), user);
  }

  public inviteStudent(user: any) {
    return this.http.post<UserModel>(API.url('auth/register/invitation'), user);
  }

  public addUser(user: UserModel) {
    return this.http.post<UserModel>(API.url('auth/add/user'), user);
  }

  public getCurrentUsers(user_index?: number) {
    return this.http.get<UserModel[]>(API.url('auth/current' + (user_index ? '/' + user_index : '')));
  }

  public setGlobalUser(user_index: number) {
    return this.http.get<UserModel[]>(API.url('auth/current/' + user_index));
  }

  public setUserPractice(practice_id: number) {
    return this.http.post<UserModel[]>(API.url('users/practice/' + practice_id), {});
  }

  public getTeachersStudents() {
    return this.http.get<StudentModel[]>(API.url('users/students/teacher'));
  }

  public getTeachersStudentsPagination(page, limit) {
    return this.http.get<{
      currentPage: number;
      data: UserModel[];
      perPage: number;
      totalData: number;
      totalPage: number;
    }>(API.url('users/students/teacher/controls?page=' + page + '&limit=' + limit));
  }

  public getTeachersParents() {
    return this.http.get<UserModel[]>(API.url('users/parents/teacher'));
  }

  public getUserById(id: string) {
    return this.http.get<UserModel>(API.url('users/' + id));
  }
  public sendVerifyCode() {
    return this.http.get<any>(API.url('auth/send-verify-code'));
  }
  public checkVerifyCode(_code:number) {
    return this.http.post<any>(API.url('auth/check-verify-code'), {code: _code});
  }



  public getStripeCustomer() {
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('users/stripe/customer'+randomHash));
  }
  public getStripeInvoices() {
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('users/stripe/invoices'+randomHash));
  }
  public createPaymentIntent(amount: any) {
    return this.http.post<any>(API.url('users/stripe/payment-intent'), {amount});
  }
  public checkoutStripe(stripe: any) {
    return this.http.post<any>(API.url('users/stripe/checkout'), {stripe});
  }

  public getStripeSubscriptions() {
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('users/stripe/subscriptions'+randomHash));
  }
  public startStripeSubscription(stripe: any) {
    return this.http.post<any>(API.url('users/stripe/subscription/create'), {stripe});
  }
  public pauseStripeSubscription(subscribe_id: any) {
    return this.http.post<any>(API.url('users/stripe/subscription/pause'), {subscribe_id});
  }
  public resumeStripeSubscription(subscribe_id: any) {
    return this.http.post<any>(API.url('users/stripe/subscription/resume'), {subscribe_id});
  }
  public cancelStripeSubscription(subscribe_id: any) {
    return this.http.post<any>(API.url('users/stripe/subscription/cancel'), {subscribe_id});
  }



}

import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {SchoolModel} from '../../../_models/school.model';
import {Injectable} from '@angular/core';
import {SchoolsService} from '../_services/schools.service';
import {CreateSchool, DeleteSchool, EditSchool, GetSchool, GetSchools} from '../_actions/school.actions';

export interface SchoolStateModel {
  isLoading: boolean;
  schoolList: SchoolModel[];
  school: SchoolModel;
}

export const clearSchools = {
  isLoading: false,
  schoolList: [],
  school: null,
};

@State<SchoolStateModel>({
  name: 'SAP_SCHOOLS',
  defaults: clearSchools,
})
@Injectable()
export class SchoolsState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  constructor(private store: Store, private schoolsService: SchoolsService) {}

  @Selector()
  public static selectSchoolsState(state: SchoolStateModel) {
    return state;
  }

  @Selector()
  public static selectSchool(state: SchoolStateModel) {
    return state.school;
  }

  @Selector()
  public static selectSchoolList(state: SchoolStateModel) {
    return state.schoolList;
  }

  ngxsAfterBootstrap(ctx?: StateContext<SchoolStateModel>): void {}

  ngxsOnInit(ctx?: StateContext<SchoolStateModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange<SchoolStateModel>): void {}

  @Action(CreateSchool)
  create({ getState, patchState }: StateContext<SchoolStateModel>, { payload }: CreateSchool) {
    patchState({
      isLoading: true,
    });
    this.schoolsService.createSchool(payload.data).subscribe({
      next: (res) => {
        const state = getState();
        patchState({
          schoolList: [res, ...state.schoolList],
          isLoading: false,
        });
        // this.store.dispatch(new EventSchoolCreated({ schoolId: res._id }));
        this.store.dispatch(new GetSchools());
        window.location.reload();
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  @Action(GetSchools)
  getSchoolList({ getState, setState }: StateContext<SchoolStateModel>) {
    return this.schoolsService.getSchoolsList().subscribe({
      next: (res) => {
        const state = getState();
        setState({
          ...state,
          schoolList: res,
        });
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  @Action(GetSchool)
  getSchool({ getState, setState, patchState }: StateContext<SchoolStateModel>, payload) {
    patchState({
      isLoading: true,
      school: null,
    });
    return this.schoolsService.getSchool(payload.schoolId).subscribe({
      next: (res) => {
        const state = getState();
        setState({
          ...state,
          school: res,
          isLoading: false,
        });
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  @Action(EditSchool)
  edit({ getState, patchState }: StateContext<SchoolStateModel>, { payload }: CreateSchool) {
    patchState({
      isLoading: true,
    });
    this.schoolsService.editSchool(payload.data).subscribe({
      next: (res) => {
        const state = getState();
        patchState({
          school: res,
          schoolList: [res, ...state.schoolList],
          isLoading: false,
        });
        // this.store.dispatch(new EventSchoolEdit({ schoolId: res._id }));
        this.store.dispatch(new GetSchools());
        window.location.reload();
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  @Action(DeleteSchool)
  delete({ getState, patchState }: StateContext<SchoolStateModel>, { payload }) {
    patchState({
      isLoading: true,
    });
    this.schoolsService.deleteSchool(payload.schoolID).subscribe({
      next: (res) => {
        const state = getState();

        patchState({
          schoolList: state.schoolList.filter((value) => value._id === res._id),
          isLoading: false,
        });
        // this.store.dispatch(new EventSchoolDelete({ schoolId: res._id }));
        this.store.dispatch(new GetSchools());
        window.location.reload();
      },
      error: (err) => {},
      complete: () => {},
    });
  }
}

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UiPageBuilderModel} from '../../_models/UiPageAssignmentBuilder.model';
import {Store} from '@ngxs/store';
import {ActivatedRoute, Router} from '@angular/router';
import {AssignmentBuilderService} from '../../_utils/assignment-builder-service';
import {ClassesState} from '../../_stores/classes/_state/classes.state';
import {UserState} from '../../_stores/user/_state/user.state';
import {AssignmentModel, CreateAssignmentDataModel} from '../../_models/assignment.model';
import {CreateAssignment, EditAssignment} from '../../_stores/asignments/_actions/asignments.actions';
import {PracticeState} from '../../_stores/practice/_state/practice.state';
import {PracticeService} from '../../_stores/practice/_service/practice.service';
import {
  AssignmentsBuilderClearMaterials,
  AssignmentsBuilderRemoveEBook,
  AssignmentsBuilderRemoveQuiz,
  AssignmentsBuilderRemoveVideo,
  AssignmentsBuilderRemoveWorksheet,
} from '../../_stores/assignments-builder/_actions/assignments-builder.actions';
import {CategoryModel} from '../../_models/category.model';
import {HttpClient} from '@angular/common/http';
import {API} from '../../_stores/api';

@Component({
  selector: 'ui-page-assignment-builder',
  templateUrl: './ui-page-assignment-builder.component.html',
  styleUrls: ['./ui-page-assignment-builder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiPageAssignmentBuilderComponent implements OnInit {
  interface: UiPageBuilderModel = {
    adding_materials: [],
    assign_materials: [],
    edit_assignment: undefined,
    edit_assignment_id: '',
    assignment: undefined,
    classes: [],
    grades: [],
    user: undefined,
    update: false,
    _cache: {
      open_select_class: false,
      open_select_grade: false,
      open_select_students: false,
      open_preview_worksheet: false,
      class_selected: undefined,
      grade_selected: undefined,
      category_selected: undefined,
      student_selected: undefined,
      category_selected_tabs: [],
      category_selected_active_tab: undefined,
      now: new Date(),
      categories: [],
      students: [],
      preview_worksheet: undefined,
    },
  };
  restore = true;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private practiceService: PracticeService,
    private assignmentBuilderService: AssignmentBuilderService,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.initEditAssignment();
    this.initDefaultAssignment();

    this.initGlobalUser();
    this.initGlobalUserClasses();
    this.initGlobalStudents();
    this.initGrades();
    this.cdr.detectChanges();
  }

  private initEditAssignment() {
    this.interface.edit_assignment_id = this.route.snapshot.paramMap.get('assignment_id');
    if (this.interface.edit_assignment_id && this.interface.edit_assignment_id.length > 0) {
      this.http
        .get<AssignmentModel>(API.url('assignments/' + this.interface.edit_assignment_id + '/creator'))
        .subscribe({
          next: (value) => {
            if (value) {
              this.interface = { ...this.interface, edit_assignment: value };
              this.interface = { ...this.interface, assignment: this.interface.edit_assignment };
              this.interface._cache = { ...this.interface._cache, class_selected: this.interface.assignment.class };
              this.interface = { ...this.interface, assign_materials: this.interface.edit_assignment.materials };
              this.cdr.detectChanges();
            } else {
              this.initDefaultAssignment();
            }
          },
        });
    }
  }

  initDefaultAssignment(): void {
    this.interface.assignment = {
      _id: '',
      class: undefined,
      creator: undefined,
      dates: { dueDate: '', scheduleDate: '', updated_at: '' },
      description: ' ',
      materials: [],
      options: { icon: '', isActive: false },
      order: 1,
      slug: '',
      students: [],
      title: '',
    };
    this.interface.assignment = { ...this.interface.assignment, materials: this.assignmentBuilderService.materials };
    this.assignmentBuilderService.ebooks.forEach((book) => {
      this.interface.assignment.materials.push(book);
    });
  }

  initGlobalUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
    });
  }

  initGlobalUserClasses(): void {
    this.store.select(ClassesState.selectClassList).subscribe({
      next: (_classes) => {
        this.interface.classes = _classes;
      },
    });
  }

  initGlobalStudents() {
    this.store.select(UserState.selectAllStudentsOfCurrentTeacher).subscribe({
      next: (_students) => {
        this.interface._cache.students = _students;
        this.cdr.detectChanges();
      },
    });
  }

  initGrades(): void {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.interface.grades = _grades;
      this.cdr.detectChanges();
    });
  }

  initGradeCategories(): void {
    this.interface.update = true;
    this.interface._cache.categories = [];
    this.interface._cache.category_selected = undefined;
    this.interface._cache.category_selected_tabs = [];
    this.interface._cache.category_selected_active_tab = undefined;
    this.practiceService.getCategoriesById(this.interface._cache.grade_selected.category).subscribe((_categories) => {
      this.interface._cache.categories = _categories;
      this.interface.update = false;
      this.cdr.detectChanges();
    });
  }

  actionToggleSelectClass(): void {
    this.interface._cache.open_select_class = !this.interface._cache.open_select_class;
  }

  actionToggleSelectGrade(): void {
    this.interface._cache.open_select_grade = !this.interface._cache.open_select_grade;
  }

  actionTogglePreviewWorksheet(item?): void {
    if (item) {
      this.interface._cache.preview_worksheet = item;
    }
    this.interface._cache.open_preview_worksheet = !this.interface._cache.open_preview_worksheet;
  }

  actionToggleSelectStudents(): void {
    this.interface._cache.open_select_students = !this.interface._cache.open_select_students;
  }

  actionAssignmentName(event): void {
    this.interface.assignment.title = event.target.value;
  }

  actionSelectClass(_class): void {
    this.interface._cache.class_selected = _class;
    this.interface._cache.open_select_class = false;
    this.interface.assignment.class = _class;
    this.interface.assignment.students = _class.students;
  }

  actionSelectStudent(_student): void {
    this.interface._cache.student_selected = _student;
    this.interface._cache.open_select_students = false;
    this.interface.assignment.students.push(_student);
    this.interface.assignment.class = this.interface.classes[0];
  }

  actionSelectDueDate(date): void {
    this.interface.assignment.dates.dueDate = date;
  }

  actionSelectScheduledDate(date): void {
    this.interface.assignment.dates.scheduleDate = date;
  }

  actionEnterInstructions(event): void {
    this.interface.assignment.description = event.target.value;
  }

  actionSelectGrade(_grade): void {
    this.interface._cache.grade_selected = _grade;
    this.interface._cache.open_select_grade = false;
    this.initGradeCategories();
  }

  actionSelectMaterialCategory(_category): void {
    this.interface._cache.category_selected = _category;
    this.interface._cache.category_selected_tabs = _category.children;
    this.actionChangeActiveTab(0);
  }

  actionChangeActiveTab(i): void {
    this.interface._cache.category_selected_active_tab = this.interface._cache.category_selected_tabs[i];
  }

  actionAssignMaterial(_assign_material): void {
    this.interface.assignment.materials = this.interface.assignment.materials.filter(
      (_material) => _material._id != _assign_material._id,
    );
    if (this.interface.edit_assignment) {
      this.interface.adding_materials = this.interface.adding_materials.filter(
        (_material) => _material._id != _assign_material._id,
      );
      this.interface.adding_materials.push(_assign_material);
      this.interface.assignment.materials.push(_assign_material);
    } else {
      this.interface.assignment.materials.push(_assign_material);
    }
    this.cdr.detectChanges();
  }

  actionUnAssignMaterial(_assign_material: CategoryModel): void {
    this.interface.assignment.materials = this.interface.assignment.materials.filter(
      (_material) => _material._id != _assign_material._id,
    );
    this.unAssignInState(_assign_material);
    this.assignmentBuilderService.materials = this.assignmentBuilderService.materials.filter(
      (_material) => _material._id != _assign_material._id,
    );
    this.cdr.detectChanges();
  }

  actionCheckAssignMaterial(_assign_material): boolean {
    return !!this.interface.assignment.materials.find((_material) => _material._id == _assign_material._id);
  }

  actionCloseMaterialTree(): void {
    this.interface._cache.grade_selected = undefined;
    this.interface._cache.categories = [];
    this.interface._cache.category_selected = undefined;
    this.interface._cache.category_selected_tabs = [];
    this.interface._cache.category_selected_active_tab = undefined;
  }

  actionCheckCrateAssignment(): boolean {
    let _check = true;

    if (this.interface.assignment.title.length == 0) {
      _check = false;
    }
    if (!this.interface.assignment.class) {
      _check = false;
    }
    if (this.interface.assignment.students.length == 0) {
      _check = false;
    }
    if (this.interface.assignment.materials.length == 0) {
      _check = false;
    }
    return _check;
  }

  actionCreateAssignment(): void {
    const data: CreateAssignmentDataModel = {
      description: this.interface.assignment.description,
      title: this.interface.assignment.title,
      class: this.interface.assignment.class._id,
      materials: this.interface.assignment.materials.reduce((materials, material) => {
        let _mat_type = material.materials;
        if (_mat_type === 'question') {
          _mat_type = 'Category';
        }
        if (_mat_type === 'video') {
          _mat_type = 'Video';
        }
        if (_mat_type === 'worksheet') {
          _mat_type = 'Worksheet';
        }
        if (_mat_type === 'book') {
          _mat_type = 'Book';
          materials.push({ id: material._id, type: _mat_type, pages: material.pages });
          return materials;
        }
        materials.push({ id: material._id, type: _mat_type });
        return materials;
      }, []),
      students: this.interface.assignment.students.map((item) => item._id),
      dates: {
        scheduleDate: this.interface.assignment.dates.scheduleDate,
        dueDate: this.interface.assignment.dates.dueDate,
      },
    };
    this.store.dispatch(new CreateAssignment({ data })).subscribe({
      next: (value) => {
        this.store.dispatch(new AssignmentsBuilderClearMaterials());
        this.assignmentBuilderService.clearEbooks();
        this.assignmentBuilderService.clearTotalPages();
        this.assignmentBuilderService.clearCount();
        this.assignmentBuilderService.materials = [];
        this.cdr.detectChanges();
        this.router.navigate(['/assignments']).then();
      },
    });
  }

  private unAssignInState(_assign_material: CategoryModel) {
    if (_assign_material.materials === 'video' || !_assign_material.materials) {
      this.store.dispatch(new AssignmentsBuilderRemoveVideo({ video: _assign_material }));
    }
    if (_assign_material.materials === 'question') {
      this.store.dispatch(new AssignmentsBuilderRemoveQuiz({ quiz: _assign_material }));
    }

    if (_assign_material.materials === 'worksheet') {
      this.store.dispatch(new AssignmentsBuilderRemoveWorksheet({ worksheet: _assign_material }));
    }
    if (_assign_material.materials === 'Book') {
      this.store.dispatch(new AssignmentsBuilderRemoveEBook({ e_book: _assign_material }));
    }
  }

  actionEditAssignment() {
    let materialsForEditAssignments = [];
    this.interface.adding_materials.forEach((material) => {
      let _mat_type = material.materials;
      if (_mat_type === 'question') {
        _mat_type = 'Category';
      }
      if (_mat_type === 'video') {
        _mat_type = 'Video';
      }
      if (_mat_type === 'worksheet') {
        _mat_type = 'Worksheet';
      }
      materialsForEditAssignments.push({ id: material._id, type: _mat_type });
    });
    let concat = this.interface.assign_materials.concat(materialsForEditAssignments);
    let edit_assignment: AssignmentModel = {
      _id: this.interface.assignment._id,
      class: this.interface.assignment.class,
      creator: this.interface.assignment.creator,
      dates: this.interface.assignment.dates,
      description: this.interface.assignment.description,
      materials: concat,
      options: this.interface.assignment.options,
      order: this.interface.assignment.order,
      slug: this.interface.assignment.slug,
      students: this.interface.assignment.students,
      title: this.interface.assignment.title,
    };
    this.store.dispatch(new EditAssignment({ data: edit_assignment })).subscribe({
      next: (value) => {
        this.store.dispatch(new AssignmentsBuilderClearMaterials());
        this.assignmentBuilderService.clearEbooks();
        this.assignmentBuilderService.clearTotalPages();
        this.assignmentBuilderService.clearCount();
        this.assignmentBuilderService.materials = [];
        this.cdr.detectChanges();
        this.router.navigate(['/assignments']).then();
      },
    });
  }

  isAssignedYetMaterials(material: any) {
    return !!this.interface.assign_materials.find((value) => value._id === material._id);
  }

  private transformMaterials(material) {
    let _mat_type = material.materials;
    if (_mat_type === 'question') {
      _mat_type = 'Category';
    }
    if (_mat_type === 'video') {
      _mat_type = 'Video';
    }
    if (_mat_type === 'worksheet') {
      _mat_type = 'Worksheet';
    }
    if (_mat_type === 'book') {
      _mat_type = 'Book';
      return { id: material._id, type: _mat_type, pages: material.pages };
    }
    return { id: material._id, type: _mat_type };
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiAssignmentUnarchiveComponent} from "./ui-assignment-unarchive.component";


@NgModule({
  declarations: [UiAssignmentUnarchiveComponent],
  exports: [UiAssignmentUnarchiveComponent],
  imports: [
    CommonModule
  ]
})
export class UiAssignmentUnarchiveModule { }

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FaqModel} from "../../_models/faq.model";
import {Store} from "@ngxs/store";
import {FaqState} from "../../_stores/faq/_state/faq.state";
import {ModalOpenAction} from "../../_stores/modal/_actions/modal.actions";
import {UserState} from "../../_stores/user/_state/user.state";

@Component({
  selector: 'ui-faq-videos',
  templateUrl: './ui-faq-videos.component.html',
  styleUrls: ['./ui-faq-videos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiFaqVideosComponent implements OnInit {
  interface: FaqModel = {
    videos: [],
    user: undefined
  }
  constructor(private store: Store, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initUser();
    this.initFaqVideos();
  }

  initFaqVideos(): void {
    this.store.select(FaqState.selectCurrentUserFaqVideos).subscribe((_videos) => {
      this.interface.videos = _videos;
      this.cdr.detectChanges();
    });
  }
  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }

  actionOpenVideo(video_src): void {
    this.store.dispatch(new ModalOpenAction('video_16_9', true, video_src));
  }
  actionOpenLanguageSelect(): void {
    this.store.dispatch(new ModalOpenAction('language_select'));
  }
}

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Actions, ofActionDispatched, Store} from "@ngxs/store";
import {AuthState} from "../_stores/auth/_state/auth.state";
import {ConnectWebSocket, WebSocketDisconnected} from "@ngxs/websocket-plugin";
import {environment} from "../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  socket = false;
  constructor(private store: Store, private actions$: Actions ) {
    this.actions$
      .pipe(ofActionDispatched(WebSocketDisconnected))
      .subscribe(({ payload }) => {
        this.socket = false;
      });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    req = req.clone({
      setHeaders: {
        //'Content-Type' : 'application/json; charset=utf-8',
        //'Accept'       : 'application/json'
      }
    });

    const token = this.store.selectSnapshot(AuthState.selectAuthToken);
    if(token) {
      req = req.clone({
        setHeaders: {
          'Authorization': 'Bearer '+token,
        }
      });
      if(!this.socket) {
        this.store.dispatch(new ConnectWebSocket({ url: environment.socket_uri + "/api/ws?toc=" + token }));
        this.socket = true;
      }
    }
    return next.handle(req);
  }
}

<div class="ui-profile-edit-container">
  <div class="ui-form">
    <div class="ui-form-text">Auth</div>
    <div class="ui-form-input-group">
      <div class="ui-form-input">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" class="h-6 w-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
        </svg>
        <input name="login" type="text" placeholder="Login" [value]="interface.auth.login" (keyup)="actionEnterLogin($event)">
      </div>
    </div>
    <div class="ui-form-input-group">
      <div class="ui-form-input">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
        </svg>
        <input name="password" type="text" placeholder="Password" [value]="interface.auth.password || ''" (keyup)="actionEnterPassword($event)">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-auto" tooltip description="Generate new password" (click)="actionGeneratePassword()">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12c0-1.232.046-2.453.138-3.662a4.006 4.006 0 013.7-3.7 48.678 48.678 0 017.324 0 4.006 4.006 0 013.7 3.7c.017.22.032.441.046.662M4.5 12l-3-3m3 3l3-3m12 3c0 1.232-.046 2.453-.138 3.662a4.006 4.006 0 01-3.7 3.7 48.657 48.657 0 01-7.324 0 4.006 4.006 0 01-3.7-3.7c-.017-.22-.032-.441-.046-.662M19.5 12l-3 3m3-3l3 3" />
        </svg>
      </div>
      <div class="ui-form-text ui-form-text-xs">If you don't want to change your password, leave the field blank.</div>
    </div>
    <div class="ui-form-text">Subscriptions</div>
    <div class="bh-subscriptions">
      <ng-container *ngFor="let subscription of interface.subscriptions; let ti = index">
        <div class="bh-subscription" [class.bh-subscription-disabled]="subscription.status != 'active'" [class.bh-subscription-invisible]="ti > 0 && !interface._cache.show_more_subscription">
          <div class="bh-subscription-info">
            <div class="bh-subscription-info-title">Premium Teacher</div>
            <ul class="bh-subscription-info-list">
              <li>
                <p>Status:</p>
                <span class="extend bg-emerald-100 text-emerald-700" *ngIf="subscription.status == 'active'">Active</span>
                <span class="extend bg-stone-100 text-stone-500" *ngIf="subscription.status == 'past_due'">Awaiting Payment</span>
                <span class="extend bg-stone-100 text-stone-500" *ngIf="subscription.status == 'unpaid'">Unpaid</span>
                <span class="extend bg-rose-100 text-rose-700" *ngIf="subscription.status == 'canceled'">Canceled</span>
                <span class="extend bg-rose-100 text-rose-700" *ngIf="subscription.status == 'incomplete'">Being Set Up</span>
                <span class="extend bg-rose-100 text-rose-700" *ngIf="subscription.status == 'incomplete_expired'">Incomplete Expired</span>
                <span class="extend bg-yellow-100 text-yellow-700" *ngIf="subscription.status == 'paused'">Paused</span>

                <span class="extend bg-rose-100 text-rose-700 ml-1" *ngIf="subscription.pause_collection?.behavior == 'mark_uncollectible'">All payments stopped</span>

              </li>
              <li>
                <p>Cost:</p>
                <span>{{subscription.plan.amount/100 | currency: 'USD'}} /Month</span>
              </li>
              <li>
                <p>Created:</p>
                <span>{{subscription.created*1000 | date: 'dd-MM-yyyy, hh:mm'}}</span>
              </li>
            </ul>
          </div>
          <div class="bh-subscription-actions" *ngIf="subscription.status == 'active' || subscription.status == 'paused'">
            <button class="ui-btn ui-btn-sm ui-btn-yellow" [disabled]="interface.submit" *ngIf="subscription.status == 'active' && !subscription.pause_collection" (click)="actionSubscriptionPause(subscription)">
              <svg *ngIf="!interface.submit" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <svg *ngIf="interface.submit" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-spin" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span>Pause</span>

            </button>
            <button class="ui-btn ui-btn-sm ui-btn-red" [disabled]="interface.submit" *ngIf="subscription.status == 'paused' || subscription.pause_collection" (click)="actionSubscriptionResume(subscription)">
              <svg *ngIf="!interface.submit" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
              </svg>
              <svg *ngIf="interface.submit" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-spin" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span>Resume</span>
            </button>
            <button class="ui-btn ui-btn-sm ui-btn-red " [disabled]="interface.submit" (click)="actionSubscriptionCancel(subscription)">
              <svg *ngIf="!interface.submit" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z" />
              </svg>
              <svg *ngIf="interface.submit" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-spin" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="interface.subscriptions.length == 0 && !interface._cache.load_subscription">

      </ng-container>
      <ng-container *ngIf="interface.subscriptions.length == 0 && interface._cache.load_subscription">
        <div class="bh-subscription gap-6">
          <div class="bh-subscription-info">
            <div class="bh-subscription-info-title h-10 overflow-hidden rounded-lg"></div>
            <ul class="bh-subscription-info-list">
              <li class="h-6 overflow-hidden rounded-lg"></li>
              <li class="h-6 overflow-hidden rounded-lg"></li>
              <li class="h-6 overflow-hidden rounded-lg"></li>
            </ul>
          </div>
          <div class="bh-subscription-actions w-32">

          </div>
        </div>
      </ng-container>
      <div *ngIf="interface.subscriptions.length > 1" class="bh-more" (click)="actionToggleShowMoreSubscription()">
        <svg *ngIf="!interface._cache.show_more_subscription" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
        <svg *ngIf="interface._cache.show_more_subscription" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
        </svg>
        <span *ngIf="!interface._cache.show_more_subscription">Show More ({{interface.subscriptions.length-1}})</span>
        <span *ngIf="interface._cache.show_more_subscription">Hide ({{interface.subscriptions.length-1}})</span>
      </div>
    </div>

    <div class="ui-form-text">Profile</div>
    <div class="ui-form-avatar">
      <div class="ui-form-avatar-current" tooltip description="Select your avatar from list">
        <svg *ngIf="!interface.profile.avatar" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
        <img *ngIf="interface.profile.avatar" src="{{interface.profile.avatar}}" alt="" loading="lazy">
      </div>
      <div class="ui-form-avatars-list">
        <ng-container *ngFor="let avatar of [].constructor(12); let a=index">
          <img src="https://cdn.argoprep.com/avatars/avatar-{{a}}.svg" alt="{{a}}" loading="lazy" (click)="actionSelectAvatar(a)">
        </ng-container>
      </div>
    </div>
    <div class="ui-form-input-group ui-form-input-group-row justify-between">
      <div class="ui-form-input ui-form-input-small ui-form-input-no-pr">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <input name="firstname" type="text" [value]="interface.profile.firstName" placeholder="Firstname" (keyup)="actionEnterFirstName($event)">
      </div>
      <div class="ui-form-input ui-form-input-small ui-form-input-no-pr">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <input name="lastname" type="text" [value]="interface.profile.lastName"  placeholder="Lastname" (keyup)="actionEnterLastName($event)">
      </div>
    </div>
    <div class="ui-form-input-group">
      <div class="ui-form-input">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
        </svg>
        <input name="email" type="text" placeholder="Email" [value]="interface.profile.email" (keyup)="actionEnterEmail($event)">
      </div>
    </div>
    <div class="ui-form-text" *ngIf="interface.user.children.length > 0">Connected accounts</div>
    <div class="ui-profile-edit-kids">
      <ng-container *ngFor="let kid of interface.user.children; let a=index">
        <div class="ui-profile-edit-kid">
          <div class="ui-profile-edit-kid-avatar">
            <img src="{{kid.avatar}}" alt="" loading="lazy">
          </div>
          <div class="ui-profile-edit-kid-name">{{kid.displayName}}</div>
          <div class="ui-profile-edit-kid-action" (click)="actionDisconnectAccount(kid)">
            <button class="ui-btn ui-btn-xs">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
              </svg>
              <span>Disconnect</span>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="ui-profile-edit-actions">
    <button class="ui-school-license-action-no" (click)="actionClose()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
      </svg>
      <span>Cancel</span>
    </button>
    <button class="ui-school-license-action-yes" (click)="actionConfirm()" [disabled]="interface.loading">
      <svg *ngIf="!interface.loading" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
      <svg *ngIf="interface.loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span>Confirm</span>
    </button>
  </div>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiAssignmentDeleteComponent} from './ui-assignment-delete.component';
import {UiTooltipModule} from '../ui-tooltip/ui-tooltip.module';

@NgModule({
  declarations: [UiAssignmentDeleteComponent],
  exports: [UiAssignmentDeleteComponent],
  imports: [CommonModule, UiTooltipModule],
})
export class UiAssignmentDeleteModule {}

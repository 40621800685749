<ng-container *ngIf="interface.type == 'quiz'">
  <ui-quiz [id]="interface._id" [homework]="true" [assignment]="interface.assignment"></ui-quiz>
</ng-container>
<ng-container *ngIf="interface.type == 'video'">
  <ui-video [id]="interface._id" [homework]="true" [assignment]="interface.assignment" ></ui-video>
</ng-container>
<ng-container *ngIf="interface.type == 'worksheet'">
  <ui-worksheet [id]="interface._id" [homework]="true" [assignment]="interface.assignment"></ui-worksheet>
</ng-container>
<ng-container *ngIf="interface.type == 'book'">
  <ui-book [id]="interface._id" [assignment]="interface.assignment"></ui-book>
</ng-container>

<div class="ui-page-small-sidebar">
  <div class="ui-page-icon" [routerLink]="'/'">
    <img src="assets/images/logo_icon.svg" alt="{{'alts.logo' | transloco}}" loading="lazy">
  </div>
  <div class="ui-page-user-mobile" tooltip  position="right" title="{{interface.user?.displayName}}" description="{{interface.user?.role?.name}}" (click)="actionOpenProfileSelect()">
    <img src="{{interface.user?.avatar}}" alt="{{interface.user?.displayName}}" loading="lazy">
  </div>
  <div class="ui-page-users">
    <ng-container *ngFor="let user of interface.users; let i=index">
      <div class="ui-page-user" [class.ui-page-user-select]="user._id === interface.user?._id" tooltip  position="right" title="{{user.displayName}}" description="{{user.role?.name}}" (click)="actionSelectGlobalUser(i)">
        <img src="{{user.avatar}}" alt="{{user.displayName}}" loading="lazy">
      </div>
    </ng-container>
    <div *ngIf="interface.user?.role._id == '6301e162029cc9403c26de0c'" class="ui-page-user ui-page-user-add" tooltip  position="right" title="{{'tooltips.add_user.title' | transloco}}" description="{{'tooltips.add_user.description' | transloco}}" (click)="actionOpenAddUserForm()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
      </svg>
    </div>
    <div *ngIf="interface.user?.role._id == '62ebbf45f009df0b43698376'" class="ui-page-user ui-page-user-add" tooltip  position="right" title="Connect account" description="Add your child's school account to your profile" (click)="actionOpenAddUserFormByAin()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
      </svg>
    </div>
  </div>
  <div class="ui-page-actions">
    <div class="ui-page-action ui-page-action-language !hidden" (click)="actionOpenLanguageSelect()">
      <ng-container *ngIf="interface.user?.language == 'en'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
          <path fill="#012169" d="M0 0h640v480H0z"/>
          <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
          <path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
          <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
          <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
        </svg>
      </ng-container>
      <ng-container *ngIf="interface.user?.language == 'ua'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
          <g fill-rule="evenodd" stroke-width="1pt">
            <path fill="#ffd700" d="M0 0h640v480H0z"/>
            <path fill="#0057b8" d="M0 0h640v240H0z"/>
          </g>
        </svg>
      </ng-container>
      <ng-container *ngIf="interface.user?.language == 'ru'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
          <g fill-rule="evenodd" stroke-width="1pt">
            <path fill="#fff" d="M0 0h640v480H0z"/>
            <path fill="#0039a6" d="M0 160h640v320H0z"/>
            <path fill="#d52b1e" d="M0 320h640v160H0z"/>
          </g>
        </svg>
      </ng-container>
    </div>
    <div class="ui-page-action" tooltip  position="right" description="{{'tooltips.logout.description' | transloco}}" (click)="actionLogOut()" (touchend)="actionLogOut()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
      </svg>
    </div>
  </div>
</div>

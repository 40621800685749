import {Component, Input, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'ui-tooltip',
  templateUrl: './ui-tooltip.component.html',
  styleUrls: ['./ui-tooltip.component.scss']
})
export class UiTooltipComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() position: string; // 'left' | 'right' | 'above' | 'below'
  @Input() contentTemplate: TemplateRef<any>;
  @Input() id: string;
  constructor() { }

  ngOnInit(): void {
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPageHeaderComponent} from "./ui-page-header.component";
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";


@NgModule({
  declarations: [UiPageHeaderComponent],
  exports: [UiPageHeaderComponent],
    imports: [
        CommonModule,
        UiTooltipModule
    ]
})
export class UiPageHeaderModule { }

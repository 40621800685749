import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API} from "../../api";
import {BookPageModel} from "../../../_models/book-page.model";
import {BookModel} from "../../../_models/book.model";
import {CategoryModel} from "../../../_models/category.model";


@Injectable({
  providedIn: 'root',
})
export class BooksService {
  constructor(private http: HttpClient) {}

  public getBooksList() {
    return this.http.get<BookModel[]>(API.url('books/list'));
  }

  public getBookPages(book_id, page_number) {
    return this.http.get<BookPageModel[]>(API.url('book-pages/'+book_id+'/'+page_number));
  }
  public getBookVideos(book_id) {
    return this.http.get<CategoryModel[]>(API.url('categories/'+book_id+'/videos'));
  }
}

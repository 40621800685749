import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiControlSchoolsEditComponent} from './ui-control-schools-edit.component';
import {TranslocoModule} from '@ngneat/transloco';
import {UiAppNoDataModule} from '../ui-app-no-data/ui-app-no-data.module';

@NgModule({
  declarations: [UiControlSchoolsEditComponent],
  exports: [UiControlSchoolsEditComponent],
  imports: [CommonModule, TranslocoModule, UiAppNoDataModule],
})
export class UiControlSchoolsEditModule {}

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";
import {ModalState} from "../../_stores/modal/_state/modal.state";
import {ModalCloseAction} from "../../_stores/modal/_actions/modal.actions";
import {API} from "../../_stores/api";
import {HttpClient} from "@angular/common/http";
import {take} from "rxjs";
import {ControlState} from "../../_stores/control/_state/control.state";
import {LoadControlSchoolsLicense} from "../../_stores/control/_actions/control.actions";

@Component({
  selector: 'ui-control-license-edit',
  templateUrl: './ui-control-license-edit.component.html',
  styleUrls: ['./ui-control-license-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiControlLicenseEditComponent implements OnInit {
  interface: any = {
    _id: null,
    loading: false,
    license: {
      active: false,
      test: false,
      teacher_access: false,
      maxCountUser: 0,
      school: null,
      whiteList: [],
      startDate: null,
      endDate: null
    },
    schools: [],
    _cache: {
      open_search_school: false,
      selected_school: '',
      error_fields: [],
    },
  }

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) { }

  ngOnInit(): void {
    this.initDataID();
    this.initEditedLicense();
  }

  initDataID() {
    this.store.select(ModalState.selectOpenedID).subscribe((_openedID) => {
      this.interface._id = _openedID;
      this.cdr.detectChanges();
    });
  }
  initEditedLicense(): void {
    this.store.select(ControlState.selectEditedLicense).pipe(take(1)).subscribe((_license) => {
      this.interface.license = {..._license.license};
      //console.log(this.interface.license);
      this.interface._cache.selected_school = this.interface.license.school.schoolName;
      this.cdr.detectChanges();
    });
  }

  actionToggleSearchSchool(): void {
    this.interface._cache.open_search_school = !this.interface._cache.open_search_school;
  }
  actionSearchSchool(event?): void {
    const _search = event? event.target.value: '';
    this.http.get<any[]>(API.url('schools/search'),  { params: { search: _search } }).subscribe({
      next: (_next) => {
        this.interface.schools = _next;
        this.cdr.detectChanges();
      },
    });
  }

  actionSelectStartDate(event): void {
    this.interface.license.startDate = event;
  }
  actionSelectEndDate(event): void {
    this.interface.license.endDate = event;
  }
  actionSelectSchool(school): void {
    this.interface._cache.open_search_school = false;
    this.interface._cache.selected_school = school.schoolName;
    this.interface.license.school = school;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'school');
    this.cdr.detectChanges();
  }
  actionToggleActive():void {
    this.interface.license.active = !this.interface.license.active;
  }
  actionToggleTest():void {
    this.interface.license.test = !this.interface.license.test;
  }
  actionToggleTeacherAccess():void {
    this.interface.license.teacher_access = !this.interface.license.teacher_access;
  }
  actionEnterMaxUsers(event): void {
    this.interface.license.maxCountUser = event.target.value;
  }
  actionEnterWhiteList(event): void {
    this.interface.license.whiteList = event.target.value.split(/\n/);
    if(this.interface.license.whiteList[0] == '') {
      this.interface.license.whiteList = [];
    }
  }


  actionConfirm(): void {
    this.interface.loading = true;
    //console.log(this.interface.license);
    const _body = this.interface.license;
    this.http.patch(API.url('licenses/'+ this.interface._id), _body).subscribe({
      next: (value) => {
        this.interface.loading = false;
        this.store.dispatch(new LoadControlSchoolsLicense());
        this.store.dispatch(new ModalCloseAction());
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }
  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }

}

<div class="ui-register-container">
  <div class="ui-register-brand">
    <img src="assets/images/logo_small.svg" alt="{{'alts.logo' | transloco}}">
  </div>
  <div class="ui-register-info">
    <h1>Register</h1>
    <p>100% free for teachers! ArgoPrep resources are used by over 1 million students to boost scores and confidence. Join today to see why educators love ArgoPrep.</p>
  </div>
  <div class="ui-register-data">
      <div class="ui-register-promo">
        <div class="ui-register-promo-item">
          <div class="ui-register-promo-item-icon">
            <img src="assets/images/icons/icon-warranty-light.svg" alt="">
          </div>
          <div class="ui-register-promo-item-data">
            <label>Award-Winning Materials for K-8 Classroom Use</label>
            <p>
              - 500+ Math & ELA video lessons<br>
              - 10,000+ Practice Quizzes & Drills<br>
              - Thousands of Printable Worksheets<br>
              - Create Classrooms & Assignments
            </p>
          </div>
        </div>
        <div class="ui-register-promo-item">
          <div class="ui-register-promo-item-icon">
            <img src="assets/images/icons/icon-delivery-light.svg" alt="">
          </div>
          <div class="ui-register-promo-item-data">
            <label>Access 100+ eBooks</label>
            <p>ArgoPrep has 100+ workbooks to support your Math, Language Arts, Science, and Social Studies needs for your classroom.</p>
          </div>
        </div>
        <div class="ui-register-promo-item">
          <div class="ui-register-promo-item-icon">
            <img src="assets/images/icons/icon-planet-light.svg" alt="">
          </div>
          <div class="ui-register-promo-item-data">
            <label>Create Classrooms & Build Assignments</label>
            <p>Teachers can create classrooms and assignments within minutes. Our platform keeps track of your student's progress and shows you if students have completed the assigned work.</p>
          </div>
        </div>
        <div class="ui-register-promo-item">
          <div class="ui-register-promo-item-icon">
            <img src="assets/images/icons/icon-chat-light.svg" alt="">
          </div>
          <div class="ui-register-promo-item-data">
            <label>24/7 Customer Support</label>
            <p>Have issues or questions about our platform? Email us at info@argoprep.com</p>
          </div>
        </div>
      </div>
      <div class="ui-register-form ui-form">
        <ng-container *ngIf="interface.clever">
          <div class="ui-form-select z-30">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path d="M12 14l9-5-9-5-9 5 9 5z" />
              <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
            </svg>
            <input id="school" name="school" type="text" readonly [value]="interface._cache.selected_school" placeholder="{{'inputs.school' | transloco}}" (click)="actionToggleSearchSchool()">
            <div class="ui-form-select-list" [class.ui-form-select-list-open]="interface._cache.open_search_school">
              <div class="ui-form-input">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
                <input id="school_search" name="school_search" type="text" required (keyup)="actionSearchSchool($event)" placeholder="{{'inputs.school_search' | transloco}} (min 3 chars)" autoComplete="off">
              </div>
              <div class="ui-form-select-list-items">
                <ng-container *ngIf="interface.schools.length == 0">
                  <div class="ui-register-select-list-item">
                    <p>Not found</p>
                    <span>No schools from your request</span>
                  </div>
                </ng-container>
                <ng-container *ngFor="let school of interface.schools">
                  <div class="ui-form-select-list-item" (click)="actionSelectSchool(school)">
                    <p>{{school.schoolName}}</p>
                    <span>{{school.address}}, {{school.city}}</span>
                  </div>
                </ng-container>
              </div>

            </div>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" (click)="actionToggleSearchSchool()">
              <path *ngIf="!interface._cache.open_search_school" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
              <path *ngIf="interface._cache.open_search_school" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
            </svg>
          </div>
          <div class="ui-form-iframe">
            <iframe width="100%" height="670px" class="min-h-[670px] h-[670px] rounded-lg overflow-hidden" [src]="interface._cache.clever_auth_url | safe: 'resourceUrl'"></iframe>
            <div class="ui-form-iframe-overlay" [class.!hidden]="interface._cache.selected_district">
              <ng-container *ngIf="!interface._cache.selected_school">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-16 h-16">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>
                <p>Please select a school first</p>
              </ng-container>
              <ng-container *ngIf="interface._cache.selected_school">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-16 h-16 text-rose-500">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                </svg>
                <p class="text-rose-500">This school not have auth with clever</p>
              </ng-container>
            </div>
          </div>
          <button class="ui-btn ui-btn-lg ui-btn-pap justify-center" (click)="actionToggleClever()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Back to register form
          </button>
        </ng-container>
        <ng-container *ngIf="!interface.clever">
          <button class="ui-btn ui-btn-lg ui-btn-white justify-center" (click)="actionToggleClever()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24">
              <rect width="24" height="24" rx="4" fill="#425AF6"/>
              <path d="M12.9614 20C8.39303 20 5 16.486 5 12.0442V11.9999C5 7.60207 8.32658 4 13.0946 4C16.0219 4 17.7738 4.97241 19.2152 6.38668L17.0419 8.88402C15.8444 7.80118 14.6247 7.13821 13.0724 7.13821C10.4555 7.13821 8.57042 9.30389 8.57042 11.9557V11.9999C8.57042 14.6518 10.4111 16.8618 13.0724 16.8618C14.8465 16.8618 15.9331 16.1547 17.1529 15.0498L19.3262 17.2374C17.7294 18.939 15.9553 20 12.9614 20Z" fill="white"/>
            </svg>
            Register with Clever
          </button>
          <div class="ui-form-divider-extend">
            <div class="ui-form-divider-extend-line" aria-hidden="true">
              <span></span>
            </div>
            <div class="ui-form-divider-extend-text ">
              <span>Or continue with</span>
            </div>
            <div class="ui-form-divider-extend-line" aria-hidden="true">
              <span></span>
            </div>
          </div>
          <div class="ui-form-select z-40">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path d="M12 14l9-5-9-5-9 5 9 5z" />
              <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
            </svg>
            <input id="school" name="school" type="text" readonly [value]="interface._cache.selected_school" placeholder="{{'inputs.school' | transloco}}" (click)="actionToggleSearchSchool()">
            <div class="ui-form-select-list !z-40" [class.ui-form-select-list-open]="interface._cache.open_search_school">
              <div class="ui-form-input">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
                <input id="school_search" name="school_search" type="text" required (keyup)="actionSearchSchool($event)" placeholder="{{'inputs.school_search' | transloco}} (min 3 chars)" autoComplete="off">
              </div>
              <div class="ui-form-select-list-items">
                <ng-container *ngIf="interface.schools.length == 0">
                  <div class="ui-register-select-list-item">
                    <p>Not found</p>
                    <span>No schools from your request</span>
                  </div>
                </ng-container>
                <ng-container *ngFor="let school of interface.schools">
                  <div class="ui-form-select-list-item" (click)="actionSelectSchool(school)">
                    <p>{{school.schoolName}}</p>
                    <span>{{school.address}}, {{school.city}}</span>
                  </div>
                </ng-container>
              </div>

            </div>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" (click)="actionToggleSearchSchool()">
              <path *ngIf="!interface._cache.open_search_school" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
              <path *ngIf="interface._cache.open_search_school" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
            </svg>
          </div>
          <div class="ui-form-select z-30">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
            </svg>
            <input id="grade" name="grade" type="text" readonly [value]="interface._cache.selected_grade" placeholder="Select grade" (click)="actionToggleSelectGrade()">
            <div class="ui-form-select-list !z-30" [class.ui-form-select-list-open]="interface._cache.open_select_grade">
              <div class="ui-form-select-list-items">
                  <div class="ui-form-select-list-item" (click)="actionSelectGrade('Kindergarten','62f515fbd62293f5fddcaca0')">
                    <p>Kindergarten</p>
                  </div>
                <div class="ui-form-select-list-item" (click)="actionSelectGrade('1st grade','62f515fbd62293f5fddcac98')">
                  <p>1st grade</p>
                </div>
                <div class="ui-form-select-list-item" (click)="actionSelectGrade('2nd grade','62f515fbd62293f5fddcac99')">
                  <p>2nd grade</p>
                </div>
                <div class="ui-form-select-list-item" (click)="actionSelectGrade('3rd grade','62f515fbd62293f5fddcac9a')">
                  <p>3rd grade</p>
                </div>
                <div class="ui-form-select-list-item" (click)="actionSelectGrade('4th grade','62f515fbd62293f5fddcac9b')">
                  <p>4th grade</p>
                </div>
                <div class="ui-form-select-list-item" (click)="actionSelectGrade('5th grade','62f515fbd62293f5fddcac9c')">
                  <p>5th grade</p>
                </div>
                <div class="ui-form-select-list-item" (click)="actionSelectGrade('6th grade','62f515fbd62293f5fddcac9d')">
                  <p>6th grade</p>
                </div>
                <div class="ui-form-select-list-item" (click)="actionSelectGrade('7th grade','62f515fbd62293f5fddcac9e')">
                  <p>7th grade</p>
                </div>
                <div class="ui-form-select-list-item" (click)="actionSelectGrade('8th grade','62f515fbd62293f5fddcac9f')">
                  <p>8th grade</p>
                </div>
              </div>

            </div>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" (click)="actionToggleSelectGrade()">
              <path *ngIf="!interface._cache.open_select_grade" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
              <path *ngIf="interface._cache.open_select_grade" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
            </svg>
          </div>
          <div class="ui-form-radio-group">
            <ng-container *ngFor="let role of interface.roles">
              <div class="ui-form-radio ui-form-radio-xs" (click)="actionSelectRole(role)" [class.ui-form-radio-selected]="interface.user.role == role._id">
                <input name="firstname" type="radio" [checked]="interface.user.role == role._id">
                <span>{{role.name | titlecase}}</span>
              </div>
            </ng-container>
          </div>
          <div class="ui-form-input-group ui-form-input-group-row justify-between">
            <div class="ui-form-input ui-form-input-small ui-form-input-no-pr">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              <input name="firstname" type="text" [value]="interface.user.firstName" placeholder="Firstname" (keyup)="actionEnterFirstName($event)">
            </div>
            <div class="ui-form-input ui-form-input-small ui-form-input-no-pr">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              <input name="lastname" type="text" [value]="interface.user.lastName" placeholder="Lastname" (keyup)="actionEnterLastName($event)">
            </div>
          </div>
          <div class="ui-form-input-group">
            <div class="ui-form-input" [class.ui-form-input-error]="interface._cache.error_fields.includes('email')">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" class="h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
              </svg>
              <input name="login" type="text" [placeholder]="interface.user.role == '62ebc2e38e33b7bc52c853c6'? 'Login (min char 6)': 'Email'"  [value]="interface.user.email" (keyup)="actionEnterLogin($event)" (paste)="actionPasteEmail($event)">
            </div>
          </div>
          <div class="ui-form-input-group !hidden" *ngIf="interface.user.role == '62ebc2da8e33b7bc52c853c4'  || interface.user?.role == '65e7e7a1b088d22453e7f59f'">
            <div class="ui-form-input flex gap-6" [class.ui-form-input-error]="interface._cache.error_fields.includes('phone')">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
              </svg>
              <input class="!w-20 !pr-0" name="phone_prefix" type="text" value="+1" readonly>
              <input class="!left-[4rem] !pl-0 !ring-0" name="phone" type="text" placeholder="Phone number"  [value]="interface.user.phone" (keyup)="actionEnterPhone($event)">
            </div>
          </div>
          <div class="ui-form-input-group">
            <div class="ui-form-input">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
              </svg>
              <input name="password" type="text" placeholder="Password" [value]="interface.user.password" (keyup)="actionEnterPassword($event)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-auto" tooltip description="Generate new password" (click)="actionGeneratePassword()">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12c0-1.232.046-2.453.138-3.662a4.006 4.006 0 013.7-3.7 48.678 48.678 0 017.324 0 4.006 4.006 0 013.7 3.7c.017.22.032.441.046.662M4.5 12l-3-3m3 3l3-3m12 3c0 1.232-.046 2.453-.138 3.662a4.006 4.006 0 01-3.7 3.7 48.657 48.657 0 01-7.324 0 4.006 4.006 0 01-3.7-3.7c-.017-.22-.032-.441-.046-.662M19.5 12l-3 3m3-3l3 3" />
              </svg>
            </div>
          </div>
          <div class="ui-form-input-group">
            <div class="ui-form-checkbox">
              <input id="agree" name="agree" type="checkbox" (change)="actionConfirmAccepted($event)">
              <label for="agree">Agree to our Terms of Use and Privacy Policy</label>
            </div>
          </div>
          <button class="ui-btn ui-btn-lg ui-btn-pap justify-center" [disabled]="!actionGrantRegister() || interface.submit" (click)="actionRegister()">
            <svg *ngIf="interface.submit" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-spin" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <svg *ngIf="!interface.submit" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
            </svg>
            {{'buttons.confirm_register' | transloco}}
          </button>
          <div class="ui-form-text ui-form-text-xs">
            If your have an account click here to <span class="ui-form-link" [routerLink]="'/auth'">sign in</span>
          </div>
        </ng-container>
      </div>
    </div>
</div>
<div class="ui-register-container-footer">
  <div class="ui-register-footer-menu">
    <a href="https://argoprep.com/privacy-policy">{{'menu.privacy-policy' | transloco}}</a>
    <a href="https://argoprep.com/cookie-policy">{{'menu.cookie-policy' | transloco}}</a>
  </div>
  <div class="ui-register-copyright">{{'copyright.text' | transloco}} © 2022 <a href="https://argoprep.com" target="_blank">ArgoPrep | Argo Brothers Inc</a>. {{'copyright.rights' | transloco}}.</div>
</div>
<ui-modal></ui-modal>

<div class="ui-block ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Homework </h2>
      <p>You have <strong>{{interface?.homeworks?.length}}</strong> homework</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile">
      <button class="ui-btn" routerLink="/homeworks">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
        <span>View all homework</span>
      </button>
    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col py-4">
    <ng-container *ngIf="interface?.loading">
      <ui-no-data text="Loading your homework..."></ui-no-data>
    </ng-container>
    <ng-container *ngIf="!interface?.loading">
      <ng-container *ngIf="interface?.homeworks?.length == 0">
        <ng-container [ngTemplateOutlet]="HOMEWORK_EMPTY"></ng-container>
      </ng-container>
      <ng-container *ngIf="interface?.homeworks?.length > 0">
        <ng-container [ngTemplateOutlet]="HOMEWORK_LIST"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
      <button class="ui-btn" routerLink="/homeworks">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
        <span>View all homework</span>
      </button>
    </div>
  </div>
</div>
<ng-template #HOMEWORK_EMPTY>
  <div class="ui-homeworks-empty">
    <svg class="ui-homeworks-empty-image" id="ui-layer_1" data-name="ui-layer 1" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 660 530">
      <defs>
        <style>.cls-1, .cls-15, .cls-6 {
          fill: none;
        }

        .cls-2 {
          fill: #e0e6f5;
        }

        .cls-3 {
          fill: #b4b2d9;
        }

        .cls-4 {
          fill: #cac6e3;
        }

        .cls-13, .cls-5 {
          fill: #fff;
        }

        .cls-6 {
          stroke: #585856;
        }

        .cls-13, .cls-15, .cls-6 {
          stroke-miterlimit: 10;
        }

        .cls-13, .cls-6 {
          stroke-width: 1.21px;
        }

        .cls-7 {
          fill: #cadbe5;
        }

        .cls-8 {
          fill: #6880bd;
        }

        .cls-9 {
          fill: #232a39;
        }

        .cls-10 {
          fill: #c6462d;
        }

        .cls-11 {
          clip-path: url(#clip-path);
        }

        .cls-12 {
          clip-path: url(#clip-path-2);
        }

        .cls-13 {
          stroke: #232a39;
        }

        .cls-14 {
          fill: #a03825;
        }

        .cls-15 {
          stroke: #c6462d;
          stroke-linecap: round;
          stroke-width: 4.83px;
        }

        .cls-16 {
          fill: #ec9823;
        }

        .cls-17 {
          fill: #ffd64e;
        }

        .cls-18 {
          fill: #7b5a2c;
        }

        .cls-19 {
          fill: #da7333;
        }</style>
        <clipPath id="clip-path">
          <path class="cls-1"
                d="M546.37,396.86c-15.06-12.67-31.23-11.36-51.11,0s-21.57,27.82-14.76,36.34,24.89,17.6,55,2.27S550.42,400.27,546.37,396.86Z" />
        </clipPath>
        <clipPath id="clip-path-2">
          <polygon class="cls-1" points="496.26 70.69 450.7 48.69 450.7 102.93 496.26 127.86 496.26 70.69" />
        </clipPath>
      </defs>
      <polygon class="cls-2"
               points="657.25 472.99 647.66 377.16 443.24 228.63 192.49 228.63 432.32 512.88 585.38 525.69 657.25 472.99" />
      <path class="cls-3" d="M497.45,364.23,394.37,407.61,391,426.42l111.61-49.25S501.57,372.41,497.45,364.23Z" />
      <polygon class="cls-4"
               points="333.92 348.34 332.09 349.09 330 362.23 391.02 426.42 394.37 407.61 333.92 348.34" />
      <path class="cls-5"
            d="M419.45,313.15A95.29,95.29,0,0,0,391,273c-18.32-10.95-32.55-7.3-32.55-7.3L280.64,291.9l-5,1.7c12.17,6.23,53,29,56.44,55.49a122.63,122.63,0,0,1,1,12.28l46.85-13Z" />
      <path class="cls-4"
            d="M275.65,293.6l-7.93,2.69L330,362.23l3.06-.86a122.63,122.63,0,0,0-1-12.28C328.67,322.61,287.82,299.83,275.65,293.6Z" />
      <path class="cls-6" d="M362.18,270.67s25,.66,41.14,33.77" />
      <path class="cls-6" d="M355.36,273.07s24.89,1,41,34.09" />
      <path class="cls-6" d="M348.54,275.48s24.77,1.28,40.9,34.39" />
      <path class="cls-6" d="M341.72,277.89s24.65,1.58,40.78,34.69" />
      <path class="cls-6" d="M334.91,280.3s24.52,1.89,40.65,35" />
      <path class="cls-6" d="M328.09,282.71s24.4,2.19,40.53,35.3" />
      <path class="cls-6" d="M321.27,285.12s24.28,2.49,40.41,35.61" />
      <path class="cls-6" d="M314.45,287.53s24.16,2.8,40.29,35.91" />
      <path class="cls-6" d="M307.63,289.94s24,3.1,40.17,36.22" />
      <path class="cls-6" d="M300.81,292.35s23.92,3.41,40.05,36.52" />
      <path class="cls-6" d="M294,294.76s23.8,3.71,39.93,36.82" />
      <path class="cls-7"
            d="M332.09,349.09a122.63,122.63,0,0,1,1,12.28l46.85-13,39.54-35.19a69.45,69.45,0,0,0-3.71-8.71,253.59,253.59,0,0,1-91.05,27.28C328.57,337.22,331.31,343.06,332.09,349.09Z" />
      <path class="cls-5"
            d="M497.45,364.23c-4.08-8.11-11.17-19.58-23.25-33.11-24.24-27.17-54.75-18-54.75-18l-85.53,35.19,60.45,59.27Z" />
      <polygon class="cls-3" points="192.49 252.59 377.75 508.31 432.32 512.88 192.49 228.63 192.49 252.59" />
      <path class="cls-8"
            d="M567.27,253.11a95.28,95.28,0,0,0-6.92,27.94c-.4,4.47-.09,9,2.6,12.8,2.47,3.45,6,6,9.46,8.49,13.89,9.92,31.26,16.64,40.12,32.11,4.34,7.57,6.15,16.51,2.65,24.76-.72,1.7,1.69,3.2,2.55,1.47,7.37-15-3.06-32.19-14.65-41.55-7-5.68-15-10-22.64-14.79a101.43,101.43,0,0,1-11-7.66c-3.55-2.91-6.27-6.24-6.59-11-.35-5.31.74-10.87,1.84-16.05a97.25,97.25,0,0,1,4.89-15.89c.58-1.45-1.76-2.06-2.35-.64Z" />
      <path class="cls-9"
            d="M487.43,185.76v57.87a18.25,18.25,0,0,1-18.25,18.25H432a2.66,2.66,0,0,0-1.73,4.67l32.66,28.2a6.4,6.4,0,0,0,4.15,1.54H513c17.48,0,24.27-20.34,24.27-37.82V215.71" />
      <path class="cls-10"
            d="M474.22,190.16A175.66,175.66,0,0,1,472.39,229c-.82,5.07-1.85,10.45-6.68,13.2-4.31,2.45-9.66,2.38-14.41,1.54-5.76-1-11.42-2.94-17.33-2.93A38.26,38.26,0,0,0,419.61,244c-9.3,3.81-17.79,9.81-27.7,12-12.3,2.76-24.7-1.83-36.84-3.39a80.73,80.73,0,0,0-35.71,3.57c-10.32,3.47-19.77,9-29.63,13.56-9.56,4.4-20.06,8-30.73,6.38-6-.92-11.71-3.25-17.56-4.89a49.62,49.62,0,0,0-17.34-2c-10.29.8-20.26,5.73-25.73,14.72a24.79,24.79,0,0,0-2.77,6.56,1.54,1.54,0,0,0,3,.81c2.91-10,12-16.66,22-18.49a42.82,42.82,0,0,1,17.53.71c5.92,1.41,11.58,3.77,17.54,5,22.25,4.73,41.08-10.48,60.64-18.16A79.13,79.13,0,0,1,352,255.23c12.38,1.06,24.52,5.72,37.06,4.14,10.3-1.3,19.12-7.08,28.34-11.37a43,43,0,0,1,14.05-4.29c5.95-.54,11.62,1.19,17.38,2.36,4.86,1,9.95,1.48,14.77,0a14.74,14.74,0,0,0,9.85-9.35,42.89,42.89,0,0,0,1.73-8.11q.72-4.75,1.19-9.54a173.52,173.52,0,0,0,.77-19.34q-.08-4.77-.41-9.54c-.12-1.59-2.6-1.62-2.49,0Z" />
      <polygon class="cls-2" points="430.3 168.89 430.3 12.94 606.17 97.27 606.17 286.14 430.3 168.89" />
      <path class="cls-9"
            d="M435.73,21.56l165,79.13V276l-165-110V21.56M424.87,4.31V171.8L611.6,296.29V93.85L424.87,4.31Z" />
      <path class="cls-8"
            d="M589.2,330.8c-2.76-4.32-7.16-8-12.41-8.61-5.48-.58-9.91,2.94-12.56,7.44-2.75,4.69-4.29,10.35-2.09,15.55,1.81,4.26,5.51,7.33,9.06,10.13,3.88,3.06,7.69,6.05,10.37,10.28a30.1,30.1,0,0,1,4.69,14.56c.22,4.45-.12,11.17-5.38,12.48a19.73,19.73,0,0,1-8.2-.24c-3-.58-6-1.45-9-2.2-5.65-1.42-11.45-2.27-17.09-.34a17.28,17.28,0,0,0-6.69,3.92,11.13,11.13,0,0,0-3.1,6.86c-.28,2.14,3.09,2.45,3.66.5,1-3.53,4.58-6,7.94-7,4.64-1.42,9.24-.71,13.86.42,5.56,1.35,11.34,3.27,17.15,2.73a11,11,0,0,0,7.19-3.07,13.16,13.16,0,0,0,3.14-6.77,33.39,33.39,0,0,0-8.46-28.26c-3.14-3.36-7.06-5.85-10.53-8.85-3-2.62-6.08-5.88-6.19-10.14a17,17,0,0,1,2.68-8.78,11.78,11.78,0,0,1,5.63-5.37c5.3-2,11,2,14.08,6.08,1,1.28,3.12.13,2.21-1.3Z" />
      <path class="cls-9"
            d="M546.37,396.86c-15.06-12.67-31.23-11.36-51.11,0s-21.57,27.82-14.76,36.34,24.89,17.6,55,2.27S550.42,400.27,546.37,396.86Z" />
      <g class="cls-11">
        <path class="cls-8"
              d="M496.53,393.53c18.62,3.58,34.91,16.17,43.71,32.89a59.63,59.63,0,0,1,5.46,14.94c.35,1.61,2.84.94,2.48-.67-4.26-19.16-17.88-35.79-35.46-44.36a62.71,62.71,0,0,0-15.53-5.21c-1.57-.3-2.23,2.1-.66,2.41Z" />
        <path class="cls-8"
              d="M524.37,403.77a74.25,74.25,0,0,1,30.5-9.12c1.64-.13,1.69-2.68,0-2.58a73.44,73.44,0,0,0-31.8,9.5,1.27,1.27,0,0,0,1.28,2.2Z" />
      </g>
      <polygon class="cls-8" points="496.26 70.69 450.7 48.69 450.7 102.93 496.26 127.86 496.26 70.69" />
      <g class="cls-12">
        <path class="cls-9" d="M455.22,106.33s4.11-20.33,17.13-13.7,15.54,32.67,15.54,32.67" />
      </g>
      <ellipse class="cls-9" cx="471.56" cy="77.77" rx="7.77" ry="10.51"
               transform="matrix(1, -0.09, 0.09, 1, -5.04, 42.17)" />
      <polygon class="cls-8" points="450.7 107.87 450.7 158.69 496.26 187.6 496.26 132.8 450.7 107.87" />
      <polygon class="cls-5" points="509.37 75.94 509.37 195.5 585.16 245.32 585.16 114.02 509.37 75.94" />
      <line class="cls-13" x1="577.81" y1="119.63" x2="516.73" y2="88.95" />
      <line class="cls-13" x1="577.81" y1="130.96" x2="516.73" y2="99.61" />
      <line class="cls-13" x1="577.81" y1="142.29" x2="516.73" y2="110.26" />
      <line class="cls-13" x1="577.81" y1="153.61" x2="516.73" y2="120.92" />
      <line class="cls-13" x1="577.81" y1="164.94" x2="516.73" y2="131.58" />
      <line class="cls-13" x1="577.81" y1="176.26" x2="516.73" y2="142.23" />
      <line class="cls-13" x1="577.81" y1="187.59" x2="516.73" y2="152.89" />
      <line class="cls-13" x1="577.81" y1="198.91" x2="516.73" y2="163.54" />
      <line class="cls-13" x1="577.81" y1="210.24" x2="516.73" y2="174.2" />
      <line class="cls-13" x1="577.81" y1="221.56" x2="516.73" y2="184.86" />
      <path class="cls-10"
            d="M27.45,429c7-2.25,176.13-13.69,176.13-13.69S269.69,459.42,271,469s-4.14,38.78-10.38,42.68-171.78,6.43-171.78,6.43-54.11-40-60.57-48.85S21.71,430.87,27.45,429Z" />
      <path class="cls-14"
            d="M96.77,487.64c-4.45-1.79-52-41.35-70.94-57.14-4,6.39-3.3,30.83,2.48,38.76,6.46,8.88,60.57,48.85,60.57,48.85s165.54-2.53,171.78-6.43c5.72-3.58,10.8-28.38,10.58-39.88C236,475.53,101.62,489.57,96.77,487.64Z" />
      <line class="cls-15" x1="363.34" y1="340.7" x2="334.85" y2="287.21" />
      <path class="cls-16"
            d="M299,301.29c-20.35-33,50.32-52.09,58.88-60,3.9-2.49-1.66-6.37-3.36-7.2-6.07-3-10.95-9.94-11.62-13.6C288.67,227.2,285.73,298,299,301.29Z" />
      <path class="cls-17"
            d="M370.07,214.62C410,151.09,327.9,99.61,248.6,104.2a142.33,142.33,0,0,0-35.81,6.36c-16.47,5.36-30,14-40.71,25.77a96,96,0,0,0-11.62,16,128.53,128.53,0,0,0-13.32,34,201.78,201.78,0,0,0-4.92,30.29,304,304,0,0,0-1,35q.16,7.11.62,14.56a167.07,167.07,0,0,1-.51,27.22,145.12,145.12,0,0,1-8,35.26,150.16,150.16,0,0,1-14,28.46,182.66,182.66,0,0,1-14.83,20.23,269.88,269.88,0,0,1-19.76,21c-26.47,23.26-45.34,23.62-50.79,29.14-4,8.34,92.21,65.81,209.7,34,13.57,11.88,32.44,19.19,55.71,15.14,2-.36,2.71-3,1.47-4.73-4-5.55-2.58-37.45,1.7-42.9,18.83-23.95,20.72-51.22-7.5-140.65C291,275.76,300.57,266.5,329,245.34a156.23,156.23,0,0,1,21.8-14l.16-.09C357.31,227.83,360,227.47,370.07,214.62Z" />
      <path class="cls-16"
            d="M206.08,101.65c4.35-4,25.92,2.28,36.16,5.94q-24.7,8-40.71,25.77C201.23,124.47,201.73,105.68,206.08,101.65Z" />
      <path class="cls-16"
            d="M165.14,163.3c2.81-5.53,17.68-11.62,24.77-14a128.82,128.82,0,0,0-13.33,34C171.6,179,162.33,168.84,165.14,163.3Z" />
      <path class="cls-16"
            d="M153.4,231.72c.59-4.68,12.42-14,18.27-18.1a306.22,306.22,0,0,0-1,35C164.66,245,152.81,236.41,153.4,231.72Z" />
      <path class="cls-16"
            d="M99.06,395.82c3-.87,11.26-.7,15-.5-5,4.79-9.91,9.28-14.61,13.53C98.11,404.87,96.09,396.7,99.06,395.82Z" />
      <path class="cls-16"
            d="M65.73,416.8c2.26.35,7.78,3.1,10.25,4.43-4.86,1.64-9.59,3.08-14.09,4.44C62.23,422.57,63.47,416.45,65.73,416.8Z" />
      <path class="cls-16"
            d="M146.27,298c2.86-4,17.53-6.74,24.5-7.62a145.51,145.51,0,0,1-8.05,35.25C156,318.12,143.41,302,146.27,298Z" />
      <path class="cls-16"
            d="M131.37,355.87c1.84-1,14.09-2.78,17.32-1.73a183.61,183.61,0,0,1-14.83,20.23C132.42,368.55,129.9,356.7,131.37,355.87Z" />
      <path class="cls-16"
            d="M251.12,347.64c7.75,3.5,10.55,14.71,6.26,25s-14,15.87-21.8,12.38-10.56-14.71-6.27-25S243.37,344.15,251.12,347.64Z" />
      <path class="cls-16"
            d="M251.09,395.54A11.9,11.9,0,0,1,257,410.9a11.07,11.07,0,0,1-14.79,6,11.9,11.9,0,0,1-5.92-15.36A11.06,11.06,0,0,1,251.09,395.54Z" />
      <path class="cls-16"
            d="M223.9,410.33c3.82,1.72,5.33,6.94,3.37,11.66s-6.64,7.14-10.47,5.41-5.33-6.94-3.37-11.66S220.07,408.61,223.9,410.33Z" />
      <ellipse class="cls-18" cx="328.76" cy="144.71" rx="13.77" ry="17.89"
               transform="translate(-6.04 14.59) rotate(-2.52)" />
      <ellipse class="cls-5" cx="334.1" cy="138.36" rx="7.31" ry="11.58"
               transform="translate(-27.42 146.65) rotate(-23.81)" />
      <circle class="cls-5" cx="331.46" cy="153.51" r="4.88" />
      <path class="cls-18"
            d="M299.21,131.66H299a1.24,1.24,0,0,1-1.08-1.19c-.44-11.17,3.17-24.56,12.25-29.28,4.39-2.28,11.87-3.3,22.53,4.83a1.24,1.24,0,0,1-1.08,2.19c-23.12-6.33-27.52,9.35-30.44,19.73-.29,1-.56,2-.83,2.86A1.25,1.25,0,0,1,299.21,131.66Z" />
      <path class="cls-17"
            d="M282.38,346.53c-42.64-17-7.89-54.16-7.89-54.16,8,15.46,31.46,8.61,47.43,2.05,8.29-3.4,17.59-5.16,26.93-1.75,14.53,5.3,10.66,36.78,5.89,33.17-3.77-2.86-5.66-4.28-7.8-4.81-1.66,4.77-5.66,11-8.78,9.46-1.55-.76-1.2-12-11-4.8C310.85,337.58,295.85,350.07,282.38,346.53Z" />
      <path class="cls-16"
            d="M231.44,450.14c2.67,3.44,7.35,7.1,12.12,11.27C191.42,476.39,82.3,480,34.56,430,156.14,498.23,197.5,419.54,231.44,450.14Z" />
      <path class="cls-10"
            d="M249.5,245.65c-8.56,14.52-.32,29.59,2.51,44.47,1.15,6.06,1.57,12.26,2.41,18.36,3,21.35,8.11,42.46,16.84,62.22,9,20.37,23.65,44.43,43.41,56,4.45,2.61,10.25,4.36,15.22,2.24,8.58-3.65,9.84-16,9.47-24-1-22.07-15.19-44.41-28.86-61.08.71,3.84,1,4.94,1.7,7.88,10.6,12.92,16.26,24.07,20.9,40.11,2.46,8.52,6,28.17-4.27,33.36-4.39,2.22-9.9-.19-13.66-2.71-18.9-12.7-32.62-35-41.49-55.45-7.59-17.55-12.31-36.25-15.26-55.1-1-6.26-1.59-12.56-2.5-18.83-2.17-15-12.22-31.19-3.66-45.87a1.6,1.6,0,0,0-2.76-1.6Z" />
      <path class="cls-10" d="M247.46,203.08c.4-2.1,17.6-99.9,20.82-101.11S292,102,294,103.58,266.34,210,266.34,210" />
      <circle class="cls-10" cx="249.39" cy="224.51" r="32.97" />
      <circle class="cls-19" cx="249.39" cy="224.51" r="28.14" />
      <path class="cls-14"
            d="M83.84,308.69c-1.73-13.44-9.4-25.59-19.28-34.87s-21.86-16-34-21.94c-9.07-4.46-18.32-8.53-27.71-12.29-1.43,31.51,17.3,143.55,23,153.77,4.52,8.17,27.55,29,47.42,40.21L83.38,329.28C84,322.43,84.71,315.51,83.84,308.69Z" />
      <path class="cls-10"
            d="M99.91,259.91c-16.88-20-80-40.16-95.26-28.29-1.05.81-1.62,3.63-1.82,8,9.39,3.76,18.64,7.83,27.71,12.29,12.16,6,24.15,12.67,34,21.94s17.55,21.43,19.28,34.87c.87,6.82.2,13.74-.46,20.59L73.21,433.57c8.42,4.76,16.29,7.8,21.92,7.39C114,439.58,116.8,279.94,99.91,259.91Z" />
      <path class="cls-9"
            d="M34.72,397.72,49,408.8a2.33,2.33,0,0,0,3.73-1.44c2.11-12.3,7.28-50.37-9.37-50.42C23.54,356.9,34.72,397.72,34.72,397.72Z" />
      <path class="cls-9"
            d="M59.56,495.88c-6.41-1.72-37.58,1.36-41.3-15.09-7.75-34.2,19.93-83.65,20.93-85.74l8.41,4c-.24.49-23.16,48.92-19.69,73.05.68,4.76,1.74,6.82,17.77,12.79Z" />
      <path class="cls-10"
            d="M62.86,450.57A79.94,79.94,0,0,1,113,469.49a7.52,7.52,0,0,0,3.67,2.13c1.51.2,3-.56,4.34-1.22,10.33-5,22.32-5.76,33.69-4.16s22.3,5.41,33.14,9.2L124.17,479c-6.87.39-13.79.77-20.58-.3a48.75,48.75,0,0,1-21.81-9.27" />
    </svg>
    <span class="ui-homeworks-empty-text">Your don't have homework</span>
  </div>
</ng-template>
<ng-template #HOMEWORK_LIST>
  <div class="ui-homeworks-listing">
    <ng-container *ngFor="let homework of interface.homeworks | sort:'desc':'createdAt' ; let cl=index">
     <ui-homework [homework]="homework"></ui-homework>
    </ng-container>
  </div>
</ng-template>

<div class="ui-a-builder-label" *ngIf="getAssignmentCount() > 0 && interface.show">
  <div class="ui-a-builder-label-title">You selected <strong>{{getAssignmentCount()}}</strong> materials:</div>
  <div class="ui-a-builder-label-data">
    <div class="ui-a-builder-label-data-item" *ngIf="true"
         [class.ui-a-builder-label-data-item-disabled]="interface.assignments.materials.quizzes == 0" tooltip
         description="Selected questions to assign">
      <div class="ui-a-builder-label-data-item-icon ui-a-builder-label-data-item-icon-questions"></div>
      <div class="ui-a-builder-label-data-item-count">{{interface.assignments.materials.quizzes}}</div>
    </div>
    <div class="ui-a-builder-label-data-item" *ngIf="true"
         [class.ui-a-builder-label-data-item-disabled]="interface.assignments.materials.videos == 0" tooltip
         description="Selected videos to assign">
      <div class="ui-a-builder-label-data-item-icon ui-a-builder-label-data-item-icon-video"></div>
      <div class="ui-a-builder-label-data-item-count">{{interface.assignments.materials.videos}}</div>
    </div>
    <div class="ui-a-builder-label-data-item" *ngIf="true"
         [class.ui-a-builder-label-data-item-disabled]="interface.assignments.materials.drills == 0" tooltip
         description="Selected drills to assign">
      <div class="ui-a-builder-label-data-item-icon ui-a-builder-label-data-item-icon-drills"></div>
      <div class="ui-a-builder-label-data-item-count">{{interface.assignments.materials.drills}}</div>
    </div>
    <div class="ui-a-builder-label-data-item" *ngIf="true"
         [class.ui-a-builder-label-data-item-disabled]="interface.assignments.materials.worksheets == 0" tooltip
         description="Selected worksheets to assign">
      <div class="ui-a-builder-label-data-item-icon ui-a-builder-label-data-item-icon-worksheets"></div>
      <div class="ui-a-builder-label-data-item-count">{{interface.assignments.materials.worksheets}}</div>
    </div>
    <div class="ui-a-builder-label-data-item" *ngIf="false"
         [class.ui-a-builder-label-data-item-disabled]="interface.assignments.materials.i_books == 0" tooltip
         description="Selected interactive books to assign">
      <div class="ui-a-builder-label-data-item-icon ui-a-builder-label-data-item-icon-i-books"></div>
      <div class="ui-a-builder-label-data-item-count">{{interface.assignments.materials.i_books}}</div>
    </div>
    <div class="ui-a-builder-label-data-item" *ngIf="true"
         [class.ui-a-builder-label-data-item-disabled]="interface.assignments.materials.e_books == 0" tooltip
         description="Selected electronic books to assign">
      <div class="ui-a-builder-label-data-item-icon ui-a-builder-label-data-item-icon-e-books"></div>
      <div class="ui-a-builder-label-data-item-count">{{interface.assignments.materials.e_books}}</div>
      <span *ngIf="interface.e_bookPages > 0" class="btn-label" tooltip [position]="'above'"
            description="total electronic books pages">{{interface.e_bookPages}}</span>
    </div>
    <div class="ui-a-builder-label-data-action">
      <button class="ui-btn ui-btn-icon" (click)="actionClear()" tooltip
              description="Remove all selected materials from assignment">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
        Clear
      </button>
      <button class="ui-btn ui-btn-icon" (click)="actionAssign()" tooltip
              description="Create assignment with selected materials">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        Assign
      </button>
    </div>
  </div>
</div>


import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {ModalCloseAction} from '../../_stores/modal/_actions/modal.actions';
import {EditSchool, GetSchool} from '../../_stores/schools/_actions/school.actions';
import {ModalState} from '../../_stores/modal/_state/modal.state';
import {SchoolsState} from '../../_stores/schools/_state/schools.state';

@Component({
  selector: 'ui-control-schools-edit',
  templateUrl: './ui-control-schools-edit.component.html',
  styleUrls: ['./ui-control-schools-edit.component.scss'],
})
export class UiControlSchoolsEditComponent implements OnInit {
  public interface = {
    school: undefined,
    _cache: {
      _id: '',
      schoolDistrict: '',
      schoolName: '',
      schoolLevel: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      ncesId: '',
    },
    _cache_error: { fields: '   ' },
    loading: false,
    _id: '',
  };
  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initDataID();
  }

  initDataID() {
    this.store.select(ModalState.selectOpenedID).subscribe((_openedID) => {
      this.interface._id = _openedID;
      this.store.dispatch(new GetSchool(this.interface._id));
      this.cdr.detectChanges();
    });
    this.store.select(SchoolsState.selectSchool).subscribe({
      next: (school) => {
        if (school) {
          //console.log('school: ', school);
          this.interface.school = school;
          this.interface._cache = {
            ...this.interface._cache,
            _id: school._id,
            city: school.city,
            schoolName: school.schoolName,
            zip: school.zip,
            schoolLevel: school.schoolLevel,
            schoolDistrict: school.schoolDistrict,
            address: school.address,
            ncesId: school.ncesId,
            state: school.state,
          };
        }
        this.cdr.detectChanges();
      },
      error: (err) => {
        //console.log('selectSchool err: ', err);
      },
    });
    this.cdr.detectChanges();
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }

  actionEnterSchoolDistrict($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.schoolDistrict = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterSchoolName($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.schoolName = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterSchoolLevel($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.schoolLevel = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterAddress($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.address = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterCity($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.city = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterState($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.state = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterZip($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.zip = $event.target.value;
    this.interface._cache.schoolDistrict.trim();
    this.actionValidateFields();
  }

  actionEnterNcesId($event: KeyboardEvent) {
    // @ts-ignore
    this.interface._cache.ncesId = $event.target.value;
    this.actionValidateFields();
  }

  actionValidateFields() {
    this.interface._cache_error.fields = '';
    Object.keys(this.interface._cache).map((item) => {
      if (!!this.interface._cache[item]) {
      } else {
        this.interface._cache_error.fields = this.interface._cache_error.fields.concat(item.toString());
      }
    });
  }

  actionEditSchool() {
    this.interface.loading = true;
    this.store.dispatch(new EditSchool({ data: this.interface._cache })).subscribe((value) => {
      if (value) {
        this.interface.loading = false;
        this.actionClose();
        this.cdr.detectChanges();
      }
    });
    this.cdr.detectChanges();
  }
}

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UiBooksModel} from "../../_models/ui-books.model";
import {Store} from "@ngxs/store";
import {Router} from "@angular/router";
import {BooksState} from "../../_stores/books/_state/books.state";
import {UserState} from "../../_stores/user/_state/user.state";
import {UserModel} from "../../_models/user.model";
import {PracticeState} from "../../_stores/practice/_state/practice.state";
import {GradeModel} from "../../_models/grade.model";

@Component({
  selector: 'ui-books',
  templateUrl: './ui-books.component.html',
  styleUrls: ['./ui-books.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiBooksComponent implements OnInit {
  interface: UiBooksModel = {
    books: []
  };
  user: UserModel;
  grades:  GradeModel[];

  constructor(private store: Store, private cdr: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {
    this.initGrades();
    this.initUser();
  }
  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.user = _user;
      this.initBooks();
      this.cdr.detectChanges();
    });
  }

  initGrades(): void {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.grades = _grades;
      this.cdr.detectChanges();
    });
  }

  initBooks(): void {
    this.store.select(BooksState.selectBooksList).subscribe((_books) => {
      if(this.interface.books.length == 0) {
        this.interface.books = _books.filter((item) => {
          return item.product.grade.replace(/-/g, '_').includes(this.getCurrentUserGradeSlug());
        });
        this.cdr.detectChanges();
      }
    })
  }

  actionOpenBook(_id: string) {
    this.router.navigateByUrl('/books/e-book/'+_id).then();
  }

  getCurrentUserGrade(): string {
    const _grade = this.grades?.find((grade) => grade._id === this.user?.practice);
    return _grade ? _grade.title : '';
  }
  getCurrentUserGradeSlug(): string {
    const _grade = this.grades?.find((grade) => grade._id === this.user?.practice);
    return _grade ? _grade.slug : '';
  }
}

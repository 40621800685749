import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserState} from "../../_stores/user/_state/user.state";
import {Store} from "@ngxs/store";
import {UiProfileSelectModel} from "../../_models/ui-profile-select.model";
import {CheckUserSchoolLicense, SetCurrentUser, SetGlobalUser} from "../../_stores/user/_actions/user.actions";
import {ModalCloseAction, ModalOpenAction} from "../../_stores/modal/_actions/modal.actions";
import {Router} from "@angular/router";

@Component({
  selector: 'ui-profile-select',
  templateUrl: './ui-profile-select.component.html',
  styleUrls: ['./ui-profile-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiProfileSelectComponent implements OnInit {
  interface: UiProfileSelectModel = {
    user: undefined,
    users: []

  }

  constructor(private store: Store, private cdr: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {
    this.initUser();
    this.initUsers();
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }

  initUsers(): void {
    this.store.select(UserState.selectUsers).subscribe((_users) => {
      this.interface.users = _users;
      this.cdr.detectChanges();
    });
  }

  actionSelectGlobalUser(i: number): void {
    this.store.dispatch(new SetCurrentUser(i));
    this.store.dispatch(new SetGlobalUser(i));
    this.store.dispatch(new ModalCloseAction());
    this.store.dispatch(new CheckUserSchoolLicense());
    this.router.navigate(['/']).then();
  }

  actionOpenAddUserForm(): void {
    this.store.dispatch(new ModalOpenAction('profile_add'));
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPracticeComponent} from "./ui-practice.component";
import {RouterModule} from "@angular/router";
import {PipesModule} from "../../app.pipes.module";


@NgModule({
  declarations: [UiPracticeComponent],
  exports: [UiPracticeComponent],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule
  ]
})
export class UiPracticeModule { }

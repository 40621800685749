import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiControlLicenseAddComponent} from "./ui-control-license-add.component";
import {AppTranslocoModule} from "../../app.transloco.module";
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";
import {UiDatePickerModule} from "../ui-date-picker/ui-date-picker.module";


@NgModule({
  declarations: [UiControlLicenseAddComponent],
  exports: [UiControlLicenseAddComponent],
  imports: [
    CommonModule,
    AppTranslocoModule,
    UiTooltipModule,
    UiDatePickerModule
  ]
})
export class UiControlLicenseAddModule { }

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiModalComponent} from './ui-modal.component';
import {AppTranslocoModule} from '../../app.transloco.module';
import {UiTooltipModule} from '../ui-tooltip/ui-tooltip.module';
import {NgxsModule} from '@ngxs/store';
import {ClassesState} from '../../_stores/classes/_state/classes.state';
import {UiClassEditModule} from '../ui-class-edit/ui-class-edit.module';
import {UiClassAddModule} from '../ui-class-add/ui-class-add.module';
import {UiProfileSelectModule} from '../ui-profile-select/ui-profile-select.module';
import {UiProfileAddModule} from '../ui-profile-add/ui-profile-add.module';
import {UiProfileEditModule} from '../ui-profile-edit/ui-profile-edit.module';
import {UiStudentAddModule} from '../ui-student-add/ui-student-add.module';
import {UiStudentDeleteModule} from '../ui-student-delete/ui-student-delete.module';
import {UiPracticeExitModule} from '../ui-practice-exit/ui-practice-exit.module';
import {UiVideoplayerModule} from '../ui-videoplayer/ui-videoplayer.module';
import {UiStudentEditModule} from '../ui-student-edit/ui-student-edit.module';
import {UiControlUserAddModule} from '../ui-control-user-add/ui-control-user-add.module';
import {UiControlUserEditModule} from '../ui-control-user-edit/ui-control-user-edit.module';
import {UiControlUserDeleteModule} from '../ui-control-user-delete/ui-control-user-delete.module';
import {UiControlLicenseAddModule} from '../ui-control-license-add/ui-control-license-add.module';
import {UiControlLicenseEditModule} from '../ui-control-license-edit/ui-control-license-edit.module';
import {UiControlLicenseDeleteModule} from '../ui-control-license-delete/ui-control-license-delete.module';
import {UiAssignmentDeleteModule} from '../ui-assignment-delete/ui-assignment-delete.module';
import {UiAssignmentArchiveModule} from '../ui-assignment-archive/ui-assignment-archive.module';
import {UiAssignmentUnarchiveModule} from '../ui-assignment-unarchive/ui-assignment-unarchive.module';
import {UiHomeworkExitModule} from '../ui-homework-exit/ui-homework-exit.module';
import {UiHomeworkDoneModule} from '../ui-homework-done/ui-homework-done.module';
import {UiProfileBindModule} from '../ui-profile-bind/ui-profile-bind.module';
import {UiControlRoleAddModule} from '../ui-control-role-add/ui-control-role-add.module';
import {UiControlRoleEditModule} from '../ui-control-role-edit/ui-control-role-edit.module';
import {UiControlRoleDeleteModule} from '../ui-control-role-delete/ui-control-role-delete.module';
import {UiControlSchoolsAddModule} from '../ui-control-schools-add/ui-control-schools-add.module';
import {UiControlSchoolsEditModule} from '../ui-control-schools-edit/ui-control-schools-edit.module';
import {UiControlSchoolsDeleteModule} from '../ui-control-schools-delete/ui-control-schools-delete.module';
import {UiControlGradeAddModule} from '../ui-control-grade-add/ui-control-grade-add.module';
import {UiControlGradeEditModule} from '../ui-control-grade-edit/ui-control-grade-edit.module';
import {UiControlGradeDeleteModule} from '../ui-control-grade-delete/ui-control-grade-delete.module';
import {UiControlCategoryAddModule} from '../ui-control-category-add/ui-control-category-add.module';
import {UiControlCategoryEditModule} from '../ui-control-category-edit/ui-control-category-edit.module';
import {UiControlCategoryDeleteModule} from '../ui-control-category-delete/ui-control-category-delete.module';
import {UiControlVideoAddModule} from '../ui-control-video-add/ui-control-video-add.module';
import {UiControlVideoEditModule} from '../ui-control-video-edit/ui-control-video-edit.module';
import {UiControlVideoDeleteModule} from '../ui-control-video-delete/ui-control-video-delete.module';
import {UiControlQuestionAddModule} from '../ui-control-question-add/ui-control-question-add.module';
import {UiControlQuestionDeleteModule} from '../ui-control-question-delete/ui-control-question-delete.module';
import {UiControlQuestionEditModule} from '../ui-control-question-edit/ui-control-question-edit.module';
import {UiAwardsModule} from "../ui-awards/ui-awards.module";
import {BhGetPremiumModule} from "../components/bh-get-premium/bh-get-premium.module";

@NgModule({
  declarations: [UiModalComponent],
  exports: [UiModalComponent],
    imports: [
        CommonModule,
        AppTranslocoModule,
        UiTooltipModule,
        NgxsModule.forFeature([ClassesState]),
        UiClassEditModule,
        UiClassAddModule,
        UiProfileSelectModule,
        UiProfileAddModule,
        UiProfileEditModule,
        UiStudentAddModule,
        UiStudentDeleteModule,
        UiPracticeExitModule,
        UiVideoplayerModule,
        UiStudentEditModule,
        UiControlUserAddModule,
        UiControlUserEditModule,
        UiControlUserDeleteModule,
        UiControlLicenseAddModule,
        UiControlLicenseEditModule,
        UiControlLicenseDeleteModule,
        UiAssignmentDeleteModule,
        UiAssignmentArchiveModule,
        UiAssignmentUnarchiveModule,
        UiHomeworkExitModule,
        UiHomeworkDoneModule,
        UiControlRoleAddModule,
        UiControlRoleEditModule,
        UiControlRoleDeleteModule,
        UiHomeworkDoneModule,
        UiProfileBindModule,
        UiControlSchoolsAddModule,
        UiControlSchoolsEditModule,
        UiControlSchoolsDeleteModule,
        UiControlGradeAddModule,
        UiControlGradeEditModule,
        UiControlGradeDeleteModule,
        UiControlCategoryAddModule,
        UiControlCategoryEditModule,
        UiControlCategoryDeleteModule,
        UiControlVideoAddModule,
        UiControlVideoEditModule,
        UiControlVideoDeleteModule,
        UiControlQuestionAddModule,
        UiControlQuestionDeleteModule,
        UiControlQuestionEditModule,
        UiAwardsModule,
        BhGetPremiumModule
    ]
})
export class UiModalModule {}

<div class="ui-block ui-block-white"
     [class.grayscale]="isScheduled()">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Details</h2>
      <p>Information about the conditions of the homework</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile">
    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ui-no-data *ngIf="!interface.homework" text="Loading homework information..."></ui-no-data>
    <div *ngIf="interface.homework" class="ui-homework-info">
      <div class="ui-homework-info-header"
           [class.completed]="isCompleted()">
        <div class="ui-homework-info-title">
          <div class="ui-homework-info-title-icon" [class.completed]="isCompleted()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
            </svg>
          </div>
          <div class="ui-homework-info-title-text">
            <h3 [class.completed]="isCompleted()">{{interface.homework.title.toUpperCase()}}</h3>
            <p>Appointed {{interface.homework.creator.firstName}} {{interface.homework.creator.lastName}}</p>
          </div>
        </div>
        <div class="ui-homework-info-data">
          <div class="ui-homework-info-data-icon" [class.completed]="isCompleted()">
            <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                 class="w-6 h-6">
              <path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="1.5" />
            </svg>
          </div>
          <div class="ui-homework-info-data-text">
            <span>Due</span>
            <p>{{interface?.homework?.dates?.dueDate | date}}</p>
          </div>
        </div>
        <div class="ui-homework-info-data">
          <div class="ui-homework-info-data-icon" [class.completed]="isCompleted()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
            </svg>
          </div>
          <div class="ui-homework-info-data-text">
            <span>Scheduled</span>
            <p> {{interface.homework.dates.scheduleDate | date}}</p>
          </div>
        </div>
        <div class="ui-homework-info-data">
          <div class="ui-homework-info-data-icon" [class.completed]="isCompleted()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
            </svg>
          </div>
          <div class="ui-homework-info-data-text">
            <span>Total progress</span>
            <p>{{getTotalProgress()}}%</p>
          </div>
        </div>
      </div>
      <div class="ui-homework-info-body">
        <div class="ui-homework-info-instructions-title">Instructions:</div>
        <div class="ui-homework-info-instructions-text">
          {{interface.homework.description || 'There were no instructions to follow when adding'}}
        </div>
      </div>
    </div>
  </div>
  <div class="ui-block-footer">
  </div>
</div>
<div class="ui-block ui-block-white"
     [class.grayscale]="isScheduled()">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Your tasks</h2>
      <p>You have <strong>{{interface.homework?.materials?.length || 0}}</strong> tasks to complete</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container *ngIf="!interface.homework">
      <ui-no-data></ui-no-data>
    </ng-container>
    <ng-container *ngIf="interface.homework">
      <div class="ui-homework-materials">
        <ng-container *ngFor="let material of interface.homework.materials; let a=index">
          <div class="ui-homework-material">
            <div class="ui-homework-material-icon">
              <svg *ngIf="material.type == 'Category'" xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
              </svg>
              <svg *ngIf="material.type == 'Worksheet'" xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>
              <svg *ngIf="material.type == 'Video'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round"
                      d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
              </svg>
              <svg *ngIf="material.type == 'Book'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
              </svg>
            </div>
            <div class="ui-homework-material-text">
              <p>{{material.title}}</p>
              <span [ngSwitch]="material.type">
                  <ng-container *ngSwitchCase="'Category'">Quiz</ng-container>
                  <ng-container *ngSwitchDefault>{{material.type}}</ng-container>
                </span>
            </div>
            <div class="ui-homework-material-action">
              <button (click)="actionOpenQuiz(material)"
                      *ngIf="material.type == 'Category'"
                      class="ui-btn ui-btn-sm"
                      [disabled]="material.isDone || isAvailable(interface.homework)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                </svg>
                <span *ngIf="!material.isDone">Start</span>
                <span *ngIf="material.isDone">Done</span>
              </button>
              <button (click)="actionOpenVideo(material)" *ngIf="material.type == 'Video'" class="ui-btn ui-btn-sm"
                      [disabled]="material.isDone || isAvailable(interface.homework)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                </svg>
                <span *ngIf="!material.isDone">Watch</span>
                <span *ngIf="material.isDone">Watched</span>
              </button>
              <button (click)="actionOpenWorksheet(material)" *ngIf="material.type == 'Worksheet'"
                      class="ui-btn ui-btn-sm" [disabled]="material.isDone || isAvailable(interface.homework)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5" />
                </svg>
                <span *ngIf="!material.isDone">View</span>
                <span *ngIf="material.isDone">Done</span>
              </button>
              <button (click)="actionOpenBook(material)" *ngIf="material.type == 'Book'" class="ui-btn ui-btn-sm"
                      [disabled]="material.isDone || isAvailable(interface.homework)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5" />
                </svg>
                <span *ngIf="!material.isDone">View</span>
                <span *ngIf="material.isDone">Done</span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>

<div class="ui-class" [class.ui-class-archive]="interface.class.options.isArchive">
  <div class="ui-class-header" [routerLink]="'../classes/'+interface.class._id">
    <div class="ui-class-header-title">{{interface.class.title}}</div>
    <div class="ui-class-header-students">
      <ng-container *ngIf="interface.class.students.length > 0">
        <ng-container *ngFor="let student of interface.class.students | slice:0:7">
          <img src="{{student.avatar}}" alt="{{student.displayName}}" loading="lazy" tooltip description="{{student.displayName}}"/>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="interface.class.students.length == 0">
        <span class="ui-class-header-students-none">There are no students in this class yet.</span>
      </ng-container>
    </div>
  </div>
  <div class="ui-class-body">
    <div class="ui-class-body-info">
      <div class="ui-class-body-info-icon">
        <img src="assets/images/shapes/school.png" loading="lazy" alt="">
      </div>
      <div class="ui-class-body-info-data">
        <strong>{{interface.class?.students.length || 0}}</strong>
        <span>Students</span>
      </div>
      <div class="ui-class-body-info-action" >
        <button tooltip description="Copy link to invite students" (click)="actionLinkToInvite(interface.class)">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
          </svg>
        </button>
      </div>
    </div>
    <div class="ui-class-body-info">
      <div class="ui-class-body-info-icon">
        <img src="assets/images/shapes/deadline.png" loading="lazy" alt="">
      </div>
      <div class="ui-class-body-info-data">
        <strong>{{interface.class?.countAssignment || 0}}</strong>
        <span>Assignments</span>
      </div>
      <div class="ui-class-body-info-action">
        <button tooltip description="Add more assignments to {{interface.class.title}}" [routerLink]="'/assignments/builder'">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </button>
      </div>
    </div>
    <div class="ui-class-body-info">
      <div class="ui-class-body-info-icon">
        <img src="assets/images/shapes/trophy.png" loading="lazy" alt="">
      </div>
      <div class="ui-class-body-info-data">
        <strong>{{interface.class?.doneCountAssignment || 0}}</strong>
        <span>Completed Assignments</span>
      </div>
      <div class="ui-class-body-info-action"></div>
    </div>
  </div>
</div>

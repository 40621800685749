import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPageRegisterComponent} from "./ui-page-register.component";
import {RouterModule} from "@angular/router";
import {AppTranslocoModule} from "../../app.transloco.module";
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";
import {UiModalModule} from "../ui-modal/ui-modal.module";
import {NgxsModule} from "@ngxs/store";
import {ModalState} from "../../_stores/modal/_state/modal.state";
import {PipesModule} from "../../app.pipes.module";


@NgModule({
  declarations: [UiPageRegisterComponent],
  exports: [UiPageRegisterComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([ModalState]),
    RouterModule.forChild([
      {
        path: "",
        component: UiPageRegisterComponent
      }
    ]),
    AppTranslocoModule,
    UiTooltipModule,
    UiModalModule,
    PipesModule
  ]
})
export class UiPageRegisterModule { }

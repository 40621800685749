import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiProfileAddComponent} from "./ui-profile-add.component";
import {AppTranslocoModule} from "../../app.transloco.module";
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";


@NgModule({
  declarations: [UiProfileAddComponent],
  exports: [UiProfileAddComponent],
  imports: [
    CommonModule,
    AppTranslocoModule,
    UiTooltipModule
  ]
})
export class UiProfileAddModule { }

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiAssignmentArchiveComponent} from './ui-assignment-archive.component';
import {UiTooltipModule} from '../ui-tooltip/ui-tooltip.module';

@NgModule({
  declarations: [UiAssignmentArchiveComponent],
  exports: [UiAssignmentArchiveComponent],
  imports: [CommonModule, UiTooltipModule],
})
export class UiAssignmentArchiveModule {}

<div class="ui-block ui-block-white ui-grade-desktop">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Choose your grade level</h2>
      <p>&nbsp;</p>
    </div>
  </div>
  <div class="ui-block-inner">
    <div class="ui-practice-grades">
      <ng-container *ngFor="let grade of interface.grades | sort:'asc':'order'; let g=index">
        <div class="ui-practice-grade" [class.ui-practice-grade-selected]="grade._id == interface.user.practice" (click)="actionChooseGrade(grade)">{{grade.title}}</div>
      </ng-container>
    </div>
  </div>
  <div class="ui-block-footer"></div>
</div>

<div class="ui-grade-mobile">
  <div class='ui-practice-select'>
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
    </svg>
    <input id='grade' name='grade' type='text' readonly [value]="getCurrentUserGrade()" placeholder="Choose your grade level" (click)="actionToggleChooseGrade()">
    <div class="ui-practice-select-list" [class.ui-practice-select-list-open]="interface._cache.open_choose_grade">
      <div class="ui-practice-select-list-items">
        <ng-container *ngFor="let grade of interface.grades | sort:'asc':'order'">
          <div class="ui-practice-select-list-item" (click)="actionChooseGrade(grade)">
            <p>{{grade.title}}</p>
          </div>
        </ng-container>
      </div>

    </div>
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" (click)="actionToggleChooseGrade()">
      <path *ngIf="!interface._cache.open_choose_grade" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
      <path *ngIf="interface._cache.open_choose_grade" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
    </svg>
  </div>
</div>

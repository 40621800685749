import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'Series'
})
export class SeriesPipe implements PipeTransform {
  transform(value: string) {
    let serie = value.replace(/-/g, ' ');
    serie = serie.charAt(0).toUpperCase() + serie.slice(1);
    serie = serie.replace('series', '');
    return serie;
  }

}

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalState} from '../../_stores/modal/_state/modal.state';
import {Store} from '@ngxs/store';
import {HttpClient} from '@angular/common/http';
import {ModalCloseAction, ModalOpenAction} from '../../_stores/modal/_actions/modal.actions';
import {API} from '../../_stores/api';
import {UserModel} from '../../_models/user.model';
import {CurrentUsers} from '../../_stores/user/_actions/user.actions';
import {UserState} from '../../_stores/user/_state/user.state';
import {UserService} from "../../_stores/user/_service/user.service";
import {AuthStatus} from "../../_stores/auth/_actions/auth.actions";

@Component({
  selector: 'ui-profile-edit',
  templateUrl: './ui-profile-edit.component.html',
  styleUrls: ['./ui-profile-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiProfileEditComponent implements OnInit {
  interface: {
    _id: null | string;
    loading: boolean;
    submit: boolean;
    subscriptions: any[],
    invoices: any[];
    user: UserModel;
    auth: {
      login: string;
      password?: string;
      verify: boolean;
    };
    profile: {
      avatar: string;
      firstName: string;
      lastName: string;
      email: string;
    };
    _cache: any
  } = {
    _id: null,
    loading: false,
    submit: false,
    subscriptions: [],
    invoices: [],
    user: undefined,
    auth: {
      login: '',
      verify: false,
    },
    profile: {
      avatar: '',
      firstName: '',
      lastName: '',
      email: '',
    },
    _cache: {
      subscribe: {
        user: undefined,
        user_department: undefined,
      },
      error_fields: [],
      load_subscription: true,
      load_invoice: true,
      show_more_subscription: false,
      show_more_invoices: false
    }
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient, private userService: UserService) {}

  ngOnInit(): void {
    this.initUser();
    this.initDataID();
  }

  initDataID() {
    this.store.select(ModalState.selectOpenedID).subscribe((_openedID) => {
      this.interface._id = _openedID;
      this.initProfile();
      this.cdr.detectChanges();
    });
  }
  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.initSubscriptions();
      this.cdr.detectChanges();
    });
  }
  initProfile(): void {
    this.http.get<any[]>(API.url('users/' + this.interface._id + '/auth')).subscribe({
      next: (_user_data: any) => {
        this.interface.auth.login = _user_data.auth.login;
        this.interface.auth.verify = _user_data.auth.verify;
        this.interface.profile.avatar = _user_data.profile.avatar;
        this.interface.profile.firstName = _user_data.profile.firstName;
        this.interface.profile.lastName = _user_data.profile.lastName;
        this.interface.profile.email = _user_data.profile.email;
        this.cdr.detectChanges();
      },
    });
  }

  actionEnterLogin(event): void {
    this.interface.auth.login = event.target.value;
  }
  actionEnterPassword(event): void {
    this.interface.auth.password = event.target.value;
  }
  actionSelectAvatar(index): void {
    this.interface.profile.avatar = 'https://cdn.argoprep.com/avatars/avatar-' + index + '.svg';
  }
  actionEnterFirstName(event): void {
    this.interface.profile.firstName = event.target.value;
  }
  actionEnterLastName(event): void {
    this.interface.profile.lastName = event.target.value;
  }
  actionEnterEmail(event): void {
    this.interface.profile.email = event.target.value;
  }
  actionGeneratePassword(): void {
    this.interface.auth.password = Math.random().toString(36).slice(-6);
  }
  actionDisconnectAccount(kid): void {
    let _body;
    _body = {
      id: kid._id,
    };
    this.http.put(API.url('auth/unbind/user'), _body).subscribe({
      next: (value) => {
        this.interface.loading = false;
        this.store.dispatch(new CurrentUsers());
        this.store.dispatch(new ModalCloseAction());
      },
      error: (err) => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }

  actionConfirm(): void {
    this.interface.loading = true;
    let _body = {
      auth: this.interface.auth,
      profile: this.interface.profile,
    };

    this.http.put<UserModel>(API.url('users/' + this.interface._id + '/auth'), _body).subscribe({
      next: (res) => {
        this.store.dispatch(new CurrentUsers());
        this.store.dispatch(new ModalCloseAction());
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }
  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }


  initSubscriptions(): void {
    this.userService.getStripeSubscriptions().subscribe(_subs_list => {
      this.interface._cache.load_subscription = false;
      if(_subs_list) {
        this.interface.subscriptions = _subs_list;
        this.cdr.detectChanges();
      }
    });
  }
  initInvoices(): void {
    this.userService.getStripeInvoices().subscribe(_invoices_list => {
      this.interface._cache.load_invoice = false;
      if(_invoices_list) {
        this.interface.invoices = _invoices_list;
      }

    });
  }

  actionToggleShowMoreSubscription(): void {
    this.interface._cache.show_more_subscription = !this.interface._cache.show_more_subscription;
  }
  actionToggleShowMoreInvoices(): void {
    this.interface._cache.show_more_invoices = !this.interface._cache.show_more_invoices;
  }

  actionSubscriptionCreate(): void {
    this.store.dispatch(new ModalOpenAction('get_premium'));
  }
  actionSubscriptionPause(_subscription): void {
    this.interface.submit = true;
    this.userService.pauseStripeSubscription(_subscription.id).subscribe(_next => {
      this.interface.submit = false;
      this.interface.subscriptions = this.interface.subscriptions.reduce((acc, record) => {
        if (record.id === _next.id) {
          acc.push(_next);
        } else {
          acc.push(record);
        }
        return acc;
      }, []);
      this.cdr.detectChanges();
    });
  }
  actionSubscriptionResume(_subscription): void {
    this.interface.submit = true;
    this.userService.resumeStripeSubscription(_subscription.id).subscribe(_next => {
      this.interface.subscriptions = this.interface.subscriptions.reduce((acc, record) => {
        if (record.id === _next.id) {
          acc.push(_next);
        } else {
          acc.push(record);
        }
        return acc;
      }, []);
      this.interface.submit = false;
      this.cdr.detectChanges();
    });
  }
  actionSubscriptionCancel(_subscription): void {
    this.interface.submit = true;
    this.userService.cancelStripeSubscription(_subscription.id).subscribe(_next => {
      this.interface.subscriptions = this.interface.subscriptions.reduce((acc, record) => {
        if (record.id === _next.id) {
          acc.push(_next);
        } else {
          acc.push(record);
        }
        return acc;
      }, []);
      this.interface.submit = false;
      this.cdr.detectChanges();
      this.store.dispatch(new AuthStatus());
    });
  }


}

<div class="ui-control-video-add-container">
  <div class="ui-form ui-control-video-add-form">
    <div class="ui-control-video-add-form-container">
      <div class="ui-control-video-add-form-text">
        <div class="ui-form-input-group w-full gap-2">
          <div class="ui-form-text ui-form-text-xs">Title</div>
          <div class="ui-form-input"
               [class.ui-form-input-error]="interface._cache_error.fields.includes('videoTitle')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 stroke-width="1.5"
                 class="h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
            </svg>
            <input id="categoryTitle" name="categoryTitle" type="text"
                   [value]="interface._cache.validate_form_fields.videoTitle || ''"
                   (keyup)="actionEnterTitle($event)" placeholder="Enter Title">
          </div>
        </div>
        <div class="ui-form-input-group w-full gap-2 grayscale">
          <div class="ui-form-text ui-form-text-xs">Slug</div>
          <div class="ui-form-input" [class.ui-form-input-error]="interface._cache_error.fields.includes('videoSlug')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 stroke-width="1.5"
                 class="h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
            </svg>
            <input id="categorySlug" name="categorySlug" type="text" readonly
                   [value]="interface._cache.validate_form_fields.videoSlug || ''"
                   (keyup)="actionEnterVideoUrl($event)" placeholder="Enter Slug" class="cursor-not-allowed">
          </div>
        </div>
        <div class="ui-form-input-group w-full gap-2">
          <div class="ui-form-text ui-form-text-xs">VimeoID</div>
          <div class="ui-form-input"
               [class.ui-form-input-error]="interface._cache_error.fields.includes('VimeoID')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 stroke-width="1.5"
                 class="h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
            </svg>
            <input id="VimeoID" name="VimeoID" type="text"
                   [value]="interface._cache.validate_form_fields.vimeoID"
                   (keyup)="actionEnterVimeoID($event)" placeholder="Enter VimeoID">
          </div>
        </div>
        <div class="ui-form-input-group w-full">
          <div class="ui-form-text ui-form-text-xs">Options</div>
          <div class="ui-form-radio-group">
            <div class="ui-form-radio ui-form-radio-xs" (click)="actionSelectVisible($event)"
                 [class.ui-form-radio-selected]="interface?.videoVisible">
              <input name="category_visible" type="radio"
                     [checked]="interface?.videoVisible">
              <span>{{'Visible'}}</span>
            </div>
            <div class="ui-form-radio ui-form-radio-xs" (click)="actionSelectVisible($event)"
                 [class.ui-form-radio-selected]="!interface?.videoVisible">
              <input name="category_visible" type="radio"
                     [checked]="!interface?.videoVisible">
              <span>{{'Hidden'}}</span>
            </div>
          </div>
        </div>
        <div class="ui-form-input-group w-full gap-2">
          <div class="ui-form-text ui-form-text-xs">Description</div>
          <div class="ui-form-textarea"
               [class.ui-form-textarea-error]="interface._cache_error.fields.includes('videoDescription')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 stroke-width="1.5"
                 class="h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
            </svg>
            <textarea id="categoryDescription" name="categoryDescription" type="text"
                      [value]="interface._cache.validate_form_fields.videoDescription || ''"
                      (keyup)="actionEnterVideoDescription($event)"
                      placeholder="Enter Description"></textarea>
          </div>
        </div>
      </div>
      <div class="ui-form-video-preview">
        <div class="ui-form-text ui-form-text-xs">Preview</div>
        <div class="ui-control-video-edit-form-video">
          <div class="ui-control-video-edit-form-video uim-video_16_9">
            <ui-videoplayer [src]="interface?._cache?.validate_form_fields?.videoUrl"></ui-videoplayer>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-control-video-add-action">
    <button class="ui-data-video-add-action-no" (click)="actionClose()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
      </svg>
      <span>Cancel</span>
    </button>
    <button class="ui-btn ui-control-video-add" (click)="actionAddVideo()"
            [disabled]="interface._cache_error.fields.length > 0">
      <svg *ngIf="!interface.loading" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
           stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
      <svg *ngIf="interface.loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span>{{'buttons.confirm' | transloco}}</span>
    </button>
  </div>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPageHomeworkComponent} from "./ui-page-homework.component";
import {RouterModule} from "@angular/router";
import {UiQuizModule} from "../ui-quiz/ui-quiz.module";
import {UiVideosModule} from "../ui-videos/ui-videos.module";
import {UiVideoplayerModule} from "../ui-videoplayer/ui-videoplayer.module";
import {UiVideoModule} from "../ui-video/ui-video.module";
import {UiWorksheetModule} from "../ui-worksheet/ui-worksheet.module";
import {UiBookModule} from "../ui-book/ui-book.module";


@NgModule({
  declarations: [UiPageHomeworkComponent],
  exports: [UiPageHomeworkComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageHomeworkComponent
      }
    ]),
    UiQuizModule,
    UiVideosModule,
    UiVideoplayerModule,
    UiVideoModule,
    UiWorksheetModule,
    UiBookModule
  ]
})
export class UiPageHomeworkModule { }

import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {PracticeService} from '../_service/practice.service';
import {CategoryModel} from '../../../_models/category.model';
import {ExamModel} from '../../../_models/exams.model';
import {GradeModel} from '../../../_models/grade.model';
import {
  ClearQuizQuestions,
  ClearQuizQuestionsResult,
  CurrentCategories,
  FillCurrentPracticeCategories,
  FillPracticeExams,
  FillPracticeGrades,
  FillPracticeVideos,
  FillQuizQuestions,
  LoadExams,
  LoadGrades,
  LoadQuizQuestions,
  LoadVideos,
  UpdateQuizQuestionsResult,
} from '../_actions/practice.actions';
import {RouterState} from '@ngxs/router-plugin';
import {RouterStateModel} from '../../../_models/router-state.model';
import {RouterStateModel as RouterStateOuterModel} from '@ngxs/router-plugin/src/router.state';
import {VideoModel} from '../../../_models/video.model';
import {QuestionModel} from '../../../_models/question.model';
import {QuestionProgressModel} from "../../../_models/ui-quiz.model";


export class PracticeStateModel {
  exams: ExamModel[];
  grades: GradeModel[];
  categories: CategoryModel[];
  questions: QuestionModel[];
  videos: VideoModel[];
  result: QuestionProgressModel[]
}

@State<PracticeStateModel>({
  name: 'SAP_PRACTICE',
  defaults: {
    exams: [],
    grades: [],
    categories: [],
    questions: [],
    videos: [],
    result: []
  },
})
@Injectable()
export class PracticeState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  constructor(private store: Store, private practiceService: PracticeService) {}

  @Selector()
  static selectState(state: PracticeStateModel) {
    return state;
  }

  @Selector()
  static selectExams(state: PracticeStateModel) {
    return state.exams;
  }

  @Selector()
  static selectGrades(state: PracticeStateModel) {
    return state.grades;
  }

  @Selector()
  static selectCategories(state: PracticeStateModel) {
    return state.categories;
  }
  @Selector([RouterState])
  static selectCategoryTree(state: PracticeStateModel, route: RouterStateOuterModel<RouterStateModel>) {
    const _need_tree = route.state.url.split('/').pop();
    return state.categories.filter((category) => category.slug === _need_tree);
  }

  static selectCategoryTreeBySlug(slug: string) {
    return createSelector([PracticeState.selectCategories], (categories: CategoryModel[]) => {
      return categories.filter((category) => category.slug === slug);
    });
  }

  @Selector([RouterState])
  static selectCurrentCategory(state: PracticeStateModel, route: RouterStateOuterModel<RouterStateModel>) {
    const _need_category_id = route.state.url.split('/').pop();
    return state.categories;
  }

  @Selector()
  static selectVideos(state: PracticeStateModel) {
    return state.videos;
  }

  @Selector()
  static selectQuestions(state: PracticeStateModel) {
    return state.questions;
  }

  @Selector()
  static selectQuestionsResult(state: PracticeStateModel) {
    return state.result;
  }

  static selectCategoryById( id: string ) {
    return createSelector([ PracticeState.selectCategories ], ( categories: any) => {
      return this.findCategory(categories, id);
    });
  }

  ngxsAfterBootstrap(ctx?: StateContext<PracticeStateModel>): void {
    this.store.dispatch(new LoadExams());
    this.store.dispatch(new LoadGrades());
    this.store.dispatch(new CurrentCategories());
  }

  ngxsOnInit(ctx?: StateContext<PracticeStateModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange<PracticeStateModel>): void {}

  @Action(LoadExams)
  loadExams(ctx: StateContext<PracticeStateModel>) {
    this.practiceService.getExams().subscribe({
      next: (_next) => {
        this.store.dispatch(new FillPracticeExams(_next));
      },
      error: (_error) => {},
    });
  }

  @Action(FillPracticeExams)
  fillPracticeExams(ctx: StateContext<PracticeStateModel>, payload) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      exams: payload.exams,
    });
  }

  @Action(LoadGrades)
  loadGrades(ctx: StateContext<PracticeStateModel>) {
    this.practiceService.getGrades().subscribe({
      next: (_next) => {
        this.store.dispatch(new FillPracticeGrades(_next));
      },
      error: (_error) => {},
    });
  }

  @Action(FillPracticeGrades)
  fillPracticeGrades(ctx: StateContext<PracticeStateModel>, payload) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      grades: payload.grades,
    });
  }

  @Action(CurrentCategories)
  currentCategories(ctx: StateContext<PracticeStateModel>) {
    this.practiceService.getCurrentCategories().subscribe({
      next: (_next) => {
        this.store.dispatch(new FillCurrentPracticeCategories(_next));
      },
      error: (_error) => {},
    });
  }

  @Action(FillCurrentPracticeCategories)
  fillCurrentPracticeCategories(ctx: StateContext<PracticeStateModel>, payload) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      categories: payload.categories,
    });
  }

  @Action(LoadVideos)
  loadVideos(ctx: StateContext<PracticeStateModel>, payload) {
    this.practiceService.getCategoryVideos(payload.category_id).subscribe({
      next: (_next) => {
        this.store.dispatch(new FillPracticeVideos(_next));
      },
      error: (_error) => {},
    });
  }

  @Action(FillPracticeVideos)
  fillPracticeVideos(ctx: StateContext<PracticeStateModel>, payload) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      videos: payload.videos,
    });
  }

  @Action(LoadQuizQuestions)
  loadQuizQuestions(ctx: StateContext<PracticeStateModel>, payload) {
    this.practiceService.getCategoryQuestions(payload.category_id).subscribe({
      next: (_next) => {
        this.store.dispatch(new FillQuizQuestions(_next));
      },
      error: (_error) => {},
    });
  }

  @Action(FillQuizQuestions)
  fillQuizQuestions(ctx: StateContext<PracticeStateModel>, payload) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      questions: payload.questions,
    });
  }

  @Action(ClearQuizQuestions)
  clearQuizQuestions(ctx: StateContext<PracticeStateModel>) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      questions: []
    });
  }

  @Action(UpdateQuizQuestionsResult)
  updateQuizQuestionsResult(ctx: StateContext<PracticeStateModel>, payload) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      result: payload.result
    });
  }

  @Action(ClearQuizQuestionsResult)
  clearQuizQuestionsResult(ctx: StateContext<PracticeStateModel>) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      result: []
    });
  }

  private static findChildren(categories: any, keyToFind: any) {
    {
      return (
        Object.entries(categories).reduce(
          (acc, [key, value]) =>
            key === keyToFind
              ? acc.concat(value)
              : typeof value === 'object' && value
                ? acc.concat(this.findChildren(value, keyToFind))
                : acc,
          [],
        ) || []
      );
    }
  }

  private static findCategory(categories: any, id: any) {
    const children = this.findChildren(categories, 'children');
    const res = categories.find((x) => x._id === id);
    if (children.length > 0 && res === undefined) {
      return this.findCategory(children, id);
    } else {
      return res;
    }
  }
}

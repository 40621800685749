<div class="ui-block ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Categories</h2>
      <p>Total number of categories: <strong>{{interface?._cache?.current_category_children?.length}}</strong></p>
    </div>
    <div class="ui-block-header-action">
      <div class="ui-categories-filters">
        <div class="ui-categories-filter-input-group">
          <div class="ui-categories-filter-input">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
            </svg>
            <input name="search" type="text" placeholder="Search Categories" (keyup)="actionSearchCategories($event)">
          </div>
          <div class="ui-categories-filter-text-xs" *ngIf="interface._cache.searched">Found
            <strong>{{interface?._cache?.current_category_children?.length}}</strong> categories
          </div>
        </div>
        <div class="ui-categories-filter-input-group">
          <div class="ui-categories-filter-select">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 stroke-width="1.5" class="h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
            </svg>
            <input name="grades" type="text" readonly [value]="interface._cache.choose_grade?.title"
                   placeholder="Choose Grade" (click)="actionToggleChooseGrade()">
            <div class="ui-categories-filter-select-list"
                 [class.ui-categories-filter-select-list-open]="interface._cache.open_choose_grade">
              <div class="ui-categories-filter-select-list-items">
                <ng-container *ngFor="let grade of interface.grades | sort:'asc':'order'">
                  <div class="ui-categories-filter-select-list-item" (click)="actionSearchCategoriesByGrade(grade)">
                    <p>{{grade.title}}</p>
                  </div>
                </ng-container>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" stroke-width="1.5" (click)="actionToggleChooseGrade()">
              <path *ngIf="!interface._cache.open_choose_grade" stroke-linecap="round" stroke-linejoin="round"
                    d="M19 9l-7 7-7-7" />
              <path *ngIf="interface._cache.open_choose_grade" stroke-linecap="round" stroke-linejoin="round"
                    d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>
      </div>
      <button class="ui-btn ui-btn-sm w-full justify-center" (click)="actionOpenAddDataModal()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
        </svg>
        <span>Add new</span>
      </button>
    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container [ngTemplateOutlet]="DATA_TABLE_ACTIONS"></ng-container>
    <ng-container *ngIf="interface?._cache?.current_category_children?.length > 0"
                  [ngTemplateOutlet]="DATA_TABLE"></ng-container>
    <ng-container
      *ngIf="interface?._cache?.current_category_children && interface?._cache?.current_category_children?.length === 0">
      <ui-no-data text="Loading data..."></ui-no-data>
    </ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
      <button class="ui-btn ui-btn-sm" (click)="actionOpenAddDataModal()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
        </svg>
        <span tooltip description="adding a child category to the parent category">Add new</span>
      </button>
    </div>
  </div>
</div>

<ng-template #DATA_TABLE_ACTIONS>
  <div class="ui-category-bread-crumbs">
    <ng-container *ngFor="let bread_crumb of interface._cache.bread_crumbs; let i=index">
      <ng-container *ngIf="i===interface._cache.bread_crumbs.length-1; else template">
        <p class="ui-category_bread_crumb_current" tooltip
           description="Current parent category">{{bread_crumb.title}}</p>
      </ng-container>
      <ng-template #template>
        <p class="ui-category_bread_crumb"
           (click)="getCategoryByParent(bread_crumb._id)">{{bread_crumb.title}}</p>
      </ng-template>
      <svg *ngIf="i!==interface._cache.bread_crumbs.length-1" class="h-5 w-5 flex-shrink-0 text-gray-400"
           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
           fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
      </svg>
    </ng-container>
  </div>
</ng-template>
<ng-template #DATA_TABLE>
  <div class="flex w-full overflow-auto rounded-lg">
    <table class="ui-table">
      <caption></caption>
      <thead>
      <tr>
        <th scope="col">
          <div class="ui-form-checkbox"><input type="checkbox"></div>
        </th>
        <th scope="col">Name</th>
        <th scope="col">Visible</th>
        <th scope="col">Description</th>
        <th scope="col">Type</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let item of interface?._cache?.current_category_children |  sort:'asc':'title'">
        <tr>
          <td>
            <div class="ui-form-checkbox"><input type="checkbox"></div>
          </td>
          <td>
            <div class="flex flex-col">
              <div class="font-medium text-slate-900" (click)="actionOpenChildrenOfCategory(item)">{{item.title}}</div>
              <div class="text-xs text-slate-400">{{item?._id}}</div>
            </div>
          </td>
          <td>
            <div class="flex items-center">
              <div class="font-medium text-slate-900">
                <ng-container *ngIf="item?.options?.visible"><span class="ui-badge ui-badge-succeed">Visible</span>
                </ng-container>
                <ng-container *ngIf="!item?.options?.visible"><span class="ui-badge ui-badge-error">Hidden</span>
                </ng-container>
              </div>
              <div class="text-slate-400"></div>
            </div>
          </td>
          <td>
            <div class="flex items-center">
              <div class="font-medium text-slate-900">{{item?.description}}</div>
              <div class="text-slate-400"></div>
            </div>
          </td>
          <td>
            <div class="flex items-center">
              <div class="font-medium text-slate-900"><span
                class="ui-badge ui-badge-info">{{getType(item.type)}}</span></div>
              <div class="text-slate-400"></div>
            </div>
          </td>
          <td>
            <ng-container *ngIf="item.materials">
              <button *ngIf="getMaterialType(item) === 'video'" tooltip title="Edit material of category"
                      class="ui-btn ui-btn-xs ui-btn-icon"
                      [routerLink]="'/control/practices/videos/' + item._id">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round"
                        d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                </svg>
              </button>
              <button *ngIf="getMaterialType(item) === 'question'" tooltip title="Edit material of category"
                      class="ui-btn ui-btn-xs ui-btn-icon"
                      [routerLink]="'/control/practices/questions/'+item._id">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                </svg>
              </button>
              <button *ngIf="getMaterialType(item) === 'worksheet'" tooltip title="Edit material of category"
                      class="ui-btn ui-btn-xs ui-btn-icon"
                      [routerLink]="'/control/practices/worksheets/'+item._id">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
              </button>
            </ng-container>
            <button tooltip title="Edit category" class="ui-btn ui-btn-xs ui-btn-icon"
                    (click)="actionOpenEditDataItemModal(item._id)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
              </svg>
            </button>
            <button tooltip title="Delete" class="ui-btn ui-btn-xs ui-btn-icon ui-btn-error"
                    (click)="actionOpenDeleteDataItemModal(item._id)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</ng-template>

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";
import {ActivatedRoute, Router} from "@angular/router";
import {ModalCloseAction} from "../../_stores/modal/_actions/modal.actions";

@Component({
  selector: 'ui-practice-exit',
  templateUrl: './ui-practice-exit.component.html',
  styleUrls: ['./ui-practice-exit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiPracticeExitComponent implements OnInit {

  constructor(private store: Store,private cdr: ChangeDetectorRef, private route: ActivatedRoute,  private router: Router) { }

  ngOnInit(): void {
  }


  actionClose():void {
    this.store.dispatch(new ModalCloseAction());
  }
  actionOpenResult(): void {
    this.store.dispatch(new ModalCloseAction());
    const _url = window.location.pathname;
    this.router.navigate([_url+'/result']).then();
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'math'
})
export class MathPipe implements PipeTransform {
  transform(value: string): string {
    const reg = /\[math](.+?)\[\/math]/igm;
    return value.replace(reg, '$$$$$1$$$$');
  }
}

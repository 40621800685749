import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngxs/store';
import {ActivatedRoute, Router} from '@angular/router';
import {SortPipe} from '../../_pipes/sort.pipe';
import {CategoriesTreeModel} from '../../_models/categories-tree.model';
import {CategoryModel} from '../../_models/category.model';
import {PracticeService} from '../../_stores/practice/_service/practice.service';
import {RouterState} from '@ngxs/router-plugin';

@Component({
  selector: 'ui-categories-tree',
  templateUrl: './ui-categories-tree.component.html',
  styleUrls: ['./ui-categories-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiCategoriesTreeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type: any;
  interface: CategoriesTreeModel = {
    progress: [],
    tree: {
      practices: [],
      tabs: [],
      filters: [],
    },
    _cache: {
      active: undefined,
      tree: [],
      worksheet: false,
      selected_filter: 'all',
    },
  };
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private sort: SortPipe,
    private practiceService: PracticeService,
  ) {}

  @Input('practice') set _practice(practice: CategoryModel[]) {
    if (practice) {
      this.interface._cache.tree = practice;
      this.initTabs();
      this.initTreeProgress();
    }
  }

  ngOnInit(): void {
    this.store.select(RouterState.url).subscribe((_url) => {
      this.interface._cache.worksheet = _url == '/practices/worksheets';
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(): void {}

  initTabs(): void {
    if (this.interface._cache.tree.length == 0) {
      return;
    }
    this.interface.tree.tabs = this.sort.transform(this.interface._cache.tree[0].children, 'desc', 'slug');

    this.actionChangeActiveTab(0);
  }

  initTreeProgress(): void {
    this.practiceService.getCategoriesProgress().subscribe((_progress) => {
      this.interface.progress = _progress;
      this.cdr.detectChanges();
    });
  }

  getProgress(_id): any {
    return this.interface.progress.find((_item: any) => _item.material == _id);
  }

  actionChangeActiveTab(i): void {
    this.interface._cache.active = this.interface.tree.tabs[i];
    this.interface.tree.practices = this.interface.tree.tabs[i].children;
    this.interface.tree.filters = this.interface.tree.tabs[i].children;
    this.interface._cache.selected_filter = 'all';
    console.log(this.interface.tree.filters);
    this.cdr.detectChanges();
  }

  actionPracticeFiltered(filter): void {
    if (filter == 'all') {
      this.interface._cache.selected_filter = 'all';
      this.interface.tree.practices = this.interface._cache.active.children;
      this.closeAllChildren();
    } else {
      this.interface._cache.selected_filter = filter.slug;
      this.interface.tree.practices = [filter];
    }
    this.cdr.detectChanges();
  }

  closeAllChildren() {
    let details = document.getElementsByTagName('details');
    for (let i = 0; i < details.length; i++) {
      details[i].open = false;
    }
  }

  actionOpenChild(id) {
    let element = document.getElementById('detail#' + id);
    element['open'] = true;
  }

  closeChildren(id) {
    let element = document.getElementById('detail#' + id);
    element['open'] = false;
  }
}

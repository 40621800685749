import {NgModule} from '@angular/core';
import {UiPageClassesComponent} from './ui-page-classes.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PipesModule} from '../../app.pipes.module';
import {UiGradesModule} from '../ui-grades/ui-grades.module';
import {UiTooltipModule} from '../ui-tooltip/ui-tooltip.module';
import {UiAppNoDataModule} from '../ui-app-no-data/ui-app-no-data.module';
import {UiClassModule} from "../ui-class/ui-class.module";

@NgModule({
  declarations: [UiPageClassesComponent],
  exports: [UiPageClassesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageClassesComponent,
        children: [
          {
            path: ":class_id",
            loadChildren: () => import("./ui-page-class/ui-page-class.module").then((mod) => mod.UiPageClassModule)
          }
        ]
      }
    ]),
    PipesModule,
    UiGradesModule,
    UiTooltipModule,
    UiAppNoDataModule,
    UiClassModule
  ]
})
export class UiPageClassesModule {}

<div class="ept-editor ept-editor-{{interface._cache.position}}" [class.ept-editor-hide]="!show">
    <div class="ept-editor-layout" #editorLayout></div>
    <div class="ept-editor-control-panel">
        <div class="ept-editor-control ept-editor-control-label" (click)="actionCancel()" tooltip description="Close editor ">Cancel</div>
        <div class="ept-editor-control ept-editor-control-btn" (click)="actionUndo()" tooltip description="Undo">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M9 13L5 9M5 9L9 5M5 9H16C17.0609 9 18.0783 9.42143 18.8284 10.1716C19.5786 10.9217 20 11.9391 20 13C20 14.0609 19.5786 15.0783 18.8284 15.8284C18.0783 16.5786 17.0609 17 16 17H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="ept-editor-control ept-editor-control-btn" (click)="actionRedo()" tooltip description="Redo">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M15 13L19 9M19 9L15 5M19 9H8C6.93913 9 5.92172 9.42143 5.17157 10.1716C4.42143 10.9217 4 11.9391 4 13C4 14.0609 4.42143 15.0783 5.17157 15.8284C5.92172 16.5786 6.93913 17 8 17H9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="ept-editor-control ept-editor-control-btn" [class.ept-editor-control-active]="interface.tools.paint" (click)="actionChoosePaint()" tooltip description="Paint">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M3 21V17C3 16.2089 3.2346 15.4355 3.67412 14.7777C4.11365 14.1199 4.73836 13.6072 5.46927 13.3045C6.20017 13.0017 7.00444 12.9225 7.78036 13.0769C8.55629 13.2312 9.26902 13.6122 9.82843 14.1716C10.3878 14.731 10.7688 15.4437 10.9231 16.2196C11.0775 16.9956 10.9983 17.7998 10.6955 18.5307C10.3928 19.2616 9.88008 19.8864 9.22228 20.3259C8.56448 20.7654 7.79113 21 7 21H3Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.9992 3C18.145 3.3904 15.4492 4.54414 13.1963 6.33944C10.9433 8.13474 9.21692 10.505 8.19922 13.2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21.0008 3C20.6104 5.85418 19.4566 8.55002 17.6613 10.8029C15.866 13.0559 13.4958 14.7823 10.8008 15.8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5996 9C12.5428 9.89687 14.1027 11.4568 14.9996 13.4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="ept-editor-control ept-editor-control-btn" [class.ept-editor-control-active]="interface.tools.erase" (click)="actionChooseErase()" tooltip description="Erase">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M18.9998 19.0001H7.99978L3.99978 15.0001C3.81353 14.8127 3.70898 14.5593 3.70898 14.2951C3.70898 14.0309 3.81353 13.7775 3.99978 13.5901L13.9998 3.59011C14.1871 3.40386 14.4406 3.29932 14.7048 3.29932C14.969 3.29932 15.2224 3.40386 15.4098 3.59011L20.4098 8.59011C20.596 8.77747 20.7006 9.03092 20.7006 9.29511C20.7006 9.55929 20.596 9.81275 20.4098 10.0001L11.4098 19.0001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.9992 12.3L11.6992 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="ept-editor-control ept-editor-control-btn" [class.ept-editor-control-active]="interface.tools.keyboard" (click)="actionChooseKeyboard()" tooltip description="Enter text">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <g clip-path="url(#clip0_94:2160)">
                    <path d="M20 6H4C2.89543 6 2 6.89543 2 8V16C2 17.1046 2.89543 18 4 18H20C21.1046 18 22 17.1046 22 16V8C22 6.89543 21.1046 6 20 6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 14V14.01" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 10V10.01" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14 10V10.01" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 10V10.01" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18 10V10.01" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18 14V14.01" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 14H14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_94:2160">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
        <div class="ept-editor-control ept-editor-control-label" (click)="actionSave()" tooltip description="Save your entered data">Save</div>

        <div class="ept-control-toolbox" *ngIf="interface._cache.toolbox">
            <ng-container *ngIf="interface.tools.paint">
                <div class="ept-control-tool">
                    <div class="ept-control-tool-name">Color</div>
                    <div class="ept-control-tool-data tool-color"><input type="range" min="0" max="360" step="1" [value]="interface.settings.color" (change)="actionChangeColor($event)"></div>
                </div>
                <div class="ept-control-tool">
                    <div class="ept-control-tool-name">Opacity</div>
                    <div class="ept-control-tool-data tool-opacity"><input type="range" min="0" max="1" step="0.01" [value]="interface.settings.opacity" (change)="actionChangeOpacity($event)"></div>
                </div>
                <div class="ept-control-tool">
                    <div class="ept-control-tool-name">Thickness</div>
                    <div class="ept-control-tool-data tool-paint-thickness"><input type="range" min="1" max="30" step="1" [value]="interface.settings.thickness.paint" (change)="actionChangeThicknessPaint($event)"></div>
                </div>
            </ng-container>
            <ng-container *ngIf="interface.tools.erase">
                <div class="ept-control-tool">
                    <div class="ept-control-tool-name">Thickness</div>
                    <div class="ept-control-tool-data tool-erase-thickness"><input type="range" min="1" max="30" step="1" [value]="interface.settings.thickness.erase" (change)="actionChangeThicknessErase($event)"></div>
                </div>
            </ng-container>
            <ng-container *ngIf="interface.tools.keyboard">
                <div class="ept-control-tool">
                    <div class="ept-control-tool-name">Text size</div>
                    <div class="ept-control-tool-data tool-text-size-thickness"><input type="range" min="20" max="300" step="2" [value]="interface.settings.thickness.text" (change)="actionChangeThicknessText($event)"></div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

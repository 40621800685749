import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngxs/store';
import {ModalState} from '../../_stores/modal/_state/modal.state';
import {ModalCloseAction} from '../../_stores/modal/_actions/modal.actions';
import {HttpClient} from '@angular/common/http';
import {API} from '../../_stores/api';
import {GetTeachersStudents} from '../../_stores/user/_actions/user.actions';

@Component({
  selector: 'ui-student-delete',
  templateUrl: './ui-student-delete.component.html',
  styleUrls: ['./ui-student-delete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiStudentDeleteComponent implements OnInit {
  interface = {
    _id: '',
    loading: false,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) {}

  ngOnInit(): void {
    this.store.select(ModalState.selectOpenedID).subscribe((_openedID) => {
      this.interface._id = _openedID;
      this.cdr.detectChanges();
    });
  }

  studentDelete() {
    this.interface.loading = true;
    this.http.delete(API.url('classes/delete/students/' + this.interface._id)).subscribe({
      next: () => {
        this.store.dispatch(new GetTeachersStudents());
        this.store.dispatch(new ModalCloseAction());
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.interface.loading = false;
      },
      complete: () => {},
    });
    this.cdr.detectChanges();
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}

<router-outlet (activate)="childContentLoad($event)" (deactivate)="childContentUnLoad($event)"></router-outlet>
<ng-container *ngIf="interface.loading">
  <div class="ui-block ui-block-white">
      <div class="ui-block-header">
        <div class="ui-block-header-filters">
          <div class="ui-block-header-filter">
            <div class="ui-tabs">
              <ng-container *ngFor="let tab of interface.tabs; let t=index">
                <div class="ui-tab" [class.ui-tab-active]="interface._cache.tab.title == tab.title" (click)="actionChangeActiveTab(tab)">
                  <svg *ngIf="tab.title == 'active'" class="ui-tab-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z" />
                  </svg>
                  <svg *ngIf="tab.title == 'completed'" class="ui-tab-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                  </svg>
                  <svg *ngIf="tab.title == 'scheduled'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                  </svg>
                  <div class="ui-tab-name">{{tab.title}}</div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="ui-block-header-filter ml-auto">
            <div class="ui-form-input ui-form-input-xs min-w-[14rem]">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              <input name="ain" type="text" [value]="" placeholder="Search homework" (keyup)="actionSearchHomeworks($event)">
            </div>
          </div>
        </div>
      </div>
      <div class="ui-block-inner ui-block-inner-col my-4">
        <ng-container *ngIf="interface.homeworks.length == 0">
          <ui-no-data *ngIf="interface._cache.tab?.title == 'active'" type="dino-computer" text="No active homework."></ui-no-data>
          <ui-no-data *ngIf="interface._cache.tab?.title == 'completed'" type="dino-computer" text="When you complete a homework, it will appear here."></ui-no-data>
          <ui-no-data *ngIf="interface._cache.tab?.title == 'scheduled'" type="dino-computer" text="Scheduled homework, it will appear here."></ui-no-data>
        </ng-container>
        <ng-container *ngIf="interface.homeworks.length > 0">
          <div class="ui-homeworks-list">
            <ng-container  [ngTemplateOutlet]="HOMEWORKS_LIST"></ng-container>
          </div>
        </ng-container>
      </div>
      <div class="ui-block-footer"></div>
  </div>
  <ng-template #HOMEWORKS_LIST>
    <ng-container *ngFor="let homework of interface.homeworks | sort:'desc':'createdAt' ; let cl=index">
      <ui-homework [homework]="homework"></ui-homework>
    </ng-container>
  </ng-template>
</ng-container>





<div class="ui-block ui-block-white">
    <div class="ui-block-header">
        <div class="ui-block-header-title">
               <h2>Information</h2>
               <p>&nbsp;</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile">
            <button class="ui-btn ui-btn-sm ui-btn-blue-outline" (click)="actionOpenEditClass()" [disabled]="!this.interface.class">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
              </svg>
              <span>Edit class</span>
            </button>
        </div>
    </div>
    <div class="ui-block-inner ui-block-inner-col my-4">
      <ng-container *ngIf="interface.loading">
        <ui-no-data text="Loading list students..."></ui-no-data>
      </ng-container>
      <ng-container *ngIf="!interface.loading">
        <ng-container *ngIf="interface.class.students.length == 0" [ngTemplateOutlet]="STUDENTS_EMPTY"></ng-container>
        <ng-container *ngIf="interface.class.students.length > 0" [ngTemplateOutlet]="STUDENTS_LIST"></ng-container>
      </ng-container>
    </div>
    <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
          <button class="ui-btn ui-btn-sm ui-btn-blue-outline" (click)="actionOpenEditClass()" [disabled]="!this.interface.class">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
            </svg>
            <span>Button</span>
          </button>
        </div>
    </div>
</div>
<div class="ui-block ui-block-white">
    <div class="ui-block-header">
        <div class="ui-block-header-title">
               <h2>Assignments</h2>
               <p>Class have <strong>{{interface.assignments.length}}</strong> assignments</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile">
            <button class="ui-btn ui-btn-sm ui-btn-blue-outline" routerLink="/assignments">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
              </svg>
              <span>View all assignments</span>
            </button>
        </div>
    </div>
    <div class="ui-block-inner ui-block-inner-col my-4">
      <ng-container *ngIf="interface.loading">
        <ui-no-data text="Loading assignments from this class..."></ui-no-data>
      </ng-container>
      <ng-container *ngIf="!interface.loading">
        <ng-container *ngIf="interface.assignments.length == 0" [ngTemplateOutlet]="ASSIGNMENTS_EMPTY"></ng-container>
        <ng-container *ngIf="interface.assignments.length > 0" [ngTemplateOutlet]="ASSIGNMENTS_LIST"></ng-container>
      </ng-container>


    </div>
    <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
          <button class="ui-btn ui-btn-sm ui-btn-blue-outline" routerLink="/assignments">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
            </svg>
            <span>View all assignments</span>
          </button>
        </div>
    </div>
</div>

<ng-template #STUDENTS_LIST>
  <table  class="ui-table">
    <thead>
    <tr>
      <th scope="col"><div class="ui-form-checkbox"><input type="checkbox"></div></th>
      <th scope="col">Name</th>
      <th scope="col">Class</th>
      <th scope="col">Grade</th>
      <th scope="col">Total Progress</th>
      <th scope="col">Actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let student of interface.class.students | sort:'asc':'displayName'">
      <tr>
        <td>
          <div class="ui-form-checkbox"><input type="checkbox"></div>
        </td>
        <td>
          <div class="flex items-center">
            <div class="h-10 w-10 flex-shrink-0">
              <img class="h-10 w-10 rounded" src="{{student.avatar}}" alt="">
            </div>
            <div class="ml-4">
              <div class="font-medium text-slate-900">{{student.firstName}} {{student.lastName}}</div>
              <div class="text-xs text-slate-400">AIN:<span class="ml-1 text-pap-300 font-medium">{{student.ain}}</span></div>
            </div>
          </div>
        </td>
        <td>
          <div class="flex flex-col text-xs">
            <span>{{interface.class.title}}</span>
          </div>
        </td>
        <td>
          <div class="flex items-center text-xs">
            {{student.practice?.title}}
          </div>
        </td>
        <td>
          <div class="flex items-center justify-center text-xs">
            <span class="ui-badge ui-badge-succeed">0</span>
          </div>
        </td>
        <td>
          <button tooltip title="Edit" class="ui-btn ui-btn-xs ui-btn-icon" (click)="actionStudentEdit(student._id)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
            </svg>
          </button>
          <button tooltip title="Delete" class="ui-btn ui-btn-xs ui-btn-icon ui-btn-error" (click)="actionStudentDelete(student._id)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
            </svg>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</ng-template>
<ng-template #STUDENTS_EMPTY>
  <ui-no-data type="dino-computer" text="You have no students from this class..."></ui-no-data>
</ng-template>

<ng-template #ASSIGNMENTS_LIST>
  <div class="ui-assignments-listing">
    <ng-container *ngFor="let assignment of interface.assignments | sort:'asc':'title' ; let cl=index">
     <ui-assignment [assignment]="assignment"></ui-assignment>
    </ng-container>
  </div>
</ng-template>
<ng-template #ASSIGNMENTS_EMPTY>
  <div class="ui-assignments-empty">
    <svg class="ui-assignments-empty-image" viewBox="0 0 145 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M67.1417 41.9944C81.2237 39.1042 75.2974 44.0351 87.9666 43.9576C108.85 43.8299 97.5419 48.4368 112.978 60.9302C120.691 67.1723 149.26 66.8194 144.314 74.941C139.367 83.0626 113.265 87.8232 88.479 91.9726C59.8491 96.7656 -17.5791 100.422 5.18016 84.1951C12.8395 78.7341 19.7337 80.3002 24.7011 74.1344C31.501 65.6942 12.7342 68.8733 20.6972 60.4207C32.3011 48.1032 56.7866 44.1197 67.1417 41.9944Z"
          fill="#BAC2FB" />
        <path
          d="M115.547 57.0828C115.367 60.1682 108.754 64.5162 105.47 66.3045C106.599 58.3 105.734 49.5671 102.854 40.0542C107.16 44.4448 115.726 53.9975 115.547 57.0828Z"
          fill="#EC9823" />
        <path
          d="M104.404 18.3685C105.977 21.4202 102.486 26.7723 100.544 29.0669C97.0576 21.04 90.264 14.2424 82.3318 5.40098C88.6642 8.98955 102.83 15.3168 104.404 18.3685Z"
          fill="#EC9823" />
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M110.986 76.8752C113.33 79.7256 116.508 83.5883 118.174 86.3535L112.096 87.6701C111.664 83.7403 110.675 79.5537 109.49 75.047C109.921 75.5808 110.43 76.1996 110.986 76.8752Z"
              fill="#EC9823" />
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M22.6942 51.8439C-2.05383 38.797 6.47587 18.421 25.7617 8.51943C49.7806 -3.81216 80.3989 -2.22096 92.7384 14.0939C97.4423 20.3133 100.429 28.6488 102.799 38.3228C104.439 45.0161 105.785 52.3507 107.201 60.068C108.812 68.8499 110.514 78.128 112.845 87.5227C105.13 89.1413 96.7316 90.5907 88.4789 91.9723C75.2455 94.1877 51.5863 96.1602 32.2143 96.0387C38.3679 81.2094 42.2291 65.5973 27.5569 54.8561C26.119 53.8035 24.5032 52.7977 22.6942 51.8439Z"
              fill="#FFD84C" />
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M112.845 87.5227C110.514 78.128 108.812 68.8499 107.201 60.068C105.785 52.3507 104.439 45.0161 102.799 38.3228C102.804 38.3228 102.808 38.3227 102.812 38.3227"
              fill="#FFD84C" />
        <ellipse rx="7.15219" ry="9.22071" transform="matrix(-0.850146 -0.526547 -0.471957 0.881621 73.5019 31.7988)" fill="#7B592A" />
        <ellipse rx="3.78146" ry="5.98731" transform="matrix(-0.71766 -0.696394 -0.696394 0.71766 72.0547 27.7592)" fill="white" />
        <circle r="2.52097" transform="matrix(-0.71766 -0.696394 -0.696394 0.71766 70.612 35.581)" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M81.2195 93.031C73.4427 55.6279 54.2698 52.5479 32.0079 52.327C30.3053 53.1923 28.6643 54.1283 27.1147 55.142C39.2926 65.7573 35.6613 77.059 31.3602 85.1879C29.7325 88.264 28.026 91.8603 26.6376 95.9403C43.8272 96.4558 66.0288 94.9817 81.2195 93.031Z"
              fill="#EC9823" />
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M26.6376 95.9403C43.8272 96.4558 66.0288 94.9817 81.2195 93.031C73.4427 55.6279 54.2698 52.5479 32.0079 52.327C29.9879 52.3069 27.9425 52.3104 25.8778 52.314C25.0754 52.3153 24.2702 52.3167 23.4623 52.3167C24.8131 53.2511 26.0269 54.1938 27.1147 55.142C39.2926 65.7573 35.6613 77.059 31.3602 85.1879C29.7325 88.264 28.026 91.8603 26.6376 95.9403Z"
              fill="#EC9823" />
        <path d="M24.8249 52.3103C41.4009 54.1023 57.978 55.4465 68.2824 64.4067C59.322 50.5183 36.6518 52.3103 24.8249 52.3103Z" fill="#B47012" />
        <path
          d="M58.1263 61.1142C59.5011 61.378 63.7547 61.412 65.5643 61.0593C65.5643 61.0593 62.8762 58.1024 57.9481 55.8623C57.2827 58.276 57.6294 61.0189 58.1263 61.1142Z"
          fill="white" />
        <path
          d="M50.1854 59.6671C51.1435 60.2282 55.1356 57.0576 57.9481 55.8623C57.0125 55.6271 53.4679 54.0703 50.3318 53.6223C48.1143 53.3055 49.6837 59.3733 50.1854 59.6671Z"
          fill="white" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="145" height="97" fill="white" transform="matrix(1 0 0 -1 0 97)" />
        </clipPath>
      </defs>
    </svg>
    <span class="ui-assignments-empty-title">No assignments from this class</span>
    <span class="ui-assignments-empty-text">Click the button below to create your first assignment!</span>
    <button class="ui-btn ui-assignments-empty-btn" routerLink="/assignments/builder">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
      </svg>
      Add new assignments
    </button>
  </div>
</ng-template>

<div class="ui-profile-bind-container">
  <div class="ui-form">
    <div class="ui-form-input-group">
      <div class="ui-form-text ui-form-text-xs">Enter the 7-digit number (AIN), which can be found when your child logs into their school account</div>
      <div class="ui-form-input">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
        </svg>
        <input name="ain" type="number" min="1000000" max="9999999" [value]="interface.student.ain" placeholder="User AIN" (keyup)="actionEnterAin($event)">
      </div>
      <div class="ui-form-text ui-form-text-error ui-form-text-xs" *ngIf="!interface._cache.check_ain">User ID not found, please use the form or enter correct AIN</div>
    </div>
    <div class="ui-form-divider">
      <div class="ui-form-divider-line" aria-hidden="true">
        <span></span>
      </div>
      <div class="ui-form-divider-text">
        <span>Bind a student</span>
      </div>
    </div>
    <div class="ui-form-avatar ui-form-avatar-readonly">
      <div class="ui-form-avatar-current">
        <svg *ngIf="!interface.student.avatar" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
        <img *ngIf="interface.student.avatar" src="{{interface.student.avatar}}" alt="" loading="lazy">
      </div>
      <div class="ui-form-avatars-list">
        <ng-container *ngFor="let avatar of [].constructor(12); let a=index">
          <img src="https://cdn.argoprep.com/avatars/avatar-{{a}}.svg" alt="{{a}}" loading="lazy">
        </ng-container>
      </div>
    </div>
    <div class="ui-form-input-group ui-form-input-group-row justify-between">
      <div class="ui-form-input ui-form-input-small ui-form-input-no-pr ui-form-input-readonly">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <input name="firstname" type="text" [value]="interface.student.firstName" [readonly]="true" placeholder="Firstname">
      </div>
      <div class="ui-form-input ui-form-input-small ui-form-input-no-pr ui-form-input-readonly">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <input name="lastname" type="text" [value]="interface.student.lastName" [readonly]="true" placeholder="Lastname">
      </div>
    </div>
  </div>
  <div class="ui-profile-bind-actions">
    <button class="ui-school-license-action-no" (click)="actionClose()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
      </svg>
      <span>Cancel</span>
    </button>
    <button class="ui-school-license-action-yes" (click)="actionConfirm()" [disabled]="interface.loading || !interface.student._id">
      <svg *ngIf="!interface.loading" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
      <svg *ngIf="interface.loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span>Confirm</span>
    </button>
  </div>
</div>

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ClassModel} from '../../_models/class.model';
import {Store} from '@ngxs/store';
import {ClassesState} from '../../_stores/classes/_state/classes.state';
import {API} from '../../_stores/api';
import {HttpClient} from '@angular/common/http';
import {UserModel} from '../../_models/user.model';
import {RouterSelectors} from '../../_stores/routing/router.selectors';
import {PracticeState} from '../../_stores/practice/_state/practice.state';
import {GradeModel} from '../../_models/grade.model';
import {CategoryModel} from '../../_models/category.model';
import {PracticeService} from '../../_stores/practice/_service/practice.service';
import {ModalOpenAction} from '../../_stores/modal/_actions/modal.actions';
import {UserState} from '../../_stores/user/_state/user.state';
import {Router} from '@angular/router';

@Component({
  selector: 'ui-page-reports',
  templateUrl: './ui-page-reports.component.html',
  styleUrls: ['./ui-page-reports.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiPageReportsComponent implements OnInit {
  interface: {
    loading: boolean;
    type: string;
    user: UserModel;
    classes: ClassModel[];
    class: ClassModel;
    students: UserModel[];
    student: UserModel;
    grades: GradeModel[];
    period: string;
    report: {
      short: {
        quiz: number;
        video: number;
        worksheet: number;
        book_pages: number;
      };
      trackers: any;
      assignments: {
        total: number;
        complete: number;
        incomplete: number;
      };
      worksheets: {
        total: number;
        print: number;
        download: number;
      };
      books: {
        total: number;
        print: number;
        download: number;
      };
      session: {
        time: string;
      };
      goings: [];
      activity: [];
      practice: {
        questions: {
          total: number;
          complete: number;
          started: number;
          incomplete: number;
        };
        drills: {
          total: number;
          complete: number;
          started: number;
          incomplete: number;
        };
        video_lectures: {
          total: number;
          complete: number;
          started: number;
          incomplete: number;
        };
        worksheets: {
          total: number;
          complete: number;
          started: number;
          incomplete: number;
        };
      };
    };
    report_practice: any;
    _cache: {
      isViewFromStudents: boolean;
      categories: CategoryModel[];
      category_selected: any;
      grade_selected: GradeModel;
      grade_report_practice_selected: any;
      period: {
        start: Date;
        end: Date;
      };
    };
  } = {
    _cache: {
      categories: [],
      category_selected: undefined,
      grade_report_practice_selected: undefined,
      grade_selected: undefined,
      isViewFromStudents: false,
      period: { end: undefined, start: undefined },
    },
    loading: false,
    type: null,
    user: undefined,
    classes: [],
    class: null,
    students: [],
    student: null,
    grades: [],
    period: 'day',
    report: {
      short: {
        quiz: 0,
        video: 0,
        worksheet: 0,
        book_pages: 0,
      },
      trackers: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: false,
          fontFamily: 'Montserrat, sans-serif',
          toolbar: {
            show: true,
            offsetY: -30,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            autoSelected: 'pan',
          },
        },
        series: [],
        colors: ['#60a5fa', '#fb7185', '#2dd4bf', '#fdba74'],
        legend: {
          show: true,
          horizontalAlign: 'center',
          offsetX: 40,
          showForSingleSeries: true,
          markers: {
            width: 20,
            height: 20,
            radius: 6,
            offsetX: -3,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            borderRadius: 4,
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['transparent'],
        },
        xaxis: {
          categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
        },
        yaxis: {
          show: true,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: false,
        },
      },
      assignments: {
        total: 0,
        complete: 0,
        incomplete: 0,
      },
      worksheets: {
        total: 0,
        print: 0,
        download: 0,
      },
      books: {
        total: 0,
        print: 0,
        download: 0,
      },
      session: {
        time: '00:00',
      },
      goings: [],
      activity: [],
      practice: {
        questions: {
          total: 0,
          complete: 0,
          started: 0,
          incomplete: 0,
        },
        drills: {
          total: 0,
          complete: 0,
          started: 0,
          incomplete: 0,
        },
        video_lectures: {
          total: 0,
          complete: 0,
          started: 0,
          incomplete: 0,
        },
        worksheets: {
          total: 0,
          complete: 0,
          started: 0,
          incomplete: 0,
        },
      },
    },
    report_practice: undefined,
  };

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private practiceService: PracticeService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.initUser();
    this.initReportType();
    this.initClasses();
    this.initReports();
    this.initGrades();
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }

  initReportType(): void {
    this.store.select(RouterSelectors.params).subscribe((_params) => {
      if (_params.student_id && _params.student_id.length > 0) {
        this.interface._cache.isViewFromStudents = true;
        this.interface.student = this.interface.user.children.find((value) => value._id === _params.student_id);
        this.actionSelectStudent(this.interface.student);
      }
      if (_params.type == 'classes' || _params.type == 'practice' || _params.type == 'students') {
        this.interface.type = _params.type;
        if (!this.interface.user?.school?.license?.active) {
          this.store.dispatch(new ModalOpenAction('license-off-extend', false));
        }
      }
    });
  }

  initClasses(): void {
    this.store
      .select(ClassesState.selectClassList)
      .pipe()
      .subscribe((classes) => {
        this.interface.classes = classes;
        this.initStudents();
        this.cdr.detectChanges();
      });
  }

  initReports(): void {
    this.interface.loading = true;
    this.interface._cache.categories = [];
    this.interface._cache.category_selected = undefined;
    this.interface._cache.grade_report_practice_selected = undefined;
    let _params = '';
    let _date = this.interface._cache.period.start;

    if (this.interface.class) {
      _params = 'class=' + this.interface.class._id;
    }

    if (this.interface.period == 'day' || this.interface.period == 'custom') {
      _params += '&beginDate=' + this.interface?._cache?.period?.start?.getTime();
    }
    if (this.interface.period == 'week') {
      _params += '&beginDate=' + (this.interface?._cache?.period?.end?.getTime() - 86400000 * 7);
      _params += '&endDate=' + this.interface?._cache?.period?.end?.getTime();
    }
    if (this.interface.period == 'month') {
      _params += '&beginDate=' + (this.interface?._cache?.period?.end?.getTime() - 86400000 * 30);
      _params += '&endDate=' + this.interface?._cache?.period?.end?.getTime();
    }

    if (this.interface.student) {
      _params += '&student=' + this.interface.student._id;
    }
    //62f515fbd62293f5fddcac98
    //_params += '&grade=5e703269f73e83064765eb0f';

    this.http.get<any>(API.url('reports/creator?' + _params)).subscribe((_report) => {
      this.initReport();
      this.interface.report.short = _report.short;
      this.interface.report.assignments = _report.assignments;
      this.interface.report.books = _report.books;
      this.interface.report.worksheets = _report.worksheets;
      this.interface.report.activity = _report.activity;
      this.interface.report.practice = _report.practice;
      this.interface.report.goings = _report.goings ? _report.goings : [];
      this.interface.report.trackers.series = _report.trackers.series;
      this.interface.loading = false;
      this.cdr.detectChanges();
    });

    this.http.get<any>(API.url('reports/creator/practice?' + _params)).subscribe((_report) => {
      this.interface.report_practice = _report;
      this.actionSelectGrade(this.interface._cache.grade_selected);
      this.cdr.detectChanges();
    });
  }

  initReport(): void {
    this.initTrackerChartDefault();
    this.initTrackerChart();
  }

  initStudents(): void {
    let _students = this.interface.classes.reduce((students, _class) => {
      students = students.concat(_class.students);
      return students;
    }, []);
    _students = _students.reduce((acc, cur) => (acc.some((x) => x._id === cur._id) ? acc : acc.concat(cur)), []);
    if (this.interface._cache.isViewFromStudents) {
      this.interface.students = _students.filter((value) => value._id === this.interface.student._id);
      return;
    }
    this.interface.students = _students;
  }

  initGrades(): void {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.interface.grades = _grades;
      this.cdr.detectChanges();
    });
  }

  initTrackerChartDefault(): void {
    let _categories = [];
    let _series = [];

    if (this.interface.period == 'day' || this.interface.period == 'custom') {
      _series = [
        {
          name: 'Quiz',
          data: []
            .constructor(24)
            .fill(0)
            .map((_, i) => {
              return Math.floor(Math.random() * 10);
            }),
        },
        {
          name: 'Video',
          data: []
            .constructor(24)
            .fill(0)
            .map((_, i) => {
              return Math.floor(Math.random() * 10);
            }),
        },
        {
          name: 'Worksheet',
          data: []
            .constructor(24)
            .fill(0)
            .map((_, i) => {
              return Math.floor(Math.random() * 10);
            }),
        },
        {
          name: 'Book pages',
          data: []
            .constructor(24)
            .fill(0)
            .map((_, i) => {
              return Math.floor(Math.random() * 10);
            }),
        },
      ];
      _categories = []
        .constructor(24)
        .fill(0)
        .map((_, i) => {
          return ('0' + ~~i + ': 0' + 60 * (i % 1)).replace(/\d(\d\d)/g, '$1');
        });
    }
    if (this.interface.period == 'week') {
      _series = [
        {
          name: 'Quiz',
          data: [].constructor(7).fill(0),
        },
        {
          name: 'Video',
          data: [].constructor(7).fill(0),
        },
        {
          name: 'Worksheet',
          data: [].constructor(7).fill(0),
        },
        {
          name: 'Book pages',
          data: [].constructor(7).fill(0),
        },
      ];
      _categories = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    }
    if (this.interface.period == 'month') {
      const _count_days = 30;
      _series = [
        {
          name: 'Quiz',
          data: [].constructor(_count_days).fill(0),
        },
        {
          name: 'Video',
          data: [].constructor(_count_days).fill(0),
        },
        {
          name: 'Worksheet',
          data: [].constructor(_count_days).fill(0),
        },
        {
          name: 'Book pages',
          data: [].constructor(_count_days).fill(0),
        },
      ];
      _categories = []
        .constructor(_count_days)
        .fill(0)
        .map((_, i) => {
          return i + 1;
        });
    }

    this.interface.report.trackers.series = _series;
    this.interface.report.trackers.xaxis.categories = _categories;
  }

  initTrackerChart(): void {
    let _categories = [];
    let _series = [];

    if (this.interface.period == 'day' || this.interface.period == 'custom') {
      _series = [
        {
          name: 'Quiz',
          data: [].constructor(24).fill(0),
        },
        {
          name: 'Video',
          data: [].constructor(24).fill(0),
        },
        {
          name: 'Worksheet',
          data: [].constructor(24).fill(0),
        },
        {
          name: 'Book pages',
          data: [].constructor(24).fill(0),
        },
      ];
    }
    if (this.interface.period == 'week') {
      _series = [
        {
          name: 'Quiz',
          data: [].constructor(7).fill(0),
        },
        {
          name: 'Video',
          data: [].constructor(7).fill(0),
        },
        {
          name: 'Worksheet',
          data: [].constructor(7).fill(0),
        },
        {
          name: 'Book pages',
          data: [].constructor(7).fill(0),
        },
      ];
    }
    if (this.interface.period == 'month') {
      const _count_days = 30;
      _series = [
        {
          name: 'Quiz',
          data: [].constructor(_count_days).fill(0),
        },
        {
          name: 'Video',
          data: [].constructor(_count_days).fill(0),
        },
        {
          name: 'Worksheet',
          data: [].constructor(_count_days).fill(0),
        },
        {
          name: 'Book pages',
          data: [].constructor(_count_days).fill(0),
        },
      ];
    }
    //this.interface.report.trackers.series = _series;
  }

  getTotalClassProgress(_class): number {
    let _progress = 0;
    _progress = Math.floor((_class.doneCountAssignment / _class.countAssignment) * 100);
    return _progress ? _progress : 0;
  }

  getGradeMaterialTotal(_id): number {
    let grade = this.interface.report_practice?.gradeMaterial?.find((_grade) => _grade.id == _id);
    return grade ? grade.data.totalMaterial : 0;
  }

  actionSelectClass(_class): void {
    if (this.interface.loading) {
      return;
    }
    this.interface.class = _class;
    if (_class) {
      this.interface.students = _class.students;
    } else {
      this.initStudents();
    }
    this.actionSelectPeriod('day');
  }

  actionSelectStudent(_student): void {
    if (this.interface.loading) {
      return;
    }
    this.interface.student = _student;
    this.actionSelectPeriod('day');
  }

  actionSelectPeriod(period): void {
    this.interface.period = period;
    if (period == 'day') {
      this.interface._cache.period.start = new Date();
      this.interface._cache.period.end = new Date();
    }

    this.initReports();
  }

  actionSelectPeriodDate(event): void {
    this.interface._cache.period.start = event;
    this.interface._cache.period.end = event;
    this.actionSelectPeriod('custom');
  }

  actionSelectGrade(grade): void {
    if (!grade) {
      this.interface._cache.grade_selected = this.interface.grades[8];
    } else {
      this.interface._cache.grade_selected = grade;
    }

    let _grade_index = this.interface.report_practice?.gradeMaterial?.findIndex(
      (_grade) => _grade.id == this.interface._cache.grade_selected.category,
    );

    this.interface._cache.grade_report_practice_selected = this.interface.report_practice?.progressTree[_grade_index];
    this.actionSelectCategory(this.interface._cache.grade_report_practice_selected[0]);

    // this.initGradeCategories();
  }

  actionSelectCategory(category): void {
    this.interface._cache.category_selected = category;
  }

  actionOpenFullReport(report): void {
    if (!this.interface.user?.school?.license?.active) {
      this.store.dispatch(new ModalOpenAction('license-off-extend'));
      return;
    }
    this.router.navigate(['reports/' + report]).then();
  }
}

<div class="ui-profiles">
  <ng-container *ngFor="let user of interface.users; let i=index">
    <div class="ui-profile" [class.ui-profile-select]="user._id === interface.user?._id" (click)="actionSelectGlobalUser(i)">
      <img src="{{user.avatar}}" alt="{{user.displayName}}" loading="lazy">
      <div class="ui-profile-info">
        <p>{{user.displayName}}</p>
        <span>{{user.role?.name}}</span>
      </div>
    </div>
  </ng-container>
  <div *ngIf="interface.user?.role._id != '62ebc2e38e33b7bc52c853c6'" class="ui-profile ui-profile-add" (click)="actionOpenAddUserForm()">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
      <path stroke-linecap="round" stroke-linejoin="round" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
    </svg>
    <span>Add new profile</span>
  </div>
</div>

<div class="ui-block ui-block-white">
  <div class="ui-block-header ">
    <div class="ui-block-header-title">
      <h2>Resources</h2>
      <p>Practice resources from <b>{{getCurrentUserGrade()}}</b> grade</p>
    </div>
    <div class="ui-books-filters !w-auto">
      <div class="ui-books-filter-input-group ml-auto">
        <div class='ui-books-filter-select'>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
          </svg>
          <input  name='grades' type='text' readonly [value]="getCurrentUserGrade()" placeholder="Choose grade level" (click)="actionToggleChooseGrade()">
          <div class="ui-books-filter-select-list" [class.ui-books-filter-select-list-open]="interface._cache.open_choose_grade">
            <div class="ui-books-filter-select-list-items">
              <ng-container *ngFor="let grade of interface.grades | sort:'asc':'order'">
                <div class="ui-books-filter-select-list-item" (click)="actionChooseGrade(grade)">
                  <p>{{grade.title}}</p>
                </div>
              </ng-container>
            </div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" (click)="actionToggleChooseGrade()">
            <path *ngIf="!interface._cache.open_choose_grade" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
            <path *ngIf="interface._cache.open_choose_grade" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-block-inner">
    <ng-container [ngTemplateOutlet]="PRACTICE_CARDS"></ng-container>
  </div>
  <div class="ui-block-footer"></div>
</div>

<ng-template #PRACTICE_CARDS>
  <div class="ui-practice-cards">
    <div class="ui-practice-card ui-practice-card-video_lectures" >
      <div class="ui-practice-card-info">
        <h3>Video lectures</h3>
        <button class="ui-btn" [routerLink]="'../practices/video_lectures'">Continue</button>
      </div>
    </div>
    <div class="ui-practice-card ui-practice-card-practice_questions" >
      <div class="ui-practice-card-info">
        <h3>Practice Questions</h3>
        <button class="ui-btn" [routerLink]="'../practices/practice_questions'">Continue</button>
      </div>
    </div>
    <div class="ui-practice-card ui-practice-card-practice_drills" >
      <div class="ui-practice-card-info">
        <h3>Practice Drills</h3>
        <button class="ui-btn" [routerLink]="'../practices/practice_drills'">Continue</button>
      </div>
    </div>
    <div class="ui-practice-card ui-practice-card-worksheets" >
      <div class="ui-practice-card-info">
        <h3>Worksheets</h3>
        <button class="ui-btn" [routerLink]="'../practices/worksheets'">Continue</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #CATEGORY_IMAGE let-category='category'>
  <ng-container [ngSwitch]="category.slug">
    <img *ngSwitchCase="'video_lectures'" src="./assets/images/objects/backpack.gif" alt="{{category.title}}" loading="lazy"/>
    <img *ngSwitchCase="'practice_questions'" src="./assets/images/objects/classboard.gif" alt="{{category.title}}" loading="lazy"/>
    <img *ngSwitchCase="'worksheets'" src="./assets/images/objects/book.gif" alt="{{category.title}}" loading="lazy"/>
    <img *ngSwitchCase="'practice_drills'" src="./assets/images/objects/cup.gif" alt="{{category.title}}" loading="lazy"/>
    <img *ngSwitchCase="'official_test_explanations'" src="./assets/images/objects/cup.gif" alt="{{category.title}}" loading="lazy"/>
  </ng-container>
</ng-template>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiGradesModule} from '../ui-grades/ui-grades.module';
import {UiTooltipModule} from '../ui-tooltip/ui-tooltip.module';
import {TranslocoModule} from '@ngneat/transloco';
import {UiClassAddComponent} from './ui-class-add.component';

@NgModule({
  declarations: [UiClassAddComponent],
  exports: [UiClassAddComponent],
  imports: [CommonModule, UiGradesModule, UiTooltipModule, TranslocoModule],
})
export class UiClassAddModule {}

import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Store} from "@ngxs/store";
import {ActivatedRoute, Router} from "@angular/router";
import {ModalCloseAction} from "../../_stores/modal/_actions/modal.actions";
import {RouterSelectors} from "../../_stores/routing/router.selectors";

@Component({
  selector: 'ui-homework-exit',
  templateUrl: './ui-homework-exit.component.html',
  styleUrls: ['./ui-homework-exit.component.scss']
})
export class UiHomeworkExitComponent implements OnInit {
  interface: {
    assignment_id: string
  } = {
    assignment_id: null
  }

  constructor(private store: Store,private cdr: ChangeDetectorRef, private route: ActivatedRoute,  private router: Router) { }

  ngOnInit(): void {
    this.initRouteParams();
  }

  initRouteParams(): void {
    this.store.select(RouterSelectors.params).subscribe((_params) => {
      this.interface.assignment_id = _params.assignment_id;
    });
  }

  actionClose():void {
    this.store.dispatch(new ModalCloseAction());
  }
  actionOpenResult(): void {
    this.store.dispatch(new ModalCloseAction());
    this.router.navigate(['homeworks/'+this.interface.assignment_id]).then();
  }
}

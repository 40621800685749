import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import {VideoPlayerModel} from "../../_models/videoplayer.model";

@Component({
  selector: 'ui-videoplayer',
  templateUrl: './ui-videoplayer.component.html',
  styleUrls: ['./ui-videoplayer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiVideoplayerComponent implements OnInit {
  @Input('src') set _src(src) {
    if (src) {
      this.actionPlayerStop();
      this.interface.src = src;
      this.interface.thumbnail = src.replace('link_file', 'poster');
    }
  }
  @Input('thumbnail') set _thumbnail(thumbnail) {
    if (thumbnail) {
      this.interface.thumbnail = thumbnail;
    }
  }
  @Input('homework') set _homework(homework) {
    if (homework) {
      this.interface.homework = homework;
    }
  }

  @Output() play: EventEmitter<any> = new EventEmitter();
  @Output() time: EventEmitter<any> = new EventEmitter();

  videoPlayer: ElementRef;
  @ViewChild('videoPlayer') set setVideoPlayer(element){
    if (element != undefined){
      this.videoPlayer = element;
      this.videoPlayer.nativeElement.addEventListener('playing', (event) => {
        this.interface.controls.play = true;
      });

      this.videoPlayer.nativeElement.addEventListener('pause', (event) => {
        this.interface.controls.play = false;
      });
    }
  };
  @ViewChild('videoContainer') videoContainer: ElementRef;
  @ViewChild('volume') volume: ElementRef;
  currentDocument = document as Document & {
    mozCancelFullScreen(): Promise<void>;
    webkitExitFullscreen(): Promise<void>;
    msExitFullscreen(): Promise<void>;
  };
  interface: VideoPlayerModel = {
    homework: false,
    src: null,
    thumbnail: null,
    controls: {
      volume: 80,
      playbackRate: 1,
      fullscreen: false,
      muted: false,
      play: false,
      time: {
        current: 0,
        playing: 0,
        total: 0
      }
    }
  }
  constructor() { }

  ngOnInit(): void {

  }

  actionPlayerPlay(): void {
    this.interface.controls.play = !this.interface.controls.play;
    this.videoPlayer?.nativeElement.play();
    this.play.emit(true);
  }
  actionPlayerPause(): void {
    this.interface.controls.play = !this.interface.controls.play;
    this.videoPlayer?.nativeElement.pause();
  }
  actionPlayerStop(): void {
    this.interface.controls.play = false;
    this.interface.controls.time.current = 0;
    this.videoPlayer?.nativeElement.pause();
    this.play.emit(false);
  }
  actionPlayerToggleVolume(): void {
    this.interface.controls.muted = !this.interface.controls.muted;
  }
  actionPlayerFullScreen(): void {
      if (this.currentDocument.exitFullscreen) {
        this.currentDocument.exitFullscreen();
      }
      if (this.currentDocument.mozCancelFullScreen) {
        this.currentDocument.mozCancelFullScreen();
      }
      if (this.currentDocument.webkitExitFullscreen) {
        this.currentDocument.webkitExitFullscreen();
      }
      if (this.currentDocument.msExitFullscreen) {
        this.currentDocument.msExitFullscreen();
      }

      if (this.videoContainer?.nativeElement.requestFullscreen) {
        this.videoContainer?.nativeElement.requestFullscreen();
      }
      if (this.videoContainer?.nativeElement.mozRequestFullScreen) {
        this.videoContainer?.nativeElement.mozRequestFullScreen();
      }
      if (this.videoContainer?.nativeElement.webkitRequestFullscreen) {
        this.videoContainer?.nativeElement.webkitRequestFullscreen();
      }
      if (this.videoContainer?.nativeElement.msRequestFullscreen) {
        this.videoContainer?.nativeElement.msRequestFullscreen();
      }
      this.interface.controls.fullscreen = !this.interface.controls.fullscreen;

  }

  actionPlayerProgressTimeupdate(): void {
    this.interface.controls.time.total = this.videoPlayer?.nativeElement.duration? this.videoPlayer?.nativeElement.duration: 0;
    this.interface.controls.time.current = this.videoPlayer?.nativeElement.currentTime;
    this.time.emit(this.interface.controls.time.current);
    //this.listenerTimeUpdateActions();
  }
  actionPlayerProgressChange(event): void {
    if(this.interface.homework) {
      return;
    }
    const convert = event.offsetX / event.target.offsetWidth;
    this.interface.controls.time.current = this.interface.controls.time.total * convert;
    this.videoPlayer.nativeElement.currentTime = this.interface.controls.time.current;
    this.videoPlayer?.nativeElement.play();
  }

  actionPlayerVolumeChange(event): void {
    const _volume = event.offsetX / this.volume?.nativeElement.offsetWidth;
    this.interface.controls.volume = event.offsetX;
    this.videoPlayer.nativeElement.volume = _volume;
  }

  actionPlayerToggleSpeed(speed): void {
    this.interface.controls.playbackRate = speed;
    this.videoPlayer.nativeElement.playbackRate = this.interface.controls.playbackRate;
  }

  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      if (this.interface.controls.play) {
        this.actionPlayerPause();
      } else {
        this.actionPlayerPlay();
      }
      event.preventDefault();
    }
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiControlDashboardComponent} from "./ui-control-dashboard.component";
import {UiAppNoDataModule} from "../../_ui/ui-app-no-data/ui-app-no-data.module";
import {NgApexchartsModule} from "ng-apexcharts";
import {NgxsModule} from "@ngxs/store";
import {ControlState} from "../../_stores/control/_state/control.state";
import {RouterModule} from "@angular/router";
import {UiTooltipModule} from "../../_ui/ui-tooltip/ui-tooltip.module";
import {PipesModule} from "../../app.pipes.module";


@NgModule({
  declarations: [UiControlDashboardComponent],
  exports: [UiControlDashboardComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([ControlState]),
    UiAppNoDataModule,
    NgApexchartsModule,
    RouterModule,
    UiTooltipModule,
    PipesModule
  ]
})
export class UiControlDashboardModule { }

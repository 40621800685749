import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiControlCategoryAddComponent} from './ui-control-category-add.component';
import {TranslocoModule} from '@ngneat/transloco';
import {UiTooltipModule} from '../ui-tooltip/ui-tooltip.module';

@NgModule({
  declarations: [UiControlCategoryAddComponent],
  exports: [UiControlCategoryAddComponent],
  imports: [CommonModule, TranslocoModule, UiTooltipModule],
})
export class UiControlCategoryAddModule {}

<ng-container *ngIf="interface.type == null" [ngTemplateOutlet]="REPORTS"></ng-container>
<ng-container *ngIf="interface.type == 'classes'" [ngTemplateOutlet]="REPORTS_CLASSES"></ng-container>
<ng-container *ngIf="interface.type == 'practice'" [ngTemplateOutlet]="REPORTS_PRACTICE"></ng-container>
<ng-container *ngIf="interface.type == 'students'" [ngTemplateOutlet]="REPORTS_STUDENTS"></ng-container>

<ng-template #REPORTS>
  <div class="ui-reports-content">
    <div class="ui-block ui-block-white ui-block-no-margin col-span-12 xl:col-span-3">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>Classes</h2>
          <p>Get a deeper insight into your class statistics</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
      </div>
      <div class="ui-block-inner ui-block-inner-col h-full">
        <div *ngIf="!interface.loading" class="ui-report-total">
          <div class="ui-report-total-short">
            <label>
              <h3>{{interface.classes.length}}</h3>
              <span></span>
              <small></small>
            </label>
            <p>Classes created</p>
          </div>
          <div class="ui-report-total-short">
            <label>
              <h3>{{interface.report.assignments.total}}</h3>
              <span></span>
              <small></small>
            </label>
            <p>Assignments</p>
            <div class="ui-reports-total mt-2 !h-auto">
              <div class="ui-reports-total-data">
                <div class="ui-reports-total-data-item">
                  <div class="ui-reports-total-data-item-icon bg-emerald-100 text-emerald-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                    </svg>

                  </div>
                  <div class="ui-reports-total-data-item-name">Complete</div>
                  <div class="ui-reports-total-data-item-count">{{interface.report.assignments.complete}}</div>
                </div>
                <div class="ui-reports-total-data-item">
                  <div class="ui-reports-total-data-item-icon bg-rose-100 text-rose-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M11.412 15.655L9.75 21.75l3.745-4.012M9.257 13.5H3.75l2.659-2.849m2.048-2.194L14.25 2.25 12 10.5h8.25l-4.707 5.043M8.457 8.457L3 3m5.457 5.457l7.086 7.086m0 0L21 21" />
                    </svg>
                  </div>
                  <div class="ui-reports-total-data-item-name">Not completed</div>
                  <div class="ui-reports-total-data-item-count">{{interface.report.assignments.incomplete}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px] mt-4"></ui-no-data>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action">
          <button *ngIf="!interface.loading" class="ui-btn ui-btn-sm ui-btn-blue-outline" (click)="actionOpenFullReport('classes')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
            </svg>
            <span>View full reports</span>
          </button>
        </div>
      </div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin col-span-12 xl:col-span-6">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>Practice</h2>
          <p>Get a deeper insight into your student practice statistics</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
      </div>
      <div class="ui-block-inner ui-block-inner-col">
        <div *ngIf="!interface.loading" class="ui-report-total !gap-6">
          <div class="ui-report-total-short">
            <label>
              <h3>{{interface.report.practice.questions.total}}</h3>
              <span></span>
              <small></small>
            </label>
            <p>Questions</p>
            <div class="ui-reports-total mt-2 !h-auto">
              <div class="ui-reports-total-data">
                <div class="ui-reports-total-data-item">
                  <div class="ui-reports-total-data-item-icon bg-emerald-100 text-emerald-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                    </svg>

                  </div>
                  <div class="ui-reports-total-data-item-name">Complete</div>
                  <div class="ui-reports-total-data-item-count">{{interface.report.practice.questions.complete}}</div>
                </div>
                <div class="ui-reports-total-data-item">
                  <div class="ui-reports-total-data-item-icon bg-yellow-100 text-yellow-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                    </svg>
                  </div>
                  <div class="ui-reports-total-data-item-name">Started</div>
                  <div class="ui-reports-total-data-item-count">{{interface.report.practice.questions.started}}</div>
                </div>
                <div class="ui-reports-total-data-item">
                  <div class="ui-reports-total-data-item-icon bg-rose-100 text-rose-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                    </svg>
                  </div>
                  <div class="ui-reports-total-data-item-name">Not completed</div>
                  <div class="ui-reports-total-data-item-count">{{interface.report.practice.questions.incomplete}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-report-total-short">
            <label>
              <h3>{{interface.report.practice.video_lectures.total}}</h3>
              <span></span>
              <small></small>
            </label>
            <p>Video lectures</p>
            <div class="ui-reports-total mt-2 !h-auto">
              <div class="ui-reports-total-data">
                <div class="ui-reports-total-data-item">
                  <div class="ui-reports-total-data-item-icon bg-emerald-100 text-emerald-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                    </svg>
                  </div>
                  <div class="ui-reports-total-data-item-name">Watched</div>
                  <div class="ui-reports-total-data-item-count">{{interface.report.practice.video_lectures.complete}}</div>
                </div>
                <div class="ui-reports-total-data-item">
                  <div class="ui-reports-total-data-item-icon bg-yellow-100 text-yellow-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                    </svg>
                  </div>
                  <div class="ui-reports-total-data-item-name">Started</div>
                  <div class="ui-reports-total-data-item-count">{{interface.report.practice.video_lectures.started}}</div>
                </div>
                <div class="ui-reports-total-data-item">
                  <div class="ui-reports-total-data-item-icon bg-rose-100 text-rose-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M12 18.75H4.5a2.25 2.25 0 01-2.25-2.25V9m12.841 9.091L16.5 19.5m-1.409-1.409c.407-.407.659-.97.659-1.591v-9a2.25 2.25 0 00-2.25-2.25h-9c-.621 0-1.184.252-1.591.659m12.182 12.182L2.909 5.909M1.5 4.5l1.409 1.409" />
                    </svg>
                  </div>
                  <div class="ui-reports-total-data-item-name">Not watch</div>
                  <div class="ui-reports-total-data-item-count">{{interface.report.practice.video_lectures.incomplete}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-report-total-short">
            <label>
              <h3>{{interface.report.practice.drills.total}}</h3>
              <span></span>
              <small></small>
            </label>
            <p>Drills</p>
            <div class="ui-reports-total mt-2 !h-auto">
              <div class="ui-reports-total-data">
                <div class="ui-reports-total-data-item">
                  <div class="ui-reports-total-data-item-icon bg-emerald-100 text-emerald-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                    </svg>

                  </div>
                  <div class="ui-reports-total-data-item-name">Complete</div>
                  <div class="ui-reports-total-data-item-count">{{interface.report.practice.drills.complete}}</div>
                </div>
                <div class="ui-reports-total-data-item">
                  <div class="ui-reports-total-data-item-icon bg-yellow-100 text-yellow-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                    </svg>
                  </div>
                  <div class="ui-reports-total-data-item-name">Started</div>
                  <div class="ui-reports-total-data-item-count">{{interface.report.practice.drills.started}}</div>
                </div>
                <div class="ui-reports-total-data-item">
                  <div class="ui-reports-total-data-item-icon bg-rose-100 text-rose-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                    </svg>
                  </div>
                  <div class="ui-reports-total-data-item-name">Not completed</div>
                  <div class="ui-reports-total-data-item-count">{{interface.report.practice.drills.incomplete}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-report-total-short">
            <label>
              <h3>{{interface.report.practice.worksheets.total}}</h3>
              <span></span>
              <small></small>
            </label>
            <p>Worksheets</p>
            <div class="ui-reports-total mt-2 !h-auto">
              <div class="ui-reports-total-data">
                <div class="ui-reports-total-data-item">
                  <div class="ui-reports-total-data-item-icon bg-emerald-100 text-emerald-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>
                  </div>
                  <div class="ui-reports-total-data-item-name">Opened</div>
                  <div class="ui-reports-total-data-item-count">{{interface.report.practice.worksheets.complete}}</div>
                </div>
                <div class="ui-reports-total-data-item">
                  <div class="ui-reports-total-data-item-icon bg-rose-100 text-rose-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>
                  </div>
                  <div class="ui-reports-total-data-item-name">Not open</div>
                  <div class="ui-reports-total-data-item-count">{{interface.report.practice.worksheets.incomplete}}</div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px] mt-4"></ui-no-data>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action">
          <button *ngIf="!interface.loading" class="ui-btn ui-btn-sm ui-btn-blue-outline" (click)="actionOpenFullReport('practice')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
            </svg>
            <span>View full reports</span>
          </button>
        </div>
      </div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin col-span-12 xl:col-span-3">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>Users</h2>
          <p>Get a deeper insight into your student and parents statistics</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
      </div>
      <div class="ui-block-inner ui-block-inner-col">
        <div *ngIf="!interface.loading" class="ui-report-total flex-wrap">
          <div class="ui-report-total-long">
            <h3>{{interface.students.length}}</h3>
            <div class="ui-report-info-students">
              <ng-container *ngFor="let student of interface.students | slice: 0:5; let s=index">
                <div class="ui-report-info-student">
                  <img  [src]="student.avatar" alt="{{student.displayName}}" tooltip description="{{student.displayName}}"/>
                </div>
              </ng-container>
              <div *ngIf="interface.students?.length > 6"
                   class="ui-report-info-student ui-report-info-student-label">
                +{{interface.students?.length - 5}}
              </div>
            </div>
          </div>
          <div class="ui-report-total-short">
            <label>
              <h3>{{interface.students.length}}</h3>
              <span></span>
              <small></small>
            </label>
            <p>Students</p>
          </div>
          <div class="ui-report-total-short">
            <label>
              <h3>0</h3>
              <span></span>
              <small></small>
            </label>
            <p>Parents</p>
          </div>

        </div>
        <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px] mt-4"></ui-no-data>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action">
          <button *ngIf="!interface.loading" class="ui-btn ui-btn-sm ui-btn-blue-outline" (click)="actionOpenFullReport('students')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
            </svg>
            <span>View full reports</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #REPORTS_CLASSES>
  <div class="ui-reports-sidebar">
    <div class="ui-reports-classes">
      <div class="ui-reports-class-all" [class.ui-reports-class-all-active]="interface.class == null" (click)="actionSelectClass(null)">
        <div class="ui-reports-class-all-icons">
          <div class="ui-reports-class-all-icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
            </svg>
          </div>
        </div>
        <div class="ui-reports-class-all-name">
          <h2>All statistics</h2>
          <p>{{interface.classes.length}} classes</p>
        </div>
      </div>
      <div class="ui-reports-class-divider">
        <div class="ui-reports-class-divider-line" aria-hidden="true">
          <span></span>
        </div>
        <div class="ui-reports-class-divider-text ">
          <span>Or only for class</span>
        </div>
        <div class="ui-reports-class-divider-line" aria-hidden="true">
          <span></span>
        </div>
      </div>
      <ng-container *ngFor="let _class of interface.classes | sort: 'desc': 'createdAt'; let cl=index">
        <div class="ui-reports-class" [class.ui-reports-class-active]="interface.class?._id == _class._id" (click)="actionSelectClass(_class)">
          <div class="ui-reports-class-header">
            <div class="ui-reports-class-header-title">
              <div class="ui-reports-class-header-title-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                </svg>
              </div>
              <div class="ui-reports-class-header-title-text">
                <h3>{{_class.title}}</h3>
                <p>{{_class.countAssignment}} Assignments</p>
              </div>
            </div>
          </div>
          <div class="ui-reports-class-students">
            <ng-container *ngFor="let student of _class.students | slice: 0:5; let s=index">
              <div class="ui-reports-class-student">
                <img  [src]="student.avatar" alt="{{student.displayName}}" tooltip description="{{student.displayName}}"/>
              </div>
            </ng-container>
            <div *ngIf="_class.students?.length > 5"
                 class="ui-reports-class-student ui-reports-class-student-label">
              +{{_class.students?.length - 5}}
            </div>
            <div *ngIf="_class.students?.length == 0" class="ui-reports-class-student ui-reports-class-no-student-label">No students from class</div>
          </div>
          <div class="ui-reports-class-progress">
            <p>
              <span>Progress: {{getTotalClassProgress(_class)}}%</span>
              <span>Done: {{_class.doneCountAssignment}} / {{_class.countAssignment}}</span>
            </p>
            <progress [max]="_class.countAssignment" [value]="_class.doneCountAssignment"></progress>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!interface.loading && interface.classes.length == 0">
        <ui-no-data text="No Classes Created"></ui-no-data>
      </ng-container>
      <ng-container *ngIf="interface.loading">
        <ui-no-data type="loading" text="loading classes"></ui-no-data>
      </ng-container>
    </div>
  </div>
  <div class="ui-reports-content">
    <div class="ui-reports-students">
      <button class="ui-btn ui-btn-sm" (click)="actionSelectStudent(null)" [class.ui-btn-pap]="interface.student == null" tooltip description="Statistics from all students">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
        </svg>
        <span>All Students</span>
      </button>
      <ng-container *ngFor="let student of interface.students; let a=index">
        <div class="ui-reports-student" [class.ui-reports-student-active]="student._id == interface.student?._id">
          <img  [src]="student.avatar" alt="{{student.displayName}}" tooltip description="Statistic from {{student.displayName}}" (click)="actionSelectStudent(student)"/>
        </div>
      </ng-container>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin sm:col-span-12">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>{{interface.student? interface.student.displayName +' progress' : 'Students Activity & Progress'}}</h2>
          <p>{{interface.class?.title || 'All classes'}}</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile gap-2 relative">
          <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('day')" [class.ui-btn-pap]="interface.period == 'day'" tooltip description="Statistic by day">
            <span>Day</span>
          </button>
          <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('week')" [class.ui-btn-pap]="interface.period == 'week'" tooltip description="Statistic by week">
            <span>Week</span>
          </button>
          <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('month')" [class.ui-btn-pap]="interface.period == 'month'" tooltip description="Statistic by month">
            <span>Month</span>
          </button>
          <ui-date-picker [future]="true" [date]="interface._cache.period.start" class="ui-reports-period" [class.ui-reports-period-active]="interface.period == 'custom'" (selectedDate)="actionSelectPeriodDate($event)"></ui-date-picker>
        </div>
      </div>
      <div class="ui-block-inner ui-block-inner-col flex-grow">
        <div *ngIf="!interface.loading" class="ui-reports-trackers">
          <div class="ui-reports-trackers-shorts">
            <div class="ui-reports-trackers-short">
              <label>
                <h3>{{interface.report.short.quiz}}q</h3>
                <span>/</span>
                <small>{{interface.period}}</small>
              </label>
              <p>Passed Quizzes</p>
            </div>
            <div class="ui-reports-trackers-short">
              <label>
                <h3>{{interface.report.short.video}}v</h3>
                <span>/</span>
                <small>{{interface.period}}</small>
              </label>
              <p>Viewed videos</p>
            </div>
            <div class="ui-reports-trackers-short">
              <label>
                <h3>{{interface.report.short.worksheet}}w</h3>
                <span>/</span>
                <small>{{interface.period}}</small>
              </label>
              <p>Passed Worksheets</p>
            </div>
            <div class="ui-reports-trackers-short">
              <label>
                <h3>{{interface.report.short.book_pages}}p</h3>
                <span>/</span>
                <small>{{interface.period}}</small>
              </label>
              <p>Read book pages</p>
            </div>
          </div>
          <div class="ui-reports-trackers-data">
          <!--  <apx-chart
              [series]="interface.report.trackers.series"
              [chart]="interface.report.trackers.chart"
              [dataLabels]="interface.report.trackers.dataLabels"
              [plotOptions]="interface.report.trackers.plotOptions"
              [yaxis]="interface.report.trackers.yaxis"
              [legend]="interface.report.trackers.legend"
              [fill]="interface.report.trackers.fill"
              [stroke]="interface.report.trackers.stroke"
              [tooltip]="interface.report.trackers.tooltip"
              [xaxis]="interface.report.trackers.xaxis"
              [colors]="interface.report.trackers.colors"
            ></apx-chart>-->
          </div>
        </div>
        <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="mt-4 h-[453px] flex-grow"></ui-no-data>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
          <button class="ui-btn ui-btn-sm ui-btn-blue-outline">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
            </svg>
            <span>Button</span>
          </button>
        </div>
      </div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin col-span-6 xl:col-span-6 2xl:col-span-3">
      <div class="ui-block-header"></div>
      <div class="ui-block-inner ui-block-inner-col">
        <div *ngIf="!interface.loading" class="ui-reports-total">
          <div class="ui-reports-total-title">Assignments</div>
          <div class="ui-reports-total-counter">{{interface.report.assignments.total}}</div>
          <div class="ui-reports-total-data">
            <div class="ui-reports-total-data-item">
              <div class="ui-reports-total-data-item-icon bg-emerald-100 text-emerald-700">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                </svg>

              </div>
              <div class="ui-reports-total-data-item-name">Complete</div>
              <div class="ui-reports-total-data-item-count">{{interface.report.assignments.complete}}</div>
            </div>
            <div class="ui-reports-total-data-item">
              <div class="ui-reports-total-data-item-icon bg-rose-100 text-rose-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M11.412 15.655L9.75 21.75l3.745-4.012M9.257 13.5H3.75l2.659-2.849m2.048-2.194L14.25 2.25 12 10.5h8.25l-4.707 5.043M8.457 8.457L3 3m5.457 5.457l7.086 7.086m0 0L21 21" />
                </svg>
              </div>
              <div class="ui-reports-total-data-item-name">Not completed</div>
              <div class="ui-reports-total-data-item-count">{{interface.report.assignments.incomplete}}</div>
            </div>
          </div>
        </div>
        <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px]"></ui-no-data>
      </div>
      <div class="ui-block-footer"></div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin col-span-6 xl:col-span-6 2xl:col-span-3">
      <div class="ui-block-header"></div>
      <div class="ui-block-inner ui-block-inner-col">
        <div *ngIf="!interface.loading" class="ui-reports-total">
          <div class="ui-reports-total-title">Worksheets</div>
          <div class="ui-reports-total-counter">{{interface.report.worksheets.total}}</div>
          <div class="ui-reports-total-data">
            <div class="ui-reports-total-data-item">
              <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-400">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
              </div>
              <div class="ui-reports-total-data-item-name">Download</div>
              <div class="ui-reports-total-data-item-count">{{interface.report.worksheets.download}}</div>
            </div>
            <div class="ui-reports-total-data-item">
              <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-400">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                </svg>
              </div>
              <div class="ui-reports-total-data-item-name">Printed</div>
              <div class="ui-reports-total-data-item-count">{{interface.report.worksheets.print}}</div>
            </div>
          </div>
        </div>
        <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px]"></ui-no-data>
      </div>
      <div class="ui-block-footer"></div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin col-span-6 xl:col-span-6 2xl:col-span-3">
      <div class="ui-block-header"></div>
      <div class="ui-block-inner ui-block-inner-col">
        <div *ngIf="!interface.loading" class="ui-reports-total">
          <div class="ui-reports-total-title">Books</div>
          <div class="ui-reports-total-counter">{{interface.report.books.total}}</div>
          <div class="ui-reports-total-data">
            <div class="ui-reports-total-data-item">
              <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-400">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
              </div>
              <div class="ui-reports-total-data-item-name">Download</div>
              <div class="ui-reports-total-data-item-count">{{interface.report.books.download}}</div>
            </div>
            <div class="ui-reports-total-data-item">
              <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-400">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                </svg>
              </div>
              <div class="ui-reports-total-data-item-name">Printed</div>
              <div class="ui-reports-total-data-item-count">{{interface.report.books.print}}</div>
            </div>
          </div>
        </div>
        <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px]"></ui-no-data>
      </div>
      <div class="ui-block-footer"></div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin col-span-6 xl:col-span-6 2xl:col-span-3">
      <div class="ui-block-header"></div>
      <div class="ui-block-inner ui-block-inner-col">
        <div *ngIf="!interface.loading" class="ui-reports-total">
          <div class="ui-reports-total-title">Average Session Time</div>
          <div class="ui-reports-total-counter text-center my-auto">{{interface.report.session.time}}</div>
          <div class="ui-reports-total-data">
          </div>
        </div>
        <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px]"></ui-no-data>
      </div>
      <div class="ui-block-footer"></div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin col-span-12 xl:col-span-6">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>Assignment Activity Feed</h2>
          <p>{{interface.student? interface.student.displayName +' do the following assignment materials' : 'Activity of students who have started but not completed an assignment'}}</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
      </div>
      <div class="ui-block-inner ui-block-inner-col">
        <div *ngIf="!interface.loading" class="ui-reports-goings">
          <ng-container *ngFor="let going of interface.report.goings | sort: 'desc': 'createdAt'; let a=index">
            <div class="ui-reports-going">
              <div class="ui-reports-going-icon" [ngSwitch]="going.typeMaterial">
                <svg *ngSwitchCase="'Question'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                </svg>
                <svg *ngSwitchCase="'Worksheet'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
                <svg *ngSwitchCase="'Video'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                </svg>
                <svg *ngSwitchCase="'Book'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                </svg>
              </div>
              <div class="ui-reports-going-data">
                <p><b>{{going.user?.displayName}}</b>
                  <ng-container [ngSwitch]="going.typeMaterial">
                    <ng-container *ngSwitchCase="'Question'">Started homework quiz from <b>{{going.assignment.title}}</b> assignment but did not answers for all questions</ng-container>
                    <ng-container *ngSwitchCase="'Worksheet'">Opened homework worksheet from <b>{{going.assignment.title}}</b> assignment but did not submit his work</ng-container>
                    <ng-container *ngSwitchCase="'Video'">Started watching homework video from <b>{{going.assignment.title}}</b> assignment but didn't finish watching</ng-container>
                    <ng-container *ngSwitchCase="'Book'">Opened homework books pages from <b>{{going.assignment.title}}</b> assignment but did not submit his work</ng-container>
                  </ng-container>
                </p>
                <span>{{going.createdAt | date: 'medium'}}</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="interface.report.goings.length == 0">
            <ui-no-data text="No assignments"></ui-no-data>
          </ng-container>
        </div>
        <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px] mt-4"></ui-no-data>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
      </div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin col-span-12 xl:col-span-6">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>{{interface.student? interface.student.displayName +' activity' : 'General Activity Feed'}}</h2>
          <p>{{interface.student? interface.student.displayName +' activities from the system' : 'Here you can find the most recent events students are doing'}} </p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
      </div>
      <div class="ui-block-inner ui-block-inner-col">
        <div *ngIf="!interface.loading" class="ui-reports-activities">
          <ng-container *ngFor="let activity of interface.report.activity | sort: 'desc': 'createdAt'; let a=index">
            <div class="ui-reports-activity" *ngIf="activity.type == 'PrepTrackerStart' || activity.type == 'PrepTrackerEnd' || activity.type == 'InviteToSchoolLinkCopied'">
              <div class="ui-reports-activity-icon ui-reports-activity-icon-{{activity.type}}" [ngSwitch]="activity.type">
                <svg *ngSwitchCase="'WorksheetDownloaded'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
                </svg>
                <svg *ngSwitchCase="'WorksheetPrinted'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                </svg>
                <svg *ngSwitchCase="'BookOpened'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                </svg>
                <svg *ngSwitchCase="'PracticeTrackerStart'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                </svg>
                <svg *ngSwitchCase="'PracticeTrackerEnd'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                </svg>
                <svg *ngSwitchCase="'InviteToSchoolLinkCopied'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                </svg>
                <svg *ngSwitchCase="'OpenModalChangeLanguage'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                </svg>
                <svg *ngSwitchCase="'PrepTrackerStart'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                </svg>
                <svg *ngSwitchCase="'PrepTrackerEnd'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                </svg>
                <svg *ngSwitchCase="'BookPrinted'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                </svg>
                <svg *ngSwitchCase="'ClassCreated'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
                <svg *ngSwitchCase="'AssignmentCreated'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>

                <svg *ngSwitchDefault xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>

              </div>
              <div class="ui-reports-activity-data">
                <span>{{activity.createdAt | date: 'medium'}}</span>
                <p>
                  <b>{{activity.user?.displayName || 'SOMEWERE'}}</b>
                  <ng-container [ngSwitch]="activity.type">
                    <ng-container *ngSwitchCase="'WorksheetDownloaded'">Download worksheet</ng-container>
                    <ng-container *ngSwitchCase="'WorksheetPrinted'">Printed worksheet</ng-container>
                    <ng-container *ngSwitchCase="'BookOpened'">Open interactive book</ng-container>
                    <ng-container *ngSwitchCase="'PracticeTrackerStart'">
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Video'">Started watching video</ng-container>
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Question'">Started doing practice quiz</ng-container>
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Worksheet'">Opened worksheet</ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'PracticeTrackerEnd'">
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Video'">Complete watching video</ng-container>
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Question'">Complete practice quiz</ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'InviteToSchoolLinkCopied'">Copied invite link to his school</ng-container>
                    <ng-container *ngSwitchCase="'OpenModalChangeLanguage'">Opened changes language for system video explanation from dashboard</ng-container>
                    <ng-container *ngSwitchCase="'PrepTrackerStart'">Started doing the task from homework:</ng-container>
                    <ng-container *ngSwitchCase="'PrepTrackerEnd'">Complete doing the task from homework:</ng-container>
                    <ng-container *ngSwitchCase="'ClassCreated'">Created Class</ng-container>
                    <ng-container *ngSwitchCase="'AssignmentCreated'">Created Assignment</ng-container>
                    <ng-container *ngSwitchCase="'BookPrinted'">Printing {{activity.resource[0]?.pages_count}} pages from </ng-container>
                    <ng-container *ngSwitchDefault>{{activity.type}}</ng-container>
                  </ng-container>
                  <ng-container [ngSwitch]="activity.type">
                    <ng-container *ngSwitchCase="'WorksheetPrinted'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'WorksheetDownloaded'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'BookOpened'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'PracticeTrackerStart'"><strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'PracticeTrackerEnd'"><strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'PrepTrackerStart'"><strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'PrepTrackerEnd'"><strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'BookPrinted'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                  </ng-container>
                </p>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="interface.report.activity.length == 0">
            <ui-no-data text="No student activity"></ui-no-data>
          </ng-container>
        </div>
        <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px] mt-4"></ui-no-data>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #REPORTS_PRACTICE>
  <div class="ui-block ui-block-white ui-block-no-margin sm:col-span-12">
    <div class="ui-block-header">
      <div class="ui-block-header-title">
        <h2>{{interface.student? interface.student.displayName +' progress' : 'Users Activity & Progress'}}</h2>
        <p>{{interface._cache.grade_selected?.title || 'All grades'}}</p>
      </div>
      <div class="ui-block-header-action ui-block-header-action-hidden-mobile gap-2 relative">
        <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('day')" [class.ui-btn-pap]="interface.period == 'day'" tooltip description="Statistic by day">
          <span>Day</span>
        </button>
        <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('week')" [class.ui-btn-pap]="interface.period == 'week'" tooltip description="Statistic by week">
          <span>Week</span>
        </button>
        <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('month')" [class.ui-btn-pap]="interface.period == 'month'" tooltip description="Statistic by month">
          <span>Month</span>
        </button>
        <ui-date-picker [future]="true" [date]="interface._cache.period.start" class="ui-reports-period" [class.ui-reports-period-active]="interface.period == 'custom'" (selectedDate)="actionSelectPeriodDate($event)"></ui-date-picker>
      </div>
    </div>
    <div class="ui-block-inner ui-block-inner-col flex-grow">
      <div class="ui-reports-trackers">
        <div class="ui-reports-trackers-grades">
          <ng-container *ngFor="let grade of interface.grades | sort:'asc':'order'; let a=index">
            <div class="ui-reports-trackers-grade" [class.ui-reports-trackers-grade-active]="interface._cache.grade_selected?._id == grade._id" (click)="actionSelectGrade(grade)">
              <label>
                <h3>{{grade.title}}</h3>
                <small></small>
              </label>
              <p>Materials: <b>{{getGradeMaterialTotal(grade.category)}}</b></p>
            </div>
          </ng-container>
        </div>
        <hr class="mt-2">
        <div  class="flex gap-4">
          <div class="ui-reports-trackers-categories min-w-[236px]">
            <ng-container *ngFor="let category_main of interface._cache.grade_report_practice_selected; let a=index">
              <div class="ui-reports-category" [class.ui-reports-category-active]="interface._cache.category_selected?.id == category_main.id" (click)="actionSelectCategory(category_main)">
                <div class="ui-reports-category-header">
                  <div class="ui-reports-category-header-title">
                    <div class="ui-reports-category-header-title-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                      </svg>
                    </div>
                    <div class="ui-reports-category-header-title-text">
                      <h3>{{category_main.title}}</h3>
                      <p>Materials: {{category_main.totalMaterial}}</p>
                    </div>
                  </div>
                </div>
                <div class="ui-reports-category-progress" *ngIf="false">
                  <p>
                    <span>Progress: 0%</span>
                    <span></span>
                  </p>
                  <progress [max]="100" [value]="0"></progress>
                </div>
              </div>
            </ng-container>
            <ui-no-data *ngIf="!interface._cache.grade_report_practice_selected" type="loading" text="Loading data" class="h-[453px] flex-grow"></ui-no-data>
          </div>
          <div class="ui-reports-trackers-categories-data">
            <div *ngIf="interface._cache.grade_report_practice_selected" class="ui-reports-trackers-shorts py-4 ml-auto">
              <ng-container *ngIf="interface._cache.category_selected?.slug == 'practice_drills'">
                <div class="ui-reports-trackers-short">
                  <label>
                    <h3>{{interface._cache.category_selected.questionsAnswered}}q</h3>
                    <span>/</span>
                    <small>{{interface.period}}</small>
                  </label>
                  <p>Questions Answered</p>
                </div>
                <div class="ui-reports-trackers-short">
                  <label>
                    <h3>{{interface._cache.category_selected.questionsAnsweredCorrect}}q</h3>
                    <span>/</span>
                    <small>{{interface.period}}</small>
                  </label>
                  <p>Questions Answered Correct</p>
                </div>
                <div class="ui-reports-trackers-short">
                  <label>
                    <h3>{{interface._cache.category_selected.questionsAnsweredIncorrect}}q</h3>
                    <span>/</span>
                    <small>{{interface.period}}</small>
                  </label>
                  <p>Questions Answered Incorrect</p>
                </div>
                <div class="ui-reports-trackers-short">
                  <label>
                    <h3>{{interface._cache.category_selected.totalDurationSpent | timer}}</h3>
                    <span>/</span>
                    <small>{{interface.period}}</small>
                  </label>
                  <p>Total Duration Spent</p>
                </div>
              </ng-container>
              <ng-container *ngIf="interface._cache.category_selected?.slug == 'practice_questions'">
                <div class="ui-reports-trackers-short">
                  <label>
                    <h3>{{interface._cache.category_selected.questionsAnswered}}q</h3>
                    <span>/</span>
                    <small>{{interface.period}}</small>
                  </label>
                  <p>Questions Answered</p>
                </div>
                <div class="ui-reports-trackers-short">
                  <label>
                    <h3>{{interface._cache.category_selected.questionsAnsweredCorrect}}q</h3>
                    <span>/</span>
                    <small>{{interface.period}}</small>
                  </label>
                  <p>Questions Answered Correct</p>
                </div>
                <div class="ui-reports-trackers-short">
                  <label>
                    <h3>{{interface._cache.category_selected.questionsAnsweredIncorrect}}q</h3>
                    <span>/</span>
                    <small>{{interface.period}}</small>
                  </label>
                  <p>Questions Answered Incorrect</p>
                </div>
                <div class="ui-reports-trackers-short">
                  <label>
                    <h3>{{interface._cache.category_selected.totalDurationSpent | timer}}</h3>
                    <span>/</span>
                    <small>{{interface.period}}</small>
                  </label>
                  <p>Total Duration Spent</p>
                </div>
              </ng-container>
              <ng-container *ngIf="interface._cache.category_selected?.slug == 'video_lectures'">
                <div class="ui-reports-trackers-short">
                  <label>
                    <h3>{{interface._cache.category_selected.openedVideo}}v</h3>
                    <span>/</span>
                    <small>{{interface.period}}</small>
                  </label>
                  <p>Opened</p>
                </div>
                <div class="ui-reports-trackers-short">
                  <label>
                    <h3>{{interface._cache.category_selected.viewsVideo}}v</h3>
                    <span>/</span>
                    <small>{{interface.period}}</small>
                  </label>
                  <p>Views</p>
                </div>
                <div class="ui-reports-trackers-short">
                  <label>
                    <h3>{{interface._cache.category_selected.timeVideo | timer}}</h3>
                    <span>/</span>
                    <small>{{interface.period}}</small>
                  </label>
                  <p>Total time watched</p>
                </div>
              </ng-container>
              <ng-container *ngIf="interface._cache.category_selected?.slug == 'worksheets'">
                <div class="ui-reports-trackers-short">
                  <label>
                    <h3>{{interface._cache.category_selected.viewedWorksheets}}w</h3>
                    <span>/</span>
                    <small>{{interface.period}}</small>
                  </label>
                  <p>Viewed</p>
                </div>
                <div class="ui-reports-trackers-short">
                  <label>
                    <h3>{{interface._cache.category_selected.printedDownloadedWorksheets}}w</h3>
                    <span>/</span>
                    <small>{{interface.period}}</small>
                  </label>
                  <p>Printed/Downloaded</p>
                </div>
              </ng-container>
            </div>
            <ng-container *ngFor="let category of interface._cache.category_selected?.children | sort:'desc':'title'; let a=index">
              <div class="ui-block ui-block-pap ui-block-no-margin">
                <div class="ui-block-header">
                  <div class="ui-block-header-title">
                    <h2>{{category.title}}</h2>
                    <div class="ui-reports-trackers-shorts py-4 ml-auto">
                      <ng-container *ngIf="interface._cache.category_selected?.slug == 'practice_drills'">
                        <div class="ui-reports-trackers-short">
                          <label>
                            <h4>{{category.questionsAnswered}}q</h4>
                            <span>/</span>
                            <small>{{interface.period}}</small>
                          </label>
                          <p>Questions Answered</p>
                        </div>
                        <div class="ui-reports-trackers-short">
                          <label>
                            <h4>{{category.questionsAnsweredCorrect}}q</h4>
                            <span>/</span>
                            <small>{{interface.period}}</small>
                          </label>
                          <p>Questions Answered Correct</p>
                        </div>
                        <div class="ui-reports-trackers-short">
                          <label>
                            <h4>{{category.questionsAnsweredIncorrect}}q</h4>
                            <span>/</span>
                            <small>{{interface.period}}</small>
                          </label>
                          <p>Questions Answered Incorrect</p>
                        </div>
                        <div class="ui-reports-trackers-short">
                          <label>
                            <h4>{{category.totalDurationSpent | timer}}</h4>
                            <span>/</span>
                            <small>{{interface.period}}</small>
                          </label>
                          <p>Total Duration Spent</p>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="interface._cache.category_selected?.slug == 'practice_questions'">
                        <div class="ui-reports-trackers-short">
                          <label>
                            <h4>{{category.questionsAnswered}}q</h4>
                            <span>/</span>
                            <small>{{interface.period}}</small>
                          </label>
                          <p>Questions Answered</p>
                        </div>
                        <div class="ui-reports-trackers-short">
                          <label>
                            <h4>{{category.questionsAnsweredCorrect}}q</h4>
                            <span>/</span>
                            <small>{{interface.period}}</small>
                          </label>
                          <p>Questions Answered Correct</p>
                        </div>
                        <div class="ui-reports-trackers-short">
                          <label>
                            <h4>{{category.questionsAnsweredIncorrect}}q</h4>
                            <span>/</span>
                            <small>{{interface.period}}</small>
                          </label>
                          <p>Questions Answered Incorrect</p>
                        </div>
                        <div class="ui-reports-trackers-short">
                          <label>
                            <h4>{{category.totalDurationSpent | timer}}</h4>
                            <span>/</span>
                            <small>{{interface.period}}</small>
                          </label>
                          <p>Total Duration Spent</p>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="interface._cache.category_selected?.slug == 'video_lectures'">
                        <div class="ui-reports-trackers-short">
                          <label>
                            <h4>{{category.openedVideo}}v</h4>
                            <span>/</span>
                            <small>{{interface.period}}</small>
                          </label>
                          <p>Opened</p>
                        </div>
                        <div class="ui-reports-trackers-short">
                          <label>
                            <h4>{{category.viewsVideo}}v</h4>
                            <span>/</span>
                            <small>{{interface.period}}</small>
                          </label>
                          <p>Views</p>
                        </div>
                        <div class="ui-reports-trackers-short">
                          <label>
                            <h4>{{category.timeVideo | timer}}</h4>
                            <span>/</span>
                            <small>{{interface.period}}</small>
                          </label>
                          <p>Total time watched</p>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="interface._cache.category_selected?.slug == 'worksheets'">
                        <div class="ui-reports-trackers-short">
                          <label>
                            <h4>{{category.viewedWorksheets}}w</h4>
                            <span>/</span>
                            <small>{{interface.period}}</small>
                          </label>
                          <p>Viewed</p>
                        </div>
                        <div class="ui-reports-trackers-short">
                          <label>
                            <h4>{{category.printedDownloadedWorksheets}}w</h4>
                            <span>/</span>
                            <small>{{interface.period}}</small>
                          </label>
                          <p>Printed/Downloaded</p>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="ui-block-inner ui-block-inner-col ui-reports-subcategory">
                  <div class="ui-reports-subcategory-children">
                    <ng-container *ngFor="let child of category.children | sort:'asc':'order'; let a=index">
                      <div class="ui-reports-subcategory-child">
                        <div class="ui-reports-subcategory-child-header">
                          <div class="ui-reports-subcategory-child-header-title">
                            <div class="ui-reports-subcategory-child-header-title-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                              </svg>
                            </div>
                            <div class="ui-reports-subcategory-child-header-title-text">
                              <h3>{{child.title}}</h3>
                              <p>Materials: {{child.totalMaterial}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="ui-reports-subcategory-child-data">
                          <div class="ui-reports-total mt-2 !h-auto">
                            <div class="ui-reports-total-data">
                              <ng-container *ngIf="interface._cache.category_selected?.slug == 'practice_drills'">
                                <div class="ui-reports-total-data-item">
                                  <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                    </svg>
                                  </div>
                                  <div class="ui-reports-total-data-item-name">Questions Answered</div>
                                  <div class="ui-reports-total-data-item-count">{{child.questionsAnswered}}</div>
                                </div>
                                <div class="ui-reports-total-data-item">
                                  <div class="ui-reports-total-data-item-icon bg-emerald-50 text-emerald-800">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                    </svg>
                                  </div>
                                  <div class="ui-reports-total-data-item-name">Questions Answered Correct</div>
                                  <div class="ui-reports-total-data-item-count">{{child.questionsAnsweredCorrect}}</div>
                                </div>
                                <div class="ui-reports-total-data-item">
                                  <div class="ui-reports-total-data-item-icon bg-rose-100 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                    </svg>
                                  </div>
                                  <div class="ui-reports-total-data-item-name">Questions Answered Incorrect</div>
                                  <div class="ui-reports-total-data-item-count">{{child.questionsAnsweredIncorrect}}</div>
                                </div>
                                <div class="ui-reports-total-data-item">
                                  <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>

                                  </div>
                                  <div class="ui-reports-total-data-item-name">Total Duration Spent</div>
                                  <div class="ui-reports-total-data-item-count">{{child.totalDurationSpent | timer}}</div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="interface._cache.category_selected?.slug == 'practice_questions'">
                                <div class="ui-reports-total-data-item">
                                  <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                    </svg>
                                  </div>
                                  <div class="ui-reports-total-data-item-name">Questions Answered</div>
                                  <div class="ui-reports-total-data-item-count">{{child.questionsAnswered}}</div>
                                </div>
                                <div class="ui-reports-total-data-item">
                                  <div class="ui-reports-total-data-item-icon bg-emerald-50 text-emerald-800">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                    </svg>
                                  </div>
                                  <div class="ui-reports-total-data-item-name">Questions Answered Correct</div>
                                  <div class="ui-reports-total-data-item-count">{{child.questionsAnsweredCorrect}}</div>
                                </div>
                                <div class="ui-reports-total-data-item">
                                  <div class="ui-reports-total-data-item-icon bg-rose-100 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                    </svg>
                                  </div>
                                  <div class="ui-reports-total-data-item-name">Questions Answered Incorrect</div>
                                  <div class="ui-reports-total-data-item-count">{{child.questionsAnsweredIncorrect}}</div>
                                </div>
                                <div class="ui-reports-total-data-item">
                                  <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>

                                  </div>
                                  <div class="ui-reports-total-data-item-name">Total Duration Spent</div>
                                  <div class="ui-reports-total-data-item-count">{{child.totalDurationSpent | timer}}</div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="interface._cache.category_selected?.slug == 'video_lectures'">
                                <div class="ui-reports-total-data-item">
                                  <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                                    </svg>
                                  </div>
                                  <div class="ui-reports-total-data-item-name">Opened</div>
                                  <div class="ui-reports-total-data-item-count">{{child.openedVideo}}</div>
                                </div>
                                <div class="ui-reports-total-data-item">
                                  <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>

                                  </div>
                                  <div class="ui-reports-total-data-item-name">Views</div>
                                  <div class="ui-reports-total-data-item-count">{{child.viewsVideo}}</div>
                                </div>
                                <div class="ui-reports-total-data-item">
                                  <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>

                                  </div>
                                  <div class="ui-reports-total-data-item-name">Total time watched</div>
                                  <div class="ui-reports-total-data-item-count">{{child.timeVideo | timer}}</div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="interface._cache.category_selected?.slug == 'worksheets'">
                                <div class="ui-reports-total-data-item">
                                  <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                  </div>
                                  <div class="ui-reports-total-data-item-name">Views</div>
                                  <div class="ui-reports-total-data-item-count">{{child.viewedWorksheets}}</div>
                                </div>
                                <div class="ui-reports-total-data-item">
                                  <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                                    </svg>
                                  </div>
                                  <div class="ui-reports-total-data-item-name">Printed/Downloaded</div>
                                  <div class="ui-reports-total-data-item-count">{{child.printedDownloadedWorksheets}}</div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="ui-block-footer"></div>
              </div>
            </ng-container>
            <ui-no-data *ngIf="!interface._cache.grade_report_practice_selected" type="loading" text="Loading data" class="h-[453px] flex-grow"></ui-no-data>
          </div>
        </div>
      </div>
    </div>
    <div class="ui-block-footer">
      <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
    </div>
  </div>
</ng-template>
<ng-template #REPORTS_STUDENTS>
  <div class="ui-reports-content">
    <div class="ui-reports-students">
      <button class="ui-btn ui-btn-sm" (click)="actionSelectStudent(null)" [class.ui-btn-pap]="interface.student == null" tooltip description="Statistics from all users">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
        </svg>
        <span>All Users</span>
      </button>
      <ng-container *ngFor="let student of interface.students; let a=index">
        <div class="ui-reports-student" [class.ui-reports-student-active]="student._id == interface.student?._id">
          <img  [src]="student.avatar" alt="{{student.displayName}}" tooltip description="Statistic from {{student.displayName}}" (click)="actionSelectStudent(student)"/>
        </div>
      </ng-container>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin row-span-6 sm:col-span-9">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>{{interface.student? interface.student.displayName +' progress' : 'Practice Users Activity & Progress'}}</h2>
          <p>{{interface._cache.grade_selected?.title || 'All grades'}}</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile gap-2 relative">
          <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('day')" [class.ui-btn-pap]="interface.period == 'day'" tooltip description="Statistic by day">
            <span>Day</span>
          </button>
          <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('week')" [class.ui-btn-pap]="interface.period == 'week'" tooltip description="Statistic by week">
            <span>Week</span>
          </button>
          <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('month')" [class.ui-btn-pap]="interface.period == 'month'" tooltip description="Statistic by month">
            <span>Month</span>
          </button>
          <ui-date-picker [future]="true" [date]="interface._cache.period.start" class="ui-reports-period" [class.ui-reports-period-active]="interface.period == 'custom'" (selectedDate)="actionSelectPeriodDate($event)"></ui-date-picker>
        </div>
      </div>
      <div class="ui-block-inner ui-block-inner-col flex-grow">
        <div class="ui-reports-trackers">
          <div class="ui-reports-trackers-grades">
            <ng-container *ngFor="let grade of interface.grades | sort:'asc':'order'; let a=index">
              <div class="ui-reports-trackers-grade" [class.ui-reports-trackers-grade-active]="interface._cache.grade_selected?._id == grade._id" (click)="actionSelectGrade(grade)">
                <label>
                  <h3 class="!text-base">{{grade.title}}</h3>
                  <small></small>
                </label>
                <p class="!text-xs">Materials: <b>{{getGradeMaterialTotal(grade.category)}}</b></p>
              </div>
            </ng-container>
          </div>
          <hr class="mt-2">
          <div  class="flex gap-4">
            <div class="ui-reports-trackers-categories min-w-[236px]">
              <ng-container *ngFor="let category_main of interface._cache.grade_report_practice_selected; let a=index">
                <div class="ui-reports-category" [class.ui-reports-category-active]="interface._cache.category_selected?.id == category_main.id" (click)="actionSelectCategory(category_main)">
                  <div class="ui-reports-category-header">
                    <div class="ui-reports-category-header-title">
                      <div class="ui-reports-category-header-title-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                        </svg>
                      </div>
                      <div class="ui-reports-category-header-title-text">
                        <h3>{{category_main.title}}</h3>
                        <p>Materials: {{category_main.totalMaterial}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="ui-reports-category-progress" *ngIf="false">
                    <p>
                      <span>Progress: 0%</span>
                      <span></span>
                    </p>
                    <progress [max]="100" [value]="0"></progress>
                  </div>
                </div>
              </ng-container>
              <ui-no-data *ngIf="!interface._cache.grade_report_practice_selected" type="loading" text="Loading data" class="h-[453px] flex-grow"></ui-no-data>
            </div>
            <div class="ui-reports-trackers-categories-data">
              <div *ngIf="interface._cache.grade_report_practice_selected" class="ui-reports-trackers-shorts py-4 ml-auto">
                <ng-container *ngIf="interface._cache.category_selected?.slug == 'practice_drills'">
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface._cache.category_selected.questionsAnswered}}q</h3>
                      <span>/</span>
                      <small>{{interface.period}}</small>
                    </label>
                    <p>Questions Answered</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface._cache.category_selected.questionsAnsweredCorrect}}q</h3>
                      <span>/</span>
                      <small>{{interface.period}}</small>
                    </label>
                    <p>Questions Answered Correct</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface._cache.category_selected.questionsAnsweredIncorrect}}q</h3>
                      <span>/</span>
                      <small>{{interface.period}}</small>
                    </label>
                    <p>Questions Answered Incorrect</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface._cache.category_selected.totalDurationSpent | timer}}</h3>
                      <span>/</span>
                      <small>{{interface.period}}</small>
                    </label>
                    <p>Total Duration Spent</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="interface._cache.category_selected?.slug == 'practice_questions'">
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface._cache.category_selected.questionsAnswered}}q</h3>
                      <span>/</span>
                      <small>{{interface.period}}</small>
                    </label>
                    <p>Questions Answered</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface._cache.category_selected.questionsAnsweredCorrect}}q</h3>
                      <span>/</span>
                      <small>{{interface.period}}</small>
                    </label>
                    <p>Questions Answered Correct</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface._cache.category_selected.questionsAnsweredIncorrect}}q</h3>
                      <span>/</span>
                      <small>{{interface.period}}</small>
                    </label>
                    <p>Questions Answered Incorrect</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface._cache.category_selected.totalDurationSpent | timer}}</h3>
                      <span>/</span>
                      <small>{{interface.period}}</small>
                    </label>
                    <p>Total Duration Spent</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="interface._cache.category_selected?.slug == 'video_lectures'">
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface._cache.category_selected.openedVideo}}v</h3>
                      <span>/</span>
                      <small>{{interface.period}}</small>
                    </label>
                    <p>Opened</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface._cache.category_selected.viewsVideo}}v</h3>
                      <span>/</span>
                      <small>{{interface.period}}</small>
                    </label>
                    <p>Views</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface._cache.category_selected.timeVideo | timer}}</h3>
                      <span>/</span>
                      <small>{{interface.period}}</small>
                    </label>
                    <p>Total time watched</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="interface._cache.category_selected?.slug == 'worksheets'">
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface._cache.category_selected.viewedWorksheets}}w</h3>
                      <span>/</span>
                      <small>{{interface.period}}</small>
                    </label>
                    <p>Viewed</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface._cache.category_selected.printedDownloadedWorksheets}}w</h3>
                      <span>/</span>
                      <small>{{interface.period}}</small>
                    </label>
                    <p>Printed/Downloaded</p>
                  </div>
                </ng-container>
              </div>
              <ng-container *ngFor="let category of interface._cache.category_selected?.children | sort:'desc':'title'; let a=index">
                <div class="ui-block ui-block-pap ui-block-no-margin">
                  <div class="ui-block-header">
                    <div class="ui-block-header-title">
                      <h2>{{category.title}}</h2>
                      <div class="ui-reports-trackers-shorts py-4 ml-auto">
                        <ng-container *ngIf="interface._cache.category_selected?.slug == 'practice_drills'">
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.questionsAnswered}}q</h4>
                              <span>/</span>
                              <small>{{interface.period}}</small>
                            </label>
                            <p>Questions Answered</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.questionsAnsweredCorrect}}q</h4>
                              <span>/</span>
                              <small>{{interface.period}}</small>
                            </label>
                            <p>Questions Answered Correct</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.questionsAnsweredIncorrect}}q</h4>
                              <span>/</span>
                              <small>{{interface.period}}</small>
                            </label>
                            <p>Questions Answered Incorrect</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.totalDurationSpent | timer}}</h4>
                              <span>/</span>
                              <small>{{interface.period}}</small>
                            </label>
                            <p>Total Duration Spent</p>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="interface._cache.category_selected?.slug == 'practice_questions'">
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.questionsAnswered}}q</h4>
                              <span>/</span>
                              <small>{{interface.period}}</small>
                            </label>
                            <p>Questions Answered</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.questionsAnsweredCorrect}}q</h4>
                              <span>/</span>
                              <small>{{interface.period}}</small>
                            </label>
                            <p>Questions Answered Correct</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.questionsAnsweredIncorrect}}q</h4>
                              <span>/</span>
                              <small>{{interface.period}}</small>
                            </label>
                            <p>Questions Answered Incorrect</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.totalDurationSpent | timer}}</h4>
                              <span>/</span>
                              <small>{{interface.period}}</small>
                            </label>
                            <p>Total Duration Spent</p>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="interface._cache.category_selected?.slug == 'video_lectures'">
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.openedVideo}}v</h4>
                              <span>/</span>
                              <small>{{interface.period}}</small>
                            </label>
                            <p>Opened</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.viewsVideo}}v</h4>
                              <span>/</span>
                              <small>{{interface.period}}</small>
                            </label>
                            <p>Views</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.timeVideo | timer}}</h4>
                              <span>/</span>
                              <small>{{interface.period}}</small>
                            </label>
                            <p>Total time watched</p>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="interface._cache.category_selected?.slug == 'worksheets'">
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.viewedWorksheets}}w</h4>
                              <span>/</span>
                              <small>{{interface.period}}</small>
                            </label>
                            <p>Viewed</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.printedDownloadedWorksheets}}w</h4>
                              <span>/</span>
                              <small>{{interface.period}}</small>
                            </label>
                            <p>Printed/Downloaded</p>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="ui-block-inner ui-block-inner-col ui-reports-subcategory">
                    <div class="ui-reports-subcategory-children">
                      <ng-container *ngFor="let child of category.children | sort:'asc':'order'; let a=index">
                        <div class="ui-reports-subcategory-child">
                          <div class="ui-reports-subcategory-child-header">
                            <div class="ui-reports-subcategory-child-header-title">
                              <div class="ui-reports-subcategory-child-header-title-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                              </div>
                              <div class="ui-reports-subcategory-child-header-title-text">
                                <h3>{{child.title}}</h3>
                                <p>Materials: {{child.totalMaterial}}</p>
                              </div>
                            </div>
                          </div>
                          <div class="ui-reports-subcategory-child-data">
                            <div class="ui-reports-total mt-2 !h-auto">
                              <div class="ui-reports-total-data">
                                <ng-container *ngIf="interface._cache.category_selected?.slug == 'practice_drills'">
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Questions Answered</div>
                                    <div class="ui-reports-total-data-item-count">{{child.questionsAnswered}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-emerald-50 text-emerald-800">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Questions Answered Correct</div>
                                    <div class="ui-reports-total-data-item-count">{{child.questionsAnsweredCorrect}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-rose-100 text-rose-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Questions Answered Incorrect</div>
                                    <div class="ui-reports-total-data-item-count">{{child.questionsAnsweredIncorrect}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>

                                    </div>
                                    <div class="ui-reports-total-data-item-name">Total Duration Spent</div>
                                    <div class="ui-reports-total-data-item-count">{{child.totalDurationSpent | timer}}</div>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="interface._cache.category_selected?.slug == 'practice_questions'">
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Questions Answered</div>
                                    <div class="ui-reports-total-data-item-count">{{child.questionsAnswered}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-emerald-50 text-emerald-800">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Questions Answered Correct</div>
                                    <div class="ui-reports-total-data-item-count">{{child.questionsAnsweredCorrect}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-rose-100 text-rose-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Questions Answered Incorrect</div>
                                    <div class="ui-reports-total-data-item-count">{{child.questionsAnsweredIncorrect}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>

                                    </div>
                                    <div class="ui-reports-total-data-item-name">Total Duration Spent</div>
                                    <div class="ui-reports-total-data-item-count">{{child.totalDurationSpent | timer}}</div>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="interface._cache.category_selected?.slug == 'video_lectures'">
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Opened</div>
                                    <div class="ui-reports-total-data-item-count">{{child.openedVideo}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                      </svg>

                                    </div>
                                    <div class="ui-reports-total-data-item-name">Views</div>
                                    <div class="ui-reports-total-data-item-count">{{child.viewsVideo}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>

                                    </div>
                                    <div class="ui-reports-total-data-item-name">Total time watched</div>
                                    <div class="ui-reports-total-data-item-count">{{child.timeVideo | timer}}</div>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="interface._cache.category_selected?.slug == 'worksheets'">
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Views</div>
                                    <div class="ui-reports-total-data-item-count">{{child.viewedWorksheets}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Printed/Downloaded</div>
                                    <div class="ui-reports-total-data-item-count">{{child.printedDownloadedWorksheets}}</div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="ui-block-footer"></div>
                </div>
              </ng-container>
              <ui-no-data *ngIf="!interface._cache.grade_report_practice_selected" type="loading" text="Loading data" class="h-[453px] flex-grow"></ui-no-data>
            </div>
          </div>
        </div>
      </div>
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>{{interface.student? interface.student.displayName +' progress' : 'Classes User Activity & Progress'}}</h2>
          <p>{{interface.class?.title || 'All classes'}}</p>
        </div>
      </div>
      <div class="ui-block-inner ui-block-inner-col flex-grow">
        <div *ngIf="!interface.loading" class="ui-reports-trackers">
          <div class="ui-reports-trackers-shorts">
            <div class="ui-reports-trackers-short">
              <label>
                <h3>{{interface.report.short.quiz}}q</h3>
                <span>/</span>
                <small>{{interface.period}}</small>
              </label>
              <p>Passed Quizzes</p>
            </div>
            <div class="ui-reports-trackers-short">
              <label>
                <h3>{{interface.report.short.video}}v</h3>
                <span>/</span>
                <small>{{interface.period}}</small>
              </label>
              <p>Viewed videos</p>
            </div>
            <div class="ui-reports-trackers-short">
              <label>
                <h3>{{interface.report.short.worksheet}}w</h3>
                <span>/</span>
                <small>{{interface.period}}</small>
              </label>
              <p>Passed Worksheets</p>
            </div>
            <div class="ui-reports-trackers-short">
              <label>
                <h3>{{interface.report.short.book_pages}}p</h3>
                <span>/</span>
                <small>{{interface.period}}</small>
              </label>
              <p>Read book pages</p>
            </div>
          </div>
          <div class="ui-reports-trackers-data">
            <apx-chart
              [series]="interface.report.trackers.series"
              [chart]="interface.report.trackers.chart"
              [dataLabels]="interface.report.trackers.dataLabels"
              [plotOptions]="interface.report.trackers.plotOptions"
              [yaxis]="interface.report.trackers.yaxis"
              [legend]="interface.report.trackers.legend"
              [fill]="interface.report.trackers.fill"
              [stroke]="interface.report.trackers.stroke"
              [tooltip]="interface.report.trackers.tooltip"
              [xaxis]="interface.report.trackers.xaxis"
              [colors]="interface.report.trackers.colors"
            ></apx-chart>
          </div>
        </div>
        <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="mt-4 h-[453px] flex-grow"></ui-no-data>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
      </div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin row-span-1 col-span-12 xl:col-span-3">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>{{interface.student? interface.student.displayName +' activity' : 'General Activity Feed'}}</h2>
          <p>{{interface.student? interface.student.displayName +' activities from the system' : 'Here you can find the most recent events are doing'}} </p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
      </div>
      <div class="ui-block-inner ui-block-inner-col">
        <div *ngIf="!interface.loading" class="ui-reports-activities">
          <ng-container *ngFor="let activity of interface.report.activity | sort: 'desc': 'createdAt'; let a=index">
            <div class="ui-reports-activity">
              <div class="ui-reports-activity-icon ui-reports-activity-icon-{{activity.type}}" [ngSwitch]="activity.type">
                <svg *ngSwitchCase="'WorksheetDownloaded'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
                </svg>
                <svg *ngSwitchCase="'WorksheetPrinted'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                </svg>
                <svg *ngSwitchCase="'BookOpened'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                </svg>
                <svg *ngSwitchCase="'PracticeTrackerStart'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                </svg>
                <svg *ngSwitchCase="'PracticeTrackerEnd'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                </svg>
                <svg *ngSwitchCase="'InviteToSchoolLinkCopied'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                </svg>
                <svg *ngSwitchCase="'OpenModalChangeLanguage'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                </svg>
                <svg *ngSwitchCase="'PrepTrackerStart'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                </svg>
                <svg *ngSwitchCase="'PrepTrackerEnd'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                </svg>
                <svg *ngSwitchCase="'BookPrinted'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                </svg>
                <svg *ngSwitchCase="'ClassCreated'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
                <svg *ngSwitchCase="'AssignmentCreated'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>

                <svg *ngSwitchDefault xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>

              </div>
              <div class="ui-reports-activity-data">
                <span>{{activity.createdAt | date: 'medium'}}</span>
                <p>
                  <b>{{activity.user?.displayName || 'SOMEWERE'}}</b>
                  <ng-container [ngSwitch]="activity.type">
                    <ng-container *ngSwitchCase="'WorksheetDownloaded'">Download worksheet</ng-container>
                    <ng-container *ngSwitchCase="'WorksheetPrinted'">Printed worksheet</ng-container>
                    <ng-container *ngSwitchCase="'BookOpened'">Open interactive book</ng-container>
                    <ng-container *ngSwitchCase="'PracticeTrackerStart'">
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Video'">Started watching video</ng-container>
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Question'">Started doing practice quiz</ng-container>
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Worksheet'">Opened worksheet</ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'PracticeTrackerEnd'">
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Video'">Complete watching video</ng-container>
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Question'">Complete practice quiz</ng-container>
                      </ng-container>
                    <ng-container *ngSwitchCase="'InviteToSchoolLinkCopied'">Copied invite link to his school</ng-container>
                    <ng-container *ngSwitchCase="'OpenModalChangeLanguage'">Opened changes language for system video explanation from dashboard</ng-container>
                    <ng-container *ngSwitchCase="'PrepTrackerStart'">Started doing the task from homework:</ng-container>
                    <ng-container *ngSwitchCase="'PrepTrackerEnd'">Complete doing the task from homework:</ng-container>
                    <ng-container *ngSwitchCase="'ClassCreated'">Created Class</ng-container>
                    <ng-container *ngSwitchCase="'AssignmentCreated'">Created Assignment</ng-container>
                    <ng-container *ngSwitchCase="'BookPrinted'">Printing {{activity.resource[0]?.pages_count}} pages from </ng-container>
                    <ng-container *ngSwitchDefault>{{activity.type}}</ng-container>
                  </ng-container>
                  <ng-container [ngSwitch]="activity.type">
                    <ng-container *ngSwitchCase="'WorksheetPrinted'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'WorksheetDownloaded'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'BookOpened'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'PracticeTrackerStart'"><strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'PracticeTrackerEnd'"><strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'PrepTrackerStart'"><strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'PrepTrackerEnd'"><strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'BookPrinted'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                  </ng-container>
                </p>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="interface.report.activity.length == 0">
            <ui-no-data text="No student activity"></ui-no-data>
          </ng-container>
        </div>
        <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px] mt-4"></ui-no-data>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
      </div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin row-span-1 col-span-12 xl:col-span-3">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>Assignment Activity Feed</h2>
          <p>{{interface.student? interface.student.displayName +' do the following assignment materials' : 'Activity who have started but not completed an assignment'}}</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
      </div>
      <div class="ui-block-inner ui-block-inner-col">
        <div *ngIf="!interface.loading" class="ui-reports-goings">
          <ng-container *ngFor="let going of interface.report.goings | sort: 'desc': 'createdAt'; let a=index">
            <div class="ui-reports-going">
              <div class="ui-reports-going-icon" [ngSwitch]="going.typeMaterial">
                <svg *ngSwitchCase="'Question'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                </svg>
                <svg *ngSwitchCase="'Worksheet'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
                <svg *ngSwitchCase="'Video'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                </svg>
                <svg *ngSwitchCase="'Book'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                </svg>
              </div>
              <div class="ui-reports-going-data">
                <p><b>{{going.user?.displayName}}</b>
                  <ng-container [ngSwitch]="going.typeMaterial">
                    <ng-container *ngSwitchCase="'Question'">Started homework quiz from <b>{{going.assignment.title}}</b> assignment but did not answers for all questions</ng-container>
                    <ng-container *ngSwitchCase="'Worksheet'">Opened homework worksheet from <b>{{going.assignment.title}}</b> assignment but did not submit his work</ng-container>
                    <ng-container *ngSwitchCase="'Video'">Started watching homework video from <b>{{going.assignment.title}}</b> assignment but didn't finish watching</ng-container>
                    <ng-container *ngSwitchCase="'Book'">Opened homework books pages from <b>{{going.assignment.title}}</b> assignment but did not submit his work</ng-container>
                  </ng-container>
                </p>
                <span>{{going.createdAt | date: 'medium'}}</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="interface.report.goings.length == 0">
            <ui-no-data text="No assignments"></ui-no-data>
          </ng-container>
        </div>
        <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px] mt-4"></ui-no-data>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
      </div>
    </div>
  </div>
</ng-template>

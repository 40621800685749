<div class="ui-auth-container" [class.ui-auth-container-extend]="interface.clever">
  <div class="ui-auth-brand">
    <img src="assets/images/logo_small.svg" alt="{{'alts.logo' | transloco}}">
  </div>
  <div class="ui-auth-info">
    <h1>Authorization</h1>
    <span>If you do not have an account, <a routerLink="/register">click here to Register</a></span>
  </div>
  <div class="ui-auth-data">
      <div class="ui-auth-form ui-form">
        <ng-container *ngIf="interface.clever">
          <div class="ui-form-iframe">
            <iframe width="100%" height="670px" class="min-h-[670px] h-[670px] rounded-lg overflow-hidden" [src]="interface._cache.clever_auth_url | safe: 'resourceUrl'"></iframe>
            <div class="ui-form-iframe-overlay" [class.!hidden]="!interface._cache.clever_error">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-16 h-16 text-rose-500">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                </svg>
                <p class="text-rose-500 max-w-[70%] text-center">This account not registered, please register first and authorize again.</p>
            </div>
          </div>
          <button class="ui-btn ui-btn-lg ui-btn-pap justify-center" (click)="actionToggleClever()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Back to sign in form
          </button>
        </ng-container>
        <ng-container *ngIf="!interface.clever">
          <div class="ui-form-input-group">
            <div class="ui-form-input" [class.ui-form-input-error]="interface._cache.error_fields.includes('email')" [class.ui-form-input-readonly]="interface.submit">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" class="h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
              </svg>
              <input name="login" type="text" placeholder="Enter your email"  [value]="interface.user.email" (keyup)="actionEnterLogin($event)" [readonly]="interface.submit">
            </div>
          </div>
          <div class="ui-form-input-group">
            <div class="ui-form-input" [class.ui-form-input-error]="interface._cache.error_fields.includes('password')" [class.ui-form-input-readonly]="interface.submit">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
              </svg>
              <input name="password" type="password" placeholder="Enter your password" [value]="interface.user.password" (keyup)="actionEnterPassword($event)" #password [readonly]="interface.submit">
              <svg *ngIf="!interface._cache.show_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-auto" tooltip description="Show password" (click)="actionToggleShowPassword()">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <svg *ngIf="interface._cache.show_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-auto" tooltip description="Hide password" (click)="actionToggleShowPassword()">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
              </svg>
            </div>
          </div>
          <div class="ui-form-text ui-form-text-xs">
            <span class="ui-form-link ml-auto" [routerLink]="'/forgot'">{{'buttons.forgot' | transloco}}</span>
          </div>
          <button class="ui-btn ui-btn-lg ui-btn-pap justify-center" [disabled]="!actionGrantLogin() || interface.submit" (click)="actionLogin()">
            <svg *ngIf="interface.submit" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-spin" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <svg *ngIf="!interface.submit" xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='1.5'>
              <path stroke-linecap='round' stroke-linejoin='round' d='M5 13l4 4L19 7' />
            </svg>
            {{'buttons.confirm_login' | transloco}}
          </button>
          <div class="ui-form-divider-extend">
            <div class="ui-form-divider-extend-line" aria-hidden="true">
              <span></span>
            </div>
            <div class="ui-form-divider-extend-text ">
              <span>Or continue with</span>
            </div>
            <div class="ui-form-divider-extend-line" aria-hidden="true">
              <span></span>
            </div>
          </div>
          <button class="ui-btn ui-btn-lg ui-btn-white justify-center" (click)="actionToggleClever()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24">
              <rect width="24" height="24" rx="4" fill="#425AF6"/>
              <path d="M12.9614 20C8.39303 20 5 16.486 5 12.0442V11.9999C5 7.60207 8.32658 4 13.0946 4C16.0219 4 17.7738 4.97241 19.2152 6.38668L17.0419 8.88402C15.8444 7.80118 14.6247 7.13821 13.0724 7.13821C10.4555 7.13821 8.57042 9.30389 8.57042 11.9557V11.9999C8.57042 14.6518 10.4111 16.8618 13.0724 16.8618C14.8465 16.8618 15.9331 16.1547 17.1529 15.0498L19.3262 17.2374C17.7294 18.939 15.9553 20 12.9614 20Z" fill="white"/>
            </svg>
            Login with Clever
          </button>
        </ng-container>
      </div>
    </div>
</div>
<div class="ui-auth-container-footer">
  <div class="ui-auth-footer-menu">
    <a href="https://argoprep.com/privacy-policy">{{'menu.privacy-policy' | transloco}}</a>
    <a href="https://argoprep.com/cookie-policy">{{'menu.cookie-policy' | transloco}}</a>
  </div>
  <div class="ui-auth-copyright">{{'copyright.text' | transloco}} © 2022 <a href="https://argoprep.com" target="_blank">ArgoPrep | Argo Brothers Inc</a>. {{'copyright.rights' | transloco}}.</div>
</div>


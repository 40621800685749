<div class="ui-homework-done">
  <img src="assets/images/objects/cup.gif" alt="">
  <div class="ui-homework-done-text">You have successfully completed the task.</div>
  <div class="ui-homework-done-text">Keep up the great work!</div>
  <div class="ui-homework-done-actions">
    <button class="ui-homework-done-action-no" (click)="actionClose()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
      </svg>
      <span>Go back</span>
    </button>
    <button class="ui-homework-done-action-yes" (click)="actionOpenHomework()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
      <span>Confirm</span>
    </button>
  </div>
</div>

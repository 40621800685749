import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiProfileEditComponent} from "./ui-profile-edit.component";
import {AppTranslocoModule} from "../../app.transloco.module";
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";
import {UiAppNoDataModule} from "../ui-app-no-data/ui-app-no-data.module";


@NgModule({
  declarations: [UiProfileEditComponent],
  exports: [UiProfileEditComponent],
  imports: [
    CommonModule,
    AppTranslocoModule,
    UiTooltipModule,
    UiAppNoDataModule
  ]
})
export class UiProfileEditModule { }

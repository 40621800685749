import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

import {PracticeService} from "../_service/practice.service";
import {map} from "rxjs/operators";
import {Observable, of, take} from "rxjs";
import {Store} from "@ngxs/store";
import {FillPracticeVideos, LoadVideos} from "../_actions/practice.actions";

@Injectable({
  providedIn: 'root'
})
export class VideosResolver implements Resolve<boolean> {
  constructor(private practiceService: PracticeService, private store: Store) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const id = route.paramMap.get("id");
    const homeworks = route.url.find(x => x.toString() == 'homeworks' )
    if (homeworks){
        return this.practiceService.getVideoById(id).pipe(take(1), map((video)=>{
        this.store.dispatch(new FillPracticeVideos([video]))
        return of (video)
      }));

    } else {
      this.store.dispatch(new LoadVideos(id));
      return of (true);
    }

  }
}

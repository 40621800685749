import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPageWorksheetComponent} from "./ui-page-worksheet.component";
import {RouterModule} from "@angular/router";
import {UiWorksheetModule} from "../ui-worksheet/ui-worksheet.module";
import {UiPageWorksheetResolver} from "./ui-page-worksheet.resolver";


@NgModule({
  declarations: [UiPageWorksheetComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        resolve:{worksheet:UiPageWorksheetResolver},
        component: UiPageWorksheetComponent,
        children: []
      }
    ]),
    UiWorksheetModule
  ]
})
export class UiPageWorksheetModule { }

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiControlSchoolComponent} from "./ui-control-school.component";
import {RouterModule} from "@angular/router";
import {UiAppNoDataModule} from "../../_ui/ui-app-no-data/ui-app-no-data.module";
import {NgApexchartsModule} from "ng-apexcharts";
import {UiTooltipModule} from "../../_ui/ui-tooltip/ui-tooltip.module";
import {PipesModule} from "../../app.pipes.module";
import {UiDatePickerModule} from "../../_ui/ui-date-picker/ui-date-picker.module";


@NgModule({
  declarations: [UiControlSchoolComponent],
  exports: [UiControlSchoolComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiControlSchoolComponent,
        children: []
      }
    ]),
    UiAppNoDataModule,
    NgApexchartsModule,
    UiTooltipModule,
    PipesModule,
    UiDatePickerModule
  ]
})
export class UiControlSchoolModule { }

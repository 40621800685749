export class CreateVideo {
  public static readonly type = '[Videos] Create Video';

  constructor(public payload: { data: any }) {}
}

export class EditVideo {
  public static readonly type = '[Videos] Edit Video';

  constructor(public payload: { data: any }) {}
}

export class GetVideo {
  public static readonly type = '[Videos] Get Video';

  constructor(public payload: { videoID: string }) {}
}

export class DeleteVideo {
  public static readonly type = '[Videos] Delete Video';

  constructor(public payload: { videoID: string }) {}
}

export class GetVideoList {
  public static readonly type = '[Videos] Get Video List';
}

export class GetVideoListByParent {
  public static readonly type = '[Videos] Get Video List By ParentId';

  constructor(public payload: { parentId: string }) {}
}

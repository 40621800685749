import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {ModalCloseAction} from '../../_stores/modal/_actions/modal.actions';
import {CreateControlGrade, LoadControlGrades} from '../../_stores/control/_actions/control.actions';

@Component({
  selector: 'ui-control-grade-add',
  templateUrl: './ui-control-grade-add.component.html',
  styleUrls: ['./ui-control-grade-add.component.scss'],
})
export class UiControlGradeAddComponent implements OnInit {
  interface: any = {
    grade: { title: '' },
    _cache: {
      error_fields: [],
    },
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new LoadControlGrades());
  }

  ngOnInit(): void {}

  actionEnterGradeName(event) {
    this.interface.grade.title = event.target.value;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'title');
  }

  actionAddGrade(): void {
    this.interface.loading = true;
    this.interface._cache.error_fields = [];
    Object.entries(this.interface.grade).forEach(([field, data]) => {
      if (!data) {
        this.interface._cache.error_fields.push(field);
      }
    });
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'title');
    if (this.interface._cache.error_fields.length > 0) {
      return;
    }
    const _body = {
      title: this.interface.grade.title,
    };
    this.store.dispatch(new CreateControlGrade({ data: _body })).subscribe({
      next: () => {
        this.interface.loading = false;
        window.location.reload();
        this.store.dispatch(new ModalCloseAction());
        this.cdr.detectChanges();
      },
      error: () => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}

import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {AppModel} from "../../../_models/app.model";
import {ClearMessageToAI, CountUsers, MessageToAI, ToggleSidebar} from "../_actions/app.actions";


export const _ModalDefault: AppModel = {
  sidebar_show: true,
  users_online: 0,
  message: null
};

@State<AppModel>({
  name: 'SAP',
  defaults: _ModalDefault,
})
@Injectable()
export class AppState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private store: Store) {}

  ngxsAfterBootstrap(ctx?: StateContext<AppModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange<AppModel>): void {}

  ngxsOnInit(ctx?: StateContext<AppModel>): any {}

  @Selector()
  static selectState(state: AppModel) {
    return state;
  }


  @Action(ToggleSidebar)
  toggleSidebar(ctx: StateContext<AppModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      sidebar_show: !state.sidebar_show,
    });
  }


  @Action(CountUsers)
  public alarmUsersWsUpdate(ctx: StateContext<AppModel>, users) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      users_online: users.message
    });
  }

  @Action(MessageToAI)
  public messageToAI(ctx: StateContext<AppModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      message: payload.message
    });
  }

  @Action(ClearMessageToAI)
  public clearMessageToAI(ctx: StateContext<AppModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      message: null
    });
  }

}

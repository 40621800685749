import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AssignmentsLabelBuilderModel} from '../../_models/assignments-label-builder.model';
import {Store} from '@ngxs/store';
import {Router} from '@angular/router';
import {AssignmentBuilderService} from '../../_utils/assignment-builder-service';
import {AssignmentsBuilderState} from '../../_stores/assignments-builder/_state/assignments-builder.state';
import {AssignmentsBuilderClearMaterials} from '../../_stores/assignments-builder/_actions/assignments-builder.actions';
import {RouterState} from '@ngxs/router-plugin';

@Component({
  selector: 'ui-assignments-label-builder',
  templateUrl: './ui-assignments-label-builder.component.html',
  styleUrls: ['./ui-assignments-label-builder.component.scss'],
})
export class UiAssignmentsLabelBuilderComponent implements OnInit {
  interface: AssignmentsLabelBuilderModel = {
    url: '',
    show: false,
    e_bookPages: 0,
    assignments: {
      count: 0,
      materials: {
        drills: 0,
        quizzes: 0,
        videos: 0,
        worksheets: 0,
        i_books: 0,
        e_books: 0,
      },
    },
    loading: false,
    update: false,
  };
  private listEbooks: { _id: string; materials: string; pages: { page: 0; id: string }[] }[] = [];

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private assignmentBuilderService: AssignmentBuilderService,
  ) {}

  ngOnInit(): void {
    this.initRouteUrl();
    this.initBuildingAssignments();
  }
  initRouteUrl(): void {
    this.store.select(RouterState.url).subscribe((_url) => {
      this.interface.url = _url;
      this.interface.show = !this.interface.url.includes('builder');
    });
  }
  initBuildingAssignments(): void {
    this.store
      .select(AssignmentsBuilderState.selectBuilderMaterials)
      .pipe()
      .subscribe((_materials) => {
        if (_materials) {
          this.interface.assignments.materials.drills = _materials.drills.length;
          this.interface.assignments.materials.videos = _materials.videos.length;
          this.interface.assignments.materials.quizzes = _materials.quizzes.length;
          this.interface.assignments.materials.worksheets = _materials.worksheets.length;
          this.interface.assignments.materials.i_books = _materials.i_books.length;
          this.listEbooks = this.assignmentBuilderService.ebooks;
          this.interface.assignments.materials.e_books = this.assignmentBuilderService.ebooks.length;
          this.interface.e_bookPages = this.assignmentBuilderService.totalPages.length;
          this.assignmentBuilderService.clearCount();
          for (const [material, count] of Object.entries(this.interface.assignments.materials)) {
            this.assignmentBuilderService.count += count;
          }
        }
      });
    this.cdr.detectChanges();
  }

  getAssignmentCount() {
    return this.assignmentBuilderService.count;
  }

  actionAssign(): void {
    this.router.navigate(['/assignments/builder']).then();
  }

  actionClear(): void {
    this.store.dispatch(new AssignmentsBuilderClearMaterials());
    this.assignmentBuilderService.clearEbooks();
    this.assignmentBuilderService.clearTotalPages();
    this.assignmentBuilderService.clearCount();
    this.assignmentBuilderService.materials = [];
    this.cdr.detectChanges();
  }

  getTotalPages() {
    let numberOfPages = 0;
    if (this.listEbooks.length > 1) {
      for (const ebook of this.listEbooks) {
        numberOfPages = numberOfPages + ebook.pages.length;
      }
    }
    return numberOfPages;
  }
}

<div class="ui-page-practices-container">
  <ui-grades></ui-grades>
  <div class="ui-block ui-block-white ui-practice-desktop">
    <div class="ui-block-header">
      <div class="ui-block-header-title">
        <h2>Your Practices</h2>
        <p>Practice material from <b>{{getCurrentUserGrade()}}</b></p>
      </div>
      <div class="ui-block-header-action"></div>
    </div>
    <div class="ui-block-inner">
      <ng-container [ngTemplateOutlet]="PRACTICE_CARDS"></ng-container>
    </div>
    <div class="ui-block-footer"></div>
  </div>
  <div class="ui-practice-mobile">
    <ng-container [ngTemplateOutlet]="PRACTICE_CARDS"></ng-container>
  </div>
</div>


<ng-template #PRACTICE_CARDS>
  <div class="ui-practice-cards">
    <ng-container *ngFor="let category of interface.categories | sort:'asc':'title'; let c=index">
      <div class="ui-practice-card ui-practice-card-{{category.slug}}" [routerLink]="''+category.slug">
        <div class="ui-practice-card-info">
          <h3>{{category.title}}</h3>
          <p>{{category.description}}</p>
          <ng-container [ngTemplateOutletContext]="{category:category}" [ngTemplateOutlet]="CATEGORY_IMAGE"></ng-container>
          <button class="ui-btn">Continue</button>
        </div>
        <div class="ui-practice-card-thumbnail">
          <ng-container [ngTemplateOutletContext]="{category:category}" [ngTemplateOutlet]="CATEGORY_IMAGE"></ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #CATEGORY_IMAGE let-category='category'>
  <ng-container [ngSwitch]="category.slug">
    <img *ngSwitchCase="'video_lectures'" src="./assets/images/objects/backpack.gif" alt="{{category.title}}" loading="lazy"/>
    <img *ngSwitchCase="'practice_questions'" src="./assets/images/objects/classboard.gif" alt="{{category.title}}" loading="lazy"/>
    <img *ngSwitchCase="'worksheets'" src="./assets/images/objects/book.gif" alt="{{category.title}}" loading="lazy"/>
    <img *ngSwitchCase="'practice_drills'" src="./assets/images/objects/cup.gif" alt="{{category.title}}" loading="lazy"/>
    <img *ngSwitchCase="'official_test_explanations'" src="./assets/images/objects/cup.gif" alt="{{category.title}}" loading="lazy"/>
  </ng-container>
</ng-template>

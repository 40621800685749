import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timeFull'
})
export class TimeFullPipe implements PipeTransform {
  transform(value: number): string {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = Math.floor(value % 60);

    const _hoursOut = ((hours.toString().length < 2) ? '0' : '') + hours;
    const _minutesOut = ((minutes.toString().length < 2) ? '0' : '') + minutes;
    const _secondsOut = ((seconds.toString().length < 2) ? '0' : '') + seconds;


/*    let result = `00:${minutes.toString().padStart(1, '00')}:${seconds.toString().padStart(2, '00')}`;
    if (!!hours) {
      result = `${hours.toString()}:${minutes.toString().padStart(2, '00')}:${seconds.toString().padStart(2, '00')}`;
    }
    return result;*/
    return _hoursOut + ':' +_minutesOut + ':' + _secondsOut;

  }
}

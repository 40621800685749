import {ChangeDetectorRef, Component, HostListener, OnInit, ViewEncapsulation} from "@angular/core";
import {Store} from "@ngxs/store";
import {AuthService} from "../../_stores/auth/_services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'ui-page-forgot',
  templateUrl: './ui-page-forgot.component.html',
  styleUrls: ['./ui-page-forgot.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiPageForgotComponent implements OnInit {
  interface: {
    loading: boolean,
    error: boolean,
    submit: boolean,
    user: {
      email: string
    },
    _cache: {
      error_fields: string[],
    }
  } = {
    loading: false,
    error: false,
    submit: false,
    user: {
      email: ''
    },
    _cache: {
      error_fields: [],
    }
  }

  constructor(private store: Store,private cdr: ChangeDetectorRef, private authService: AuthService,private router: Router,) {}

  ngOnInit(): void {

  }

  actionEnterLogin(event): void {
    this.interface.user.email = event.target.value.toLowerCase();
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'email');
  }
  actionGrantForgot(): boolean {
    let _grant = true;
    if(this.interface.user.email.length < 6) {
      _grant = false;
    }
    return _grant;
  }
  actionForgot(): void {
    this.interface.submit = true;
    this.authService.forgot(this.interface.user.email).subscribe({
      next: (_next) => {
        this.interface.submit = false;
        this.router.navigate(['/']).then();
        this.cdr.detectChanges();
      },
      error: (_error) => {
        this.interface.submit = false;
        this.interface._cache.error_fields.push('email');
        this.cdr.detectChanges();
      },
    });
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'Enter' && this.actionGrantForgot()) {
      this.actionForgot();
    }
  }

}

import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Store} from "@ngxs/store";
import {RouterSelectors} from "../../_stores/routing/router.selectors";

@Component({
  selector: 'ui-page-homework',
  templateUrl: './ui-page-homework.component.html',
  styleUrls: ['./ui-page-homework.component.scss']
})
export class UiPageHomeworkComponent implements OnInit {
  interface: {
    loading: boolean,
    type: string,
    _id: string,
    assignment: string
  } = {
    loading: false,
    type: null,
    _id: null,
    assignment: null
  }
  constructor(private store: Store, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initHomeworkType();
  }
  initHomeworkType(): void {
    this.store.select(RouterSelectors.params).subscribe((_params) => {
      this.interface.type = _params.type;
      this.interface._id = _params.id;
      this.interface.assignment = _params.assignment_id;
    });
  }
}

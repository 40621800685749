import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'agoDate'
})
export class AgoDatePipe implements PipeTransform {
  transform(value: string, dayonly?: boolean) {
    const _dateIn = value?new Date(value): new Date();
    const _dateNow = new Date();
    // tslint:disable-next-line:max-line-length
    let _dateDiffDays = (Date.UTC(_dateIn.getFullYear(), _dateIn.getMonth(), _dateIn.getDate()) - Date.UTC(_dateNow.getFullYear(), _dateNow.getMonth(), _dateNow.getDate())) / (1000 * 60 * 60 * 24);
    if (dayonly) {
      if (_dateDiffDays < 0) {
        _dateDiffDays = _dateDiffDays * -1;
      }
    }
    if (isNaN(_dateDiffDays)) {
      _dateDiffDays = 0;
    }
    return _dateDiffDays;
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPageComponent} from './ui-page.component';
import {RouterModule} from '@angular/router';
import {AppTranslocoModule} from '../../app.transloco.module';
import {UiTooltipModule} from '../ui-tooltip/ui-tooltip.module';
import {NgxsModule} from '@ngxs/store';
import {UserState} from '../../_stores/user/_state/user.state';
import {UiPageSmallSidebarModule} from '../ui-page-small-sidebar/ui-page-small-sidebar.module';
import {UiPageSidebarModule} from '../ui-page-sidebar/ui-page-sidebar.module';
import {FaqState} from '../../_stores/faq/_state/faq.state';
import {UiFaqVideosModule} from '../ui-faq-videos/ui-faq-videos.module';
import {UiPageHeaderModule} from '../ui-page-header/ui-page-header.module';
import {UiPageCrumbsModule} from '../ui-page-crumbs/ui-page-crumbs.module';
import {UiPageFooterModule} from '../ui-page-footer/ui-page-footer.module';
import {UiModalModule} from '../ui-modal/ui-modal.module';
import {ModalState} from '../../_stores/modal/_state/modal.state';
import {PracticeState} from '../../_stores/practice/_state/practice.state';
import {UiClassEditModule} from '../ui-class-edit/ui-class-edit.module';
import {BooksState} from '../../_stores/books/_state/books.state';
import {CrumbsState} from '../../_stores/crumbs/_state/crumbs.state';
import {AssignmentsState} from '../../_stores/asignments/_state/assignments.state';
import {ClassesState} from '../../_stores/classes/_state/classes.state';
import {UiBooksModule} from '../ui-books/ui-books.module';
import {UiClassesModule} from '../ui-classes/ui-classes.module';
import {UiAssignmentsModule} from '../ui-assignments/ui-assignments.module';
import {UiHomeworksModule} from '../ui-homeworks/ui-homeworks.module';
import {EventsState} from '../../_stores/event_ws/_state/events.state';
import {AssignmentsBuilderState} from '../../_stores/assignments-builder/_state/assignments-builder.state';
import {VideosResolver} from '../../_stores/practice/_resolver/videos.resolver';
import {UiControlDashboardModule} from '../../_ui_control/ui-control-dashboard/ui-control-dashboard.module';
import {UiNotificationModule} from '../ui-notification/ui-notification.module';
import {NotificationState} from '../../_stores/notification/_state/notification.state';
import {SchoolsState} from '../../_stores/schools/_state/schools.state';
import {CategoriesState} from '../../_stores/categories/_state/categories.state';
import {VideosState} from '../../_stores/videos/_state/videos.state';
import {UiPracticeModule} from '../ui-practice/ui-practice.module';
import {UiAiModule} from "../ui-ai/ui-ai.module";

@NgModule({
  declarations: [UiPageComponent],
  exports: [UiPageComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      AssignmentsState,
      AssignmentsBuilderState,
      ClassesState,
      BooksState,
      PracticeState,
      FaqState,
      UserState,
      CrumbsState,
      ModalState,
      NotificationState,
      EventsState,
      SchoolsState,
      CategoriesState,
      VideosState
    ]),
    RouterModule.forChild([
      {
        path: "",
        component: UiPageComponent,
        children: [
          {
            path: "practices/practice_questions/:id/result",
            loadChildren: () =>
              import("../ui-page-questions-result/ui-page-questions-result.module").then(
                (mod) => mod.UiPageQuestionsResultModule
              )
          },
          {
            path: "practices/practice_drills/:id/result",
            loadChildren: () =>
              import("../ui-page-questions-result/ui-page-questions-result.module").then(
                (mod) => mod.UiPageQuestionsResultModule
              )
          },
          {
            path: "practices/practice_questions/:id",
            loadChildren: () =>
              import("../ui-page-questions/ui-page-questions.module").then((mod) => mod.UiPageQuestionsModule)
          },
          {
            path: "practices/practice_drills/:id",
            loadChildren: () =>
              import("../ui-page-questions/ui-page-questions.module").then((mod) => mod.UiPageQuestionsModule)
          },
          {
            path: "practices/practice_drills",
            loadChildren: () =>
              import("../ui-page-practices-tree/ui-page-practices-tree.module").then(
                (mod) => mod.UiPagePracticesTreeModule
              )
          },
          {
            path: "practices/practice_questions",
            loadChildren: () =>
              import("../ui-page-practices-tree/ui-page-practices-tree.module").then(
                (mod) => mod.UiPagePracticesTreeModule
              )
          },
          {
            path: "practices/video_lectures/:id",
            resolve: [VideosResolver],
            loadChildren: () => import("../ui-page-video/ui-page-video.module").then((mod) => mod.UiPageVideoModule)
          },
          {
            path: "practices/video_lectures",
            loadChildren: () =>
              import("../ui-page-practices-tree/ui-page-practices-tree.module").then(
                (mod) => mod.UiPagePracticesTreeModule
              )
          },
          {
            path: "practices/worksheets",
            loadChildren: () =>
              import("../ui-page-practices-tree/ui-page-practices-tree.module").then(
                (mod) => mod.UiPagePracticesTreeModule
              )
          },
          {
            path: "practices/worksheets/:id",
            loadChildren: () =>
              import("../ui-page-worksheet/ui-page-worksheet.module").then((mod) => mod.UiPageWorksheetModule)
          },
          {
            path: "practices",
            loadChildren: () =>
              import("../ui-page-practices/ui-page-practices.module").then((mod) => mod.UiPagePracticesModule)
          },
          {
            path: "books/e-book/:id",
            loadChildren: () => import("../ui-page-e-book/ui-page-e-book.module").then((mod) => mod.UiPageEBookModule)
          },
          {
            path: "books",
            loadChildren: () => import("../ui-page-books/ui-page-books.module").then((mod) => mod.UiPageBooksModule)
          },
          {
            path: "worksheet-generators",
            loadChildren: () => import("../ui-page-worksheet-generators/ui-page-worksheet-generators.module").then((mod) => mod.UiPageWorksheetGeneratorsModule)
          },
          {
            path: "students",
            loadChildren: () =>
              import("../ui-page-students/ui-page-students.module").then((mod) => mod.UiPageStudentsModule)
          },
          {
            path: "parents",
            loadChildren: () =>
              import("../ui-page-parents/ui-page-parents.module").then((mod) => mod.UiPageParentsModule)
          },
          {
            path: "classes",
            loadChildren: () =>
              import("../ui-page-classes/ui-page-classes.module").then((mod) => mod.UiPageClassesModule)
          },
          {
            path: "assignments",
            loadChildren: () =>
              import("../ui-page-assignments/ui-page-assignments.module").then((mod) => mod.UiPageAssignmentsModule)
          },
          {
            path: "homeworks",
            loadChildren: () =>
              import("../ui-page-homeworks/ui-page-homeworks.module").then((mod) => mod.UiPageHomeworksModule)
          },
          {
            path: "homework/:type/:id/:assignment_id",
            loadChildren: () =>
              import("../ui-page-homework/ui-page-homework.module").then((mod) => mod.UiPageHomeworkModule)
          },
          {
            path: "reports/:type",
            loadChildren: () =>
              import("../ui-page-reports/ui-page-reports.module").then((mod) => mod.UiPageReportsModule)
          },
          {
            path: "reports/:type/:student_id",
            loadChildren: () =>
              import("../ui-page-reports/ui-page-reports.module").then((mod) => mod.UiPageReportsModule)
          },
          {
            path: "reports",
            loadChildren: () =>
              import("../ui-page-reports/ui-page-reports.module").then((mod) => mod.UiPageReportsModule)
          },
          {
            path: "control",
            loadChildren: () =>
              import("../ui-page-control/ui-page-control.module").then((mod) => mod.UiPageControlModule)
          },
          {
            path: "**",
            redirectTo: "/"
          }
        ]
      }
    ]),
    AppTranslocoModule,
    UiTooltipModule,
    UiPageSmallSidebarModule,
    UiPageSidebarModule,
    UiFaqVideosModule,
    UiPageHeaderModule,
    UiPageCrumbsModule,
    UiPageFooterModule,
    UiModalModule,
    UiClassEditModule,
    UiBooksModule,
    UiClassesModule,
    UiAssignmentsModule,
    UiHomeworksModule,
    UiControlDashboardModule,
    UiNotificationModule,
    UiPracticeModule,
    UiAiModule
  ]
})
export class UiPageModule {}

<div class="ui-awards">
  <div class="ui-awards-title">
    <ng-container *ngIf="interface.user?.awards.length == 1">You are at the beginning of the journey</ng-container>
    <ng-container *ngIf="interface.user?.awards.length > 1 && interface.user?.awards.length < 4">You are better than <strong>24%</strong> of users</ng-container>
    <ng-container *ngIf="interface.user?.awards.length >= 4 && interface.user?.awards.length < 10">You are better than <strong>62%</strong> of users</ng-container>
    <ng-container *ngIf="interface.user?.awards.length >= 11">You are better than <strong>97%</strong> of users</ng-container>
  </div>
  <div class="ui-awards-user">
    <img src="{{interface.user?.avatar}}" alt="{{interface.user?.displayName}}" loading="lazy">
  </div>
  <div class="ui-awards-text">You have unlocked <strong>{{interface.user?.awards.length}}</strong> out of <strong>{{interface.awards?.length}}</strong> awards</div>
  <div class="ui-awards-list">
    <ng-container *ngFor="let award of interface.awards;">
      <div class="ui-award" [class.ui-award-active]="checkUserAward(award)">
        <img src="assets/images/awards/{{award.order}}.svg" alt="{{award?.title}}" tooltip title="{{award?.title}}" description="{{award?.description}}"/>
        <span>{{award?.title}}</span>
      </div>
    </ng-container>
  </div>

</div>

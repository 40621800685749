import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";
import {ModalOpenAction} from "../../_stores/modal/_actions/modal.actions";
import {FooterModel} from "../../_models/footer.model";
import {Router} from "@angular/router";
import {UserState} from "../../_stores/user/_state/user.state";
import {ClassesState} from "../../_stores/classes/_state/classes.state";
import {AssignmentsState} from "../../_stores/asignments/_state/assignments.state";

@Component({
  selector: 'ui-page-footer',
  templateUrl: './ui-page-footer.component.html',
  styleUrls: ['./ui-page-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiPageFooterComponent implements OnInit {
  interface: FooterModel = {
    assignments: [],
    classes: [],
    homeworks: [],
    user: undefined

  }
  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.initUser();
    this.initClasses();
    this.initAssignments();
    this.initHomeworks();
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }
  initClasses(): void {
    this.store.select(ClassesState.selectClassList).subscribe((_classes) => {
      if(_classes) {
        this.interface.classes = _classes;
        this.cdr.detectChanges();
      }
    });
  }
  initAssignments(): void {
    this.store.select(AssignmentsState.selectAssignmentList).subscribe((_assignments) => {
      if(_assignments) {
        this.interface.assignments = _assignments;
        this.cdr.detectChanges();
      }
    });
  }
  initHomeworks(): void {

  }


  actionOpenMenu(): void {
    this.store.dispatch( new ModalOpenAction('menu'));
  }
}

<div class="ui-block ui-block-white">
  <div class="ui-block-header ">
    <div class="ui-block-header-title">
      <h2>Generators</h2>
      <p>Available generators</p>
    </div>
    <div class="ui-books-filters !w-auto">
      <div class="ui-books-filter-input-group ml-auto"></div>
    </div>
  </div>
  <div class="ui-block-inner">
    <ng-container [ngTemplateOutlet]="PRACTICE_CARDS"></ng-container>
  </div>
  <div class="ui-block-footer"></div>
</div>

<ng-template #PRACTICE_CARDS>
  <div class="ui-practice-cards">
    <div class="ui-practice-card" >
      <div class="ui-practice-card-info">
        <h3>Number Line</h3>
        <a class="ui-btn" href="https://argoprep.com/number-line/" target="_blank">Open</a>
      </div>
    </div>

    <div class="ui-practice-card" >
      <div class="ui-practice-card-info">
        <h3>Name Tracing</h3>
        <a class="ui-btn" href="https://argoprep.com/name-tracing/" target="_blank">Open</a>
      </div>
    </div>
    <div class="ui-practice-card" >
      <div class="ui-practice-card-info">
        <h3>Multiplication And Division Fact Families</h3>
        <a class="ui-btn" href="https://argoprep.com/multiplication-and-division-fact-families/" target="_blank">Open</a>
      </div>
    </div>
    <div class="ui-practice-card" >
      <div class="ui-practice-card-info">
        <h3>Graph Paper</h3>
        <a class="ui-btn" href="https://argoprep.com/graph-paper/" target="_blank">Open</a>
      </div>
    </div>
    <div class="ui-practice-card" >
      <div class="ui-practice-card-info">
        <h3>Addition And Subtraction Fact Families</h3>
        <a class="ui-btn" href="https://argoprep.com/addition-and-subtraction-fact-families/" target="_blank">Open</a>
      </div>
    </div>

  </div>
</ng-template>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {ClassModel} from '../../../_models/class.model';
import {API} from '../../api';

@Injectable({
  providedIn: 'root',
})
export class ClassesService {
  constructor(private http: HttpClient) {}

  getAllClasses(): Observable<ClassModel[]> {
    return this.http
      .get<ClassModel[]>(API.url('classes/teacher'))
      .pipe(map((response) => response));
  }

  getClass(id: string): Observable<ClassModel> {
    return this.http
      .get<ClassModel>(API.url('classes/' + id))
      .pipe(map((response) => response));
  }

  updateClass(updateClass: ClassModel): Observable<ClassModel[]> {
    return this.http
      .patch<ClassModel[]>(API.url('classes/' + updateClass._id), updateClass)
      .pipe(map((response) => response));
  }

  deleteClass(id: string): Observable<ClassModel[]> {
    return this.http
      .delete<ClassModel[]>(API.url('classes/' + id))
      .pipe(map((response) => response));
  }

  createClass(title: string): Observable<ClassModel> {

    return this.http
      .post<ClassModel>(API.url('classes/teacher'), { title: title })
      .pipe(map((response) => response));
  }
}

import {LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeEN from '@angular/common/locales/en';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxsModule} from '@ngxs/store';
import {NgxsRouterPluginModule, RouterStateSerializer} from '@ngxs/router-plugin';
import {NgxsWebsocketPluginModule} from "@ngxs/websocket-plugin";
import {NgxsStoragePluginModule} from "@ngxs/storage-plugin";
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';

import {CustomRouterStateSerializer} from "./_stores/routing/router-state.serializer";
import {environment} from '../environments/environment';

import {AppTranslocoModule} from "./app.transloco.module";
import {PipesModule} from "./app.pipes.module";
import {AppRoutingModule} from './app.routing.module';
import {AppComponent} from './app.component';
import {AuthInterceptor} from "./_utils/auth.interceptor";
import {AuthState} from "./_stores/auth/_state/auth.state";
import {UserState} from "./_stores/user/_state/user.state";
import {FaqState} from "./_stores/faq/_state/faq.state";
import {PracticeState} from "./_stores/practice/_state/practice.state";
import {BooksState} from "./_stores/books/_state/books.state";
import {AppState} from "./_stores/app/_state/app.state";
import {ControlState} from "./_stores/control/_state/control.state";


registerLocaleData(localeEN, 'en');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NgxsModule.forRoot([AuthState, AppState], {developmentMode: environment.storeDevtools,}),

    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({key: [AuthState, UserState, FaqState, PracticeState, BooksState, AppState, ControlState]}),

    NgxsWebsocketPluginModule.forRoot({}),
    NgxsReduxDevtoolsPluginModule.forRoot(),

/*    ServiceWorkerModule.register('sap-sw.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),*/
    AppRoutingModule,
    HttpClientModule,
    AppTranslocoModule,
    PipesModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

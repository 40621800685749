import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPracticeExitComponent} from "./ui-practice-exit.component";
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [UiPracticeExitComponent],
  exports: [UiPracticeExitComponent],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class UiPracticeExitModule { }

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthLogout} from "../../_stores/auth/_actions/auth.actions";
import {Store} from "@ngxs/store";
import {UserState} from "../../_stores/user/_state/user.state";
import {PageSmallSidebarModel} from "../../_models/page-small-sidebar.model";
import {SetCurrentUser, SetGlobalUser} from "../../_stores/user/_actions/user.actions";
import {ModalOpenAction} from "../../_stores/modal/_actions/modal.actions";
import {Router} from "@angular/router";

@Component({
  selector: 'ui-page-small-sidebar',
  templateUrl: './ui-page-small-sidebar.component.html',
  styleUrls: ['./ui-page-small-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiPageSmallSidebarComponent implements OnInit {
  interface: PageSmallSidebarModel = {
    users: [],
    user: undefined
  }




  constructor(private store: Store, private cdr: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {
    this.initUser();
    this.initUsers();
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }
  initUsers(): void {
    this.store.select(UserState.selectUsers).subscribe((_users) => {
      this.interface.users = _users;
      this.cdr.detectChanges();
    });
  }

  actionSelectGlobalUser(i: number): void {
    if(this.interface.user._id == this.interface.users[i]._id) {
      return;
    }
    this.store.dispatch(new SetCurrentUser(i));
    this.store.dispatch(new SetGlobalUser(i));
    this.router.navigate(['/']).then();
  }

  actionLogOut(): void {
    this.store.dispatch(new AuthLogout());
  }

  actionOpenProfileSelect(): void {
    this.store.dispatch( new ModalOpenAction('profile_select'));
  }

  actionOpenAddUserForm(): void {
    this.store.dispatch(new ModalOpenAction('profile_add'));
  }
  actionOpenAddUserFormByAin(): void {
    this.store.dispatch(new ModalOpenAction('profile_bind'));
  }

  actionOpenLanguageSelect(): void {
    this.store.dispatch(new ModalOpenAction('language_select'));
  }

}

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Store} from "@ngxs/store";
import {BooksState} from "../../_stores/books/_state/books.state";
import {Router} from "@angular/router";
import {PageBooksModel} from "../../_models/page-books.model";
import {PracticeState} from "../../_stores/practice/_state/practice.state";
import {UserState} from "../../_stores/user/_state/user.state";

@Component({
  selector: 'ui-page-books',
  templateUrl: './ui-page-books.component.html',
  styleUrls: ['./ui-page-books.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiPageBooksComponent implements OnInit {
  interface: PageBooksModel = {
    grades: [],
    series: [],
    books: [],
    user: undefined,
    _cache: {
      books: [],
      searched: false,
      open_choose_grade: false,
      open_choose_series: false,
      choose_grade: undefined,
      choose_serie: undefined,
      user_grade: undefined,
    }
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private router: Router) { }


  ngOnInit(): void {
    this.initUser();
    this.initBooks();
  }
  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      console.log(this.interface.user.practice);
      this.cdr.detectChanges();
    });
  }
  initBooks(): void {
    this.store.select(BooksState.selectBooksList).subscribe((_books) => {
      if(this.interface.books.length == 0) {
        this.interface.books = _books;
        this.interface._cache.books = _books;
        this.initGrades();
        this.initSeries();
        this.cdr.detectChanges();
      }
    })
  }

  initGrades(): void {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.interface.grades = _grades;
      const _user_grade = _grades.find((_grade) => _grade._id == this.interface.user.practice);
      if(_user_grade) {
        this.interface._cache.user_grade = _user_grade;
        this.actionSearchBooksByGrade(_user_grade);
        this.interface._cache.books = this.interface._cache.books.filter((item) => {
          return item.product.grade.replace(/-/g, '_').includes(_user_grade.slug);
        });
      }
      this.cdr.detectChanges();
    });
  }
  initSeries(): void {
    this.interface.series = this.interface.books.reduce((series, book) => {
      if (!series.includes(book.product.series)) {
        series.push(book.product.series);
      }
      return series;
    }, []);
  }

  actionOpenBook(_id: string) {
    this.router.navigateByUrl('/books/e-book/'+_id).then();
  }
  actionSearchBooks(event): void {
    if (event.target.value.length === 0) {
      this.interface.books = this.interface._cache.books;
      this.interface._cache.searched = false;
    } else {
      this.interface._cache.searched = true;
      this.interface._cache.choose_grade = undefined;
      this.interface.books = this.interface._cache.books.filter((item) => {
        return item.title.toLowerCase().includes(event.target.value.toLowerCase());
      });
    }
  }
  actionToggleChooseGrade(): void {
    return;
    this.interface._cache.open_choose_grade = !this.interface._cache.open_choose_grade;
    this.interface._cache.open_choose_series = false;
  }
  actionToggleChooseSeries(): void {
    this.interface._cache.open_choose_series = !this.interface._cache.open_choose_series;
    this.interface._cache.open_choose_grade = false;
  }
  actionSearchBooksByGrade(grade): void {
    this.actionClearChooseSerie();
    this.interface._cache.open_choose_grade = false;
    this.interface._cache.choose_grade = grade;
    this.interface.books = this.interface._cache.books.filter((item) => {
      return item.product.grade.replace(/-/g, '_').includes(grade.slug);
    });
  }
  actionSearchBooksBySerie(serie): void {
    //this.actionClearChooseGrade();
    this.interface._cache.open_choose_series = false;
    this.interface._cache.choose_serie = serie;
    this.interface.books = this.interface._cache.books.filter((item) => {
      return item.product.series == serie;
    });
  }

  actionClearChooseGrade(): void {
    this.interface._cache.choose_grade = undefined;
    this.interface.books = this.interface._cache.books;
  }
  actionClearChooseSerie(): void {
    this.interface._cache.choose_serie = undefined;
    this.interface.books = this.interface._cache.books;
  }
}

<div class="ui-block ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h3>List of parents</h3>
      <p>You students have: <strong>{{interface.parents ? interface.parents.length : 0 }}</strong> parents</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col mt-4">
    <ng-container *ngIf="interface.loading">
      <ui-no-data text="Loading list parents..."></ui-no-data>
    </ng-container>
    <ng-container *ngIf="!interface.loading">
      <ng-container *ngIf="interface.parents.length == 0" [ngTemplateOutlet]="PARENTS_EMPTY"></ng-container>
      <ng-container *ngIf="interface.parents.length > 0" [ngTemplateOutlet]="PARENTS_LIST"></ng-container>
    </ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<ng-template #PARENTS_LIST>
  <table  class="ui-table">
    <thead>
    <tr>
      <th scope="col"><div class="ui-form-checkbox"><input type="checkbox"></div></th>
      <th scope="col">Name</th>
      <th scope="col">Student</th>
      <th scope="col">Actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let parent of interface.parents | sort:'asc':'displayName'">
      <tr>
        <td>
          <div class="ui-form-checkbox"><input type="checkbox"></div>
        </td>
        <td>
          <div class="flex items-center">
            <div class="h-10 w-10 flex-shrink-0">
              <img class="h-10 w-10 rounded" src="{{parent.avatar}}" alt="">
            </div>
            <div class="ml-4">
              <div class="font-medium text-slate-900">{{parent.firstName}} {{parent.lastName}}</div>
              <div class="text-xs text-slate-400">AIN:<span class="ml-1 text-pap-300 font-medium">{{parent.ain}}</span></div>
            </div>
          </div>
        </td>
        <td>
          <div class="flex items-center text-xs">
            <ng-container *ngFor="let student of parent.children | sort:'asc':'displayName'">
              <div class="flex items-center">
                <div class="h-10 w-10 flex-shrink-0">
                  <img class="h-10 w-10 rounded" src="{{student.avatar}}" alt="">
                </div>
                <div class="ml-4">
                  <div class="font-medium text-slate-900">{{student.firstName}} {{student.lastName}}</div>
                  <div class="text-xs text-slate-400">AIN:<span class="ml-1 text-pap-300 font-medium">{{student.ain}}</span></div>
                </div>
              </div>
            </ng-container>
          </div>
        </td>
        <td></td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</ng-template>
<ng-template #PARENTS_EMPTY>
  <ui-no-data type="dino-computer" text="No parents have connected to a student account yet."></ui-no-data>
</ng-template>


<div class="ui-block ui-block-white">
    <div class="ui-block-header">
        <div class="ui-block-header-title">
               <h2>Books</h2>
               <p>Access all of our workbooks to use for classwork, homework, or test prep purposes</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile">
            <button class="ui-btn " routerLink="/books">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
              </svg>
              <span>View all books</span>
            </button>
        </div>
    </div>
    <div class="ui-block-inner ui-block-inner-col py-4">
      <ng-container *ngIf="interface.books.length == 0">
        <ui-no-data type="dino-table" text="Loading awesome books..."></ui-no-data>
      </ng-container>
      <ng-container *ngIf="interface.books.length > 0">
        <div class="ui-books-list">
          <ng-container *ngFor="let book of interface.books | random | slice: 0:12; let i=index">
            <div class="ui-book" (click)="actionOpenBook(book._id)" tooltip title="{{book.title}}" description="{{book.description}}">
              <div class="ui-book-image">
                <img alt="{{book.title}}" [lazyLoad]="book.thumbnail+'@200'" [defaultImage]="'https://www.gstatic.com/psa/static/1.gif'" [decode]="true" src=""/>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
          <button class="ui-btn" routerLink="/books">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
            </svg>
            <span>View all books</span>
          </button>
        </div>
    </div>
</div>

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Store} from "@ngxs/store";
import {ControlService} from "../../_stores/control/_service/control.service";
import {ActivatedRoute} from "@angular/router";
import {UserModel} from "../../_models/user.model";
import {LoadControlUserData} from "../../_stores/control/_actions/control.actions";
import {ControlState} from "../../_stores/control/_state/control.state";

@Component({
  selector: 'ui-control-user',
  templateUrl: './ui-control-user.component.html',
  styleUrls: ['./ui-control-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiControlUserComponent implements OnInit {
  interface: {
    loading: boolean;
    statistic: any;
    charts: any;
    user: UserModel
    _cache: {
      detail: any;
    }
  } = {
    loading: false,
    statistic: undefined,
    charts: {
      today: Date.now(),
      start_date: new Date(),
      end_date: new Date(),
      tracker: {
        chart: {
          height: 300,
          type: "rangeBar",
          stacked: false,
          fontFamily: 'Montserrat, sans-serif',
          toolbar: {
            show: false,
            offsetY: -30,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            autoSelected: 'pan'
          }
        },
        colors: ['#9EA7F2', '#FEC688'],
        tooltip: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 4,
          colors: ['transparent']
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '40%'
          }
        },
        legend: {
          show: true,
          horizontalAlign: "center",
          offsetX: 40,
          showForSingleSeries: true,
          markers: {
            width: 20,
            height: 20,
            radius: 6,
            offsetX: -3
          }
        },
        series: [
          {
            name: "Practice",
            data: []
          },
          {
            name: "Prep",
            data: []
          },
        ],
        dataLabels: {
          enabled: false,
          style: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 200
          },
          background: {
            borderRadius: 5
          }
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: [
          {
            forceNiceScale: true,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: false,
            },
            title: {
              text: "",
              style: {
              }
            }
          }
        ]
      },
    },
    user: undefined,
    _cache: {
      detail: undefined
    }
  };

  constructor(private cdr: ChangeDetectorRef, private store: Store, private controlService: ControlService, private route: ActivatedRoute) {
    this.store.dispatch(new LoadControlUserData(this.route.snapshot.paramMap.get('id')));
  }

  ngOnInit(): void {
    this.initUserData();
  }

  initUserData(): void {
    this.interface.loading = true;
    this.store.select(ControlState.selectUser).subscribe((_details) => {
      this.interface._cache.detail = _details;
      this.interface.user = _details;

      this.initChartTracker();
      this.interface.loading = false;
      this.cdr.detectChanges();
    });
  }

  initChartTracker(): void {
    this.interface.charts.tracker.series[0].data = this.interface.user?.practiceTracker.reduce((data, current) => {
      let _track = {
        x: 'Prep',
        y: [
          new Date(current.createdAt).getTime(),
          new Date(current.updatedAt).getTime()
        ]
      }
      data.push(_track);
      return data
    }, [])
    this.interface.charts.tracker.series[1].data = this.interface.user?.prepTracker.reduce((data, current) => {
      let _track = {
        x: 'Prep',
        y: [
          new Date(current.createdAt).getTime(),
          new Date(current.updatedAt).getTime()
        ]
      }
      data.push(_track);
      return data
    }, [])
  }


  findActiveSubscription(): boolean {
    if(this.interface.user.subscribe.find((s) => s.status == 'active')) {
      return true;
    } else {
      return false;
    }

  }

}

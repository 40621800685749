import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import Konva from 'konva';
import {ActivatedRoute} from '@angular/router';
import {TextNodeService} from './text-node.service';


@Component({
  selector: 'ui-editor',
  templateUrl: './ui-editor.component.html',
  styleUrls: ['./ui-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiEditorComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  interface: any = {
    loading: false,
    update: false,
    konva: {
      stage: undefined,
      layer: undefined,
      line: undefined,
      text: undefined,
      save: [],
    },
    tools: {
      erase: false,
      keyboard: false,
      paint: false
    },
    settings: {
      color: 180,
      opacity: 1,
      thickness: {
        paint: 12,
        erase: 12,
        text: 12,
      },
    },
    _cache: {
      toolbox: false,
      position: 'bottom',
      painting: false,
      erasing: false,
      history: {
        undo: [],
        redo: []
      }
    },
  };

  @Input() show: boolean;
  @Input('position') set _position( position ) {
    if (position) {
      this.interface._cache.position = position;
    }
  }
  @Input() backgroundImage: string;
  @Input() backgroundImageList: string[];
  @Output() drawing: boolean;
  @Output() hide: EventEmitter<any> = new EventEmitter();
  @Output() dataURL: EventEmitter<any> = new EventEmitter();
  editorLayout: ElementRef;
  @ViewChild('editorLayout') set _editorLayout(v) {
    this.editorLayout = v;
  }
  page: any;

  constructor(private eRef: ElementRef,
              private activatedRoute: ActivatedRoute,
              private textNodeService: TextNodeService,
              public cdr: ChangeDetectorRef
              ) {
    }

  ngOnDestroy(): void {

    }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.backgroundImageList) {
      this.page = this.getIndexCurrentPage() + 1;
      this.initData();
    } else {
      this.activatedRoute.fragment.subscribe(x => {
        this.page = x;
        this.initData();
      });
    }
    if (this.show && !this.interface.konva.stage) {
      this.initKonvaStage();
    } else if (this.show && this.backgroundImage) {
      const image = this.interface.konva.layer.getChildren((node) => {
        return node.getClassName() === 'Image';
      });
      if (image.length === 0) {
        this.initBackgroundImage(this.backgroundImage);
      }
    }
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {

  }

  private initData() {
    const data = (this.interface.konva.save as any[]).find(item => item.page === this.page);
    if (this.interface.konva.stage) {
      if (data) {
        this.interface.konva.layer.children = data.data;
        this.interface.konva.layer.draw();
      } else {
        this.interface.konva.layer.children = [];
        this.interface.konva.layer.draw();
      }
    }
  }

  initKonvaStage(): void {
    this.interface.konva.stage = new Konva.Stage({
      container: this.editorLayout?.nativeElement,
      width: this.editorLayout?.nativeElement?.clientWidth,
      height: this.editorLayout?.nativeElement?.clientHeight,
    });
    this.interface.konva.layer = new Konva.Layer();
    this.interface.konva.stage.add(this.interface.konva.layer);
    if (this.backgroundImage){
      this.initBackgroundImage(this.backgroundImage);
    }
  }
  initPaint(): void {
    this.interface.konva.stage.on('mousedown touchstart', () => {
      // non-obvious behavior
      this.interface._cache.toolbox = false;
      this.cdr.markForCheck();
      if (!this.interface.tools.paint) { return; }
      this.interface._cache.painting = true;
      const CURSOR_POSITION = this.interface.konva.stage.getPointerPosition();
      this.interface.konva.line = new Konva.Line({
        stroke: 'hsl(' + Math.round(this.interface.settings.color) % 360 + ', 100%, 50%)',
        strokeWidth: this.interface.settings.thickness.paint,
        opacity: this.interface.settings.opacity,
        globalCompositeOperation: 'source-over',
        lineCap: 'round',
        lineJoin: 'round',
        bezier: true,
        points: [CURSOR_POSITION.x, CURSOR_POSITION.y, CURSOR_POSITION.x, CURSOR_POSITION.y],
      });
      this.interface.konva.layer.add(this.interface.konva.line);
    });
    this.interface.konva.stage.on('mousemove touchmove', (e) => {
      if (!this.interface.tools.paint) { return; }
      if (this.interface._cache.painting) {
        e.evt.preventDefault();
        const CURSOR_POSITION = this.interface.konva.stage.getPointerPosition();
        const newPoints = this.interface.konva.line.points().concat([CURSOR_POSITION.x, CURSOR_POSITION.y]);
        this.interface.konva.line.points(newPoints);
      }
    });
    this.interface.konva.stage.on('mouseup touchend', () => {
      // non-obvious behavior
      this.interface._cache.toolbox = true;
      if (!this.interface.tools.paint) { return; }
      this.interface._cache.painting = false;
      if (this.interface.konva.layer.children.length > 0) {
        this.interface._cache.history.undo.push([...this.interface.konva.layer.children]);
      }
      this.interface.konva.line = undefined;
    });
  }
  initErase(): void {

    this.interface.konva.stage.on('mousedown touchstart', () => {
      if (!this.interface.tools.erase) { return; }
      this.interface._cache.erasing = true;
      const CURSOR_POSITION = this.interface.konva.stage.getPointerPosition();
      this.interface.konva.line = new Konva.Line({
        stroke: 'hsl(' + Math.round(this.interface.settings.color) % 360 + ', 100%, 50%)',
        strokeWidth: this.interface.settings.thickness.erase,
        opacity: 1,
        globalCompositeOperation: 'destination-out',
        lineCap: 'round',
        lineJoin: 'round',
        bezier: true,
        points: [CURSOR_POSITION.x, CURSOR_POSITION.y, CURSOR_POSITION.x, CURSOR_POSITION.y],
      });
      this.interface.konva.layer.add(this.interface.konva.line);
    });
    this.interface.konva.stage.on('mousemove touchmove', (e) => {
      if (!this.interface.tools.erase) { return; }
      if (this.interface._cache.erasing) {
        e.evt.preventDefault();
        const CURSOR_POSITION = this.interface.konva.stage.getPointerPosition();
        const newPoints = this.interface.konva.line.points().concat([CURSOR_POSITION.x, CURSOR_POSITION.y]);
        this.interface.konva.line.points(newPoints);
      }
    });
    this.interface.konva.stage.on('mouseup touchend', () => {
      if (!this.interface.tools.erase) { return; }
      this.interface._cache.erasing = false;
      this.interface.konva.line = undefined;
    });
  }
  initTextEnter(): void {

    this.interface.konva.stage.on('mousedown touchstart', (e) => {
      if (!this.interface.tools.keyboard) { return; }
      if (!this.interface.konva.text || e.target._id !== this.interface.konva.stage.mouseClickStartShape?._id) {
         this.interface.konva.text = this.textNodeService.textNode(this.interface.konva.stage, this.interface.konva.layer);
      }
    });
  }

  initBackgroundImage(url: string){
    Konva.Image.fromURL(url,  (imageNode) => {
      imageNode.setAttrs({
        x: 0,
        y: 0,
        width: this.editorLayout.nativeElement.clientWidth,
        height: this.editorLayout.nativeElement.clientHeight,
      });
      imageNode.addName('backgroundImage');
      this.interface.konva.layer.add(imageNode);
    });
  }


  actionSave(): void {
    this.interface.tools.paint = false;
    this.interface._cache.toolbox = false;
    const temp = {page: this.page, data: this.interface.konva.layer.children};
    (this.interface.konva.save as any[]).push(temp);
    this.hide.emit(false);
    this.show = false;
    this.actionSaveDataURL();
  }
  actionCancel(): void {
    this.interface.konva.stage.clear();
    if (this.interface.konva.layer.children){
     this.interface.konva.layer.children = [];
    }
    this.hide.emit(false);
    this.dataURL.emit(null);
    this.show = false;
  }
  actionUndo(): void {
    if (this.interface._cache.history.undo.length === 0) {
      return;
    }
    this.interface.konva.stage.clear();
    const _lastlayer = this.interface._cache.history.undo.pop();
    let _undolayer = this.interface._cache.history.undo[this.interface._cache.history.undo.length - 1];
    if (!_undolayer) {
      _undolayer = [];
    }
    this.interface.konva.layer.children = _undolayer;
    this.interface.konva.stage.add(this.interface.konva.layer);
    this.interface._cache.history.redo.push(_lastlayer);
  }
  actionRedo(): void {
    if (this.interface._cache.history.redo.length === 0) {
      return;
    }

    this.interface.konva.stage.clear();
    let _redolayer = this.interface._cache.history.redo.pop();
    if (!_redolayer) {
      _redolayer = [];
    }
    this.interface.konva.layer.children = _redolayer;
    this.interface.konva.stage.add(this.interface.konva.layer);
    this.interface._cache.history.undo.push(_redolayer);
  }
  actionOpenToolbox(): void {
    this.interface._cache.toolbox = this.interface.tools.paint || this.interface.tools.erase || this.interface.tools.keyboard;
  }
  actionCloseToolbox(): void {
    this.interface._cache.toolbox = false;
  }
  actionChoosePaint(): void {
    this.actionCloseToolbox();
    this.interface.tools.paint = !this.interface.tools.paint;
    this.interface.tools.erase = false;
    this.interface.tools.keyboard = false;
    if (this.interface.tools.paint) {
      this.actionOpenToolbox();
      this.initPaint();
    }
  }
  actionChooseErase(): void {
    this.actionCloseToolbox();
    this.interface.tools.erase = !this.interface.tools.erase;
    this.interface.tools.paint = false;
    this.interface.tools.keyboard = false;
    if (this.interface.tools.erase) {
      this.actionOpenToolbox();
      this.initErase();
    }
  }
  actionChooseKeyboard(): void {
    this.actionCloseToolbox();
    this.interface.tools.keyboard = !this.interface.tools.keyboard;
    this.interface.tools.paint = false;
    this.interface.tools.erase = false;
    if (this.interface.tools.keyboard) {
      this.actionOpenToolbox();
      this.initTextEnter();
    }
  }
  actionChangeColor(event): void {
    this.interface.settings.color = Number(event.target.value);
    this._DEBUG();
  }
  actionChangeOpacity(event): void {
    this.interface.settings.opacity = Number(event.target.value);
    this._DEBUG();
  }
  actionChangeThicknessPaint(event): void {
    this.interface.settings.thickness.paint = Number(event.target.value);
    this._DEBUG();
  }
  actionChangeThicknessErase(event): void {
    this.interface.settings.thickness.erase = Number(event.target.value);
    this._DEBUG();
  }
  actionChangeThicknessText(event): void {
    this.interface.settings.thickness.text = Number(event.target.value);
    this._DEBUG();
  }

  actionSaveDataURL(): void {
    const dataURL = this.interface.konva.stage.toDataURL({ pixelRatio: 4 });
    this.dataURL.emit(dataURL);
  }
  getIndexCurrentPage() {
    return this.backgroundImageList.indexOf(this.backgroundImage);
  }

  _DEBUG(): void {

  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {

    }
  }

}

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngxs/store';
import {UserState} from '../../_stores/user/_state/user.state';
import {PageHeaderModel} from '../../_models/page-header.model';
import {ToggleSidebar} from "../../_stores/app/_actions/app.actions";
import {AppState} from "../../_stores/app/_state/app.state";
import {Clipboard} from "@angular/cdk/clipboard";
import {EventInviteToSchoolLinkCopied} from "../../_stores/event_ws/_actions/events.actions";
import {NotificationOpen} from "../../_stores/notification/_actions/notification.actions";
import {ModalOpenAction} from "../../_stores/modal/_actions/modal.actions";


@Component({
  selector: 'ui-page-header',
  templateUrl: './ui-page-header.component.html',
  styleUrls: ['./ui-page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiPageHeaderComponent implements OnInit {
  interface: PageHeaderModel = {
    app: undefined,
    user: undefined,
    _cache: {
      copied: false
    }
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private clipboard: Clipboard) {}

  ngOnInit(): void {
    this.initApp();
    this.initUser();
  }
  initApp(): void {
    this.store.select(AppState.selectState).subscribe((_app) => {
      if(!_app) { return; }
      this.interface.app = _app;
      this.cdr.detectChanges();
    });
  }
  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }


  actionToggleSidebar(): void {
    this.store.dispatch(new ToggleSidebar());
  }
  actionClipboardInviteLink(): void {

    if (!this.interface.user?.school?.license?.active) {
      this.store.dispatch(new ModalOpenAction('license-off-extend'));
      return;
    }

    let _link = window.location.origin + '/invite/' + this.interface.user.school.invitation.sin;
    this.clipboard.copy(_link);
    this.store.dispatch(new EventInviteToSchoolLinkCopied());
    this.store.dispatch(new NotificationOpen('school_invite_link_copy'));
    this.interface._cache.copied = true;
    this.cdr.detectChanges();
  }

  actionTest(): void {
    //this.store.dispatch(new EventTestKlaviyo());
  }
  actionOpenUpgradeModal(): void {
    this.store.dispatch(new ModalOpenAction('upgrade'));
  }

}

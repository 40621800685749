import {ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {QuestionModel} from "../../_models/question.model";
import {QuestionProgressModel} from "../../_models/ui-quiz.model";

@Component({
  selector: 'ui-quiz-result',
  templateUrl: './ui-quiz-result.component.html',
  styleUrls: ['./ui-quiz-result.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiQuizResultComponent implements OnInit {
  @Input('homework') set _homework(homework) {
    if (homework) {
      this.interface.homework = homework;
    }
  }
  @Input('questions') set _questions(questions) {
    if (questions) {
      this.interface.questions = questions;
      if(this.interface.questions.length > 0) {
        this.interface.loading = false;
      }
    }
  }
  @Input('result') set _result(result) {
    if (result) {
      this.interface.result = result;
      this.initProgress();
    }
  }
  @Input('time') set _time(time) {
    if (time) {
      this.interface.statistic.time = time;
    }
  }

  interface: {
    loading: boolean,
    homework: boolean,
    questions: QuestionModel[],
    result: QuestionProgressModel[],
    statistic: {
      total: number;
      correct: number;
      wrong: number;
      answered: number;
      time: number;
    }
  } = {
    loading: true,
    homework: false,
    questions: [],
    result: [],
    statistic: {
      total: 0,
      correct: 0,
      wrong: 0,
      answered: 0,
      time: 0,
    }
  }


  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  checkQuestionStatus(question): any {
    return this.interface.result.find((_question) => _question.id == question._id);
  }

  actionToggleZoomThumbnail(): void {
   // this.interface.controls.zoom = !this.interface.controls.zoom;
  }

  initProgress(): void {
    this.interface.statistic.total = this.interface.result.length;
    console.log(this.interface.result);
    this.interface.result.forEach(_question => {
      if (_question.answered) {
        this.interface.statistic.answered++;
        if (_question.correct) {
          this.interface.statistic.correct++;
        } else {
          this.interface.statistic.wrong++;
        }
      }
    });
  }

}

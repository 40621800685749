import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPaginationComponent} from './ui-pagination.component';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [UiPaginationComponent],
  exports: [UiPaginationComponent],
  imports: [CommonModule, RouterModule],
})
export class UiPaginationModule {}

<div class="ui-block ui-block-no-margin ui-block-white col-span-2 row-span-5">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Roles</h2>
      <p>School users by role</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col">
    <div class="ui-school-stat-users">
      <ng-container *ngIf="!interface.loading">
        <div class="ui-school-stat-users-roles">
          <ng-container *ngFor="let role of interface.statistic?.user.roles | sort:'asc':'name'; let a=index">
            <div class="ui-school-stat-users-role">
              <div class="ui-school-stat-users-role-icon">
                <svg *ngIf="role.name.toUpperCase() === 'PARENT'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                </svg>
                <svg *ngIf="role.name.toUpperCase() === 'ADMINISTRATOR'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                </svg>
                <svg *ngIf="role.name.toUpperCase() === 'STUDENT'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                </svg>
                <svg *ngIf="role.name.toUpperCase() === 'TEACHER'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>
              </div>
              <div class="ui-school-stat-users-role-name">{{role.name}}</div>
              <div class="ui-school-stat-users-role-count" tooltip description="Show users"
                   [routerLink]="'/control/users/'+role._id+'/'+interface._id">{{role.count}}</div>
            </div>
          </ng-container>
          <ui-no-data *ngIf="interface.statistic?.user.roles.length == 0" text="User roles not found"></ui-no-data>
        </div>
      </ng-container>
      <ng-container *ngIf="interface.loading">
        <ui-no-data type="loading" text="Loading data" class="min-h-[240px] mt-4 flex-grow"></ui-no-data>
      </ng-container>
    </div>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-white col-span-10 row-span-6">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>New users</h2>
      <p>Registered new user statistic by date</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile gap-4">
      <ui-date-picker [future]="true" class="w-52 h-[44px]"></ui-date-picker>
      <ui-date-picker [future]="true" class="w-52 h-[44px]"></ui-date-picker>
    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col flex-grow">
    <ng-container *ngIf="!interface.loading">
      <apx-chart
        [colors]="interface.charts.users_register_by_roles.colors"
        [series]="interface.charts.users_register_by_roles.series"
        [chart]="interface.charts.users_register_by_roles.chart"
        [yaxis]="interface.charts.users_register_by_roles.yaxis"
        [xaxis]="interface.charts.users_register_by_roles.xaxis"
        [stroke]="interface.charts.users_register_by_roles.stroke"
        [tooltip]="interface.charts.users_register_by_roles.tooltip"
        [legend]="interface.charts.users_register_by_roles.legend"
        [dataLabels]="interface.charts.users_register_by_roles.dataLabels"
        [plotOptions]="interface.charts.users_register_by_roles.plotOptions"
      ></apx-chart>
    </ng-container>
    <ng-container *ngIf="interface.loading">
      <ui-no-data type="loading" text="Loading data" class="mt-4 flex-grow"></ui-no-data>
    </ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-white col-span-2">
  <div class="ui-block-header">
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col flex-grow">
    <ng-container *ngIf="interface.loading">
      <ui-no-data type="loading" text="Loading data" class=""></ui-no-data>
    </ng-container>
    <ng-container *ngIf="!interface.loading">
      <div class="ui-school-stat-licenses">
        <div *ngIf="!interface.license" class="ui-school-stat-no-license">
          <p>The school does not have a license, the use of educational content is not available.</p>
          <button class="ui-btn ui-btn-sm ui-btn-blue-outline" (click)="actionOpenAddLicenseModal()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            <span class="whitespace-nowrap">Add license</span>
          </button>
        </div>
        <div *ngIf="interface.license" class="ui-school-stat-license">
          <span>License</span>
          <p>{{interface.license?.active ? 'Active' : 'Deactivated'}}</p>
          <div class="ui-school-stat-license-data">
            <div class="ui-school-stat-license-data-item">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
              </svg>
              <p>Created</p>
              <span>{{interface.license?.startDate | date:'mediumDate'}}</span>
            </div>
            <div class="ui-school-stat-license-data-item">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
              <p>Updated</p>
              <span>{{interface.license?.updatedAt | date:'mediumDate'}}</span>
            </div>
            <div class="ui-school-stat-license-data-item">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
              </svg>
              <p>Expired</p>
              <span>{{interface.license?.endDate | date:'mediumDate'}}</span>
            </div>
            <div class="ui-school-stat-license-data-item">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
              </svg>

              <p>Max users</p>
              <span>{{interface.license?.maxCountUser}}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>

<div class="sm:col-span-12">
  <div class="ui-reports-content">
    <div class="ui-reports-students">
      <button class="ui-btn ui-btn-pap h-full" (click)="actionSelectStudent(null)" tooltip
              description="Statistics from all users">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
        </svg>
        <span>All Users</span>
      </button>
      <div class="ui-form-select max-w-[20rem]">
        <svg *ngIf="!interface.reports.student" xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
        </svg>
        <svg *ngIf="interface.reports.student && interface.reports.student.role.name.toUpperCase() === 'STUDENT'"
             xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
        </svg>
        <svg *ngIf="interface.reports.student && interface.reports.student.role.name.toUpperCase() === 'PARENT'"
             xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
        </svg>
        <svg *ngIf="interface.reports.student && interface.reports.student.role.name.toUpperCase() === 'ADMINISTRATOR'"
             xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
        </svg>
        <svg *ngIf="interface.reports.student && interface.reports.student.role.name.toUpperCase() === 'TEACHER'"
             xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
        </svg>
        <input name="class" type="text" readonly [value]="interface.reports.student?.displayName"
               placeholder="Select user" (click)="actionToggleSelectStudent()">
        <div class="ui-form-select-list"
             [class.ui-form-select-list-open]="interface.reports._cache.open_select_student">
          <div class="ui-form-select-list-items ui-select-school-list">
            <ng-container *ngIf="interface.reports.students.length == 0">
              <div class="ui-form-select-list-item">
                <p>No Users</p>
                <span>&nbsp;</span>
              </div>
            </ng-container>
            <ng-container *ngFor="let student of interface.reports.students">
              <div class="ui-form-select-list-item" (click)="actionSelectStudent(student)">
                <div class="ui-reports-activity-icon">
                  <svg *ngIf="student.role.name.toUpperCase() === 'STUDENT'" xmlns="http://www.w3.org/2000/svg"
                       fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                  </svg>
                  <svg *ngIf="student.role.name.toUpperCase() === 'TEACHER'" xmlns="http://www.w3.org/2000/svg"
                       fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                  </svg>
                  <svg *ngIf="student.role.name.toUpperCase() === 'ADMINISTRATOR'" xmlns="http://www.w3.org/2000/svg"
                       fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                  </svg>
                  <svg *ngIf="student.role.name.toUpperCase() === 'PARENT'" xmlns="http://www.w3.org/2000/svg"
                       fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                  </svg>
                </div>
                <div class="flex flex-col"><p>{{student.displayName}}</p>
                  <ng-container *ngIf="student.role.name.toUpperCase() === 'ADMINISTRATOR'"><span>Administrator</span>
                  </ng-container>
                  <ng-container *ngIf="student.role.name.toUpperCase() === 'STUDENT'"><span>Student</span>
                  </ng-container>
                  <ng-container *ngIf="student.role.name.toUpperCase() === 'TEACHER'"><span>Teacher</span>
                  </ng-container>
                  <ng-container *ngIf="student.role.name.toUpperCase() === 'PARENT'"><span>Parent</span>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pointer-events-none" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" stroke-width="1.5">
          <path *ngIf="!interface.reports._cache.open_select_student" stroke-linecap="round" stroke-linejoin="round"
                d="M19 9l-7 7-7-7" />
          <path *ngIf="interface.reports._cache.open_select_student" stroke-linecap="round" stroke-linejoin="round"
                d="M5 15l7-7 7 7" />
        </svg>
      </div>

      <!--    <ng-container *ngFor="let student of interface.reports.students; let a=index">
            <div class="ui-reports-student" [class.ui-reports-student-active]="student._id == interface.reports.student?._id">
              <img  [src]="student.avatar" alt="{{student.displayName}}" tooltip description="Statistic from {{student.displayName}}" (click)="actionSelectStudent(student)"/>
            </div>
          </ng-container>-->
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin row-span-1 col-span-12 xl:col-span-6">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>{{interface.reports.student ? interface.reports.student.displayName + ' activity' : 'General Activity Feed'}}</h2>
          <p>{{interface.reports.student ? interface.reports.student.displayName + ' activities from the system' : 'Here you can find the most recent events are doing'}} </p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
      </div>
      <div class="ui-block-inner ui-block-inner-col">
        <div *ngIf="!interface.reports.loading" class="ui-reports-activities">
          <ng-container
            *ngFor="let activity of interface.reports.report.activity | sort: 'desc': 'createdAt'; let a=index">
            <div class="ui-reports-activity">
              <div class="ui-reports-activity-icon ui-reports-activity-icon-{{activity.type}}"
                   [ngSwitch]="activity.type">
                <svg *ngSwitchCase="'WorksheetDownloaded'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
                </svg>
                <svg *ngSwitchCase="'WorksheetPrinted'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                </svg>
                <svg *ngSwitchCase="'BookOpened'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                </svg>
                <svg *ngSwitchCase="'PracticeTrackerStart'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                </svg>
                <svg *ngSwitchCase="'PracticeTrackerEnd'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                </svg>
                <svg *ngSwitchCase="'InviteToSchoolLinkCopied'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                </svg>
                <svg *ngSwitchCase="'OpenModalChangeLanguage'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                </svg>
                <svg *ngSwitchCase="'PrepTrackerStart'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                </svg>
                <svg *ngSwitchCase="'PrepTrackerEnd'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                </svg>
                <svg *ngSwitchCase="'BookPrinted'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                </svg>
                <svg *ngSwitchCase="'ClassCreated'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
                <svg *ngSwitchCase="'AssignmentCreated'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>

                <svg *ngSwitchDefault xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>

              </div>
              <div class="ui-reports-activity-data">
                <span>{{activity.createdAt | date: 'medium'}}</span>
                <p>
                  <b>{{activity.user?.displayName || 'SOMEWERE'}}</b>
                  <ng-container [ngSwitch]="activity.type">
                    <ng-container *ngSwitchCase="'WorksheetDownloaded'">Download worksheet</ng-container>
                    <ng-container *ngSwitchCase="'WorksheetPrinted'">Printed worksheet</ng-container>
                    <ng-container *ngSwitchCase="'BookOpened'">Open interactive book</ng-container>
                    <ng-container *ngSwitchCase="'PracticeTrackerStart'">
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Video'">Started watching video
                      </ng-container>
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Question'">Started doing
                        practice
                        quiz
                      </ng-container>
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Worksheet'">Opened worksheet
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'PracticeTrackerEnd'">
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Video'">Complete watching video
                      </ng-container>
                      <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Question'">Complete practice
                        quiz
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'InviteToSchoolLinkCopied'">Copied invite link to his school
                    </ng-container>
                    <ng-container *ngSwitchCase="'OpenModalChangeLanguage'">Opened changes language for system video
                      explanation from dashboard
                    </ng-container>
                    <ng-container *ngSwitchCase="'PrepTrackerStart'">Started doing the task from homework:
                    </ng-container>
                    <ng-container *ngSwitchCase="'PrepTrackerEnd'">Complete doing the task from homework:
                    </ng-container>
                    <ng-container *ngSwitchCase="'ClassCreated'">Created Class</ng-container>
                    <ng-container *ngSwitchCase="'AssignmentCreated'">Created Assignment</ng-container>
                    <ng-container *ngSwitchCase="'BookPrinted'">Printing {{activity.resource[0]?.pages_count}} pages
                      from
                    </ng-container>
                    <ng-container *ngSwitchDefault>{{activity.type}}</ng-container>
                  </ng-container>
                  <ng-container [ngSwitch]="activity.type">
                    <ng-container *ngSwitchCase="'WorksheetPrinted'">
                      <strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'WorksheetDownloaded'">
                      <strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'BookOpened'"><strong>{{activity.resourceData[0]?.title}}</strong>
                    </ng-container>
                    <ng-container *ngSwitchCase="'PracticeTrackerStart'">
                      <strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'PracticeTrackerEnd'">
                      <strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'PrepTrackerStart'">
                      <strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'PrepTrackerEnd'">
                      <strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                    <ng-container *ngSwitchCase="'BookPrinted'"><strong>{{activity.resourceData[0]?.title}}</strong>
                    </ng-container>
                  </ng-container>
                </p>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="interface.reports.report.activity.length == 0">
            <ui-no-data text="No activity"></ui-no-data>
          </ng-container>
        </div>
        <ui-no-data *ngIf="interface.reports.loading" type="loading" text="Loading data"
                    class="h-[204px] mt-4"></ui-no-data>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
      </div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin row-span-1 col-span-12 xl:col-span-3">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>Assignment Activity Feed</h2>
          <p>{{interface.reports.student ? interface.reports.student.displayName + ' do the following assignment materials' : 'Activity who have started but not completed an assignment'}}</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
      </div>
      <div class="ui-block-inner ui-block-inner-col">
        <div *ngIf="!interface.reports.loading" class="ui-reports-goings">
          <ng-container
            *ngFor="let going of interface.reports.report.goings | sort: 'desc': 'createdAt'; let a=index">
            <div class="ui-reports-going">
              <div class="ui-reports-going-icon" [ngSwitch]="going.typeMaterial">
                <svg *ngSwitchCase="'Question'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                </svg>
                <svg *ngSwitchCase="'Worksheet'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
                <svg *ngSwitchCase="'Video'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round"
                        d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                </svg>
                <svg *ngSwitchCase="'Book'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                </svg>
              </div>
              <div class="ui-reports-going-data">
                <p><b>{{going.user?.displayName}}</b>
                  <ng-container [ngSwitch]="going.typeMaterial">
                    <ng-container *ngSwitchCase="'Question'">Started homework quiz from
                      <b>{{going.assignment.title}}</b> assignment but did not answers for all questions
                    </ng-container>
                    <ng-container *ngSwitchCase="'Worksheet'">Opened homework worksheet from
                      <b>{{going.assignment.title}}</b> assignment but did not submit his work
                    </ng-container>
                    <ng-container *ngSwitchCase="'Video'">Started watching homework video from
                      <b>{{going.assignment.title}}</b> assignment but didn't finish watching
                    </ng-container>
                    <ng-container *ngSwitchCase="'Book'">Opened homework books pages from
                      <b>{{going.assignment.title}}</b> assignment but did not submit his work
                    </ng-container>
                  </ng-container>
                </p>
                <span>{{going.createdAt | date: 'medium'}}</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="interface.reports.report.goings.length == 0">
            <ui-no-data text="No assignments"></ui-no-data>
          </ng-container>
        </div>
        <ui-no-data *ngIf="interface.reports.loading" type="loading" text="Loading data"
                    class="h-[204px] mt-4"></ui-no-data>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
      </div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin row-span-1 col-span-12 xl:col-span-3">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>Events</h2>
          <p>Users events</p>
        </div>
      </div>
      <div class="ui-block-inner ui-block-inner-col">
        <ng-container *ngIf="interface.loading">
          <ui-no-data type="loading" text="Loading data" class="mt-4"></ui-no-data>
        </ng-container>
        <ng-container *ngIf="!interface.loading">
          <div class="ui-school-stat-events">
            <ng-container *ngFor="let event of interface.statistic?.event.group | sort: 'desc': 'count'  let a=index">
              <div class="ui-school-stat-event">
                <div class="ui-school-stat-event-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 12.75c1.148 0 2.278.08 3.383.237 1.037.146 1.866.966 1.866 2.013 0 3.728-2.35 6.75-5.25 6.75S6.75 18.728 6.75 15c0-1.046.83-1.867 1.866-2.013A24.204 24.204 0 0112 12.75zm0 0c2.883 0 5.647.508 8.207 1.44a23.91 23.91 0 01-1.152 6.06M12 12.75c-2.883 0-5.647.508-8.208 1.44.125 2.104.52 4.136 1.153 6.06M12 12.75a2.25 2.25 0 002.248-2.354M12 12.75a2.25 2.25 0 01-2.248-2.354M12 8.25c.995 0 1.971-.08 2.922-.236.403-.066.74-.358.795-.762a3.778 3.778 0 00-.399-2.25M12 8.25c-.995 0-1.97-.08-2.922-.236-.402-.066-.74-.358-.795-.762a3.734 3.734 0 01.4-2.253M12 8.25a2.25 2.25 0 00-2.248 2.146M12 8.25a2.25 2.25 0 012.248 2.146M8.683 5a6.032 6.032 0 01-1.155-1.002c.07-.63.27-1.222.574-1.747m.581 2.749A3.75 3.75 0 0115.318 5m0 0c.427-.283.815-.62 1.155-.999a4.471 4.471 0 00-.575-1.752M4.921 6a24.048 24.048 0 00-.392 3.314c1.668.546 3.416.914 5.223 1.082M19.08 6c.205 1.08.337 2.187.392 3.314a23.882 23.882 0 01-5.223 1.082" />
                  </svg>

                </div>
                <div class="ui-school-stat-event-name">{{event.name}}</div>
                <div class="ui-school-stat-event-count">{{event.count}}</div>
              </div>
            </ng-container>
            <ui-no-data *ngIf="interface.statistic?.event.group.length == 0"
                        text="User events not found"></ui-no-data>
          </div>
        </ng-container>
      </div>
      <div class="ui-block-footer">
      </div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin row-span-4 sm:col-span-9 self-baseline">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>{{interface.reports.student ? interface.reports.student.displayName + ' progress' : 'Practice Users Activity & Progress'}}</h2>
          <p>{{interface.reports._cache.grade_selected?.title || 'All grades'}}</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile gap-2 relative">
          <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('day')"
                  [class.ui-btn-pap]="interface.reports.period == 'day'" tooltip description="Statistic by day">
            <span>Day</span>
          </button>
          <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('week')"
                  [class.ui-btn-pap]="interface.reports.period == 'week'" tooltip description="Statistic by week">
            <span>Week</span>
          </button>
          <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('month')"
                  [class.ui-btn-pap]="interface.reports.period == 'month'" tooltip description="Statistic by month">
            <span>Month</span>
          </button>
          <ui-date-picker [future]="true" [date]="interface.reports._cache.period.start" class="ui-reports-period"
                          [class.ui-reports-period-active]="interface.reports.period == 'custom'"
                          (selectedDate)="actionSelectPeriodDate($event)"></ui-date-picker>
        </div>
      </div>
      <div class="ui-block-inner ui-block-inner-col flex-grow">
        <div class="ui-reports-trackers">
          <div class="ui-reports-trackers-grades">
            <ng-container *ngFor="let grade of interface.reports.grades | sort:'asc':'order'; let a=index">
              <div class="ui-reports-trackers-grade"
                   [class.ui-reports-trackers-grade-active]="interface.reports._cache.grade_selected?._id == grade._id"
                   (click)="actionSelectGrade(grade)">
                <label>
                  <h3 class="!text-base">{{grade.title}}</h3>
                  <small></small>
                </label>
                <p class="!text-xs">Materials: <b>{{getGradeMaterialTotal(grade.category)}}</b></p>
              </div>
            </ng-container>
          </div>
          <hr class="mt-2">
          <div class="flex gap-4">
            <div class="ui-reports-trackers-categories min-w-[236px]">
              <ng-container
                *ngFor="let category_main of interface.reports._cache.grade_report_practice_selected; let a=index">
                <div class="ui-reports-category"
                     [class.ui-reports-category-active]="interface.reports._cache.category_selected?.id == category_main.id"
                     (click)="actionSelectCategory(category_main)">
                  <div class="ui-reports-category-header">
                    <div class="ui-reports-category-header-title">
                      <div class="ui-reports-category-header-title-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round"
                                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                        </svg>
                      </div>
                      <div class="ui-reports-category-header-title-text">
                        <h3>{{category_main.title}}</h3>
                        <p>Materials: {{category_main.totalMaterial}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="ui-reports-category-progress" *ngIf="false">
                    <p>
                      <span>Progress: 0%</span>
                      <span></span>
                    </p>
                    <progress [max]="100" [value]="0"></progress>
                  </div>
                </div>
              </ng-container>
              <ui-no-data *ngIf="!interface.reports._cache.grade_report_practice_selected" type="loading"
                          text="Loading data" class="h-[453px] flex-grow"></ui-no-data>
            </div>
            <div class="ui-reports-trackers-categories-data">
              <div *ngIf="interface.reports._cache.grade_report_practice_selected"
                   class="ui-reports-trackers-shorts py-4 ml-auto">
                <ng-container *ngIf="interface.reports._cache.category_selected?.slug == 'practice_drills'">
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.questionsAnswered}}q</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Questions Answered</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.questionsAnsweredCorrect}}q</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Questions Answered Correct</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.questionsAnsweredIncorrect}}q</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Questions Answered Incorrect</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.totalDurationSpent | timeFull}}</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Total Duration Spent</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="interface.reports._cache.category_selected?.slug == 'practice_questions'">
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.questionsAnswered}}q</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Questions Answered</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.questionsAnsweredCorrect}}q</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Questions Answered Correct</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.questionsAnsweredIncorrect}}q</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Questions Answered Incorrect</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.totalDurationSpent | timeFull}}</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Total Duration Spent</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="interface.reports._cache.category_selected?.slug == 'video_lectures'">
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.openedVideo}}v</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Opened</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.viewsVideo}}v</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Views</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.timeVideo | timeFull}}</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Total time watched</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.avgTimeVideo}}%</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Avg. % watched</p>
                  </div>

                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.finishesVideo}}v</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Finishes</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>0</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Most views by device</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="interface.reports._cache.category_selected?.slug == 'worksheets'">
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.viewedWorksheets}}w</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Viewed</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h3>{{interface.reports._cache.category_selected.printedDownloadedWorksheets}}w</h3>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Printed/Downloaded</p>
                  </div>
                  <div class="ui-reports-trackers-short">
                    <label>
                      <h4>{{0}}w</h4>
                      <span>/</span>
                      <small>{{interface.reports.period}}</small>
                    </label>
                    <p>Most Popular worksheets</p>
                  </div>
                </ng-container>
              </div>
              <ng-container
                *ngFor="let category of interface.reports._cache.category_selected?.children | sort:'desc':'title'; let a=index">
                <div class="ui-block ui-block-pap ui-block-no-margin">
                  <div class="ui-block-header">
                    <div class="ui-block-header-title">
                      <h2>{{category.title}}</h2>
                      <div class="ui-reports-trackers-shorts py-4 ml-auto">
                        <ng-container *ngIf="interface.reports._cache.category_selected?.slug == 'practice_drills'">
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.questionsAnswered}}q</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Questions Answered</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.questionsAnsweredCorrect}}q</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Questions Answered Correct</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.questionsAnsweredIncorrect}}q</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Questions Answered Incorrect</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.totalDurationSpent | timeFull}}</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Total Duration Spent</p>
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="interface.reports._cache.category_selected?.slug == 'practice_questions'">
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.questionsAnswered}}q</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Questions Answered</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.questionsAnsweredCorrect}}q</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Questions Answered Correct</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.questionsAnsweredIncorrect}}q</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Questions Answered Incorrect</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.totalDurationSpent | timeFull}}</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Total Duration Spent</p>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="interface.reports._cache.category_selected?.slug == 'video_lectures'">
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.openedVideo}}v</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Opened</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.viewsVideo}}v</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Views</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.timeVideo | timeFull}}</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Total time watched</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.avgTimeVideo}}%</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Avg. % watched</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.finishesVideo}}v</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Finishes</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>0</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Most views by device</p>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="interface.reports._cache.category_selected?.slug == 'worksheets'">
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.viewedWorksheets}}w</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Viewed</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{category.printedDownloadedWorksheets}}w</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Printed/Downloaded</p>
                          </div>
                          <div class="ui-reports-trackers-short">
                            <label>
                              <h4>{{0}}w</h4>
                              <span>/</span>
                              <small>{{interface.reports.period}}</small>
                            </label>
                            <p>Most Popular worksheets</p>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="ui-block-inner ui-block-inner-col ui-reports-subcategory">
                    <div class="ui-reports-subcategory-children">
                      <ng-container *ngFor="let child of category.children | sort:'asc':'order'; let a=index">
                        <div class="ui-reports-subcategory-child">
                          <div class="ui-reports-subcategory-child-header">
                            <div class="ui-reports-subcategory-child-header-title">
                              <div class="ui-reports-subcategory-child-header-title-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                  <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                              </div>
                              <div class="ui-reports-subcategory-child-header-title-text">
                                <h3>{{child.title}}</h3>
                                <p>Materials: {{child.totalMaterial}}</p>
                              </div>
                            </div>
                          </div>
                          <div class="ui-reports-subcategory-child-data">
                            <div class="ui-reports-total mt-2 !h-auto">
                              <div class="ui-reports-total-data">
                                <ng-container
                                  *ngIf="interface.reports._cache.category_selected?.slug == 'practice_drills'">
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Questions Answered</div>
                                    <div class="ui-reports-total-data-item-count">{{child.questionsAnswered}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-emerald-50 text-emerald-800">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Questions Answered Correct</div>
                                    <div
                                      class="ui-reports-total-data-item-count">{{child.questionsAnsweredCorrect}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-rose-100 text-rose-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Questions Answered Incorrect</div>
                                    <div
                                      class="ui-reports-total-data-item-count">{{child.questionsAnsweredIncorrect}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>

                                    </div>
                                    <div class="ui-reports-total-data-item-name">Total Duration Spent</div>
                                    <div
                                      class="ui-reports-total-data-item-count">{{child.totalDurationSpent | timeFull}}</div>
                                  </div>
                                </ng-container>
                                <ng-container
                                  *ngIf="interface.reports._cache.category_selected?.slug == 'practice_questions'">
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Questions Answered</div>
                                    <div class="ui-reports-total-data-item-count">{{child.questionsAnswered}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-emerald-50 text-emerald-800">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Questions Answered Correct</div>
                                    <div
                                      class="ui-reports-total-data-item-count">{{child.questionsAnsweredCorrect}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-rose-100 text-rose-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Questions Answered Incorrect</div>
                                    <div
                                      class="ui-reports-total-data-item-count">{{child.questionsAnsweredIncorrect}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>

                                    </div>
                                    <div class="ui-reports-total-data-item-name">Total Duration Spent</div>
                                    <div
                                      class="ui-reports-total-data-item-count">{{child.totalDurationSpent | timeFull}}</div>
                                  </div>
                                </ng-container>
                                <ng-container
                                  *ngIf="interface.reports._cache.category_selected?.slug == 'video_lectures'">
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round"
                                              d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Opened</div>
                                    <div class="ui-reports-total-data-item-count">{{child.openedVideo}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                      </svg>

                                    </div>
                                    <div class="ui-reports-total-data-item-name">Views</div>
                                    <div class="ui-reports-total-data-item-count">{{child.viewsVideo}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>

                                    </div>
                                    <div class="ui-reports-total-data-item-name">Total time watched</div>
                                    <div class="ui-reports-total-data-item-count">{{child.timeVideo | timeFull}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>

                                    </div>
                                    <div class="ui-reports-total-data-item-name">Avg. % watched</div>
                                    <div class="ui-reports-total-data-item-count">{{child.avgTimeVideo}}%</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round"
                                              d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Finishes</div>
                                    <div class="ui-reports-total-data-item-count">{{child.finishesVideo}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round"
                                              d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Most views by device</div>
                                    <div class="ui-reports-total-data-item-count">0</div>
                                  </div>
                                </ng-container>
                                <ng-container
                                  *ngIf="interface.reports._cache.category_selected?.slug == 'worksheets'">
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Views</div>
                                    <div class="ui-reports-total-data-item-count">{{child.viewedWorksheets}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Printed/Downloaded</div>
                                    <div
                                      class="ui-reports-total-data-item-count">{{child.printedDownloadedWorksheets}}</div>
                                  </div>
                                  <div class="ui-reports-total-data-item">
                                    <div class="ui-reports-total-data-item-icon bg-slate-100 text-slate-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                      </svg>
                                    </div>
                                    <div class="ui-reports-total-data-item-name">Most Popular worksheets</div>
                                    <div class="ui-reports-total-data-item-count">0</div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="ui-block-footer"></div>
                </div>
              </ng-container>
              <ui-no-data *ngIf="!interface.reports._cache.grade_report_practice_selected" type="loading"
                          text="Loading data" class="h-[453px] flex-grow"></ui-no-data>
            </div>
          </div>
        </div>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
      </div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin row-span-1 col-span-12 xl:col-span-3">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>Average Session Time</h2>
          <p>from all</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
      </div>
      <div class="ui-block-inner ui-block-inner-col my-4">
        <ng-container *ngIf="interface.loading">
          <ui-no-data type="loading"></ui-no-data>
        </ng-container>
        <ng-container *ngIf="!interface.loading">
          <div class="ui-school-stat-licenses">
            <div class="ui-school-stat-licenses-statuses">
              <div class="ui-school-stat-licenses-status ">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="ui-school-stat-licenses-status-name">Total Session Time</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count">{{interface.statistic.sessionDuration?.sessionUser?.duration | timeFull}}</div>
              </div>
              <div class="ui-school-stat-licenses-status ">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="ui-school-stat-licenses-status-name">Average Session Time</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count">{{interface.statistic.sessionDuration?.sessionUserAverage?.duration | timeFull}}</div>
              </div>
              <div class="ui-school-stat-licenses-status ">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="ui-school-stat-licenses-status-name">Video Lectures</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count">{{interface.statistic.sessionDuration?.sessionVideo?.duration | timeFull}}</div>
              </div>
              <div class="ui-school-stat-licenses-status ">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="ui-school-stat-licenses-status-name">Practice Questions</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count">{{interface.statistic.sessionDuration?.sessionPracticeQuestions?.duration | timeFull}}</div>
              </div>
              <div class="ui-school-stat-licenses-status ">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="ui-school-stat-licenses-status-name">Practice Drills</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count">{{interface.statistic.sessionDuration?.sessionPracticeDrills?.duration | timeFull}}</div>
              </div>
              <div class="ui-school-stat-licenses-status ">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="ui-school-stat-licenses-status-name">Worksheets</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count">{{interface.statistic.sessionDuration?.sessionWorksheet?.duration | timeFull}}</div>
              </div>
              <div class="ui-school-stat-licenses-status ">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="ui-school-stat-licenses-status-name">eBooks</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count">{{interface.statistic.sessionDuration?.sessionEBook?.duration | timeFull}}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
      </div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin row-span-1 col-span-12 xl:col-span-3">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>Books</h2>
          <p>ebook data from all</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
      </div>
      <div class="ui-block-inner ui-block-inner-col my-4">
        <ng-container *ngIf="interface.loading">
          <ui-no-data type="loading"></ui-no-data>
        </ng-container>
        <ng-container *ngIf="!interface.loading">
          <div class="ui-school-stat-licenses">
            <div class="ui-school-stat-licenses-statuses">
              <div class="ui-school-stat-licenses-status ">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                  </svg>

                </div>
                <div class="ui-school-stat-licenses-status-name">Opened</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count">{{interface.statistic.books.bookOpened.count}}</div>
              </div>
              <div class="ui-school-stat-licenses-status ">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>

                </div>
                <div class="ui-school-stat-licenses-status-name">Pages Viewed</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count">{{interface.statistic.books.bookPageView.count}}</div>
              </div>
              <div class="ui-school-stat-licenses-status ">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                  </svg>

                </div>
                <div class="ui-school-stat-licenses-status-name">Printed/Downloaded</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count">{{interface.statistic.books.bookPrinted.count}}</div>
              </div>
              <div class="ui-school-stat-licenses-status ">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                </div>
                <div class="ui-school-stat-licenses-status-name">Pages Assigned</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count">{{interface.statistic.books.bookPagesAssigned.count}}</div>
              </div>
              <div class="ui-school-stat-licenses-status ">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9" />
                  </svg>
                </div>
                <div class="ui-school-stat-licenses-status-name">Most Popular</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count">{{interface.statistic.books.bookMostPopular.count}}</div>
              </div>
              <div class="ui-school-stat-licenses-status ">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round"
                          d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                  </svg>
                </div>
                <div class="ui-school-stat-licenses-status-name">Video explanations time watched</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count">{{interface.statistic.books.videoExplanationsTime.count}}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
      </div>
    </div>
    <div class="ui-block ui-block-white ui-block-no-margin row-span-1 col-span-12 xl:col-span-3">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>Classes</h2>
          <p>Created</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
      </div>
      <div class="ui-block-inner ui-block-inner-col my-4">
        <ng-container *ngIf="interface.loading">
          <ui-no-data type="loading"></ui-no-data>
        </ng-container>
        <ng-container *ngIf="!interface.loading">
          <div class="ui-school-stat-licenses">
            <div class="ui-school-stat-licenses-total">
              <span>Total Classes</span>
              <p>{{interface.statistic?.classes?.totalClasses}}</p>
            </div>
            <div class="ui-school-stat-licenses-statuses">
              <div class="ui-school-stat-licenses-status ui-school-stat-licenses-status-active">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                  </svg>
                </div>
                <div class="ui-school-stat-licenses-status-name">Active classes</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count of active classes">{{interface.statistic?.classes?.active}}</div>
              </div>
              <div class="ui-school-stat-licenses-status ui-school-stat-licenses-status-active">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                  </svg>
                </div>
                <div class="ui-school-stat-licenses-status-name">Archived classes</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show count of archive classes">{{interface.statistic?.classes?.archive}}</div>
              </div>
              <div class="ui-school-stat-licenses-status ui-school-stat-licenses-status-deactive">
                <div class="ui-school-stat-licenses-status-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                  </svg>
                </div>
                <div class="ui-school-stat-licenses-status-name">Total Assignments</div>
                <div class="ui-school-stat-licenses-status-count ui-school-stat-licenses-status-count-active" tooltip
                     description="Show total numbers of assignments">{{interface.statistic?.classes?.totalAssignments}}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
      </div>
    </div>
  </div>
</div>

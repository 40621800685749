<div class="ui-result-quiz-tree">
  <ng-container *ngIf="interface.loading">
    <ui-no-data type="loading" text="Calculate result..."></ui-no-data>
  </ng-container>
  <ng-container *ngIf="!interface.loading">
    <div class="ui-result-quiz-trackers ml-auto">
      <div class="ui-result-quiz-tracker-shorts">
        <div class="ui-result-quiz-tracker-short">
          <label>
            <h3>{{interface.statistic.total}}</h3>
            <span>/</span>
            <small>questions</small>
          </label>
          <p>Total</p>
        </div>
        <div class="ui-result-quiz-tracker-short">
          <label>
            <h3>{{interface.statistic.answered}}</h3>
            <span>/</span>
            <small>questions</small>
          </label>
          <p>Answered</p>
        </div>
        <div class="ui-result-quiz-tracker-short">
          <label>
            <h3>{{interface.statistic.correct}}</h3>
            <span>/</span>
            <small>questions</small>
          </label>
          <p class="ui-result-quiz-tracker-short-correct">Correct</p>
        </div>
        <div class="ui-result-quiz-tracker-short">
          <label>
            <h3>{{interface.statistic.wrong}}</h3>
            <span>/</span>
            <small>questions</small>
          </label>
          <p  class="ui-result-quiz-tracker-short-wrong">Wrong</p>
        </div>
        <div class="ui-result-quiz-tracker-short">
          <label>
            <h3>{{interface.statistic.time | timeFull}}</h3>
            <span></span>
            <small></small>
          </label>
          <p>Time spent</p>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let question of interface.questions | sort:'asc':'order'; let s=index">
      <details class="ui-quiz-detail">
        <summary>
          <div class="ui-quiz-detail-info">
            <div class="ui-quiz-detail-icon" [class.ui-quiz-detail-icon-correct]="checkQuestionStatus(question)?.answered && checkQuestionStatus(question)?.correct" [class.ui-quiz-detail-icon-wrong]="checkQuestionStatus(question)?.answered && !checkQuestionStatus(question)?.correct">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
              </svg>
            </div>
            <div class="ui-quiz-detail-name">
              <p>Question: {{question.title}}</p>
              <span>{{question.options.complexity}}</span>
            </div>
            <div class="ui-quiz-detail-status"></div>
            <div class="ui-quiz-detail-action">
              <button class="ui-btn ui-btn-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 action-plus">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 action-minus">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                </svg>
              </button>
            </div>
          </div>
        </summary>
        <div class="ui-quiz-detail-node">
          <div class="ui-quiz-detail-node-thumbnail" *ngIf="question.thumbnail">
            <img src="{{question.thumbnail}}" loading="lazy" alt="{{question.title}}">
            <button class="ui-btn ui-btn-icon !hidden" tooltip description="Zoom image" (click)="actionToggleZoomThumbnail()">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6" />
              </svg>
            </button>
          </div>
          <div class="ui-quiz-detail-node-text">
            <div class="ui-quiz-detail-node-text-full" *ngIf="question.text.full">
              <ng-katex-html [html]="question.text.full | math | striphtml"></ng-katex-html>
            </div>
            <div class="ui-quiz-detail-node-text-except" *ngIf="question.text.except">
              <ng-katex-html [html]="question.text.except | math | striphtml"></ng-katex-html>
            </div>
          </div>
          <div class="ui-quiz-detail-node-answers">
            <div class="ui-quiz-detail-node-answers-title">
              <span>The answer given:</span>
            </div>
            <ng-container *ngIf="!checkQuestionStatus(question)?.answered">
              <div class="ui-quiz-detail-node-answer ui-quiz-detail-node-answer-no-choose">
                <div class="ui-quiz-detail-node-answer-letter">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                  </svg>
                </div>
                <div class="ui-quiz-detail-node-answer-text">
                  <input name="answer" type="text" readonly value="Question not answered" autofocus placeholder="Your answer">
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="checkQuestionStatus(question)?.answered">
              <ng-container *ngFor="let answer of checkQuestionStatus(question).answers; let a=index">
                <div class="ui-quiz-detail-node-answer" [class.ui-quiz-detail-node-answer-correct]="answer.isCorrect" [class.ui-quiz-detail-node-answer-wrong]="!answer.isCorrect">
                  <div class="ui-quiz-detail-node-answer-letter">{{answer.letter}}</div>
                  <div class="ui-quiz-detail-node-answer-text">
                    <ng-katex-html [html]="answer.text | math"></ng-katex-html>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div class="ui-quiz-detail-node-answers">
            <div class="ui-quiz-detail-node-answers-title">
              <span>Correct answer:</span>
            </div>
            <ng-container *ngFor="let answer of question.answers; let a=index">
              <ng-container *ngIf="question.options.answers_type == 'single'">
                <div class="ui-quiz-detail-node-answer" [class.ui-quiz-detail-node-answer-correct]="answer.isCorrect">
                  <div class="ui-quiz-detail-node-answer-letter">{{answer.letter}}</div>
                  <div class="ui-quiz-detail-node-answer-text">
                    <ng-katex-html [html]="answer.text | math"></ng-katex-html>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="question.options.answers_type == 'input'">
                  <div class="ui-quiz-detail-node-answer" [class.ui-quiz-detail-node-answer-correct]="answer.isCorrect">
                    <div class="ui-quiz-detail-node-answer-letter">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                      </svg>
                    </div>
                    <div class="ui-quiz-detail-node-answer-text">
                      <input name="answer" type="text" readonly [value]="answer.text" autofocus placeholder="Your answer">
                    </div>
                  </div>
              </ng-container>
              <ng-container *ngIf="question.options.answers_type == 'multiple'">
                <div class="ui-quiz-detail-node-answer" [class.ui-quiz-detail-node-answer-correct]="answer.isCorrect">
                  <div class="ui-quiz-detail-node-answer-letter">{{answer.letter}}</div>
                  <div class="ui-quiz-detail-node-answer-text">
                    <ng-katex-html [html]="answer.text | math"></ng-katex-html>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </details>
    </ng-container>
  </ng-container>
</div>

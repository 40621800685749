import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserState} from '../../_stores/user/_state/user.state';
import {Store} from '@ngxs/store';
import {AddUserToCurrentUsers} from '../../_stores/user/_actions/user.actions';
import {UiProfileAddModel} from '../../_models/ui-profile-add.model';
import {UserModel} from '../../_models/user.model';
import {GradeModel} from '../../_models/grade.model';
import {RoleModel} from '../../_models/role.model';
import {SchoolModel} from '../../_models/school.model';
import {API} from "../../_stores/api";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'ui-profile-add',
  templateUrl: './ui-profile-add.component.html',
  styleUrls: ['./ui-profile-add.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiProfileAddComponent implements OnInit {
  interface: UiProfileAddModel = {
    _cache: {
      ain: false,
      accepted: false,
      error_fields: [],
      open_search_school: false,
      selected_school: '',
      user_number: undefined,
    },
    loading: false,
    roles: [],
    schools: [],
    user: new (class implements UserModel {
      _id: string;
      avatar: string;
      language: string;
      displayName: string;
      email: string;
      firstName: string;
      lastName: string;
      password: string;
      practice: string | GradeModel;
      role: RoleModel;
      school: SchoolModel;
      ain: number
    })(),
  };
  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) {}

  ngOnInit(): void {
    this.initUser();
    this.initGlobalUser();
    this.initRoles();
    this.actionSearchSchool();
  }

  initUser(): void {
    this.interface.user = {
      _id: '',
      avatar: '',
      language: 'en',
      displayName: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      practice: '',
      role: undefined,
      school: undefined,
      ain: null
    };
  }

  initGlobalUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user.school = { ..._user.school };
      this.interface._cache.selected_school = this.interface.user.school.schoolName;
      this.interface.user.practice = _user.practice;
      this.interface.user.language = _user.language;
    });
  }
  initRoles(): void {
    this.interface.roles = [
      {
        _id: '62ebc2da8e33b7bc52c853c4',
        name: 'TEACHER',
      },
      {
        _id: '65e7e7a1b088d22453e7f59f',
        name: 'PREMIUM TEACHER',
      },
      {
        _id: '62ebbf45f009df0b43698376',
        name: 'PARENT',
      },
      {
        _id: '62ebc2e38e33b7bc52c853c6',
        name: 'STUDENT',
      },
    ];
    this.interface.user.role = this.interface.roles[2];
  }

  actionToggleSearchSchool(): void {
    if(this.interface._cache.ain) {
      return;
    }
    this.interface._cache.open_search_school = !this.interface._cache.open_search_school;
  }
  actionSearchSchool(event?): void {
    const _search = event? event.target.value: '';
    this.http.get<any[]>(API.url('schools/search'),  { params: { search: _search } }).subscribe({
        next: (_next) => {
          this.interface.schools = _next;
          this.cdr.detectChanges();
        },
      });
  }
  actionSelectSchool(school): void {
    this.interface._cache.open_search_school = false;
    this.interface._cache.selected_school = school.schoolName;
    this.interface.user.school = school._id;
    this.cdr.detectChanges();
  }
  actionEnterAIN(event): void {
    this.interface._cache.ain = event.target.value.length > 0;

    if(event.target.value.length >= 0 && event.target.value.length <= 7) {
      this.interface.user.ain = event.target.value;
      this.interface.user.avatar = '';
      this.interface.user.firstName = '';
      this.interface.user.lastName = '';
    } else {
      event.target.value = this.interface.user.ain;
    }

    if(event.target.value > 1000000 && event.target.value < 9999999) {
      this.actionCheckUserByAIN();
    }
  }

  actionSelectAvatar(index): void {
    if(this.interface._cache.ain) {
      return;
    }
    this.interface.user.avatar = 'https://cdn.argoprep.com/avatars/avatar-' + index + '.svg';
  }
  actionSelectRole(role): void {
    if(this.interface._cache.ain) {
      return;
    }
    this.interface.user.role = role;
  }
  actionEnterFirstName(event) {
    this.interface.user.firstName = event.target.value;
  }
  actionEnterLastName(event) {
    this.interface.user.lastName = event.target.value;
  }

  actionCheckUserByAIN():void {

  }
  actionAddProfile(): void {
    this.store.dispatch(new AddUserToCurrentUsers(this.interface.user));
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiFaqVideosComponent} from "./ui-faq-videos.component";
import {TranslocoModule} from "@ngneat/transloco";
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";


@NgModule({
  declarations: [UiFaqVideosComponent],
  exports: [UiFaqVideosComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    UiTooltipModule
  ]
})
export class UiFaqVideosModule { }

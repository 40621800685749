import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngxs/store';
import {LoadControlSchoolsLicense} from '../../_stores/control/_actions/control.actions';
import {ControlState} from '../../_stores/control/_state/control.state';
import {ModalOpenAction, ModalOpenActionWithID} from '../../_stores/modal/_actions/modal.actions';
import {UserModel} from '../../_models/user.model';
import {LicenseModel} from '../../_models/license.model';
import {Router} from '@angular/router';

export interface UiControlSchoolsLicensesModel {
  load: boolean;
  states: any[];
  sortIndex: string;
  data: { license: LicenseModel; users: UserModel[] }[];
  _cache: {
    open_choose_state: boolean;
    choose_state: string;
    data: { license: LicenseModel; users: UserModel[] }[];
  };
}

@Component({
  selector: 'ui-control-schools-licenses',
  templateUrl: './ui-control-schools-licenses.component.html',
  styleUrls: ['./ui-control-schools-licenses.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlSchoolsLicensesComponent implements OnInit {
  interface: UiControlSchoolsLicensesModel = {
    load: false,
    _cache: { choose_state: '', data: [], open_choose_state: false },
    states: [],
    data: [],
    sortIndex: '',
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private router: Router) {
    this.store.dispatch(new LoadControlSchoolsLicense());
  }

  ngOnInit(): void {
    this.initStates();
    this.initData();
  }

  initData(sortIndex?: string): void {
    this.store.select(ControlState.selectSchoolsLicense).subscribe((_data) => {
      if (sortIndex && sortIndex.length > 0) {
        if (sortIndex === 'asc') {
          this.interface.sortIndex = sortIndex;
          this.interface = {
            ...this.interface,
            data: [..._data].sort((a, b) => a.license.countUsers - b.license.countUsers),
          };
          this.cdr.detectChanges();
        }
        if (sortIndex === 'desc') {
          this.interface.sortIndex = sortIndex;
          this.interface = {
            ...this.interface,
            data: [..._data].sort((a, b) => b.license.countUsers - a.license.countUsers),
          };
          this.cdr.detectChanges();
        }
        this.interface._cache.data = [..._data];
        this.cdr.detectChanges();
      } else {
        this.interface.data = [..._data];
        this.interface._cache.data = [..._data];
      }
      this.interface.load = true;
      this.cdr.detectChanges();
    });
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(new ModalOpenAction('control_license_add'));
  }

  actionOpenEditDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_license_edit', _id));
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_license_delete', _id, false));
  }

  actionSearchLicense(event) {
    if (event.target.value && event.target.value.length > 0) {
      this.interface.data = this.interface.data.filter((item) => {
        return item.license.school.schoolName.toLowerCase().includes(event.target.value.toLowerCase());
      });
    } else {
      this.interface.data = this.interface._cache.data;
    }
  }

  getCountUsers(users: UserModel[]) {
    let argoprep_count = 0;
    let clever_count = 0;
    for (const user of users) {
      if (user.providerRegister && user.providerRegister.length > 0) {
        if (user.providerRegister.find((value) => value.name === 'argoprep' && value.active === true)) {
          argoprep_count++;
        }
        if (user.providerRegister.find((value) => value.name === 'clever' && value.active === true)) {
          clever_count++;
        }
      }
    }
    return { clever: clever_count, argoprep: argoprep_count };
  }

  actionSortByField(sortIndex: string) {
    this.interface.data = [...this.interface.data];
    if (sortIndex === 'asc') {
      this.initData(sortIndex);
    }
    if (sortIndex === 'desc') {
      this.initData(sortIndex);
    }
  }

  actionToggleChooseState() {
    this.interface._cache.open_choose_state = !this.interface._cache.open_choose_state;
  }

  actionSearchSchoolsByState(state?) {
    this.interface.data = this.interface._cache.data;
    if (state === 'No data') {
      this.interface.data = this.interface.data.filter((value) => !value.license.school.state);
      this.interface._cache.choose_state = 'No data';
      this.actionToggleChooseState();
    }
    if (state && state.state_code.length > 0) {
      this.interface._cache.choose_state = state.state_name;
      this.interface.data = this.interface.data.filter(
        (value) => value.license.school.state.toUpperCase() === state.state_code.toUpperCase(),
      );
      this.actionToggleChooseState();
    }
    if (!state) {
      this.interface._cache.choose_state = 'All states';
      this.interface.data = this.interface._cache.data;
      this.actionToggleChooseState();
    }

    this.cdr.detectChanges();
  }

  getStateName(state: string) {
    if (state && state.length > 0) {
      return this.interface.states.find(
        (value) =>
          value.state_code.toUpperCase() === state.toUpperCase() ||
          value.state_name.toUpperCase() === state.toUpperCase(),
      )?.state_name;
    }
    return 'No data';
  }

  goToDetail(partUrl: string) {
    let url = this.router.url;
    let indexOf = url.indexOf('licenses');
    let newUrl = url.substring(0, indexOf);
    return newUrl + partUrl;
  }

  private initStates() {
    this.interface.states = [
      { state_code: 'ID', state_name: 'Idaho' },
      { state_code: 'IA', state_name: 'Iowa' },
      { state_code: 'AL', state_name: 'Alabama' },
      { state_code: 'AK', state_name: 'Alaska' },
      { state_code: 'AZ', state_name: 'Arizona' },
      { state_code: 'AR', state_name: 'Arkansas' },
      { state_code: 'WY', state_name: 'Wyoming' },
      { state_code: 'WA', state_name: 'Washington' },
      { state_code: 'VT', state_name: 'Vermont' },
      { state_code: 'VA', state_name: 'Commonwealth of Virginia' },
      { state_code: 'WI', state_name: 'Wisconsin' },
      { state_code: 'HI', state_name: 'Hawaii' },
      { state_code: 'DE', state_name: 'Delaware' },
      { state_code: 'GA', state_name: 'Georgia' },
      { state_code: 'WV', state_name: 'West Virginia' },
      { state_code: 'IL', state_name: 'Illinois' },
      { state_code: 'IN', state_name: 'Indiana' },
      { state_code: 'CA', state_name: 'California' },
      { state_code: 'KS', state_name: 'Kansas' },
      { state_code: 'KY', state_name: 'Kentucky' },
      { state_code: 'CO', state_name: 'Colorado' },
      { state_code: 'CT', state_name: 'Connecticut' },
      { state_code: 'LA', state_name: 'Louisiana' },
      { state_code: 'MA', state_name: 'Massachusetts' },
      { state_code: 'MN', state_name: 'Minnesota' },
      { state_code: 'MS', state_name: 'Mississippi' },
      { state_code: 'MO', state_name: 'Missouri' },
      { state_code: 'MI', state_name: 'Michigan' },
      { state_code: 'MT', state_name: 'Montana' },
      { state_code: 'ME', state_name: 'Maine' },
      { state_code: 'MD', state_name: 'Maryland' },
      { state_code: 'NE', state_name: 'Nebraska' },
      { state_code: 'NV', state_name: 'Nevada' },
      { state_code: 'NH', state_name: 'New Hampshire' },
      { state_code: 'NJ', state_name: 'New Jersey' },
      { state_code: 'NY', state_name: 'New York' },
      { state_code: 'NM', state_name: 'New Mexico' },
      { state_code: 'OH', state_name: 'Ohio' },
      { state_code: 'OK', state_name: 'Oklahoma' },
      { state_code: 'OR', state_name: 'Oregon' },
      { state_code: 'PA', state_name: 'Pennsylvania' },
      { state_code: 'RI', state_name: 'Rhode Island and Providence Plantations' },
      { state_code: 'ND', state_name: 'North Dakota' },
      { state_code: 'NC', state_name: 'North Carolina' },
      { state_code: 'TN', state_name: 'Tennessee' },
      { state_code: 'TX', state_name: 'Texas' },
      { state_code: 'FL', state_name: 'Florida' },
      { state_code: 'SD', state_name: 'South Dakota' },
      { state_code: 'SC', state_name: 'South Carolina' },
      { state_code: 'UT', state_name: 'Utah' },
    ];
    this.interface._cache.choose_state = 'All states';
    this.cdr.detectChanges();
  }
}

import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  AssignmentsBuilderAddDrill,
  AssignmentsBuilderAddEBook,
  AssignmentsBuilderAddIBook,
  AssignmentsBuilderAddQuiz,
  AssignmentsBuilderAddVideo,
  AssignmentsBuilderAddWorksheet,
  AssignmentsBuilderClearMaterials,
  AssignmentsBuilderRemoveDrill,
  AssignmentsBuilderRemoveEBook,
  AssignmentsBuilderRemoveIBook,
  AssignmentsBuilderRemoveQuiz,
  AssignmentsBuilderRemoveVideo,
  AssignmentsBuilderRemoveWorksheet,
} from '../_actions/assignments-builder.actions';

export interface AssignmentsBuilderStateModel {
  materials: {
    quizzes: any;
    drills: any;
    videos: any;
    worksheets: any;
    i_books: any;
    e_books: any;
  };
}

@State<AssignmentsBuilderStateModel>({
  name: 'SAP_A_BUILDER',
  defaults: {
    materials: {
      quizzes: [],
      drills: [],
      videos: [],
      worksheets: [],
      i_books: [],
      e_books: [],
    },
  },
})
@Injectable()
export class AssignmentsBuilderState {
  constructor() {}

  @Selector()
  static selectBuilderState(state: AssignmentsBuilderStateModel) {
    return state;
  }

  @Selector()
  static selectBuilderMaterials(state: AssignmentsBuilderStateModel) {
    return state.materials;
  }

  @Selector()
  static selectBuilderWorksheets(state: AssignmentsBuilderStateModel) {
    return state.materials.worksheets;
  }

  @Selector()
  static selectBuilderVideos(state: AssignmentsBuilderStateModel) {
    return state.materials.videos;
  }

  @Selector()
  static selectBuilderQuizzes(state: AssignmentsBuilderStateModel) {
    return state.materials.quizzes;
  }

  @Selector()
  static selectBuilderDrills(state: AssignmentsBuilderStateModel) {
    return state.materials.drills;
  }

  @Selector()
  static selectBuilderIBooks(state: AssignmentsBuilderStateModel) {
    return state.materials.i_books;
  }

  @Selector()
  static selectBuilderEBooks(state: AssignmentsBuilderStateModel) {
    return state.materials.e_books;
  }

  @Action(AssignmentsBuilderClearMaterials)
  public AssignmentsBuilderClearMaterials(ctx: StateContext<AssignmentsBuilderStateModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      materials: {
        quizzes: [],
        drills: [],
        videos: [],
        worksheets: [],
        i_books: [],
        e_books: [],
      },
    });
  }

  @Action(AssignmentsBuilderAddWorksheet)
  public AssignmentsBuilderAddWorksheet(ctx: StateContext<AssignmentsBuilderStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      materials: {
        quizzes: state.materials.quizzes,
        drills: state.materials.drills,
        videos: state.materials.videos,
        worksheets: [...state.materials.worksheets, payload.worksheet],
        i_books: state.materials.i_books,
        e_books: state.materials.e_books,
      },
    });
  }

  @Action(AssignmentsBuilderRemoveWorksheet)
  public AssignmentsBuilderRemoveWorksheet(ctx: StateContext<AssignmentsBuilderStateModel>, { payload }: any) {
    const state = ctx.getState();
    const _worksheets = [...state.materials.worksheets];
    const worksheetIndex = _worksheets.findIndex((_ws) => _ws._id === payload.worksheet._id);
    _worksheets.splice(worksheetIndex, 1);

    ctx.patchState({
      ...state,
      materials: {
        quizzes: state.materials.quizzes,
        drills: state.materials.drills,
        videos: state.materials.videos,
        worksheets: _worksheets,
        i_books: state.materials.i_books,
        e_books: state.materials.e_books,
      },
    });
  }

  @Action(AssignmentsBuilderAddQuiz)
  public AssignmentsBuilderAddQuiz(ctx: StateContext<AssignmentsBuilderStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      materials: {
        quizzes: [...state.materials.quizzes, payload.quiz],
        drills: state.materials.drills,
        videos: state.materials.videos,
        worksheets: state.materials.worksheets,
        i_books: state.materials.i_books,
        e_books: state.materials.e_books,
      },
    });
  }

  @Action(AssignmentsBuilderRemoveQuiz)
  public AssignmentsBuilderRemoveQuiz(ctx: StateContext<AssignmentsBuilderStateModel>, { payload }: any) {
    const state = ctx.getState();
    const _quizzes = [...state.materials.quizzes];
    const _drills = [...state.materials.quizzes];
    const quizIndex = _quizzes.findIndex((_q) => _q._id === payload.quiz._id);
    if (quizIndex === -1) {
      const drillsIndex = _drills.findIndex((_q) => _q._id === payload.quiz._id);
      _drills.splice(drillsIndex, 1);
    } else {
      _quizzes.splice(quizIndex, 1);
    }
    _quizzes.splice(quizIndex, 1);

    ctx.patchState({
      ...state,
      materials: {
        quizzes: _quizzes,
        drills: _drills,
        videos: state.materials.videos,
        worksheets: state.materials.worksheets,
        i_books: state.materials.i_books,
        e_books: state.materials.e_books,
      },
    });
  }

  @Action(AssignmentsBuilderAddDrill)
  public AssignmentsBuilderAddDrill(ctx: StateContext<AssignmentsBuilderStateModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      materials: {
        quizzes: state.materials.quizzes,
        drills: [...state.materials.drills, payload.drill],
        videos: state.materials.videos,
        worksheets: state.materials.worksheets,
        i_books: state.materials.i_books,
        e_books: state.materials.e_books,
      },
    });
  }

  @Action(AssignmentsBuilderRemoveDrill)
  public AssignmentsBuilderRemoveDrill(ctx: StateContext<AssignmentsBuilderStateModel>, { payload }: any) {
    const state = ctx.getState();
    const _drills = [...state.materials.drills];
    const drillIndex = _drills.findIndex((_d) => _d._id === payload.drill._id);
    _drills.splice(drillIndex, 1);

    ctx.patchState({
      ...state,
      materials: {
        quizzes: state.materials.quizzes,
        drills: _drills,
        videos: state.materials.videos,
        worksheets: state.materials.worksheets,
        i_books: state.materials.i_books,
        e_books: state.materials.e_books,
      },
    });
  }

  @Action(AssignmentsBuilderAddVideo)
  public AssignmentsBuilderAddVideo(ctx: StateContext<AssignmentsBuilderStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      materials: {
        quizzes: state.materials.quizzes,
        drills: state.materials.drills,
        videos: [...state.materials.videos, payload.video],
        worksheets: state.materials.worksheets,
        i_books: state.materials.i_books,
        e_books: state.materials.e_books,
      },
    });
  }

  @Action(AssignmentsBuilderRemoveVideo)
  public AssignmentsBuilderRemoveVideo(ctx: StateContext<AssignmentsBuilderStateModel>, { payload }: any) {
    const state = ctx.getState();
    const _videos = [...state.materials.videos];
    const videoIndex = _videos.findIndex((_vi) => _vi._id === payload.video._id);
    _videos.splice(videoIndex, 1);

    ctx.patchState({
      ...state,
      materials: {
        quizzes: state.materials.quizzes,
        drills: state.materials.drills,
        videos: _videos,
        worksheets: state.materials.worksheets,
        i_books: state.materials.i_books,
        e_books: state.materials.e_books,
      },
    });
  }

  @Action(AssignmentsBuilderAddIBook)
  public AssignmentsBuilderAddIBook(ctx: StateContext<AssignmentsBuilderStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      materials: {
        quizzes: state.materials.quizzes,
        drills: state.materials.drills,
        videos: state.materials.videos,
        worksheets: state.materials.worksheets,
        i_books: [...state.materials.i_books, payload.i_book],
        e_books: state.materials.e_books,
      },
    });
  }

  @Action(AssignmentsBuilderRemoveIBook)
  public AssignmentsBuilderRemoveIBook(ctx: StateContext<AssignmentsBuilderStateModel>, { payload }: any) {
    const state = ctx.getState();
    const _ibooks = [...state.materials.i_books];

    ctx.patchState({
      ...state,
      materials: {
        quizzes: state.materials.quizzes,
        drills: state.materials.drills,
        videos: state.materials.videos,
        worksheets: state.materials.worksheets,
        i_books: _ibooks,
        e_books: state.materials.e_books,
      },
    });
  }

  @Action(AssignmentsBuilderAddEBook)
  public AssignmentsBuilderAddEBook(ctx: StateContext<AssignmentsBuilderStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      materials: {
        quizzes: state.materials.quizzes,
        drills: state.materials.drills,
        videos: state.materials.videos,
        worksheets: state.materials.worksheets,
        i_books: state.materials.i_books,
        e_books: [...state.materials.e_books, payload.e_book],
      },
    });
  }

  @Action(AssignmentsBuilderRemoveEBook)
  public AssignmentsBuilderRemoveEBook(ctx: StateContext<AssignmentsBuilderStateModel>, { payload }: any) {
    const state = ctx.getState();
    const _ebooks = [...state.materials.e_books];
    const ebooksIndex = _ebooks.findIndex((_eb) => _eb._id === payload.e_books._id);
    _ebooks.splice(ebooksIndex, 1);
    if (payload.e_books.pages.length > 0) {
      _ebooks.push(payload.e_books);
    }
    ctx.patchState({
      ...state,
      materials: {
        quizzes: state.materials.quizzes,
        drills: state.materials.drills,
        videos: state.materials.videos,
        worksheets: state.materials.worksheets,
        i_books: state.materials.i_books,
        e_books: _ebooks,
      },
    });
  }
}

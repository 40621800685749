<router-outlet (activate)="childContentLoad($event)" (deactivate)="childContentUnLoad($event)"></router-outlet>
<ng-container *ngIf="interface.loading">
  <div class="ui-block ui-block-white">
      <div class="ui-block-header">
        <div class="ui-block-header-filters">
          <div class="ui-block-header-filter">
            <div class="ui-tabs">
              <div class="ui-tab" [class.ui-tab-active]="interface.tab == 'active'" (click)="actionChangeActiveTab('active')">
                <svg class="ui-tab-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                </svg>
                <div class="ui-tab-name">Active</div>
              </div>
              <div class="ui-tab" [class.ui-tab-active]="interface.tab == 'archive'" (click)="actionChangeActiveTab('archive')">
                <svg class="ui-tab-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                </svg>
                <div class="ui-tab-name">Archived</div>
              </div>
            </div>
          </div>
          <div class="ui-block-header-filter ml-auto">
            <div class="ui-form-input ui-form-input-xs min-w-[14rem]">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              <input name="ain" type="text" [value]="" placeholder="Search class" (keyup)="actionSearchClass($event)">
            </div>
          </div>
          <div class="ui-block-header-filter">
            <button class="ui-btn ui-btn-pap" (click)="actionOpenAddClassModal()">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              <span>Add new class</span>
            </button>
          </div>

        </div>
      </div>
      <div class="ui-block-inner ui-block-inner-col my-4">
        <div class="ui-classes">
          <ng-container *ngIf="interface.classes.data.length < 1">
            <ng-container  [ngTemplateOutlet]="CLASSES_EMPTY"></ng-container>
          </ng-container>
          <ng-container *ngIf="interface.classes.data.length > 0">
            <div class="ui-classes-list" [class.ui-classes-list-order]="!dashboard" [class.ui-classes-list-archive-empty]="interface.classes.current.length == 0 && interface.tab == 'archive'">
              <ng-container  [ngTemplateOutlet]="CLASSES_LIST"></ng-container>
              <ng-container *ngIf="interface.classes.current.length == 0 && interface.tab == 'archive'">
                <ui-no-data text="When you archive a class, it will appear here."></ui-no-data>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="ui-block-footer"></div>
  </div>
  <ng-template #CLASSES_EMPTY>
    <div class="ui-classes-empty">
      <svg class="ui-classes-empty-image" viewBox="0 0 145 97" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path
            d="M67.1417 41.9944C81.2237 39.1042 75.2974 44.0351 87.9666 43.9576C108.85 43.8299 97.5419 48.4368 112.978 60.9302C120.691 67.1723 149.26 66.8194 144.314 74.941C139.367 83.0626 113.265 87.8232 88.479 91.9726C59.8491 96.7656 -17.5791 100.422 5.18016 84.1951C12.8395 78.7341 19.7337 80.3002 24.7011 74.1344C31.501 65.6942 12.7342 68.8733 20.6972 60.4207C32.3011 48.1032 56.7866 44.1197 67.1417 41.9944Z"
            fill="#BAC2FB" />
          <path
            d="M115.547 57.0828C115.367 60.1682 108.754 64.5162 105.47 66.3045C106.599 58.3 105.734 49.5671 102.854 40.0542C107.16 44.4448 115.726 53.9975 115.547 57.0828Z"
            fill="#EC9823" />
          <path
            d="M104.404 18.3685C105.977 21.4202 102.486 26.7723 100.544 29.0669C97.0576 21.04 90.264 14.2424 82.3318 5.40098C88.6642 8.98955 102.83 15.3168 104.404 18.3685Z"
            fill="#EC9823" />
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M110.986 76.8752C113.33 79.7256 116.508 83.5883 118.174 86.3535L112.096 87.6701C111.664 83.7403 110.675 79.5537 109.49 75.047C109.921 75.5808 110.43 76.1996 110.986 76.8752Z"
                fill="#EC9823" />
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M22.6942 51.8439C-2.05383 38.797 6.47587 18.421 25.7617 8.51943C49.7806 -3.81216 80.3989 -2.22096 92.7384 14.0939C97.4423 20.3133 100.429 28.6488 102.799 38.3228C104.439 45.0161 105.785 52.3507 107.201 60.068C108.812 68.8499 110.514 78.128 112.845 87.5227C105.13 89.1413 96.7316 90.5907 88.4789 91.9723C75.2455 94.1877 51.5863 96.1602 32.2143 96.0387C38.3679 81.2094 42.2291 65.5973 27.5569 54.8561C26.119 53.8035 24.5032 52.7977 22.6942 51.8439Z"
                fill="#FFD84C" />
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M112.845 87.5227C110.514 78.128 108.812 68.8499 107.201 60.068C105.785 52.3507 104.439 45.0161 102.799 38.3228C102.804 38.3228 102.808 38.3227 102.812 38.3227"
                fill="#FFD84C" />
          <ellipse rx="7.15219" ry="9.22071" transform="matrix(-0.850146 -0.526547 -0.471957 0.881621 73.5019 31.7988)" fill="#7B592A" />
          <ellipse rx="3.78146" ry="5.98731" transform="matrix(-0.71766 -0.696394 -0.696394 0.71766 72.0547 27.7592)" fill="white" />
          <circle r="2.52097" transform="matrix(-0.71766 -0.696394 -0.696394 0.71766 70.612 35.581)" fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M81.2195 93.031C73.4427 55.6279 54.2698 52.5479 32.0079 52.327C30.3053 53.1923 28.6643 54.1283 27.1147 55.142C39.2926 65.7573 35.6613 77.059 31.3602 85.1879C29.7325 88.264 28.026 91.8603 26.6376 95.9403C43.8272 96.4558 66.0288 94.9817 81.2195 93.031Z"
                fill="#EC9823" />
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M26.6376 95.9403C43.8272 96.4558 66.0288 94.9817 81.2195 93.031C73.4427 55.6279 54.2698 52.5479 32.0079 52.327C29.9879 52.3069 27.9425 52.3104 25.8778 52.314C25.0754 52.3153 24.2702 52.3167 23.4623 52.3167C24.8131 53.2511 26.0269 54.1938 27.1147 55.142C39.2926 65.7573 35.6613 77.059 31.3602 85.1879C29.7325 88.264 28.026 91.8603 26.6376 95.9403Z"
                fill="#EC9823" />
          <path d="M24.8249 52.3103C41.4009 54.1023 57.978 55.4465 68.2824 64.4067C59.322 50.5183 36.6518 52.3103 24.8249 52.3103Z" fill="#B47012" />
          <path
            d="M58.1263 61.1142C59.5011 61.378 63.7547 61.412 65.5643 61.0593C65.5643 61.0593 62.8762 58.1024 57.9481 55.8623C57.2827 58.276 57.6294 61.0189 58.1263 61.1142Z"
            fill="white" />
          <path
            d="M50.1854 59.6671C51.1435 60.2282 55.1356 57.0576 57.9481 55.8623C57.0125 55.6271 53.4679 54.0703 50.3318 53.6223C48.1143 53.3055 49.6837 59.3733 50.1854 59.6671Z"
            fill="white" />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="145" height="97" fill="white" transform="matrix(1 0 0 -1 0 97)" />
          </clipPath>
        </defs>
      </svg>
      <span class="ui-classes-empty-title">You don't have any classes created yet</span>
      <span class="ui-classes-empty-text">Click the button below to create your first class!</span>
      <button class="ui-btn ui-classes-empty-btn" (click)="actionOpenAddClassModal()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
        Add new class
      </button>
    </div>
  </ng-template>
  <ng-template #CLASSES_LIST>
    <ng-container *ngFor="let school_class of interface.classes.current | sort:'asc':'title' ; let cl=index">
     <ui-class [class]="school_class"></ui-class>
    </ng-container>
  </ng-template>
</ng-container>

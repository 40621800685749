import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiControlUserComponent} from "./ui-control-user.component";
import {RouterModule} from "@angular/router";
import {UiAppNoDataModule} from "../../_ui/ui-app-no-data/ui-app-no-data.module";
import {PipesModule} from "../../app.pipes.module";
import {NgApexchartsModule} from "ng-apexcharts";


@NgModule({
  declarations: [UiControlUserComponent],
  exports: [UiControlUserComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiControlUserComponent,
        children: []
      }
    ]),
    UiAppNoDataModule,
    PipesModule,
    NgApexchartsModule
  ]
})
export class UiControlUserModule { }

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {AssignmentModel} from '../../_models/assignment.model';
import {HttpClient} from '@angular/common/http';
import {AssignmentsState} from '../../_stores/asignments/_state/assignments.state';
import {GetHomeworks} from '../../_stores/asignments/_actions/asignments.actions';

@Component({
  selector: 'ui-page-homework-details',
  templateUrl: './ui-page-homework-details.component.html',
  styleUrls: ['./ui-page-homework-details.component.scss'],
})
export class UiPageHomeworkComponentDetails implements OnInit {
  interface: {
    homework: AssignmentModel;
    _cache: {
      selected_material: any;
    };
  } = {
    homework: undefined,
    _cache: {
      selected_material: undefined,
    },
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient, private router: Router) {
    this.store.dispatch(new GetHomeworks());
  }

  ngOnInit(): void {
    this.initHomework();
  }

  initHomework(): void {
    this.store.select(AssignmentsState.selectHomeworkByRouteId).subscribe((_homework) => {
      if (_homework) {
        this.interface.homework = { ..._homework };
        this.normalizeMaterials();
        this.cdr.detectChanges();
      }
    });
  }

  normalizeMaterials(): void {
    this.interface.homework.materials = this.interface.homework.materials.reduce((materials, material) => {
      let _material: any = { ...material };
      _material._id = _material.id;
      materials.push(_material);
      return materials;
    }, []);
  }

  getTotalProgress(): number {
    let _progress = 0;
    let _done_materials = this.interface.homework.materials.filter((_material) => _material.isDone).length;
    let _all_materials = this.interface.homework.materials.length;
    _progress = Math.floor((_done_materials / _all_materials) * 100);
    return _progress;
  }

  actionOpenQuiz(material): void {
    this.router.navigate(['homework/quiz/' + material._id + '/' + this.interface.homework._id]).then();
  }

  actionOpenVideo(material): void {
    this.router.navigate(['homework/video/' + material._id + '/' + this.interface.homework._id]).then();
  }

  actionOpenWorksheet(material): void {
    this.router.navigate(['homework/worksheet/' + material._id + '/' + this.interface.homework._id]).then();
  }

  actionOpenBook(material): void {
    this.router.navigate(['homework/book/' + material._id + '/' + this.interface.homework._id]).then();
  }

  isAvailable(homework: AssignmentModel) {
    let now = new Date();
    let _scheduleDate = new Date(homework.dates.scheduleDate);
    return _scheduleDate > now;
  }

  isScheduled() {
    let now = new Date();
    let _scheduleDate = new Date(this.interface?.homework?.dates?.scheduleDate);
    return (
      this.interface?.homework?.options?.isActive &&
      this.interface?.homework?.dates?.scheduleDate !== null &&
      _scheduleDate > now
    );
  }

  isCompleted() {
    return this.interface.homework.options.isActive && this.interface.homework.options.isDone;
  }
}

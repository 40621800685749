import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ui-no-data',
  templateUrl: './ui-app-no-data.component.html',
  styleUrls: ['./ui-app-no-data.component.scss'],
})
export class UiAppNoDataComponent implements OnInit {
  @Input() text: string;
  @Input() type: any;

  constructor() {}

  ngOnInit(): void {}
}

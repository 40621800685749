import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {FaqVideoModel} from "../../../_models/faq.model";
import {InitFaqVideos} from "../_actions/faq.actions";
import {HttpClient} from "@angular/common/http";
import {UserState, UserStateModel} from "../../user/_state/user.state";


export class FaqStateModel {
  videos: FaqVideoModel[];
}


@State<FaqStateModel>({
  name: 'SAP_FAQ',
  defaults: {
    videos: [],
  },
})
@Injectable()
export class FaqState
  implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap
{
  constructor(private store: Store, private http: HttpClient) {}

  @Selector()
  static selectFaqVideos(state: FaqStateModel) {
    return state.videos;
  }

  @Selector([UserState])
  static selectCurrentUserFaqVideos(state: FaqStateModel, userState: UserStateModel) {
    return state.videos.filter((video) => {return video.roles.includes(userState.user.role._id) && video.language == userState.user.language;});
  }


  ngxsAfterBootstrap(ctx?: StateContext<FaqStateModel>): void {
    this.store.dispatch(new InitFaqVideos());
  }

  ngxsOnInit(ctx?: StateContext<FaqStateModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange<FaqStateModel>): void {}

  @Action(InitFaqVideos)
  initFaqVideos(ctx: StateContext<FaqStateModel>) {
    this.http.get('./assets/json/faq_videos.json').subscribe((res: any)=>{
      const state = ctx.getState();
      ctx.patchState({
        ...state,
        videos: res,
      });
    });

  }
}

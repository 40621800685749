<div class="ui-block ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h3>List of students</h3>
      <p>You have: <strong>{{interface.students ? interface.students.length : 0 }}</strong> students</p>
    </div>
    <div class="ui-block-header-action">
      <div class="ui-block-header-filter">
        <div class="ui-block-header-filter-container ui-form-input ui-form-input-xs">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
          <input name="ain" type="text" [value]="" placeholder="Search Students"
                 (keyup)="actionSearchStudents($event)">
        </div>
      </div>
      <div class="ui-students-filter-select">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
        </svg>
        <input name="class" type="text" readonly [value]="interface._cache.choose_class" placeholder="Choose class"
               (click)="actionToggleChooseClass()">
        <div class="ui-students-filter-select-list"
             [class.ui-students-filter-select-list-open]="interface._cache.open_choose_classes">
          <div class="ui-students-filter-select-list-items">
            <ng-container *ngFor="let _class of interface.classes">
              <div class="ui-students-filter-select-list-item" (click)="actionFilteredStudentsByClass(_class)">
                <p>{{_class}}</p>
              </div>
            </ng-container>
          </div>
        </div>
        <svg [ngStyle]="{'visibility':interface._cache.choose_class === 'All classes' ? 'hidden' : 'visible'}"
             xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-auto stroke-rose-500 invisible" fill="none"
             viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5"
             [class.clear-select-show]="interface._cache.choose_class" (click)="actionClearChooseClass()">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5" (click)="actionToggleChooseClass()">
          <path *ngIf="!interface._cache.open_choose_classes" stroke-linecap="round" stroke-linejoin="round"
                d="M19 9l-7 7-7-7" />
          <path *ngIf="interface._cache.open_choose_classes" stroke-linecap="round" stroke-linejoin="round"
                d="M5 15l7-7 7 7" />
        </svg>
      </div>
      <button class="ui-btn min-w-fit w-full" tooltip description="Add Student" (click)="actionStudentAdd()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
        </svg>
        Add Student
      </button>
      <button class="ui-btn min-w-fit w-full" tooltip description="Import student from Clever"
              (click)="actionCleverStudentImport()" disabled>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24">
          <rect width="24" height="24" rx="4" fill="#425AF6" />
          <path
            d="M12.9614 20C8.39303 20 5 16.486 5 12.0442V11.9999C5 7.60207 8.32658 4 13.0946 4C16.0219 4 17.7738 4.97241 19.2152 6.38668L17.0419 8.88402C15.8444 7.80118 14.6247 7.13821 13.0724 7.13821C10.4555 7.13821 8.57042 9.30389 8.57042 11.9557V11.9999C8.57042 14.6518 10.4111 16.8618 13.0724 16.8618C14.8465 16.8618 15.9331 16.1547 17.1529 15.0498L19.3262 17.2374C17.7294 18.939 15.9553 20 12.9614 20Z"
            fill="white" />
        </svg>
        Import from Clever
      </button>
    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col mt-4">
    <ng-container *ngIf="interface.loading">
      <ui-no-data text="Loading list students..."></ui-no-data>
    </ng-container>
    <ng-container *ngIf="!interface.loading">
      <ng-container *ngIf="interface.students.length == 0" [ngTemplateOutlet]="STUDENTS_EMPTY"></ng-container>
      <ng-container *ngIf="interface.students.length > 0" [ngTemplateOutlet]="STUDENTS_LIST"></ng-container>
      <ng-container *ngIf="false" [ngTemplateOutlet]="DATA_TABLE_PAGINATION"></ng-container>
    </ng-container>
  </div>
  <div class="ui-block-footer"></div>
</div>
<ng-template #STUDENTS_LIST>
  <div class="flex w-full overflow-auto rounded-lg">
    <table class="ui-table">
      <thead>
      <tr>
        <th scope="col">
          <div class="ui-form-checkbox"><input type="checkbox"></div>
        </th>
        <th scope="col">Name</th>
        <th scope="col">Class</th>
        <th scope="col">Schools</th>
        <th scope="col">Grade</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let student of interface.students | sort:'asc':'displayName'">
        <tr>
          <td>
            <div class="ui-form-checkbox"><input type="checkbox"></div>
          </td>
          <td>
            <div class="flex items-center">
              <div class="h-10 w-10 flex-shrink-0">
                <img class="h-10 w-10 rounded" src="{{student.avatar}}" alt="">
              </div>
              <div class="ml-4">
                <a class="font-medium text-slate-900" [href]="'/reports/students/'+student._id"
                   target="_blank">{{student.firstName}} {{student.lastName}}</a>
                <div class="text-xs text-slate-400">AIN:<span
                  class="ml-1 text-pap-300 font-medium">{{student.ain}}</span></div>
              </div>
            </div>
          </td>
          <td>
            <div class="flex flex-col text-xs">
              <ng-container *ngFor="let _class of student.classes | sort:'asc':'order'">
                <span>{{_class.title}}</span>
              </ng-container>
            </div>
          </td>
          <td>
            <div class="flex items-center text-xs">
              {{student.school?.schoolName}}
            </div>
          </td>
          <td>
            <div class="flex items-center text-xs">
              {{student.practice?.title}}
            </div>
          </td>
          <td>
            <button tooltip title="Edit" class="ui-btn ui-btn-xs ui-btn-icon" (click)="actionStudentEdit(student._id)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
              </svg>
            </button>
            <button tooltip title="Delete" class="ui-btn ui-btn-xs ui-btn-icon ui-btn-error"
                    (click)="actionStudentDelete(student._id)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</ng-template>
<ng-template #STUDENTS_EMPTY>
  <ui-no-data type="dino-computer" text="You have no students..."></ui-no-data>
</ng-template>
<ng-template #DATA_TABLE_PAGINATION>
  <div class="ui-table-pagination">
    <button class="ui-btn ui-btn-icon ui-btn-sm" (click)="actionFirstPage()" [disabled]="interface.currentPage == 1">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
      </svg>
    </button>
    <button class="ui-btn ui-btn-icon ui-btn-sm" (click)="actionPrevPage()" [disabled]="interface.currentPage == 1">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
      </svg>
    </button>
    <ng-container *ngFor="let item of [].constructor(interface.totalPage); let i = index">
      <button class="ui-btn ui-btn-icon ui-btn-sm" [class.ui-btn-pagination-active]="i == interface.currentPage-1"
              (click)="actionLoadPage(i+1)">
        {{i + 1}}
      </button>
    </ng-container>
    <button class="ui-btn ui-btn-icon ui-btn-sm" (click)="actionNextPage()"
            [disabled]="interface.currentPage == interface.totalPage">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
      </svg>
    </button>
    <button class="ui-btn ui-btn-icon ui-btn-sm" (click)="actionLastPage()"
            [disabled]="interface.currentPage == interface.totalPage">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
      </svg>
    </button>
  </div>
</ng-template>

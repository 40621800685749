import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiControlUserAddComponent} from "./ui-control-user-add.component";
import {AppTranslocoModule} from "../../app.transloco.module";
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";
import {PipesModule} from "../../app.pipes.module";


@NgModule({
  declarations: [UiControlUserAddComponent],
  exports: [UiControlUserAddComponent],
  imports: [
    CommonModule,
    AppTranslocoModule,
    UiTooltipModule,
    PipesModule
  ]
})
export class UiControlUserAddModule { }

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";

@Component({
  selector: 'ui-control-statistic',
  templateUrl: './ui-control-statistic.component.html',
  styleUrls: ['./ui-control-statistic.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiControlStatisticComponent implements OnInit {
  interface: any = {}

  constructor(private store: Store, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

}

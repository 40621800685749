import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiControlUsersComponent} from './ui-control-users.component';
import {RouterModule} from '@angular/router';
import {UiAppNoDataModule} from '../../_ui/ui-app-no-data/ui-app-no-data.module';
import {UiTooltipModule} from '../../_ui/ui-tooltip/ui-tooltip.module';
import {PipesModule} from '../../app.pipes.module';
import {AppTranslocoModule} from '../../app.transloco.module';
import {UiPaginationModule} from '../../_ui/ui-pagination/ui-pagination.module';
import {UiDatePickerModule} from "../../_ui/ui-date-picker/ui-date-picker.module";

@NgModule({
  declarations: [UiControlUsersComponent],
  exports: [UiControlUsersComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiControlUsersComponent,
        children: []
      },
      {
        path: ":role/:school",
        component: UiControlUsersComponent
      },
      {
        path: ":role",
        component: UiControlUsersComponent
      }
    ]),
    UiAppNoDataModule,
    UiTooltipModule,
    PipesModule,
    AppTranslocoModule,
    UiPaginationModule,
    UiDatePickerModule
  ]
})
export class UiControlUsersModule {}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiControlGradeEditComponent} from './ui-control-grade-edit.component';
import {TranslocoModule} from '@ngneat/transloco';

@NgModule({
  declarations: [UiControlGradeEditComponent],
  exports: [UiControlGradeEditComponent],
  imports: [CommonModule, TranslocoModule],
})
export class UiControlGradeEditModule {}

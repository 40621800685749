import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiControlRolesComponent} from "./ui-control-roles.component";
import {RouterModule} from "@angular/router";
import {UiAppNoDataModule} from "../../_ui/ui-app-no-data/ui-app-no-data.module";
import {UiTooltipModule} from "../../_ui/ui-tooltip/ui-tooltip.module";
import {PipesModule} from "../../app.pipes.module";
import {AppTranslocoModule} from "../../app.transloco.module";


@NgModule({
  declarations: [UiControlRolesComponent],
  exports: [UiControlRolesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: UiControlRolesComponent,
        children: [],
      }
    ]),
    UiAppNoDataModule,
    UiTooltipModule,
    PipesModule,
    AppTranslocoModule,
  ]
})
export class UiControlRolesModule { }

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiGradesComponent} from "./ui-grades.component";
import {PipesModule} from "../../app.pipes.module";


@NgModule({
  declarations: [UiGradesComponent],
  exports: [UiGradesComponent],
  imports: [
    CommonModule,
    PipesModule
  ]
})
export class UiGradesModule { }

<div class="ui-block ui-block-white">
    <div class="ui-block-header">
        <div class="ui-block-header-title">
               <h2>{{interface.video?.title}}</h2>
               <p>&nbsp;</p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile">
            <button class="ui-btn ui-btn-sm ui-btn-blue-outline" (click)="actionExitHomework()">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
              </svg>
              <span>Exit homework</span>
            </button>
        </div>
    </div>
    <div class="ui-block-inner ui-block-inner-col my-4">
      <ng-container *ngIf="!interface.video">
        <ui-no-data></ui-no-data>
      </ng-container>
      <ng-container *ngIf="interface.video">
        <div class="ui-video">
          <ui-videoplayer [src]="interface.video.options.url" [thumbnail]="interface.video.thumbnail" [homework]="true" (play)="actionVideoIsPlay($event)" (time)="actionVideoTime($event)"></ui-videoplayer>
        </div>
      </ng-container>
    </div>
    <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
          <button class="ui-btn ui-btn-sm ui-btn-blue-outline" (click)="actionExitHomework()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
            </svg>
            <span>Exit homework</span>
          </button>
        </div>
    </div>
</div>

<div class="ui-page-header">
  <div class="ui-page-menu-action" *ngIf="!interface.app?.sidebar_show">
    <button (click)="actionToggleSidebar()" tooltip description="Show sidebar">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
        <path d="M4 6H20M4 12H12M4 18H20"  stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.5 14L20 11.5L17.5 9" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>
  <div class="ui-page-header-items">
    <ng-container *ngIf="interface.user?.role?._id == '6301e162029cc9403c26de0c'">
      <div class="ui-page-header-item">
        <div class="ui-page-header-item-icon ui-page-header-item-icon-success">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
          </svg>
        </div>
        <div class="ui-page-header-item-data">
          <p>Backend Server</p>
          <span>Working</span>
        </div>
      </div>
      <div class="ui-page-header-item">
        <div class="ui-page-header-item-icon ui-page-header-item-icon-success">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
          </svg>
        </div>
        <div class="ui-page-header-item-data">
          <p>Database Server</p>
          <span>Online</span>
        </div>
      </div>
      <div class="ui-page-header-item ui-page-header-item-reverse ml-auto">
        <div class="ui-page-header-item-icon ui-page-header-item-icon-white">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
          </svg>
        </div>
        <div class="ui-page-header-item-data">
          <p>Users Online</p>
          <span>{{interface.app?.users_online}}</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="interface.user?.role?._id != '6301e162029cc9403c26de0c'">
      <div class="ui-page-header-item">
        <div class="ui-page-header-item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
          </svg>
        </div>
        <div class="ui-page-header-item-data">
          <p>{{interface.user?.school?.schoolName}}</p>
          <span>{{interface.user?.school?.address}}</span>
        </div>
      </div>
      <div class="ui-page-header-item">
        <div class="ui-page-header-item-icon" [class.ui-page-header-item-icon-error]="!interface.user?.school?.license?.active" [class.ui-page-header-item-icon-success]="interface.user?.school?.license?.active">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
          </svg>
        </div>
        <div class="ui-page-header-item-data">
          <p>License</p>
          <span>
          <ng-container *ngIf="interface.user?.school?.license?.active">
            Active license
          </ng-container>
          <ng-container *ngIf="!interface.user?.school?.license?.active">
            Not licensed
          </ng-container>

        </span>
        </div>
      </div>

      <div class="ui-page-header-item ui-page-header-item-reverse ml-auto invisible">
        <div class="ui-page-header-item-icon ui-page-header-item-icon-invite" tooltip description="Copy parent invitation link" (click)="actionClipboardInviteLink()">
          <svg *ngIf="!interface._cache.copied" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
          </svg>
          <svg *ngIf="interface._cache.copied" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
        </div>
        <div class="ui-page-header-item-data">
          <p>Invite to school</p>
          <span>Invite parents to use ArgoPrep for free</span>
        </div>
      </div>
      <div *ngIf="interface.user?.role._id != '65e7e7a1b088d22453e7f59f'" class="ui-page-header-item ui-page-header-item-full"  tooltip title="Upgrade" description="Upgrade your access level and grant full access to all functions" (click)="actionOpenUpgradeModal()">
        <div class="ui-page-header-item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
          </svg>
        </div>
        <div class="ui-page-header-item-data">
          <p>Upgrade</p>
        </div>
      </div>
      <div class="ui-page-header-item">
        <div class="ui-page-header-item-icon ui-page-header-item-icon-support" tooltip title="Support" description="If you have any questions or issues please email us at info@argoprep.com" position="left">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </div>
        <div class="ui-page-header-item-data"></div>
      </div>
    </ng-container>
  </div>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiControlLicenseDeleteComponent} from "./ui-control-license-delete.component";


@NgModule({
  declarations: [UiControlLicenseDeleteComponent],
  exports: [UiControlLicenseDeleteComponent],
  imports: [
    CommonModule
  ]
})
export class UiControlLicenseDeleteModule { }

 <div class="ui-datepicker-input">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" (click)="showDatepicker = !showDatepicker">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
    </svg>
    <input id="ui-i-datepicker" type="text" readonly [value]="datepickerValue | date:'dd-MM-yyyy'" placeholder="Select date" (keydown.escape)="showDatepicker = false" (click)="showDatepicker = !showDatepicker"/>
  </div>
 <div class="ui-datepicker ui-datepicker-{{position}}" [hidden]="!showDatepicker" (keydown.away)="showDatepicker = false">
    <div class="ui-datepicker-header">
      <div>
        <div class="ui-datepicker-header-month">{{'datepicker.month.'+ displayMonth | transloco}}</div>
        <div class="ui-datepicker-header-year">{{ displayYear }}</div>
      </div>
      <div>
        <button type="button" (click)="month = month - 1; getNoOfDays()" aria-label="Prev">
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
          </svg>
        </button>
        <button type="button" (click)="month = month + 1; getNoOfDays()" aria-label="Next">
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
          </svg>
        </button>
      </div>
    </div>
    <div class="ui-datepicker-info">
      <ng-container *ngFor="let day of [0,1,2,3,4,5,6]; let index = index">
        <div class="ui-datepicker-info-day" [class.ui-datepicker-info-day-utc]="checkOptionOfCalendar()">{{'datepicker.days.'+ day | transloco}}</div>
      </ng-container>
    </div>
    <div class="ui-datepicker-days">
      <ng-container *ngFor="let day of blankdays">
        <div class="ui-datepicker-days-day ui-datepicker-days-day-blank"></div>
      </ng-container>
      <ng-container
        *ngFor="let date of no_of_days; let dateIndex = index; trackBy: trackByIdentity">
        <div class="ui-datepicker-days-day" (click)="getDateValue(date)" [class.ui-datepicker-days-day-select]="isToday(date)"  [class.ui-datepicker-days-day-now]="isSelectDate(date) && !isToday(date)">
          {{date}}
        </div>
      </ng-container>
    </div>
  </div>

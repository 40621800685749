<router-outlet (activate)="childContentLoad($event)" (deactivate)="childContentUnLoad($event)"></router-outlet>
<ng-container *ngIf="interface.loading">
  <div class="ui-block ui-block-white">
    <div class="ui-block-header">
      <div class="ui-block-header-filters">
        <div class="ui-block-header-filter">
          <div class="ui-tabs">
            <ng-container *ngFor="let tab of interface.tabs; let t=index">
              <div class="ui-tab" [class.ui-tab-active]="interface._cache.tab.title == tab.title" (click)="actionChangeActiveTab(tab)">
                <svg *ngIf="tab.title == 'active'" class="ui-tab-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z" />
                </svg>
                <svg *ngIf="tab.title == 'archive'" class="ui-tab-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                </svg>
                <div class="ui-tab-name">{{tab.title}}</div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="ui-block-header-filter ml-auto">
          <div class="ui-form-input ui-form-input-xs min-w-[14rem]">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
            <input name="ain" type="text" [value]="" placeholder="Search assignments" (keyup)="actionSearchAssignments($event)">
          </div>
        </div>
        <div class="ui-block-header-filter">
          <button class="ui-btn ui-btn-pap" [routerLink]="'/assignments/builder'">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            <span>Add new assignment</span>
          </button>
        </div>
      </div>
    </div>
    <div class="ui-block-inner ui-block-inner-col my-4">
      <div class="ui-assignments">
        <ng-container *ngIf="interface.assignments.length == 0">
          <ui-no-data *ngIf="interface._cache.tab?.title == 'active'" type="dino-computer" text="No active assignments. Please add a assignment."></ui-no-data>
          <ui-no-data *ngIf="interface._cache.tab?.title == 'archive'" type="dino-computer" text="When you archive a assignments, it will appear here."></ui-no-data>
        </ng-container>
        <ng-container *ngIf="interface.assignments.length > 0">
          <div class="ui-assignments-list">
            <ng-container  [ngTemplateOutlet]="ASSIGNMENT_LIST"></ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="ui-block-footer"></div>
  </div>
  <ng-template #ASSIGNMENT_LIST>
    <ng-container *ngFor="let assignment of interface.assignments | sort:'desc':'createdAt' ; let cl=index">
     <ui-assignment [assignment]="assignment"></ui-assignment>
    </ng-container>
  </ng-template>
</ng-container>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiHomeworkDoneComponent} from "./ui-homework-done.component";


@NgModule({
  declarations: [UiHomeworkDoneComponent],
  exports: [UiHomeworkDoneComponent],
  imports: [
    CommonModule
  ]
})
export class UiHomeworkDoneModule { }

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserState} from '../../_stores/user/_state/user.state';
import {Store} from '@ngxs/store';
import {CreateClass} from '../../_stores/classes/_actions/classes.actions';
import {ModalCloseAction} from "../../_stores/modal/_actions/modal.actions";


@Component({
  selector: 'ui-class-add',
  templateUrl: './ui-class-add.component.html',
  styleUrls: ['./ui-class-add.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiClassAddComponent implements OnInit {
  interface: any = {
    class: {
      current: undefined,
      name: ''
    },
    user: undefined,
  };

  constructor(private cdr: ChangeDetectorRef, private store: Store) {}

  ngOnInit(): void {
    this.initUser();
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((user) => {
      this.interface.user = user;
      this.cdr.detectChanges();
    });
  }

  actionEnterClassName(event) {
    this.interface.class.name = event.target.value;
  }
  actionConfirm(): void {
    this.store.dispatch(new CreateClass({ title: this.interface.class.name })).subscribe({
      next: (res) => {
        if (res) {
          this.interface.class.current = res;
          this.store.dispatch(new ModalCloseAction());
        }
      },
      error: (err) => {},
    });
  }
  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}

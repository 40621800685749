import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Store} from "@ngxs/store";
import {UserModel} from "../../_models/user.model";
import {UserState} from "../../_stores/user/_state/user.state";
import {GetTeachersParents} from "../../_stores/user/_actions/user.actions";

@Component({
  selector: 'ui-page-parents',
  templateUrl: './ui-page-parents.component.html',
  styleUrls: ['./ui-page-parents.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiPageParentsComponent implements OnInit {
  interface: {
    loading: boolean;
    parents: UserModel[];
  } = {
    loading: false,
    parents: [],
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new GetTeachersParents());
  }

  ngOnInit(): void {
    this.initParents();
  }
  initParents() {
    this.interface.loading = true;
    this.store.select(UserState.selectAllParentsOfCurrentTeacher).subscribe({
      next: (_parents) => {
        if(_parents) {
          this.interface.parents = _parents;
          this.interface.loading = false;
          this.cdr.detectChanges();
        }
      },
    });
  }
}

<div class="ui-block ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Books</h2>
      <p>Documents <b>{{interface.books.length}}</b></p>
    </div>
    <div class="ui-block-header-action">
      <div class="ui-books-filters">
        <div class="ui-books-filter-input-group">
          <div class="ui-books-filter-input">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
            </svg>
            <input  name='search' type='text' placeholder="Search books" (keyup)="actionSearchBooks($event)">
          </div>
          <div class="ui-books-filter-text-xs" *ngIf="interface._cache.searched">Found <b>{{interface.books.length}}</b> books</div>
        </div>
        <div class="ui-books-filter-input-group">
          <div class='ui-books-filter-select'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
            </svg>
            <input name='series' type='text' readonly [value]="interface._cache.choose_serie  || '' | Series" placeholder="Choose book series" (click)="actionToggleChooseSeries()">
            <div class="ui-books-filter-select-list" [class.ui-books-filter-select-list-open]="interface._cache.open_choose_series">
              <div class="ui-books-filter-select-list-items">
                <ng-container *ngFor="let series of interface.series | sort:'asc':'order'">
                  <div class="ui-books-filter-select-list-item" (click)="actionSearchBooksBySerie(series)">
                    <p>{{series | Series}}</p>
                  </div>
                </ng-container>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-auto stroke-rose-500 invisible" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" [class.clear-select-show]="interface._cache.choose_serie" (click)="actionClearChooseSerie()">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" (click)="actionToggleChooseSeries()">
              <path *ngIf="!interface._cache.open_choose_series" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
              <path *ngIf="interface._cache.open_choose_series" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>
        <div class="ui-books-filter-input-group">
          <div class='ui-books-filter-select'>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
            </svg>
            <input  name='grades' type='text' readonly [value]="interface._cache.choose_grade?.title" placeholder="Choose grade level" (click)="actionToggleChooseGrade()">
            <div class="ui-books-filter-select-list" [class.ui-books-filter-select-list-open]="interface._cache.open_choose_grade">
              <div class="ui-books-filter-select-list-items">
                <ng-container *ngFor="let grade of interface.grades | sort:'asc':'order'">
                  <div class="ui-books-filter-select-list-item" (click)="actionSearchBooksByGrade(grade)">
                    <p>{{grade.title}}</p>
                  </div>
                </ng-container>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-auto stroke-rose-500 invisible" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" [class.clear-select-show]="interface._cache.choose_grade" (click)="actionClearChooseGrade()">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" (click)="actionToggleChooseGrade()">
              <path *ngIf="!interface._cache.open_choose_grade" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
              <path *ngIf="interface._cache.open_choose_grade" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>
      </div>
      <button class="ui-btn add-book" (click)="actionOpenAddDataModal()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
        </svg>
        <span>Add book</span>
      </button>
    </div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container *ngIf="interface.books.length == 0">
      <ui-no-data text="Loading books..."></ui-no-data>
    </ng-container>
    <ng-container *ngIf="interface.books.length > 0">
      <ng-container [ngTemplateOutlet]="DATA_TABLE_ACTIONS"></ng-container>
      <ng-container [ngTemplateOutlet]="DATA_TABLE"></ng-container>
    </ng-container>
  </div>
  <div class="ui-block-footer">
<!--    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">-->
<!--      <button class="ui-btn ui-btn-sm" (click)="actionOpenAddDataModal()">-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">-->
<!--          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />-->
<!--        </svg>-->
<!--        <span>Add book</span>-->
<!--      </button>-->
<!--    </div>-->
  </div>
</div>

<ng-template #DATA_TABLE_ACTIONS></ng-template>
<ng-template #DATA_TABLE>
  <div class="flex w-full overflow-auto overflow-y-auto rounded-lg">
  <table class="ui-table">
    <thead>
    <tr>
      <th scope="col"><div class="ui-form-checkbox"><input type="checkbox"></div></th>
      <th scope="col">Name</th>
      <th scope="col">Actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of interface.books | sort:'asc':'title'">
      <tr>
        <td>
          <div class="ui-form-checkbox"><input type="checkbox"></div>
        </td>
        <td>
          <div class="flex">
            <div class="h-14 w-12 flex-shrink-0 rounded overflow-hidden">
              <img class="block w-full aspect-w-7 aspect-h-9" src="{{item.thumbnail}}" alt="">
            </div>
            <div class="ml-4">
              <div class="font-medium text-slate-900">{{item.title}}</div>
              <div class="text-xs text-slate-400">{{item._id}}</div>
            </div>
          </div>
        </td>
        <td>
          <button tooltip title="Edit" class="ui-btn ui-btn-xs ui-btn-icon" (click)="actionOpenEditDataItemModal(item._id)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
            </svg>
          </button>
          <button tooltip title="Delete" class="ui-btn ui-btn-xs ui-btn-icon ui-btn-error" (click)="actionOpenDeleteDataItemModal(item._id)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
            </svg>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  </div>
</ng-template>

<div class="ui-block ui-block-no-margin ui-block-12 ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Users</h2>
      <p>User statistic by date</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <div class="flex gap-4 w-full">
      <div class="ui-stat-users">
        <div class="ui-stat-users-total">
          <span>Total users</span>
          <p>{{interface.statistic?.user.total}}</p>
        </div>
        <div class="ui-stat-users-roles">
          <ng-container *ngFor="let role of interface.statistic?.user.roles | sort:'asc':'role'; let a=index">
            <div class="ui-stat-users-role">
              <div class="ui-stat-users-role-icon">
                <svg *ngIf="role.role.toUpperCase() === 'PARENT'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                </svg>
                <svg *ngIf="role.role.toUpperCase() === 'ADMINISTRATOR'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                </svg>
                <svg *ngIf="role.role.toUpperCase() === 'STUDENT'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                </svg>
                <svg *ngIf="role.role.toUpperCase() === 'TEACHER'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>
                <svg *ngIf="role.role.toUpperCase() === 'PREMIUM TEACHER'" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>
              </div>
              <div class="ui-stat-users-role-name">{{role.role}}</div>
              <div class="ui-stat-users-role-count" tooltip description="Show users"
                   [routerLink]="'/control/users/'+role._id">{{role.count}}</div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="ui-stat-chart">
        <apx-chart class="w-full"
                   [colors]="interface.charts.users_register_by_roles.colors"
                   [series]="interface.charts.users_register_by_roles.series"
                   [chart]="interface.charts.users_register_by_roles.chart"
                   [yaxis]="interface.charts.users_register_by_roles.yaxis"
                   [xaxis]="interface.charts.users_register_by_roles.xaxis"
                   [stroke]="interface.charts.users_register_by_roles.stroke"
                   [tooltip]="interface.charts.users_register_by_roles.tooltip"
                   [legend]="interface.charts.users_register_by_roles.legend"
                   [dataLabels]="interface.charts.users_register_by_roles.dataLabels"
                   [plotOptions]="interface.charts.users_register_by_roles.plotOptions"
        ></apx-chart>
      </div>
      <div class="ui-stat-events">
        <ng-container *ngFor="let event of interface.statistic?.event.group | sort: 'desc': 'count'  let a=index">
          <div class="ui-stat-event" *ngIf="a < 10">
            <div class="ui-stat-event-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 12.75c1.148 0 2.278.08 3.383.237 1.037.146 1.866.966 1.866 2.013 0 3.728-2.35 6.75-5.25 6.75S6.75 18.728 6.75 15c0-1.046.83-1.867 1.866-2.013A24.204 24.204 0 0112 12.75zm0 0c2.883 0 5.647.508 8.207 1.44a23.91 23.91 0 01-1.152 6.06M12 12.75c-2.883 0-5.647.508-8.208 1.44.125 2.104.52 4.136 1.153 6.06M12 12.75a2.25 2.25 0 002.248-2.354M12 12.75a2.25 2.25 0 01-2.248-2.354M12 8.25c.995 0 1.971-.08 2.922-.236.403-.066.74-.358.795-.762a3.778 3.778 0 00-.399-2.25M12 8.25c-.995 0-1.97-.08-2.922-.236-.402-.066-.74-.358-.795-.762a3.734 3.734 0 01.4-2.253M12 8.25a2.25 2.25 0 00-2.248 2.146M12 8.25a2.25 2.25 0 012.248 2.146M8.683 5a6.032 6.032 0 01-1.155-1.002c.07-.63.27-1.222.574-1.747m.581 2.749A3.75 3.75 0 0115.318 5m0 0c.427-.283.815-.62 1.155-.999a4.471 4.471 0 00-.575-1.752M4.921 6a24.048 24.048 0 00-.392 3.314c1.668.546 3.416.914 5.223 1.082M19.08 6c.205 1.08.337 2.187.392 3.314a23.882 23.882 0 01-5.223 1.082" />
              </svg>

            </div>
            <div class="ui-stat-event-name">{{event.name}}</div>
            <div class="ui-stat-event-count">{{event.count}}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-6 ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Schools</h2>
      <p>Schools and Library statistics</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <div class="flex gap-16 w-full">
      <div class="ui-stat-schools">
        <div class="ui-stat-schools-total">
          <span>Total schools</span>
          <p>{{interface.statistic?.school.total}}</p>
        </div>
        <div class="ui-stat-schools-statuses">
          <div class="ui-stat-schools-status">
            <div class="ui-stat-schools-status-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
              </svg>
            </div>
            <div class="ui-stat-schools-status-name">With licenses</div>
            <div class="ui-stat-schools-status-count ui-stat-schools-status-count-active" tooltip
                 description="Show schools"
                 [routerLink]="'/control/schools/active'">{{interface.statistic?.school.active}}</div>
          </div>
          <div class="ui-stat-schools-status">
            <div class="ui-stat-schools-status-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
              </svg>
            </div>
            <div class="ui-stat-schools-status-name">With out licenses</div>
            <div
              class="ui-stat-schools-status-count">{{interface.statistic?.school.total - interface.statistic?.school.active}}</div>
          </div>
        </div>
      </div>
      <div class="ui-stat-licenses">
        <div class="ui-stat-licenses-total">
          <span>Total licenses</span>
          <p>{{interface.statistic?.license.total}}</p>
        </div>
        <div class="ui-stat-licenses-statuses">
          <div class="ui-stat-licenses-status ui-stat-licenses-status-active"
               [routerLink]="'/control/schools/licenses'">
            <div class="ui-stat-licenses-status-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
              </svg>
            </div>
            <div class="ui-stat-licenses-status-name">Activated</div>
            <div class="ui-stat-licenses-status-count ui-stat-licenses-status-count-active" tooltip
                 description="Show licenses">{{interface.statistic?.license.active}}</div>
          </div>
          <div class="ui-stat-licenses-status ui-stat-licenses-status-deactive"
               [routerLink]="'/control/schools/licenses'">
            <div class="ui-stat-licenses-status-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
              </svg>
            </div>
            <div class="ui-stat-licenses-status-name">Deactivated</div>
            <div class="ui-stat-licenses-status-count ui-stat-licenses-status-count-active" tooltip
                 description="Show licenses">{{interface.statistic?.license.total - interface.statistic?.license.active}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-6 ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Resources</h2>
      <p>Resources short statistics</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <div class="flex gap-16 w-full">
      <div class="ui-stat-practices">
        <ng-container *ngFor="let practice of interface.statistic?.practice let a=index">
          <div class="ui-stat-practice" [routerLink]="'/control/practices/'+practice.name+'s'">
            <div class="ui-stat-practice-icon">
              <svg *ngIf="practice.name == 'category'" xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
              </svg>
              <svg *ngIf="practice.name == 'video'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round"
                      d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
              </svg>
              <svg *ngIf="practice.name == 'book'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
                   viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
              </svg>
              <svg *ngIf="practice.name == 'question'" xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
              </svg>
              <svg *ngIf="practice.name == 'worksheet'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
                   viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
            </div>
            <div class="ui-stat-practice-name">{{practice.name}}</div>
            <div class="ui-stat-practice-count">{{practice.count}}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>

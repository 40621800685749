import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiNotificationComponent} from "./ui-notification.component";


@NgModule({
  declarations: [UiNotificationComponent],
  exports: [UiNotificationComponent],
  imports: [
    CommonModule
  ]
})
export class UiNotificationModule { }

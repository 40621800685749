<div class="ui-results">
  <div class="ui-results-board">
    <div class="ui-block ui-block-white">
      <div class="ui-block-header">
        <div class="ui-block-header-title">
          <h2>Your practice result</h2>
          <p *ngIf="false">
            You answered <strong>{{interface.progress?.statistic.answered}}</strong> out of <strong>{{interface.questions.length}}</strong> questions.
            Correct: <strong>{{interface.progress?.statistic.correct}}</strong> and Wrong <strong>{{interface.progress?.statistic.wrong}}</strong>
          </p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile">
          <button class="ui-btn ui-btn-sm ui-btn-blue-outline" [routerLink]="'../'" >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12c0-1.232.046-2.453.138-3.662a4.006 4.006 0 013.7-3.7 48.678 48.678 0 017.324 0 4.006 4.006 0 013.7 3.7c.017.22.032.441.046.662M4.5 12l-3-3m3 3l3-3m12 3c0 1.232-.046 2.453-.138 3.662a4.006 4.006 0 01-3.7 3.7 48.657 48.657 0 01-7.324 0 4.006 4.006 0 01-3.7-3.7c-.017-.22-.032-.441-.046-.662M19.5 12l-3 3m3-3l3 3" />
            </svg>
            <span>Practice again</span>
          </button>
        </div>
      </div>
      <div class="ui-block-inner ui-block-inner-col my-4">
        <!--<ui-no-data *ngIf="interface.progress.list.length == 0" type="dino" text="You didn't go through anything"></ui-no-data>-->
        <ui-quiz-result [questions]="interface.questions" [result]="interface.progress.list"></ui-quiz-result>
      </div>
      <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
          <button class="ui-btn ui-btn-sm ui-btn-blue-outline">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12c0-1.232.046-2.453.138-3.662a4.006 4.006 0 013.7-3.7 48.678 48.678 0 017.324 0 4.006 4.006 0 013.7 3.7c.017.22.032.441.046.662M4.5 12l-3-3m3 3l3-3m12 3c0 1.232-.046 2.453-.138 3.662a4.006 4.006 0 01-3.7 3.7 48.657 48.657 0 01-7.324 0 4.006 4.006 0 01-3.7-3.7c-.017-.22-.032-.441-.046-.662M19.5 12l-3 3m3-3l3 3" />
            </svg>
            <span>Practice again</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-results-awards">
    <ui-no-data></ui-no-data>
  </div>
  <div class="ui-results-books">
    <ui-books></ui-books>
  </div>
</div>

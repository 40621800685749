import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";
import {UiEditorComponent} from "./ui-editor.component";


@NgModule({
  declarations: [UiEditorComponent],
  exports: [UiEditorComponent],
  imports: [
    CommonModule,
    UiTooltipModule
  ]
})
export class UiEditorModule { }

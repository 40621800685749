import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPageSmallSidebarComponent} from "./ui-page-small-sidebar.component";
import {AppTranslocoModule} from "../../app.transloco.module";
import {UiTooltipModule} from "../ui-tooltip/ui-tooltip.module";
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [UiPageSmallSidebarComponent],
  exports: [UiPageSmallSidebarComponent],
  imports: [
    CommonModule,
    UiTooltipModule,
    RouterModule,
    AppTranslocoModule
  ]
})
export class UiPageSmallSidebarModule { }

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'striphtml'
})

export class StripHtmlPipe implements PipeTransform {
  transform(value: string): any {
    let _value = '';
    _value = value.replace(/<\/?(?!br)(?!b)(?!u)\w*\b[^>]*>/ig, '');
    _value += ' ';
    return _value;
  }
}

import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {
  EventWorksheetClosed,
  EventWorksheetDownloaded,
  EventWorksheetOpened,
  EventWorksheetPrinted,
} from '../../_stores/event_ws/_actions/events.actions';
import {UserState} from '../../_stores/user/_state/user.state';
import {AssignmentBuilderService} from '../../_utils/assignment-builder-service';
import {ModalOpenAction} from '../../_stores/modal/_actions/modal.actions';
import {WorksheetsService} from '../../_stores/worksheets/_service/worksheets.service';
import {UserModel} from '../../_models/user.model';
import {DatePipe} from '@angular/common';
import {API} from '../../_stores/api';
import {HttpClient} from '@angular/common/http';
import {AssignmentModel} from '../../_models/assignment.model';
import {AssignmentsBuilderAddWorksheet} from '../../_stores/assignments-builder/_actions/assignments-builder.actions';
import {PracticeState} from '../../_stores/practice/_state/practice.state';
import {Router} from '@angular/router';
import {RouterSelectors} from '../../_stores/routing/router.selectors';

@Component({
  selector: 'ui-worksheet',
  templateUrl: './ui-worksheet.component.html',
  styleUrls: ['./ui-worksheet.component.scss'],
})
export class UiWorksheetComponent implements OnInit, OnDestroy {
  interface: {
    assignment_id: string;
    urlList: { image: string; order: number }[];
    _id: string;
    user: UserModel;
    homework: boolean;
    assignment: AssignmentModel;
    worksheet: any;
    tracker: any;
    loading: boolean;
    controls: {
      edit: boolean;
      print: boolean;
      upload: boolean;
      download_worksheet: boolean;
      download_worksheet_answers: boolean;
    };
    _cache: {
      editing: boolean;
      gallery: {
        index: number;
        current: string;
        list: string[];
        backgrounds: { order: number; data: string }[];
        edited: string[];
      };
      files: { order: number; data: File }[];
    };
  } = {
    assignment_id: '',
    urlList: [],
    _id: null,
    user: undefined,
    homework: false,
    assignment: undefined,
    worksheet: undefined,
    tracker: undefined,
    loading: false,
    controls: {
      edit: false,
      print: false,
      upload: false,
      download_worksheet: false,
      download_worksheet_answers: false,
    },
    _cache: {
      editing: false,
      gallery: {
        index: 0,
        current: undefined,
        list: [],
        backgrounds: [],
        edited: [],
      },
      files: [],
    },
  };

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private datePipe: DatePipe,
    private worksheetsService: WorksheetsService,
    private assignmentBuilderService: AssignmentBuilderService,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.store.dispatch(
      new EventWorksheetClosed({
        worksheetId: this.interface.worksheet._id,
        categoryId: this.interface.worksheet.categories[0],
      }),
    );
  }

  @Input('id') set _id(id) {
    if (id) {
      this.interface._id = id;
      this.initWorksheet();
    }
  }

  @Input('homework') set _homework(homework) {
    if (homework) {
      this.interface.homework = homework;
    }
  }

  @Input('worksheet') set _worksheet(value: any) {}

  @Input('assignment') set _assignment(assignment) {
    if (assignment) {
      this.interface.assignment = assignment;
    }
  }

  ngOnInit(): void {
    this.initRouteParams();
    this.initUser();
  }

  initRouteParams(): void {
    this.store.select(RouterSelectors.params).subscribe((_params) => {
      this.interface.assignment_id = _params.assignment_id;
    });
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }

  initWorksheet(): void {
    this.worksheetsService.getWorksheetById(this.interface._id).subscribe((_worksheet: any) => {
      this.interface.worksheet = _worksheet;
      this.interface._cache.gallery.list = _worksheet.gallery;
      for (let i = 0; i < this.interface._cache.gallery.list.length; i++) {
        this.interface._cache.gallery.backgrounds.push({ order: i, data: this.interface._cache.gallery.list[i] });
      }
      this.initWorksheetGalleryCurrent();
      this.initWorksheetControlsBehavior();
      this.initTracker();
      this.store.dispatch(
        new EventWorksheetOpened({
          worksheetId: this.interface.worksheet._id,
          categoryId: this.interface.worksheet.categories[0],
        }),
      );
      this.cdr.detectChanges();
    });
  }

  initWorksheetGalleryCurrent(): void {
    this.interface._cache.gallery.current = this.interface._cache.gallery.list[this.interface._cache.gallery.index];
  }

  initWorksheetControlsBehavior(): void {
    this.interface.controls.edit = true;
    this.interface.controls.print = true;
    this.interface.controls.download_worksheet = this.interface.worksheet.files_worksheets.question;
    this.interface.controls.download_worksheet_answers = this.interface.worksheet.files_worksheets.answers;
  }

  initTracker(): void {
    if (this.interface.homework) {
      let _body = {
        assignment: this.interface.assignment,
        material: this.interface._id,
        typeMaterial: 'Worksheet',
      };
      this.http.post<any>(API.url('trackers/prep-begin'), _body).subscribe((_tracker) => {
        this.interface.tracker = _tracker;
        this.cdr.detectChanges();
      });
    } else {
      let _body = {
        material: this.interface._id,
        typeMaterial: 'Worksheet',
      };
      this.http.post<any>(API.url('trackers/practice-begin'), _body).subscribe((_tracker) => {
        this.interface.tracker = _tracker;
        this.cdr.detectChanges();
      });
    }
  }

  actionNextGallery(): void {
    this.interface._cache.gallery.index += 1;
    this.initWorksheetGalleryCurrent();
  }

  actionPrevGallery(): void {
    this.interface._cache.gallery.index -= 1;
    this.initWorksheetGalleryCurrent();
  }

  getIndex(): number {
    return this.interface._cache.gallery.list.findIndex((value) => value === this.interface._cache.gallery.current);
  }

  actionPrint(): void {
    this.store.dispatch(
      new EventWorksheetPrinted({
        worksheetId: this.interface.worksheet._id,
        categoryId: this.interface.worksheet.categories[0],
      }),
    );

    let _printwindow;
    const chrome =
      navigator.userAgent.toLowerCase().indexOf('chrome') > -1 && navigator.vendor.toLowerCase().indexOf('google') > -1;
    const watermark =
      'The use of this page is granted to "' +
      this.interface.user.school.schoolName +
      '" and expires on "' +
      this.datePipe.transform(this.interface.user.school.license.endDate, 'longDate') +
      '". After this date, this page may not be used under any purposes under copyright laws.';
    if (chrome) {
      _printwindow = window.open('/', 'argo', 'width=700,height=450');
    } else {
      _printwindow = window.open('', 'PRINT');
    }

    _printwindow.document.write(
      '<style type="text/css" media="all">@page { margin: 0; size: A4 } body { margin: 0 } .ui-print-page { margin: 0; overflow: hidden; position: relative; box-sizing: border-box; page-break-after: always; } .ui-print-page-watermark { position: absolute; background: transparent; width: 75%; height: auto; bottom: 1%; left: 12.565%; z-index: 99; display: flex; align-items: center; justify-content: center; margin: 0 auto; font-size: small; text-align: center; color: #7a7a7a; } body.A3 .ui-print-page { width: 297mm; height: 419mm } body.A3.landscape .ui-print-page { width: 420mm; height: 296mm } body.A4 .ui-print-page { width: 210mm; height: 296mm } body.A4.landscape .ui-print-page { width: 297mm; height: 209mm } body.A5 .ui-print-page { width: 148mm; height: 209mm } body.A5.landscape .ui-print-page { width: 210mm; height: 147mm } body.letter .ui-print-page { width: 216mm; height: 279mm } body.letter.landscape .ui-print-page { width: 280mm; height: 215mm } body.legal .ui-print-page { width: 216mm; height: 356mm } body.legal.landscape .ui-print-page { width: 357mm; height: 215mm } @media screen { body { background: #e0e0e0 } .ui-print-page { background: white; box-shadow: 0 .5mm 2mm rgba(0,0,0,.3); margin: 5mm auto; } } @media print { body.A3.landscape { width: 420mm } body.A3, body.A4.landscape { width: 297mm } body.A4, body.A5.landscape { width: 210mm } body.A5 { width: 148mm } body.letter, body.legal { width: 216mm } body.letter.landscape { width: 280mm } body.legal.landscape { width: 357mm } }</style>',
    );
    _printwindow.document.write('<script>window.onload = function() {window.print();window.close();};</script>');
    _printwindow.document.write('<body class="A4"></body>');
    this.interface._cache.gallery.list.forEach((img_src: any) => {
      _printwindow.document.write(
        '<section class="ui-print-page"><img src="' +
          img_src +
          '" alt="" width="100%"><div class="ui-print-page-watermark">' +
          watermark +
          '</div></section>',
      );
    });
    _printwindow.document.close();
    _printwindow.focus();
  }

  actionToggleEditMode(): void {
    this.interface._cache.editing = !this.interface._cache.editing;
  }

  actionExitPractice(): void {
    this.store.dispatch(new ModalOpenAction('practice_exit', false));
  }

  actionExitHomework(): void {
    this.store.dispatch(new ModalOpenAction('homework_exit', false));
  }

  actionDownloadAnswers(): void {
    window.open(this.interface.worksheet.files_worksheets.answers, 'argo', 'width=700,height=900');
  }

  actionDownloadWorksheet(): void {
    this.store.dispatch(
      new EventWorksheetDownloaded({
        worksheetId: this.interface.worksheet._id,
        categoryId: this.interface.worksheet.categories[0],
      }),
    );
    window.open(this.interface.worksheet.files_worksheets.question, 'argo', 'width=700,height=900');
  }

  async actionGetEditWorksheet(edited) {
    if (!edited) {
      return;
    }
    const base64Response = await fetch(edited);
    const base64Blob = await base64Response.blob();
    // this.actionClearUploadFiles();
    let index = this.interface._cache.gallery.list.findIndex(
      (value) => value === this.interface._cache.gallery.current,
    );
    let _file = new File([base64Blob], 'homework_' + index.toString(), { type: 'image/png' });
    this.interface._cache.gallery.backgrounds = this.interface._cache.gallery.backgrounds.filter(
      (item) => item.order !== index,
    );
    this.interface._cache.files.push({ order: index, data: _file });
    this.cdr.detectChanges();
  }

  actionUpload(event) {
    this.interface._cache.files.push({ order: 0, data: event.target.files[0] });
    if (this.interface._cache.files.length > 0) {
      this.interface.controls.upload = !this.interface.controls.upload;
    }
  }

  actionClearUploadFiles(): void {
    this.interface._cache.files = [];
    if (this.interface.controls.upload) {
      this.interface.controls.upload = false;
    }
  }

  actionSubmitHomework(): void {
    this.interface.loading = true;
    this.initImagesUrls();
  }

  actionSelectToAssign() {
    let isAssign = this.isAssignMaterial();
    if (!isAssign) {
      this.store.dispatch(new AssignmentsBuilderAddWorksheet({ worksheet: this.interface.worksheet }));
      this.store.select(PracticeState.selectCategoryById(this.interface.worksheet._id)).subscribe({
        next: (res) => {
          this.assignmentBuilderService.materials.push(res);
        },
      });
    }
  }

  isAssignMaterial() {
    return this.assignmentBuilderService.materials.find((value) => value._id === this.interface?.worksheet?._id);
  }

  async dataURItoBlob(dataURI: any, fileName: string): Promise<File> {
    if (!dataURI) {
      return;
    }
    const base64Response = await fetch(dataURI);
    const base64Blob = await base64Response.blob();
    return new File([base64Blob], fileName, { type: 'image/png' });
  }

  private async initImagesUrls() {
    await this.initBackGrounds();
    this.interface._cache.files.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
    for (const file of this.interface._cache.files) {
      const formData: FormData = new FormData();
      const date = new Date();
      formData.append('dir', 'answers_worksheet');
      formData.append('dynamic_dirs[]', this.interface.tracker._id);
      formData.append('FID', date.getTime().toString());
      formData.append('files[]', file.data);
      this.http
        .post<any>('https://cdn.argoprep.com/upload.php', formData, {
          headers: {
            enctype: 'multipart/form-data',
          },
        })
        .subscribe({
          next: (_next) => {
            if (_next) {
              this.interface.urlList.push({ image: _next.url, order: file.order });
              if (this.interface.urlList && this.interface.urlList.length === this.interface._cache.files.length) {
                let _body = {
                  trackerId: this.interface.tracker._id,
                  materialsData: this.interface.urlList.sort((a, b) =>
                    a.order > b.order ? 1 : b.order > a.order ? -1 : 0,
                  ),
                };
                this.http.patch<any>(API.url('trackers/prep-end'), _body).subscribe((_tracker) => {
                  this.router.navigate(['homeworks/' + this.interface.assignment_id]).then();
                  this.cdr.detectChanges();
                });
              }
            }
          },
        });
    }
  }

  private async initBackGrounds() {
    if (this.interface._cache.gallery.backgrounds.length > 0 && !this.interface.controls.upload) {
      for (const background of this.interface._cache.gallery.backgrounds) {
        let backgroundFile = await this.dataURItoBlob(
          background.data,
          'homework_' + background.order.toString() + '.png',
        );
        this.interface._cache.files.push({ order: background.order, data: backgroundFile });
      }
    }
  }

  actionDownloadWorksheetAnswers() {
    this.store.dispatch(
      new EventWorksheetDownloaded({
        worksheetId: this.interface.worksheet._id,
        categoryId: this.interface.worksheet.categories[0],
      }),
    );
    window.open(this.interface.worksheet.files_worksheets.answer, 'argo', 'width=700,height=900');
  }
}

import {BookModel} from "../../../_models/book.model";

export class GetBooksList {
  static readonly type = '[Books] Get List';
}

export class SetBooksList {
  static readonly type = '[Books] Set List';
  constructor(public list: BookModel[]) {}
}

export class LoadBookPages {
  static readonly type = '[Books] LoadBookPages';
  constructor(public book_id: string, public page_number: number) {}
}
export class ClearBookPages {
  static readonly type = '[Books] ClearBookPages';
}

export class LoadBookVideos {
  static readonly type = '[Books] LoadBookVideos';
  constructor(public book_id: string) {}
}
export class ClearBookVideos {
  static readonly type = '[Books] ClearBookVideos';
}

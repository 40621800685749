import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiAssignmentsLabelBuilderComponent} from './ui-assignments-label-builder.component';
import {UiTooltipModule} from '../ui-tooltip/ui-tooltip.module';

@NgModule({
  declarations: [UiAssignmentsLabelBuilderComponent],
  exports: [UiAssignmentsLabelBuilderComponent],
  imports: [CommonModule, UiTooltipModule],
})
export class UiAssignmentsLabelBuilderModule {}

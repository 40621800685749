import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserModel} from '../../_models/user.model';
import {GradeModel} from '../../_models/grade.model';
import {RoleModel} from '../../_models/role.model';
import {SchoolModel} from '../../_models/school.model';
import {Store} from '@ngxs/store';
import {UserState} from '../../_stores/user/_state/user.state';
import {UiStudentAddModel} from '../../_models/ui-student-add.model';
import {ClassesState} from '../../_stores/classes/_state/classes.state';
import {GetClassList} from '../../_stores/classes/_actions/classes.actions';
import {HttpClient} from '@angular/common/http';
import {API} from '../../_stores/api';
import {ModalCloseAction} from '../../_stores/modal/_actions/modal.actions';
import {GetTeachersStudents} from "../../_stores/user/_actions/user.actions";

@Component({
  selector: 'ui-student-add',
  templateUrl: './ui-student-add.component.html',
  styleUrls: ['./ui-student-add.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiStudentAddComponent implements OnInit {
  interface: UiStudentAddModel = {
    loading: false,
    student: new (class implements UserModel {
      _id: string;
      avatar: string;
      language: string;
      displayName: string;
      email: string;
      firstName: string;
      lastName: string;
      password: string;
      practice: string | GradeModel;
      role: RoleModel;
      school: SchoolModel;
      ain: number;
    })(),
    classes: [],
    user: undefined,
    _cache: {
      open_select_class: false,
      class_selected: undefined,
      check_ain: true
    },
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) {
    this.store.dispatch(new GetClassList());
  }

  ngOnInit(): void {
    this.initDefaultStudent();
    this.initGlobalUser();
    this.initGlobalUserClasses();
  }

  initDefaultStudent(): void {
    this.interface.student = {
      _id: '',
      avatar: '',
      language: 'en',
      displayName: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      practice: '',
      role: {
        _id: '62ebc2e38e33b7bc52c853c6',
        name: 'Student'
      },
      school: undefined,
      ain: null,
    };
  }
  initGlobalUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.interface.student.school = this.interface.user.school;
    });
  }
  initGlobalUserClasses(): void {
    this.store.select(ClassesState.selectClassList).subscribe({
      next: (_classes) => {
        this.interface.classes = _classes;
      },
    });
  }


  actionEnterAin(event): void {
    if (event.target.value.length >= 0 && event.target.value.length <= 7) {
      this.interface.student.ain = event.target.value;
    } else {
      event.target.value = this.interface.student.ain;
    }

    if (this.interface.student.ain > 1000000 && this.interface.student.ain < 9999999) {
      this.actionCheckUserAin();
    }
  }
  actionSelectClass(_class): void {
    this.interface._cache.class_selected = _class;
    this.interface._cache.open_select_class = false;
  }
  actionSelectAvatar(index): void {
    this.interface.student.avatar = 'https://cdn.argoprep.com/avatars/avatar-' + index + '.svg';
  }
  actionEnterFirstName(event): void {
    this.interface.student.firstName = event.target.value;
  }
  actionEnterLastName(event): void {
    this.interface.student.lastName = event.target.value;
  }
  actionEnterLogin(event): void {
    this.interface.student.email = event.target.value;
  }
  actionEnterPassword(event): void {
    this.interface.student.password = event.target.value;
  }
  actionGeneratePassword(): void {
    if(this.interface.student.ain) {
      return;
    }
    this.interface.student.password = Math.random().toString(36).slice(-6);
  }
  actionToggleSelectClass(): void {
    this.interface._cache.open_select_class = !this.interface._cache.open_select_class;
  }
  actionCheckUserAin(): void {
    this.http.get<UserModel>(API.url('users/student/ain/'+this.interface.student.ain)).subscribe({
      next: (user) => {
        this.interface.student = user;
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.interface.student.ain = null;
        this.interface._cache.check_ain = false;
        this.cdr.detectChanges();
      },
    });


  }

  actionGrantAddStudent(): boolean {
    let _grant = true;

    if(this.interface.student.avatar.length == 0) {
      _grant = false;
    }
    if(this.interface.student.firstName.length < 3) {
      _grant = false;
    }
    if(this.interface.student.lastName.length < 3) {
      _grant = false;
    }
    if(this.interface.student.email.length < 6) {
      _grant = false;
    }
    if(this.interface.student.password?.length == 0) {
      _grant = false;
    }
    if(!this.interface._cache.class_selected) {
      _grant = false;
    }

    return _grant;
  }


  actionConfirm(): void {
    this.interface.loading = true;
    let _body;
    if(this.interface.student.ain) {
      _body = {
        classId: this.interface._cache.class_selected._id,
        ain: this.interface.student.ain,
      }
      this.http.put(API.url('classes/add/student/ain'), _body).subscribe({
        next: (value) => {
          this.store.dispatch(new GetTeachersStudents());
          this.store.dispatch(new ModalCloseAction());
        },
        error: (err) => {
          this.interface.loading = false;
          this.cdr.detectChanges();
        },
        });
    }
    else {
      _body = {
        firstName: this.interface.student.firstName,
        lastName: this.interface.student.lastName,
        email: this.interface.student.email,
        password: this.interface.student.password,
        school: this.interface.student.school,
        role: this.interface.student.role,
        class: this.interface._cache.class_selected,
        avatar: this.interface.student.avatar,
      }
      this.http.put(API.url('classes/add/student/new'), _body).subscribe({
          next: (value) => {
            this.store.dispatch(new GetTeachersStudents());
            this.store.dispatch(new GetClassList());
            this.store.dispatch(new ModalCloseAction());
          },
          error: (err) => {
            this.interface.loading = false;
            this.cdr.detectChanges();
          },
        });
    }
  }
  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}

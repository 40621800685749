<div *ngIf="pages && pages.length > 0" class="ui-table-pagination">
  <ng-container *ngIf="currentPage<=10 && pages && pages.length > 0">
    <ng-container [ngTemplateOutlet]="ACTION_FIRST_PAGE"></ng-container>
    <ng-container [ngTemplateOutlet]="ACTION_PREV_PAGE"></ng-container>
    <ng-container *ngFor="let page of pages; let i = index">
      <button class="ui-btn ui-btn-icon ui-btn-sm btn-item-paginate" [routerLink]="[]" [fragment]="page.toString()" [class.ui-btn-pagination-active]="page == currentPage"
              (click)="actionLoadPage(page)">
        {{page}}
      </button>
    </ng-container>
    <ng-container [ngTemplateOutlet]="ACTION_NEXT_PAGE"></ng-container>
    <ng-container [ngTemplateOutlet]="ACTION_LAST_PAGE"></ng-container>
  </ng-container>
  <ng-container *ngIf="currentPage>10 && currentPage < totalPage - 2 && pages && pages.length > 0">
    <ng-container [ngTemplateOutlet]="ACTION_FIRST_PAGE"></ng-container>
    <ng-container [ngTemplateOutlet]="ACTION_PREV_PAGE"></ng-container>
    <button class="ui-btn ui-btn-icon ui-btn-sm btn-item-paginate" [class.ui-btn-pagination-active]="currentPage===1"
            (click)="actionLoadPage(1)">
      {{1}}
    </button>
    <button class="ui-btn ui-btn-icon ui-btn-sm btn-item-paginate">...</button>
    <ng-container *ngFor="let page of pages; let i = index">
      <button class="ui-btn ui-btn-icon ui-btn-sm btn-item-paginate" [routerLink]="[]" [fragment]="page.toString()" [class.ui-btn-pagination-active]="page == currentPage"
              (click)="actionLoadPage(page)">
        {{page}}
      </button>
    </ng-container>
    <button class="ui-btn ui-btn-icon ui-btn-sm btn-item-paginate">...</button>
    <button class="ui-btn ui-btn-icon ui-btn-sm btn-item-paginate" [class.ui-btn-pagination-active]="currentPage===totalPage"
            (click)="actionLoadPage(totalPage)">
      {{totalPage}}
    </button>
    <ng-container [ngTemplateOutlet]="ACTION_NEXT_PAGE"></ng-container>
    <ng-container [ngTemplateOutlet]="ACTION_LAST_PAGE"></ng-container>
  </ng-container>
  <ng-container *ngIf="currentPage>10 && currentPage >= totalPage - 2 && pages && pages.length > 0">
    <ng-container [ngTemplateOutlet]="ACTION_FIRST_PAGE"></ng-container>
    <ng-container [ngTemplateOutlet]="ACTION_PREV_PAGE"></ng-container>
    <button class="ui-btn ui-btn-icon ui-btn-sm btn-item-paginate" [class.ui-btn-pagination-active]="currentPage===1"
            (click)="actionLoadPage(1)">
      {{1}}
    </button>
    <button class="ui-btn ui-btn-icon ui-btn-sm btn-item-paginate">...</button>
    <ng-container *ngFor="let page of pages; let i = index">
      <button class="ui-btn ui-btn-icon ui-btn-sm btn-item-paginate" [routerLink]="[]" [fragment]="page.toString()" [class.ui-btn-pagination-active]="page == currentPage"
              (click)="actionLoadPage(page)">
        {{page}}
      </button>
    </ng-container>
    <ng-container [ngTemplateOutlet]="ACTION_NEXT_PAGE"></ng-container>
    <ng-container [ngTemplateOutlet]="ACTION_LAST_PAGE"></ng-container>
  </ng-container>
</div>
<ng-template #ACTION_FIRST_PAGE>
  <button class="ui-btn ui-btn-icon ui-btn-sm" [routerLink]="[]" [fragment]="'1'" (click)="actionFirstPage()" [disabled]="currentPage == 1">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
         class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
    </svg>
  </button>
</ng-template>
<ng-template #ACTION_PREV_PAGE>
  <button class="ui-btn ui-btn-icon ui-btn-sm"  [routerLink]="[]" [fragment]="(currentPage - 1).toString()"  (click)="actionPrevPage()" [disabled]="currentPage == 1">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
         class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
    </svg>
  </button>
</ng-template>
<ng-template #ACTION_NEXT_PAGE>
  <button class="ui-btn ui-btn-icon ui-btn-sm" [routerLink]="[]" [fragment]="(currentPage + 1).toString()" (click)="actionNextPage()"
          [disabled]="currentPage == totalPage">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
         class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
    </svg>
  </button>
</ng-template>
<ng-template #ACTION_LAST_PAGE>
  <button class="ui-btn ui-btn-icon ui-btn-sm" [routerLink]="[]" [fragment]="totalPage.toString()" (click)="actionLastPage()"
          [disabled]="currentPage == totalPage">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
         class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
    </svg>
  </button>
</ng-template>

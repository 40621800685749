<div class="ui-block ui-block-white">
    <div class="ui-block-header">
        <div class="ui-block-header-title">
               <h2>Users</h2>
               <p>Total number of users: <strong>{{interface.totalData}}</strong></p>
        </div>
        <div class="ui-block-header-action ui-block-header-action-hidden-mobile">
          <div class="ui-users-filters">
            <div class="ui-users-filter-input-group">
              <div class="ui-users-filter-input">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" stroke-width="1.5">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                </svg>
                <input name="search" type="text" placeholder="Search" (keyup)="actionSearch($event)">
              </div>
              <div class="ui-users-filter-text-xs" *ngIf="interface?._cache?.search">Found
                <strong>{{interface.data.length}}</strong> users
              </div>
            </div>
          </div>
          <button class="ui-btn add-user" (click)="actionOpenAddDataModal()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 flex-shrink-0">
              <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
            </svg>
            <span class="whitespace-nowrap">Add new</span>
          </button>
          <button class="ui-btn ui-btn-error add-user" (click)="actionUpdateKlavio()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 flex-shrink-0">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
            <span class="whitespace-nowrap">Update Klaviyo license</span>
          </button>
        </div>
    </div>
    <div class="ui-block-inner ui-block-inner-col my-4">
      <ng-container [ngTemplateOutlet]="DATA_TABLE_ACTIONS"></ng-container>
      <ng-container [ngTemplateOutlet]="DATA_TABLE"></ng-container>
      <ng-container [ngTemplateOutlet]="DATA_TABLE_PAGINATION"></ng-container>
    </div>
    <div class="ui-block-footer">
        <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet">
          <button class="ui-btn ui-btn-sm" (click)="actionOpenAddDataModal()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
            </svg>
            <span>Add new</span>
          </button>
        </div>
    </div>
</div>

<ng-template #DATA_TABLE_ACTIONS>
  <div class="table-actions">
    <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('all')" [class.ui-btn-pap]="interface.period == 'all'" tooltip description="All users">
      <span>All</span>
    </button>
    <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('day')" [class.ui-btn-pap]="interface.period == 'day'" tooltip description="Users by day">
      <span>Day</span>
    </button>
    <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('week')" [class.ui-btn-pap]="interface.period == 'week'" tooltip description="Users by week">
      <span>Week</span>
    </button>
    <button class="ui-btn ui-btn-sm" (click)="actionSelectPeriod('month')" [class.ui-btn-pap]="interface.period == 'month'" tooltip description="Users by month">
      <span>Month</span>
    </button>
    <ui-date-picker [future]="true" [date]="interface._cache.period.start" class="ui-reports-period" [class.ui-reports-period-active]="interface.period == 'custom'" (selectedDate)="actionSelectPeriodDate($event)"></ui-date-picker>
    <div class="ui-form w-[20rem]">
      <div class="ui-form-select max-w-[20rem]">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
        </svg>
        <input  name="class" type="text" readonly [value]="interface._cache.role_selected" placeholder="Select user" (click)="actionToggleSelectRole()">
        <div class="ui-form-select-list" [class.ui-form-select-list-open]="interface._cache.open_roles_selected">
          <div class="ui-form-select-list-items ui-select-school-list">
            <div class="ui-form-select-list-item" [routerLink]="'/control/users/'" (click)="actionSelectRole('All')">
              <p>All</p>
              <span></span>
            </div>
            <ng-container *ngFor="let role of interface._cache.roles">
              <div class="ui-form-select-list-item" [routerLink]="'/control/users/'+role._id" (click)="actionSelectRole(role)">
                <p>{{role.name}}</p>
                <span></span>
              </div>
            </ng-container>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pointer-events-none" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" >
          <path *ngIf="!interface._cache.open_roles_selected" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
          <path *ngIf="interface._cache.open_roles_selected" stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
        </svg>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #DATA_TABLE>
  <div class="flex w-full overflow-auto rounded-lg">
  <table class="ui-table">
    <caption></caption>
    <thead>
    <tr>
      <th scope="col"><div class="ui-form-checkbox"><input type="checkbox"></div></th>
      <th scope="col">Name</th>
      <th scope="col">AIN</th>
      <th scope="col">Role</th>
      <th scope="col">Schools</th>
      <th scope="col">Date</th>
      <th scope="col">Actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of interface.data | sort:'asc':'order'">
      <tr>
        <td>
          <div class="ui-form-checkbox"><input type="checkbox"></div>
        </td>
        <td>
          <div class="flex items-center">
            <div class="h-10 w-10 flex-shrink-0">
              <img class="h-10 w-10 rounded" src="{{item.avatar}}" alt="">
            </div>
            <div class="ml-4">
              <a class="font-medium text-slate-900 hover:text-pap-400" [href]="'/control/users/'+item._id+'/info'" target="_blank"> {{item.firstName}} {{item.lastName}}</a>
              <div class="text-xs text-slate-400">{{item._id}}</div>
            </div>
          </div>
        </td>
        <td>
          <div class="flex items-center text-xs">
            {{item.ain}}
          </div>
        </td>
        <td>
          <div class="flex items-center text-xs">
            {{item.role?.name}}
          </div>
        </td>
        <td>
          <div class="flex items-center text-xs hover:text-pap-400" [routerLink]="'/control/schools/'+item.school?._id+'/detail'" >
            {{item.school?.schoolName}}
          </div>
        </td>
        <td>
          <div class="flex flex-col w-full items-start text-xs">
            <div class="flex w-full items-center gap-1">
              <p class="text-slate-400">Created</p>
              <span class="ml-auto">{{item.createdAt | date: 'medium'}}</span>
            </div>
            <div class="flex w-full items-center gap-1">
              <p class="text-slate-400">Updated</p>
              <span class="ml-auto">{{item.updatedAt | date: 'medium'}}</span>
            </div>
          </div>
        </td>
        <td>
          <button tooltip title="Edit" class="ui-btn ui-btn-xs ui-btn-icon" (click)="actionOpenEditDataItemModal(item._id)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
            </svg>
          </button>
          <button tooltip title="Delete" class="ui-btn ui-btn-xs ui-btn-icon ui-btn-error" (click)="actionOpenDeleteDataItemModal(item._id)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
            </svg>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  </div>
</ng-template>
<ng-template #DATA_TABLE_PAGINATION>
  <ui-pagination [pages]="interface.pages"
                 [currentPage]="interface.currentPage"
                 [totalPage]="interface.totalPage" (nextPage)="actionNextPage($event)">
  </ui-pagination>
</ng-template>

<div class="bh-get-premium">
  <div class="bh-get-premium-subscription">
    <div class="bh-get-premium-subscription-icon">

    </div>
    <div class="bh-get-premium-subscription-data">
      <div class="bh-get-premium-subscription-cost">
        <p>$19.99</p>
        <small>/{{'components.get-premium.month' | transloco}}</small>
      </div>
      <div class="bh-get-premium-subscription-info">
        <p>{{'components.get-premium.promo-text' | transloco}}</p>
      </div>
    </div>
  </div>
  <div class="ui-form px-2" *ngIf="interface.subscribes">
    <ngx-stripe-card-group [elementsOptions]="interface.payment.stripe.elementsOptions">
      <div class="ui-form-input-group">
        <div class="ui-form-text ui-form-text-xs">{{'components.get-premium.form.display_name' | transloco}}</div>
        <div class="ui-form-input" [class.ui-form-input-readonly]="true">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
          <input name="display_name" type="text" role="spinbutton" [readonly]="true" [value]="interface.user.displayName">
        </div>
        <div class="ui-form-text ui-form-text-xs ui-form-text-error">&nbsp;</div>
      </div>
      <div class="ui-form-input-group">
        <div class="ui-form-text ui-form-text-xs">{{'components.get-premium.form.card_number' | transloco}}</div>
        <div class="ui-form-input" [class.ui-form-input-error]="interface._cache.error_fields.includes('number')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
          </svg>
          <ngx-stripe-card-number [options]="interface.payment.stripe.cardOptions" (change)="actionCardNumberChange($event)"></ngx-stripe-card-number>
        </div>
        <div class="ui-form-text ui-form-text-xs ui-form-text-error">{{interface._cache.error_messages.number}}&nbsp;</div>
      </div>
      <div class="flex flex-row gap-6 w-full">
        <div class="ui-form-input-group w-full">
          <div class="ui-form-text ui-form-text-xs">{{'components.get-premium.form.card_expiry' | transloco}}</div>
          <div class="ui-form-input" [class.ui-form-input-error]="interface._cache.error_fields.includes('expire')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
            </svg>
            <ngx-stripe-card-expiry [options]="interface.payment.stripe.cardOptions" (change)="actionCardExpireChange($event)"></ngx-stripe-card-expiry>
          </div>
          <div class="ui-form-text ui-form-text-xs ui-form-text-error">{{interface._cache.error_messages.expire}}&nbsp;</div>
        </div>
        <div class="ui-form-input-group w-full">
          <div class="ui-form-text ui-form-text-xs">{{'components.get-premium.form.card_cvc' | transloco}}</div>
          <div class="ui-form-input" [class.ui-form-input-error]="interface._cache.error_fields.includes('cvc')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
            </svg>
            <ngx-stripe-card-cvc [options]="interface.payment.stripe.cardOptions" (change)="actionCardCvcChange($event)"></ngx-stripe-card-cvc>
          </div>
          <div class="ui-form-text ui-form-text-xs ui-form-text-error">{{interface._cache.error_messages.cvc}}&nbsp;</div>
        </div>
      </div>
      <button class="ui-btn ui-btn-red mt-4 mx-auto ui-btn-full" [disabled]="checkPaymentFields() || interface.submit" (click)="actionConfirmPayment()">
        <svg *ngIf="!interface.submit" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <svg *ngIf="interface.submit" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-spin" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <span *ngIf="!interface.submit" >{{'components.get-premium.buttons.confirm_payment' | transloco}}</span>
        <span *ngIf="interface.submit" >Checking data...</span>

      </button>
    </ngx-stripe-card-group>
    <span class="inline-flex gap-3 text-center justify-center text-xs">Powered and payment processing by <svg class="text-slate-500" focusable="false" width="33" height="15" role="img" aria-labelledby="stripe-title"><title id="stripe-title">Stripe</title><g fill-rule="evenodd"><path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path></g></svg></span>
  </div>
  <div class="" *ngIf="!interface.subscribes">

  </div>
</div>

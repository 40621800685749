export class LoadExams {
  static readonly type = '[Practice] LoadExams';
}

export class FillPracticeExams {
  static readonly type = '[Practice] FillPracticeExams';
  constructor(public exams: any) {}
}

export class LoadGrades {
  static readonly type = '[Practice] LoadGrades';
}

export class FillPracticeGrades {
  static readonly type = '[Practice] FillPracticeGrades';
  constructor(public grades: any) {}
}

export class CurrentCategories {
  static readonly type = '[Practice] CurrentCategories';
}

export class FillCurrentPracticeCategories {
  static readonly type = '[Practice] FillCurrentPracticeCategories';
  constructor(public categories: any) {}
}

export class LoadVideos {
  static readonly type = '[Practice] LoadVideos';
  constructor(public category_id: string) {}
}

export class FillPracticeVideos {
  static readonly type = '[Practice] FillPracticeVideos';
  constructor(public videos: any) {}
}

export class LoadQuizQuestions {
  static readonly type = '[Practice] LoadQuizQuestions';
  constructor(public category_id: string) {}
}

export class FillQuizQuestions {
  static readonly type = '[Practice] FillQuizQuestions';
  constructor(public questions: any) {}
}
export class ClearQuizQuestions {
  static readonly type = '[Practice] ClearQuizQuestions';
  constructor() {}
}


export class UpdateQuizQuestionsResult {
  static readonly type = '[Practice] UpdateQuizQuestionsResult';
  constructor(public result: any) {}
}
export class ClearQuizQuestionsResult {
  static readonly type = '[Practice] ClearQuizQuestionsResult';
  constructor() {}
}

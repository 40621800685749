import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPageCrumbsComponent} from './ui-page-crumbs.component';
import {UiAssignmentsLabelBuilderModule} from '../ui-assignments-label-builder/ui-assignments-label-builder.module';

@NgModule({
  declarations: [UiPageCrumbsComponent],
  exports: [UiPageCrumbsComponent],
  imports: [CommonModule, UiAssignmentsLabelBuilderModule],
})
export class UiPageCrumbsModule {}

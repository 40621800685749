<div class="ui-assignment" [class.ui-assignment-archive]="!interface.assignment.options.isActive">
  <div class="ui-assignment-header">
    <div class="ui-class-assignment-header-title">
      <h4 (click)="actionOpenAssignmentPage()">{{interface.assignment.title}}</h4>
      <div class="ui-class-assignment-header-title-action">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" tooltip description="Change assignment" (click)="actionOpenAssignmentMenu($event)">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
        </svg>
        <ul class="ui-class-assignment-header-title-action-menu">
          <li (click)="actionAssignmentArchive(interface.assignment, $event)">
            <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
            </svg>
            <ng-container *ngIf="!interface.assignment.options.isActive">Unarchived</ng-container>
            <ng-container *ngIf="interface.assignment.options.isActive">Archive</ng-container>

          </li>
          <li (click)="actionAssignmentDelete(interface.assignment, $event)" class="ui-class-assignment-header-title-action-menu-delete">
            <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
            Delete
          </li>
        </ul>
      </div>
    </div>
    <div class="ui-assignment-class" [routerLink]="'../classes/'+interface.assignment.class._id">{{interface.assignment.class?.title}}</div>
    <div class="ui-assignment-date">You edited on {{interface.assignment.dates.dueDate | date}}</div>
  </div>
  <div class="ui-assignment-body">
    <div class="ui-assignment-info mb-4" [class.ui-assignment-info-error]="(interface.assignment?.dates?.dueDate | agoDate) < 0" [class.ui-assignment-info-attention]="((interface.assignment?.dates?.dueDate | agoDate) >= 0 && (interface.assignment?.dates?.dueDate | agoDate) < 1)" [class.ui-assignment-info-success]="interface.assignment?.dates?.dueDate === null">
      <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <label>
        <ng-container>{{interface.assignment?.dates?.dueDate === null ? 'without a deadline' : interface.assignment?.dates?.dueDate | agoDate:true}}</ng-container>
        <ng-container *ngIf="(interface.assignment?.dates?.dueDate | agoDate) < 0"> days past due</ng-container>
        <ng-container *ngIf="interface.assignment?.dates?.dueDate !== null && (interface.assignment?.dates?.dueDate | agoDate) >= 0"> days left</ng-container>
      </label>
    </div>
    <div class="ui-assignment-info">
      <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>
      <p>Due:</p>
      <span>{{interface.assignment?.dates?.dueDate | date}}</span>
    </div>
    <div class="ui-assignment-info">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.795 21.0001H5C4.46957 21.0001 3.96086 20.7893 3.58579 20.4143C3.21071 20.0392 3 19.5305 3 19.0001V7.00006C3 6.46963 3.21071 5.96092 3.58579 5.58585C3.96086 5.21077 4.46957 5.00006 5 5.00006H17C17.5304 5.00006 18.0391 5.21077 18.4142 5.58585C18.7893 5.96092 19 6.46963 19 7.00006V11.0001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18 22.0001C20.2091 22.0001 22 20.2092 22 18.0001C22 15.7909 20.2091 14.0001 18 14.0001C15.7909 14.0001 14 15.7909 14 18.0001C14 20.2092 15.7909 22.0001 18 22.0001Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15 3.00006V7.00006" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7 3.00006V7.00006" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3 11.0001H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18 16.496V18L19 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <p>Scheduled:</p>
      <span>{{interface.assignment?.dates?.scheduleDate | date}}</span>
    </div>
    <div class="ui-assignment-info">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
      </svg>
      <p>Number of materials:</p>
      <span><strong>{{interface.assignment?.materials?.length}}</strong></span>
    </div>
    <div class="ui-assignment-info">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
      </svg>
      <p>Total progress:</p>
      <span><strong>{{interface.assignment?.countTotalProgress || 0}}</strong>  %</span>
    </div>
  </div>
  <div class="ui-assignment-footer">
    <div class="ui-assignment-cup">
      <img src="assets/images/shapes/trophy.png" loading="lazy" alt="">
      <strong>{{interface.assignment?.countDoneMaterial || 0}} / {{interface.assignment?.materials?.length}}</strong>
      <span>Done</span>
    </div>
    <div class="ui-assignment-students">
      <ng-container *ngFor="let student of interface.assignment?.students | slice: 0:3; let s=index">
        <div class="ui-assignment-student">
          <img src="{{student.avatar}}" alt="{{student.displayName}}" tooltip description="{{student.displayName}}"/>
        </div>
      </ng-container>
    </div>
  </div>
</div>

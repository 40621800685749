import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngxs/store';
import {LoadControlQuestions} from '../../_stores/control/_actions/control.actions';
import {ControlState} from '../../_stores/control/_state/control.state';
import {ModalOpenAction, ModalOpenActionWithID} from '../../_stores/modal/_actions/modal.actions';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {API} from '../../_stores/api';
import {QuestionModel} from '../../_models/question.model';
import {CategoriesBreadCrumbModel} from '../../_models/categories-bread-crumb-model';

@Component({
  selector: 'ui-control-questions',
  templateUrl: './ui-control-questions.component.html',
  styleUrls: ['./ui-control-questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlQuestionsComponent implements OnInit {
  interface: any = {
    bread_crumbs: [],
    category_parent: '',
    pages: [],
    currentPage: 0,
    data: [],
    perPage: 0,
    totalData: 0,
    totalPage: 0,
  };

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private route: ActivatedRoute,
  ) {
    this.store.dispatch(new LoadControlQuestions(1));
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    this.interface.category_parent = this.route.snapshot.paramMap.get('category');
    if (this.interface.category_parent && this.interface.category_parent.length > 0) {
      this.http.get<QuestionModel[]>(API.url('question/category/' + this.interface.category_parent)).subscribe({
        next: (_data) => {
          if (_data && _data.length > 0) {
            this.interface.data = _data;
            this.cdr.detectChanges();
          }
        },
      });
      this.http
        .get<CategoriesBreadCrumbModel[]>(API.url('categories/bread_crumbs/' + this.interface.category_parent))
        .subscribe({
          next: (_bread_crumbs) => {
            if (_bread_crumbs && _bread_crumbs.length > 0) {
              this.interface = { ...this.interface, bread_crumbs: _bread_crumbs };
              this.cdr.detectChanges();
            }
          },
        });
    } else {
      this.store.select(ControlState.selectQuestions).subscribe((_data) => {
        this.interface.data = _data.data;
        this.interface = {
          ...this.interface,
          currentPage: _data.currentPage,
          totalPage: _data.totalPage,
          totalData: _data.totalData,
          pages: this.getPages(this.interface.currentPage, this.interface.totalPage),
        };
        this.cdr.detectChanges();
      });
    }
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(new ModalOpenAction('control_question_add'));
  }

  actionOpenEditDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_question_edit', _id));
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_question_delete', _id));
  }

  actionSearchQuestions(event) {
    if (event.target.value && event.target.value.length > 0) {
      this.interface.data = this.interface.data.filter((item) => {
        return item.title.toLowerCase().includes(event.target.value.toLowerCase());
      });
    } else {
      this.initData();
    }
  }

  actionNextPage(event) {
    this.interface = {
      ...this.interface,
      currentPage: event,
    };
    this.interface.currentPage = event;
    this.store.dispatch(new LoadControlQuestions(this.interface.currentPage));
    this.cdr.detectChanges();
  }

  private getPages(currentPage: number, totalPage: number) {
    if (currentPage <= 10) {
      if (totalPage < 10) {
        return [...Array(totalPage).keys()].map((x) => ++x);
      }
      return [...Array(10).keys()].map((x) => ++x);
    }
    if (currentPage > 10) {
      if (currentPage >= totalPage - 2) {
        return [totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
      } else {
        return [currentPage - 1, currentPage, currentPage + 1];
      }
    }
  }

  getCategoryByParent(_id: string) {}
}

<div class="ui-control-school-edit-container">
  <ng-container *ngIf="!interface.school"><ui-no-data type="loading" text="Loading data"></ui-no-data></ng-container>
  <ng-container *ngIf="interface.school">
    <div class="ui-form ui-control-school-edit-form">
      <div class="ui-form-input-group">
        <div class="ui-form-text ui-form-text-xs">Name</div>
        <div class="ui-form-input" [class.ui-form-input-error]="interface._cache_error.fields.includes('schoolName')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5"
               class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
          </svg>
          <input id="schoolName" name="schoolName" type="text" [value]="interface._cache.schoolName || ''" (keyup)="actionEnterSchoolName($event)" placeholder="Enter school name">
        </div>
      </div>
      <div class="ui-form-divider">
        <div class="ui-form-divider-line" aria-hidden="true">
          <span></span>
        </div>
        <div class="ui-form-divider-text">
          <span>Location</span>
        </div>
      </div>
      <div class="ui-form-input-group ui-form-input-group-row justify-between">
        <div class="ui-form-input-group w-full">
          <div class="ui-form-text ui-form-text-xs">Zip</div>
          <div class="ui-form-input" [class.ui-form-input-error]="interface._cache_error.fields.includes('zip')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
            </svg>
            <input id="zip" name="zip" type="text" [value]="interface._cache.zip || ''" (keyup)="actionEnterZip($event)" required placeholder="Enter school zip">
          </div>
        </div>
        <div class="ui-form-input-group w-full">
          <div class="ui-form-text ui-form-text-xs">State</div>
          <div class="ui-form-input" [class.ui-form-input-error]="interface._cache_error.fields.includes('state')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
            </svg>
            <input id="state" name="state" type="text" [value]="interface._cache.state || ''" (keyup)="actionEnterState($event)" placeholder="Enter school state">
          </div>
        </div>
      </div>
      <div class="ui-form-input-group">
        <div class="ui-form-text ui-form-text-xs">City</div>
        <div class="ui-form-input" [class.ui-form-input-error]="interface._cache_error.fields.includes('city')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
          </svg>
          <input id="city" name="city" type="text" [value]="interface._cache.city || ''" (keyup)="actionEnterCity($event)" placeholder="Enter school city">
        </div>
      </div>
      <div class="ui-form-input-group">
        <div class="ui-form-text ui-form-text-xs">Address</div>
        <div class="ui-form-input" [class.ui-form-input-error]="interface._cache_error.fields.includes('address')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
          </svg>
          <input id="address" name="address" type="text" [value]="interface._cache.address || ''" (keyup)="actionEnterAddress($event)" placeholder="Enter school address">
        </div>
      </div>
      <div class="ui-form-divider">
        <div class="ui-form-divider-line" aria-hidden="true">
          <span></span>
        </div>
        <div class="ui-form-divider-text">
          <span>Properties</span>
        </div>
      </div>
      <div class="ui-form-input-group ui-form-input-group-row justify-between">
        <div class="ui-form-input-group w-full">
          <div class="ui-form-text ui-form-text-xs">Level</div>
          <div class="ui-form-input" [class.ui-form-input-error]="interface._cache_error.fields.includes('schoolLevel')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <input id="schoolLevel" name="schoolLevel" type="text" [value]="interface._cache.schoolLevel || ''" (keyup)="actionEnterSchoolLevel($event)" placeholder="Enter school level">
          </div>
        </div>
        <div class="ui-form-input-group w-full">
          <div class="ui-form-text ui-form-text-xs">NCESID (optional)</div>
          <div class="ui-form-input" [class.ui-form-input-error]="interface._cache_error.fields.includes('ncesId')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
            </svg>
            <input id="ncesId" name="ncesId" type="number" [value]="interface._cache.ncesId || ''" (keyup)="actionEnterNcesId($event)" placeholder="Enter school nces Id number">
          </div>
        </div>
      </div>
      <div class="ui-form-input-group">
        <div class="ui-form-text ui-form-text-xs">District</div>
        <div class="ui-form-input" [class.ui-form-input-error]="interface._cache_error.fields.includes('schoolDistrict')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
          </svg>
          <input id="schoolDistrict" name="schoolDistrict" type="text" [value]="interface._cache.schoolDistrict || ''" (keyup)="actionEnterSchoolDistrict($event)" placeholder="Enter school district">
        </div>
      </div>
    </div>
    <div class="ui-control-school-edit-action">
      <button class="ui-data-school-edit-action-no" (click)="actionClose()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
        </svg>
        <span>Cancel</span>
      </button>
      <button class="ui-btn ui-control-school-edit" (click)="actionEditSchool()"
              [disabled]="interface._cache_error.fields.length !== 0 ">
        <svg *ngIf="!interface.loading" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
             viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        <svg *ngIf="interface.loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <span>{{'buttons.confirm' | transloco}}</span>
      </button>
    </div>
  </ng-container>
</div>

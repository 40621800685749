import {Injectable} from '@angular/core';
import {CanLoad, Route, Router, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthState} from "../_stores/auth/_state/auth.state";

@Injectable({
  providedIn: 'root',
})
export class LoadPublicGuard implements CanLoad {

  constructor(private store: Store, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const authed = this.store.selectSnapshot(AuthState.selectAuthStatus);
    if(authed) {
      this.router.navigate(['/']).then();
    }
    return !authed;
  }

}

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UiAssignmentsModel} from '../../_models/ui-assignments.model';
import {Store} from '@ngxs/store';
import {AssignmentsState} from '../../_stores/asignments/_state/assignments.state';

@Component({
  selector: 'ui-assignments',
  templateUrl: './ui-assignments.component.html',
  styleUrls: ['./ui-assignments.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiAssignmentsComponent implements OnInit {
  interface: UiAssignmentsModel = {
    assignments: [],
    loading: false,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initAssignments();
  }

  initAssignments(): void {
    this.store.select(AssignmentsState.selectAssignmentList).subscribe((_assignments) => {
      this.interface.assignments = _assignments.filter((_assignments) => _assignments.options.isActive);
      this.interface.loading = false;
      this.cdr.detectChanges();
    });
  }
}

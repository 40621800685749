<div class="ui-block ui-block-no-margin ui-block-3 ui-block-white">
  <div class="ui-block-inner ui-block-inner-col my-6">
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading"></ui-no-data></ng-container>
    <ng-container *ngIf="!interface.loading">
      <div class="ui-stat-user">
        <div class="flex items-center gap-4">
          <div class="ui-stat-user-avatar">
            <img src="{{interface.user.avatar}}" alt="" loading="lazy">
          </div>
          <div class="ui-stat-user-info">
            <div class="ui-stat-user-info-username">{{interface.user.displayName}}</div>
            <div class="ui-stat-user-info-userrole">{{interface.user.role.name}}</div>
            <div class="ui-stat-user-info-userain">{{interface.user.ain}}</div>
          </div>
        </div>
        <div class="ui-stat-user-details">
          <div class="ui-stat-user-detail">
            <p>First name</p>
            <span>{{interface.user.firstName}}</span>
          </div>
          <div class="ui-stat-user-detail">
            <p>Last name</p>
            <span>{{interface.user.lastName}}</span>
          </div>
          <div class="ui-stat-user-detail">
            <p>Email</p>
            <span>
              <ng-container *ngIf="interface.user.role._id == '62ebc2e38e33b7bc52c853c6'">----</ng-container>
              <ng-container *ngIf="interface.user.role._id != '62ebc2e38e33b7bc52c853c6'">{{interface.user.email}}</ng-container>
            </span>
          </div>
          <div class="ui-stat-user-detail">
            <p>Language</p>
            <span>{{interface.user.language}}</span>
          </div>
          <div class="ui-stat-user-detail">
            <p>Practice</p>
            <span>{{interface.user?.practice?.title}}</span>
          </div>

          <div class="ui-stat-user-detail mt-4">
            <p>Created</p>
            <span>{{interface.user.createdAt | date: 'medium'}}</span>
          </div>
          <div class="ui-stat-user-detail">
            <p>Updated</p>
            <span>{{interface.user.updatedAt | date: 'medium'}}</span>
          </div>
        </div>
        <div class="ui-stat-user-school">
          <div class="ui-stat-user-school-icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
            </svg>
          </div>
          <div class="ui-stat-user-school-data">
            <p [routerLink]="'/control/schools/'+interface.user?.school?._id+'/detail'">{{interface.user?.school?.schoolName}}</p>
            <span>{{interface.user?.school?.address}}</span>
          </div>
        </div>
        <div class="ui-stat-user-school">
          <div class="ui-stat-user-school-icon !bg-slate-100">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 !text-slate-500">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
            </svg>
          </div>
          <div class="ui-stat-user-school-data">
            <p class="">License</p>
            <span *ngIf="findActiveSubscription()">Active</span>
            <span *ngIf="!findActiveSubscription()">Deactive</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-9 ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Trackers</h2>
      <p>&nbsp;</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading"></ui-no-data></ng-container>
    <ng-container *ngIf="!interface.loading">
      <apx-chart
        [colors]="interface.charts.tracker.colors"
        [series]="interface.charts.tracker.series"
        [chart]="interface.charts.tracker.chart"
        [yaxis]="interface.charts.tracker.yaxis"
        [xaxis]="interface.charts.tracker.xaxis"
        [stroke]="interface.charts.tracker.stroke"
        [tooltip]="interface.charts.tracker.tooltip"
        [legend]="interface.charts.tracker.legend"
        [dataLabels]="interface.charts.tracker.dataLabels"
        [plotOptions]="interface.charts.tracker.plotOptions"
      ></apx-chart>
    </ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-3 ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Subscriptions</h2>
      <p>User subscriptions list</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading"></ui-no-data></ng-container>
    <ng-container *ngIf="!interface.loading">
      <div class="ui-stat-activities">
        <ng-container *ngFor="let _subs of interface.user.subscribe">
          <div class="ui-stat-activity">
            <div class="ui-stat-activity-icon ui-stat-activity-icon-{{_subs.status}}"  [ngSwitch]="_subs.status">
              <svg *ngSwitchCase="'active'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              <svg *ngSwitchCase="'canceled'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
              </svg>
              <svg *ngSwitchDefault xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
              </svg>
            </div>
            <div class="ui-stat-activity-data">
              <span>{{_subs.created*1000 | date: 'medium'}}</span>
              <p>
                <b>Status:</b>
                {{_subs.status}}
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="interface.user?.subscribe.length == 0"><ui-no-data text="No subscriptions..."></ui-no-data></ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-2 ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Classes</h2>
      <p>
        <ng-container *ngIf="interface.user?.role._id == '62ebc2e38e33b7bc52c853c6'">Consists of classes</ng-container>
        <ng-container *ngIf="interface.user?.role._id != '62ebc2e38e33b7bc52c853c6'">Creator of the class</ng-container>
      </p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading"></ui-no-data></ng-container>
    <ng-container *ngIf="!interface.loading">
      <div class="ui-stat-classes">
        <ng-container *ngFor="let _class of interface.user.classes">
          <div class="ui-stat-class">
            <div class="ui-stat-class-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
              </svg>
            </div>
            <div class="ui-stat-class-text">
              <h3>{{_class.title}}</h3>
              <p>
                <ng-container *ngIf="_class.teacher == interface.user._id">&nbsp;{{interface.user.firstName}} {{interface.user.lastName}} </ng-container>
                <ng-container *ngIf="_class.teacher != interface.user._id">&nbsp;{{_class.teacher.firstName}} {{_class.teacher.lastName}}</ng-container>
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="interface.user?.classes.length == 0"><ui-no-data text="No classes..."></ui-no-data></ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-3 ui-block-white self-baseline !hidden">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Students</h2>
      <p>From classes</p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col my-4">
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading"></ui-no-data></ng-container>
    <ng-container *ngIf="!interface.loading">
      <div class="ui-stat-classes">
        <ng-container *ngFor="let _class of interface.user?.classes">

        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="interface.user?.classes.length == 0"><ui-no-data text="No classes..."></ui-no-data></ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>
<div class="ui-block ui-block-no-margin ui-block-3 ui-block-white ">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>
        <ng-container *ngIf="interface.user?.role._id == '62ebc2e38e33b7bc52c853c6'">Homeworks</ng-container>
        <ng-container *ngIf="interface.user?.role._id != '62ebc2e38e33b7bc52c853c6'">Assignments</ng-container>
      </h2>
      <p>
        <ng-container *ngIf="interface.user?.role._id == '62ebc2e38e33b7bc52c853c6'">Appointed</ng-container>
        <ng-container *ngIf="interface.user?.role._id != '62ebc2e38e33b7bc52c853c6'">Created</ng-container>
      </p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col">
    <ng-container *ngIf="interface.loading"><ui-no-data type="loading"></ui-no-data></ng-container>
    <ng-container *ngIf="!interface.loading">
      <ng-container *ngIf="interface.user.role._id == '62ebc2e38e33b7bc52c853c6'">
        <div class="ui-stat-homeworks">
          <ng-container *ngFor="let homework of interface.user.homeworks">
            <div class="ui-stat-homework">
              <div class="ui-stat-homework-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                </svg>
              </div>
              <div class="ui-stat-homework-text">
                <h3>{{homework.title}}</h3>
                <div>
                  <span><b>Class: </b>{{homework.class.title}} </span>
                  <span><b>Created: </b>{{homework.createdAt | date: 'longDate'}}</span>
                  <span><b>Done: </b>{{homework.options.isDone}}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="interface.user.homeworks.length == 0"><ui-no-data text="No homework..."></ui-no-data></ng-container>
      </ng-container>
      <ng-container *ngIf="interface.user.role._id != '62ebc2e38e33b7bc52c853c6'">
        <div class="ui-stat-assignments">
          <ng-container *ngFor="let assignment of interface.user.assignments">
            <div class="ui-stat-assignment">
              <div class="ui-stat-assignment-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                </svg>
              </div>
              <div class="ui-stat-assignment-text">
                <h3>{{assignment.title}}</h3>
                <div>
                  <span><b>Class: </b>{{assignment.class.title}} </span>
                  <span><b>Created: </b>{{assignment.createdAt | date: 'longDate'}}</span>
                  <span><b>Students: </b>{{assignment.students.length}}</span>
                  <span><b>Done: </b>{{assignment.options.isDone}}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="interface.user.assignments.length == 0"><ui-no-data text="No assignments..."></ui-no-data></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>


<div class="ui-block ui-block-no-margin ui-block-4 ui-block-white">
  <div class="ui-block-header">
    <div class="ui-block-header-title">
      <h2>Activity</h2>
      <p>Total events <b>{{interface.user?.events.length}}</b></p>
    </div>
    <div class="ui-block-header-action ui-block-header-action-hidden-mobile"></div>
  </div>
  <div class="ui-block-inner ui-block-inner-col">
    <div *ngIf="!interface.loading" class="ui-stat-activities">
      <ng-container *ngFor="let activity of interface.user.events | sort: 'desc': 'createdAt'; let a=index">
        <div class="ui-stat-activity">
          <div class="ui-stat-activity-icon ui-stat-activity-icon-{{activity.type}}" [ngSwitch]="activity.type">
            <svg *ngSwitchCase="'WorksheetDownloaded'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
            </svg>
            <svg *ngSwitchCase="'WorksheetPrinted'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
            </svg>
            <svg *ngSwitchCase="'BookOpened'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
            </svg>
            <svg *ngSwitchCase="'PracticeTrackerStart'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
            </svg>
            <svg *ngSwitchCase="'PracticeTrackerEnd'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
            </svg>
            <svg *ngSwitchCase="'InviteToSchoolLinkCopied'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
            </svg>
            <svg *ngSwitchCase="'OpenModalChangeLanguage'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
            </svg>
            <svg *ngSwitchCase="'PrepTrackerStart'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
            </svg>
            <svg *ngSwitchCase="'PrepTrackerEnd'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
            </svg>
            <svg *ngSwitchCase="'BookPrinted'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
            </svg>
            <svg *ngSwitchCase="'ClassCreated'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>
            <svg *ngSwitchCase="'AssignmentCreated'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>

            <svg *ngSwitchDefault xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
            </svg>

          </div>
          <div class="ui-stat-activity-data">
            <span>{{activity.createdAt | date: 'medium'}}</span>
            <p>
              <b>{{interface.user.displayName}}</b>
              <ng-container [ngSwitch]="activity.type">
                <ng-container *ngSwitchCase="'WorksheetDownloaded'">Download worksheet</ng-container>
                <ng-container *ngSwitchCase="'WorksheetPrinted'">Printed worksheet</ng-container>
                <ng-container *ngSwitchCase="'BookOpened'">Open interactive book</ng-container>
                <ng-container *ngSwitchCase="'PracticeTrackerStart'">
                 <!-- <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Video'">Started watching video</ng-container>
                  <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Question'">Started doing practice quiz</ng-container>
                  <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Worksheet'">Opened worksheet</ng-container>-->
                </ng-container>
                <ng-container *ngSwitchCase="'PracticeTrackerEnd'">
                  <!--<ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Video'">Complete watching video</ng-container>
                  <ng-container *ngIf="activity.resourceData[0]?.typeMaterial == 'Question'">Complete practice quiz</ng-container>-->
                </ng-container>
                <ng-container *ngSwitchCase="'InviteToSchoolLinkCopied'">Copied invite link to his school</ng-container>
                <ng-container *ngSwitchCase="'OpenModalChangeLanguage'">Opened changes language for system video explanation from dashboard</ng-container>
                <ng-container *ngSwitchCase="'PrepTrackerStart'">Started doing the task from homework:</ng-container>
                <ng-container *ngSwitchCase="'PrepTrackerEnd'">Complete doing the task from homework:</ng-container>
                <ng-container *ngSwitchCase="'ClassCreated'">Created Class</ng-container>
                <ng-container *ngSwitchCase="'AssignmentCreated'">Created Assignment</ng-container>
                <ng-container *ngSwitchCase="'BookPrinted'">Printing {{activity.resource[0]?.pages_count}} pages from </ng-container>
                <ng-container *ngSwitchDefault>{{activity.type}}</ng-container>
              </ng-container>
              <ng-container [ngSwitch]="activity.type">
<!--                <ng-container *ngSwitchCase="'WorksheetPrinted'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                <ng-container *ngSwitchCase="'WorksheetDownloaded'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                <ng-container *ngSwitchCase="'BookOpened'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>
                <ng-container *ngSwitchCase="'PracticeTrackerStart'"><strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                <ng-container *ngSwitchCase="'PracticeTrackerEnd'"><strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                <ng-container *ngSwitchCase="'PrepTrackerStart'"><strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                <ng-container *ngSwitchCase="'PrepTrackerEnd'"><strong>{{activity.resourceData[0]?.belongDocument?.title}}</strong></ng-container>
                <ng-container *ngSwitchCase="'BookPrinted'"><strong>{{activity.resourceData[0]?.title}}</strong></ng-container>-->
              </ng-container>
            </p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="interface.user.events.length == 0">
        <ui-no-data text="No activity"></ui-no-data>
      </ng-container>
    </div>
    <ui-no-data *ngIf="interface.loading" type="loading" text="Loading data" class="h-[204px] mt-4"></ui-no-data>
  </div>
  <div class="ui-block-footer">
    <div class="ui-block-footer-action ui-block-footer-action-hidden-tablet"></div>
  </div>
</div>

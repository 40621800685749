import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiControlCategoryDeleteComponent} from './ui-control-category-delete.component';

@NgModule({
  declarations: [UiControlCategoryDeleteComponent],
  exports: [UiControlCategoryDeleteComponent],
  imports: [CommonModule],
})
export class UiControlCategoryDeleteModule {}
